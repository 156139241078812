import PropTypes from "prop-types";
import React from "react";

import BasePlayerEmbed from "./players/BasePlayerEmbed";
import QQPlayerEmbed from "./players/QQPlayerEmbed";
import VidyardPlayerEmbed from "./players/VidyardPlayerEmbed";
import VimeoPlayerEmbed from "./players/VimeoPlayerEmbed";
import YouTubePlayerEmbed from "./players/YouTubePlayerEmbed";
import KalturaPlayerEmbed from "./players/KalturaPlayerEmbed";

const VideoPlayerEmbed = ({ kind, ...frameProps }) => {
  let frame;
  const { videoId } = frameProps;

  switch (kind) {
    case "dam":
      frame = <BasePlayerEmbed src={videoId} {...frameProps} />;
      break;
    case "qq":
      frame = <QQPlayerEmbed {...frameProps} />;
      break;
    case "vidyard":
      frame = <VidyardPlayerEmbed {...frameProps} />;
      break;
    case "vimeo":
      frame = <VimeoPlayerEmbed {...frameProps} />;
      break;
    case "youtube":
      frame = <YouTubePlayerEmbed {...frameProps} />;
      break;
    case "kaltura":
      frame = <KalturaPlayerEmbed {...frameProps} />;
      break;
    default:
      frame = null;
  }

  return (
    <div className={frameProps.getClassName({ descendantName: "player" })}>
      {frame}
    </div>
  );
};

VideoPlayerEmbed.propTypes = {
  getClassName: PropTypes.func.isRequired,
  kind: PropTypes.oneOf(["dam", "qq", "vidyard", "vimeo", "youtube", "kaltura"])
    .isRequired,
  videoId: PropTypes.string
};

export default VideoPlayerEmbed;
