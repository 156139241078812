import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import DateTime from "./index";
import DateTimeREADME from "../README.md";
import supportedLanguages from "./supportedLanguages";
import "./DateTime.css";

const stories = storiesOf("Components/DateTime", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DateTimeREADME));

const recentTime = new Date().toISOString();

stories
  .add("Default", () => (
    <DateTime
      dateTime={text("Date and time", "2016-03-02 13:57")}
      showTime={boolean("Show time?", DateTime.defaultProps.showTime)}
      format={text("Custom format", DateTime.defaultProps.format)}
      lang={select(
        "Language",
        Object.keys(supportedLanguages),
        DateTime.defaultProps.lang
      )}
    />
  ))
  .add("Relative time", () => (
    <DateTime
      dateTime={text("Date and time", recentTime)}
      lang={select(
        "Language",
        Object.keys(supportedLanguages),
        DateTime.defaultProps.lang
      )}
      relative
    />
  ))
  .add("Arabic", () => (
    <DateTime dateTime={text("Date and time", recentTime)} lang="arSA" />
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <DateTime dateTime={text("Date and time", recentTime)} showTime={true} />
    </StaticRenderer>
  ));
