import PropTypes from "prop-types";
import React from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const Panel = ({ children }) => {
  const getClassName = getClassNameFactory(Panel.displayName);

  return <div className={getClassName()}>{children}</div>;
};

Panel.displayName = "Panel";

Panel.propTypes = {
  /**
   * Components and markup elements to render in Panel
   */
  children: PropTypes.node
};

export default Panel;
