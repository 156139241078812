import React from "react";
import TableWithFilters from "./TableWithFilters";

// eslint-disable-next-line require-await
const rehydrator = async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    data: readData("table-data"),
    filtersHideSelectedOptions: readData("filters-hide-selected-options"),
    filtersNoOptionsMessage: readData("filters-no-options-message"),
    filtersPlaceholder: readData("filters-placeholder"),
    infoText: readData("info-text"),
    noResultsMessage: readData("no-results-message"),
    pagesNavigableOnSide: readData("pages-navigable-on-side"),
    paginationResultSummaryText: readData("pagination-result-summary-text"),
    rowsPerPage: readData("rows-per-page"),
    subtitleHeadingRank: readData("subtitle-heading-rank"),
    title: readData("title"),
    titleHeadingRank: readData("title-heading-rank"),
    isMulti: readData("is-multi"),
    backgroundColor: readData("background-color"),
    infoLink: readData("info-link"),
    languageSelectedOption: readData("language-selected-option"),
    timezoneSelectedOption: readData("timezone-selected-option"),
    timezones: readData("timezones"),
    filterHeader: readData("filters-mapping-with-table-headers"),
    isOverflow: readData("is-overflow")
  };

  return <TableWithFilters {...props} />;
};

export default rehydrator;
