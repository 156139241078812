import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import MenuWithContext from "./Menu";
import kebabCase from "lodash.kebabcase";

const rehydrator = async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("SiteHeaderMenu");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "children" })}`
  );
  const findAnchor = anchorDomNode => anchorDomNode.querySelector("a");
  const transformDatasetToString = datasets => {
    Object.fromEntries = arr =>
      Object.assign(
        {},
        ...Array.from(arr, ([key, value]) => ({ [key]: value }))
      );

    return Object.fromEntries(
      Object.entries(datasets).map(([key, value]) => [
        `data-${kebabCase(key)}`,
        value
      ])
    );
  };

  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const rehydratedProps = {
    title: domNode.getAttribute("data-title"),
    datasets: JSON.stringify(
      transformDatasetToString(findAnchor(domNode).dataset)
    )
  };
  const href = domNode.getAttribute("data-href");
  const fallbackHref = domNode.getAttribute("data-fallback-href");

  if (href && href.length) {
    rehydratedProps.href = href;
  }

  if (fallbackHref && fallbackHref.length) {
    rehydratedProps.fallbackHref = fallbackHref;
  }

  return (
    <MenuWithContext {...rehydratedProps}>{reactifiedChildren}</MenuWithContext>
  );
};

export default rehydrator;
