import React from "react";
import Block from "@emcm-ui/component-block";
import Grid from "@emcm-ui/component-grid";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const GridSystem = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section>
          <Grid variant="even-12">
            {/* eslint-disable-next-line no-magic-numbers*/}
            {[...Array(12).keys()].map(i => (
              <Grid.Item key={i}>
                <Block color="blue" height={400} />
              </Grid.Item>
            ))}
          </Grid>
        </Section>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default GridSystem;
