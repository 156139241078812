import React from "react";
import TwitterCardTimeline from "./TwitterCardTimeline";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    isRehydrating: true,
    timelineDataSource: readData("timeline-data-source"),
    timelineOptions: readData("timeline-options")
  };

  return <TwitterCardTimeline {...props} />;
};
