import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Duration from "./index";
import "./Duration.css";
import DurationREADME from "../README.md";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/Duration", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DurationREADME));

stories
  .add("Default", () => (
    <Duration
      duration={text("Duration", "P1M")}
      icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
    >
      {text("Text", "1 min")}
    </Duration>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Duration
        duration={text("Duration", "P1M")}
        icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
      >
        {text("Text", "مرحبا بالعالم")}
      </Duration>
    </Language>
  ));
