import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SectionStack = ({ children }) => {
  const getClassName = getClassNameFactory("SiteHeaderSectionStack");

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "children" })}>
        {children}
      </div>
    </div>
  );
};

SectionStack.propTypes = {
  /**
   * The contents of the section. These should be `SiteHeader.Section`s.
   */
  children: PropTypes.node
};

SectionStack.displayName = "SiteHeader.SectionStack";

export default SectionStack;
