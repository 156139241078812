import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import className from "classnames";

const Heading = ({ align, children, rank, boldOrange, type, id }) => {
  const getClassName = getClassNameFactory(Heading.displayName);

  let HeadingTag = "span";

  if (Heading.ranks.indexOf(rank) >= 0) {
    HeadingTag = `h${rank}`;
  }

  return (
    <HeadingTag
      className={getClassName({
        modifiers: className({
          [type]: true,
          [align]: align !== "left",
          boldOrange
        }),
        utilities: type === "small-caps" && "typographySmallCaps"
      })}
      id={id}
    >
      {children}
    </HeadingTag>
  );
};

Heading.alignments = ["center", "left", "right"];
Heading.ranks = ["1", "2", "3", "4", "5", "6"];
Heading.types = ["small-caps", "xxs", "xs", "s", "m", "l", "xl", "xxl"];

Heading.displayName = "Heading";

/* eslint-disable max-len  */
Heading.propTypes = {
  /**
   * Heading children.
   */
  align: PropTypes.oneOf(Heading.alignments),
  /**
   * Heading children.
   */
  children: PropTypes.node,
  /**
   * Heading rank. 1 will render `<h1 />`, and so on. If null, will render a `<div />`.
   */
  rank: PropTypes.oneOf(Heading.ranks),
  /**
   * Set color of inline `<b />` and `<strong />` elements to dark orange.
   */
  boldOrange: PropTypes.bool,
  /**
   * Style of the heading.
   */
  type: PropTypes.oneOf(Heading.types).isRequired,
  /**
   * Primarily used to group accessible elements Example. aria-labelledby.
   */
  id: PropTypes.string
};
/* eslint-enable max-len */

Heading.defaultProps = {
  align: "left"
};

export default Heading;
