import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "./svg";

class Icon extends Component {
  static displayName = "Icon";

  static sizeOptions = ["xs", "s", "m", "l", "xl"];

  static propTypes = {
    /**
     * Path of svg icon
     */
    path: PropTypes.string.isRequired,
    /**
     * Alt for image
     */
    alt: PropTypes.string,
    /**
     * Size for image
     */
    size: PropTypes.oneOf(Icon.sizeOptions)
  };
  getClassName = getClassNameFactory(Icon.displayName);
  render() {
    const { path, alt, size } = this.props;

    return (
      <img
        className={this.getClassName({ modifiers: size })}
        alt={alt || ""}
        src={path}
      />
    );
  }
}

Icon.SVGIcon = SVGIcon;
export default Icon;
