import React from "react";
import Button from "@emcm-ui/component-button";
import Group from "@emcm-ui/component-group";
import Heading from "@emcm-ui/component-heading";
import Image from "@emcm-ui/component-image";
import Icon from "@emcm-ui/component-icon";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import { getSocialIcon } from "./icons";
import Link from "@emcm-ui/component-link";
import RichText from "@emcm-ui/component-rich-text";
import TitleTextAsset from "@emcm-ui/component-title-text-asset";
import TitleTextAssetReadme from "../README.md";
import Standfirst from "@emcm-ui/component-standfirst";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, text, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import VideoPlayer from "@emcm-ui/component-video-player";
import Section from "@emcm-ui/component-section";
import "./TitleTextAsset.css";
import "@emcm-ui/component-block/lib/Block.css";

const horizontalImages = [
  "test-images/titleTextAsset/horizontal-small.png",
  "test-images/titleTextAsset/horizontal-medium.png",
  "test-images/titleTextAsset/horizontal-large.png"
];

const verticalImages = [
  "test-images/titleTextAsset/vertical-small.png",
  "test-images/titleTextAsset/vertical-medium.png",
  "test-images/titleTextAsset/vertical-large.png"
];

const CTAs = {
  Button: (
    <Group>
      <Group.Item>
        <Button color="primary" kind="button" href="https://example.com">
          Button
        </Button>
      </Group.Item>
      <Group.Item>
        <Button color="secondary" kind="button" href="https://example.com">
          Button
        </Button>
      </Group.Item>
    </Group>
  ),
  "Primary Link": (
    <Link
      href="https://example.com"
      type="icon"
      icon={<Icon path="./icons/ui/blue/arrow-right.svg" size="s" />}
      iconPosition={"right"}
    >
      Primary link
    </Link>
  ),
  Share: (
    <Group compact>
      <Group.Item>
        <a href="https://notvisited.com">
          <SVGIcon
            name="facebook"
            size="m"
            fill="#4D4D4D"
            getExternalIconPath={getSocialIcon}
          />
        </a>
      </Group.Item>
      <Group.Item>
        <a href="https://notvisited.com">
          <SVGIcon
            name="twitter"
            size="m"
            fill="#4D4D4D"
            getExternalIconPath={getSocialIcon}
          />
        </a>
      </Group.Item>
      <Group.Item>
        <a href="https://notvisited.com">
          <SVGIcon
            name="linkedin"
            size="m"
            fill="#4D4D4D"
            getExternalIconPath={getSocialIcon}
          />
        </a>
      </Group.Item>
      <Group.Item>
        <a href="https://notvisited.com">
          <SVGIcon
            name="email"
            size="m"
            fill="#4D4D4D"
            getExternalIconPath={getSocialIcon}
          />
        </a>
      </Group.Item>
    </Group>
  )
};

const stories = storiesOf("Components/TitleTextAsset", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TitleTextAssetReadme));

stories
  .add("Horizontal", () => {
    const mediaItems = {
      Image: (
        <Image
          alt="Placeholder image"
          src={select("Image", horizontalImages, horizontalImages[0])}
          fit="cover"
        />
      ),
      Video: (
        <VideoPlayer
          duration={text("Duration", "PT1M35S")}
          kind="vimeo"
          playLabel="play video"
          videoId={text("Video ID", "114765613")}
        >
          <Image
            alt="Placeholder image"
            fit="cover"
            src="placeholder-images/1920x1080.png"
          />
        </VideoPlayer>
      )
    };

    return (
      <Section>
        <TitleTextAsset
          variant="horizontal"
          assetSize={select("Asset Size", TitleTextAsset.assetSize, "Small")}
          layouts={select("Layouts", TitleTextAsset.layouts, "Right")}
          smallCaps={
            boolean("SmallCaps", true) && (
              <Heading type="small-caps">small caps</Heading>
            )
          }
          heading={
            <Heading rank="1" type="m">
              <strong>Heading</strong>
            </Heading>
          }
          subHeading={
            boolean("Sub-heading", true) && (
              <Standfirst>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                porta, ligula sit amet dignissim facilisis, ante nulla aliquam
                ipsum, in vulputate purus turpis et turpis.
              </Standfirst>
            )
          }
          media={
            mediaItems[
              select(
                "Media",
                Object.keys(mediaItems),
                Object.keys(mediaItems)[0]
              )
            ]
          }
        >
          {boolean("Description", true) && (
            <TitleTextAsset.ContentItem>
              <RichText>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  porta, ligula sit amet dignissim facilisis, ante nulla aliquam
                  ipsum, in vulputate purus turpis et turpis.
                </p>
              </RichText>
            </TitleTextAsset.ContentItem>
          )}
          {CTAs[select("CTA", Object.keys(CTAs), Object.keys(CTAs)[0])]}
        </TitleTextAsset>
      </Section>
    );
  })
  .add("Vertical", () => {
    const mediaItems = {
      Image: (
        <Image
          alt="Placeholder image"
          src={select("Image", verticalImages, verticalImages[0])}
          fit="cover"
        />
      ),
      Video: (
        <VideoPlayer
          duration={text("Duration", "PT1M35S")}
          kind="vimeo"
          playLabel="play video"
          videoId={text("Video ID", "114765613")}
        >
          <Image
            alt="Placeholder image"
            fit="cover"
            src="placeholder-images/1920x1080.png"
          />
        </VideoPlayer>
      )
    };

    return (
      <Section>
        <TitleTextAsset
          variant="vertical"
          assetSize={select("Asset Size", TitleTextAsset.assetSize, "Small")}
          layouts={select("Layouts", TitleTextAsset.layouts, "Right")}
          smallCaps={
            boolean("SmallCaps", true) && (
              <Heading type="small-caps">small caps</Heading>
            )
          }
          heading={
            <Heading rank="1" type="m">
              <strong>Heading</strong>
            </Heading>
          }
          subHeading={
            boolean("Sub-heading", true) && (
              <Standfirst>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                porta, ligula sit amet dignissim facilisis, ante nulla aliquam
                ipsum, in vulputate purus turpis et turpis.
              </Standfirst>
            )
          }
          media={
            mediaItems[
              select(
                "Media",
                Object.keys(mediaItems),
                Object.keys(mediaItems)[0]
              )
            ]
          }
        >
          {boolean("Description", true) && (
            <TitleTextAsset.ContentItem>
              <RichText>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  porta, ligula sit amet dignissim facilisis, ante nulla aliquam
                  ipsum, in vulputate purus turpis et turpis.
                </p>
              </RichText>
            </TitleTextAsset.ContentItem>
          )}
          {CTAs[select("CTA", Object.keys(CTAs), Object.keys(CTAs)[0])]}
        </TitleTextAsset>
      </Section>
    );
  });
