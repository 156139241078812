import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import GroupItem from "./GroupItem";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Group = ({ children, compact, vertical }) => {
  const getClassName = getClassNameFactory(Group.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames({ compact, vertical })
      })}
    >
      <div className={getClassName({ descendantName: "inner" })}>
        {children}
      </div>
    </div>
  );
};

Group.displayName = "Group";

/* eslint-disable max-len */
Group.propTypes = {
  /**
   * Group.Item components
   */
  children: PropTypes.node.isRequired,

  /**
   * Decrease the gap between items
   */
  compact: PropTypes.bool,

  /**
   * Display vertically
   */
  vertical: PropTypes.bool
};
/* eslint-enable max-len */

Group.Item = GroupItem;

export default Group;
