import AuthState from "./AuthState";
import LoginManager from "./LoginManager";
import ECommLoginService, {
  ECommLoginServiceConfiguration,
  ECommLoginServiceConfigurationUAT,
  ECommLoginServiceConfigurationPPE,
  ECommLoginServiceConfigurationProd,
  LegalECommLoginServiceConfiguration,
  LegalECommLoginServiceConfigurationUAT,
  LegalECommLoginServiceConfigurationPPE,
  LegalECommLoginServiceConfigurationProd
} from "./services/ECommLoginService";
import EMCMLoginService, {
  EMCMLoginServiceConfiguration,
  EMCMLoginServiceConfigurationUAT,
  EMCMLoginServiceConfigurationPPE,
  EMCMLoginServiceConfigurationProd
} from "./services/EMCMLoginService";
import FakeLoginService from "./services/FakeLoginService";

export default LoginManager;
export {
  AuthState,
  ECommLoginService,
  ECommLoginServiceConfiguration,
  ECommLoginServiceConfigurationUAT,
  ECommLoginServiceConfigurationPPE,
  ECommLoginServiceConfigurationProd,
  LegalECommLoginServiceConfiguration,
  LegalECommLoginServiceConfigurationUAT,
  LegalECommLoginServiceConfigurationPPE,
  LegalECommLoginServiceConfigurationProd,
  EMCMLoginService,
  EMCMLoginServiceConfiguration,
  EMCMLoginServiceConfigurationUAT,
  EMCMLoginServiceConfigurationPPE,
  EMCMLoginServiceConfigurationProd,
  FakeLoginService
};
