export default class {
  estoreLocation = null;
  siteLocation = null;
  nameCookiesInternal = [];

  get nameCookies() {
    return this.nameCookiesInternal;
  }

  get cartUrl() {
    if (this.cartUrlInternal) {
      return this.cartUrlInternal;
    }

    return `${this.estoreLocation}/cart`;
  }

  set cartUrl(value) {
    this.cartUrlInternal = value;
  }

  get loginUrl() {
    if (this.loginUrlInternal) {
      return this.loginUrlInternal;
    }

    return `${this.siteLocation}/your-accounts/`;
  }

  set loginUrl(value) {
    this.loginUrlInternal = value;
  }

  get accountOverviewUrl() {
    if (this.accountOverviewUrlInternal) {
      return this.accountOverviewUrlInternal;
    }

    return `${this.siteLocation}/your-accounts/`;
  }

  set accountOverviewUrl(value) {
    this.accountOverviewUrlInternal = value;
  }

  get logoutUrl() {
    if (this.logoutUrlInternal) {
      return this.logoutUrlInternal;
    }

    return `${this.siteLocation}/your-accounts/?signout=true`;
  }

  set logoutUrl(value) {
    this.logoutUrlInternal = value;
  }
}
