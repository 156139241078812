import PropTypes from "prop-types";
import React, { Component } from "react";
import loadVidyard from "../apiLoaders/loadVidyard";
import BasePlayerEmbed from "./BasePlayerEmbed";
import debounce from "lodash.debounce";

import { canPublish } from "../utilities";
import { fireEnd, firePause, firePlay } from "../analytics";

const FIRE_PLAY_DEBOUNCE_TIME = 500;

class VidyardPlayerEmbed extends Component {
  static propTypes = {
    videoId: PropTypes.string.isRequired,
    analytics: PropTypes.func
  };

  static addAnalyticsToPlayer(player, videoMetadata, analytics) {
    // Debounce, because Vidyard tends to fire multiple play events
    player.on(
      "play",
      debounce(
        currentTime => firePlay({ ...videoMetadata, currentTime }, analytics),
        FIRE_PLAY_DEBOUNCE_TIME,
        { leading: true, trailing: false }
      )
    );

    player.on("pause", () =>
      firePause(
        { ...videoMetadata, currentTime: player.status.currentTime },
        analytics
      )
    );

    player.on("playerComplete", () =>
      fireEnd(
        { ...videoMetadata, currentTime: videoMetadata.duration },
        analytics
      )
    );
  }

  handleFrameReady = async () => {
    if (this.props.analytics || canPublish()) {
      const Vidyard = await loadVidyard();
      const players = new Vidyard.players(); // eslint-disable-line new-cap
      /**
       * Check to see if this player API instance already exists, does not
       * get removed on player iframe removal.
       */
      let player = players[this.props.videoId];

      if (!player) {
        player = new Vidyard.player(this.props.videoId); // eslint-disable-line new-cap

        const metadata = await new Promise(resolve => {
          player.on("ready", () => {
            resolve(player.metadata);
          });
        });
        const videoMetadata = {
          duration: metadata.length_in_seconds,
          id: this.props.videoId,
          title: metadata.name,
          type: "Vidyard"
        };

        VidyardPlayerEmbed.addAnalyticsToPlayer(
          player,
          videoMetadata,
          this.props.analytics
        );
      }
    }
  };

  render() {
    const { videoId, ...extra } = this.props; // eslint-disable-line no-unused-vars
    const frameUrl = `//play.vidyard.com/${videoId}.html?type=inline&autoplay=1`;

    return (
      <BasePlayerEmbed
        id={`vidyard_iframe_${videoId}`}
        src={frameUrl}
        onFrameReady={this.handleFrameReady}
        {...extra}
      />
    );
  }
}

export default VidyardPlayerEmbed;
