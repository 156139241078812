import React from "react";
import AjaxContentWithContext from "./AjaxContentWithContext";

// eslint-disable-next-line require-await
export default async (domNode, rehydrateChildren, extra) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const children = await rehydrateChildren(domNode);

  const failedContent = readData("failed-content-message")
    ? readData("failed-content-message")
    : "Failed to retrieve content";

  const rehydrationProps = {
    autoLoad: Boolean(readData("auto-load")) || false,
    failedContent,
    loadingLabel: readData("loading-label"),
    location: readData("location"),
    minHeight: readData("min-height")
  };

  return (
    <AjaxContentWithContext
      {...rehydrationProps}
      reactFromMarkupRehydrators={extra.rehydrators}
      reactFromMarkupOptions={{ extra }}
    >
      {children}
    </AjaxContentWithContext>
  );
};
