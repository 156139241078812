import React from "react";
import DismissibleBanner from "@emcm-ui/component-dismissible-banner";
import MarkupContainer from "@emcm-ui/component-markup-container";

const SampleDismissibleBanner = () => (
  <MarkupContainer>
    <DismissibleBanner
      fixed
      heading="Our Privacy Statment & Cookie Policy"
      text="All Thomson Reuters websites use cookies to improve your online experience. They were placed on your computer when you launched this website. You can change your cookie settings through your browser."
    >
      <DismissibleBanner.LinkItem href="#" text="Privacy Statement" />
      <DismissibleBanner.LinkItem href="#" text="Cookie Policy" />
    </DismissibleBanner>
  </MarkupContainer>
);

export default SampleDismissibleBanner;
