import React from "react";

import Alignment from "@emcm-ui/component-alignment";
import Button from "@emcm-ui/component-button";
import Group from "@emcm-ui/component-group";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import Poster from "@emcm-ui/component-poster";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Icon from "@emcm-ui/component-icon";

import billboardOne from "../../sampleAssets/billboards/billboard-one-1440.jpg";
import billboardTwo from "../../sampleAssets/billboards/billboard-two-1440.jpg";
import billboardThree from "../../sampleAssets/billboards/billboard-three-1440.jpg";
import billboardFour from "../../sampleAssets/billboards/billboard-four-1440.jpg";
import billboardFive from "../../sampleAssets/billboards/billboard-five-1440.jpg";
import billboardSix from "../../sampleAssets/billboards/billboard-six-1440.jpg";

const Icons = {
  arrowRight: "./icons/ui/white/arrow-right.svg",
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg"
};

const Billboards = () => {
  return (
    <div>
      <Section background="grayDark" fullWidth>
        <Poster height="m" imageSrc={billboardOne} imageInlineAtNarrow>
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Location" />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl">
                  Discover the <strong>Gaylord Opryland Resort</strong> for
                  SYNERGY conference
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Book your room online or call the hotel directly at{" "}
                    <a href="tel:1-877-382-7299">1-877-382-7299</a>. Mention
                    Thomson Reuters for a discounted rate (Code: TR1).
                  </p>
                </RichText>
              </VerticalSpacing>
              <Link
                href="https://example.com"
                type="icon"
                icon={<Icon path={Icons.arrowRight} size="s" />}
                iconPosition={"right"}
              >
                Learn more
              </Link>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="m" imageSrc={billboardOne}>
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Location" />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl">
                  Discover the <strong>Gaylord Opryland Resort</strong> for
                  SYNERGY conference
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Book your room online or call the hotel directly at{" "}
                    <a href="tel:1-877-382-7299">1-877-382-7299</a>. Mention
                    Thomson Reuters for a discounted rate (Code: TR1).
                  </p>
                </RichText>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button
                    color="primary"
                    href="https://example.com"
                    kind="link"
                  >
                    Learn more
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button
                    color="secondaryDark"
                    href="https://example.com"
                    kind="link"
                  >
                    Download in the App Store
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="m" imageSrc={billboardTwo} imageInlineAtNarrow>
          <Grid variant="1/6,2/3,1/6" gutterlessAtNarrow>
            <Grid.Item />
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Alignment horizontal="center">
                  <Eyebrow text="All about BEPS" />
                </Alignment>
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl" align="center">
                  BEPS legislation is transforming tax planning and compliance
                  for global businesses. Thomson Reuters gives you the
                  information and tools you need to navigate the new
                  regulations.
                </Heading>
              </VerticalSpacing>
              <Alignment horizontal="center">
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRight} size="s" />}
                  iconPosition={"right"}
                >
                  Learn more
                </Link>
              </Alignment>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="m" imageSrc={billboardThree} imageInlineAtNarrow>
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item />
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Our brands" />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl">
                  We deliver the most comprehensive tax and accounting solutions
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Advanced research and education solutions for accounting
                    firms, corporations, governments, financial and academic
                    institutions.
                  </p>
                </RichText>
              </VerticalSpacing>
              <Button color="primary" href="https://example.com" kind="link">
                Learn more
              </Button>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayDark" fullWidth>
        <Poster height="m" imageSrc={billboardThree}>
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Location" />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl">
                  Discover the <strong>Gaylord Opryland Resort</strong> for
                  SYNERGY conference
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Book your room online or call the hotel directly at{" "}
                    <a href="tel:1-877-382-7299">1-877-382-7299</a>. Mention
                    Thomson Reuters for a discounted rate (Code: TR1).
                  </p>
                </RichText>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button
                    color="primary"
                    href="https://example.com"
                    kind="link"
                  >
                    Learn more
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button
                    color="secondaryDark"
                    href="https://example.com"
                    kind="link"
                  >
                    Download in the App Store
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayLighter" fullWidth>
        <Poster height="m" imageSrc={billboardFour} imageInlineAtNarrow>
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="World-check one" />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl">
                  <strong>Enhance your data feed experience</strong> with
                  World-Check One
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    World-Check One combines World-Check data with purpose-built
                    software to help accelerate and simplify your clients’
                    obligations.
                  </p>
                </RichText>
              </VerticalSpacing>
              <Button color="primary" href="https://example.com" kind="link">
                Learn more
              </Button>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayLighter" fullWidth>
        <Poster height="m" imageSrc={billboardFive}>
          <Grid variant="1/6,2/3,1/6" gutterlessAtNarrow>
            <Grid.Item />
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Alignment horizontal="center">
                  <Eyebrow text="Tax and accounting products" />
                </Alignment>
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl" align="center">
                  <strong>See all of our products</strong>
                </Heading>
              </VerticalSpacing>
              <Alignment horizontal="center">
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  Learn more
                </Link>
              </Alignment>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
      <Section background="grayLighter" fullWidth>
        <Poster height="m" imageSrc={billboardSix} imageInlineAtNarrow>
          <Grid variant="even-2" gutterlessAtNarrow>
            <Grid.Item />
            <Grid.Item>
              <VerticalSpacing size="xs">
                <Eyebrow text="Tax and accounting products" />
              </VerticalSpacing>
              <VerticalSpacing size="xs">
                <Heading type="xl">
                  <strong>Add eSignature</strong> for the most convenient,
                  secure and efficient way to electronically sign tax documents
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="l">
                <RichText large>
                  <p>
                    Advanced research and education solutions for accounting
                    firms, corporations, governments, financial and academic
                    institutions.
                  </p>
                </RichText>
              </VerticalSpacing>
              <Group>
                <Group.Item>
                  <Button
                    color="secondary"
                    href="https://example.com"
                    kind="link"
                  >
                    Learn more about eSignature
                  </Button>
                </Group.Item>
                <Group.Item>
                  <Button
                    color="secondary"
                    href="https://example.com"
                    kind="link"
                  >
                    Download in the App Store
                  </Button>
                </Group.Item>
              </Group>
            </Grid.Item>
          </Grid>
        </Poster>
      </Section>
    </div>
  );
};

export default Billboards;
