// Vendor
import React from "react";
import { storiesOf } from "@storybook/react";
import {
  withKnobs,
  select,
  text,
  boolean,
  object
} from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";

// Local
import "./index.css";
import Playlist from "./index";
import PlaylistREADME from "../README.md";

const states = ["PLAYING", "PAUSED", "VIEWED"];
const ellipsisSizes = ["small", "medium", "large"];
const label = {
  playlist: "PLAYLIST",
  playing: "PLAYING",
  paused: "PAUSED",
  viewed: "VIEWED"
};
const stories = storiesOf("Components/Playlist", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(PlaylistREADME));

stories
  .add("Default", () => (
    <div>
      <Playlist.Item
        title={text(
          "Title",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
        )}
        description={text("Description", "05:44 - PAUSED")}
        state={select("State", states, "PAUSED")}
        url="https://www.example.com"
        isTitleEllipsisActive={boolean("Activate ellipsis for title", true)}
        titleEllipsisLinesSize={select(
          "Ellipsis lines size for title",
          ellipsisSizes,
          "medium"
        )}
        isActive={boolean("Activate selected view", false)}
      />
      <Playlist.Item
        title={text(
          "Title",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
        )}
        description={text("Description", "05:44 - PLAYING")}
        state={select("State", states, "PLAYING")}
        url="https://www.example.com"
        isTitleEllipsisActive={boolean("Activate ellipsis for title", true)}
        titleEllipsisLinesSize={select(
          "Ellipsis lines size for title",
          ellipsisSizes,
          "medium"
        )}
        isActive={boolean("Activate selected view", true)}
      />
      <Playlist.Item
        title={text(
          "Title",
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt"
        )}
        description={text("Description", "05:44 - VIEWED")}
        state={select("State", states, "VIEWED")}
        url="https://www.example.com"
        isTitleEllipsisActive={boolean("Activate ellipsis for title", true)}
        titleEllipsisLinesSize={select(
          "Ellipsis lines size for title",
          ellipsisSizes,
          "medium"
        )}
        isActive={boolean("Activate selected view", false)}
      />
    </div>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Playlist
        lineItems={object("Playlist items", [
          {
            id: "1_qysmwsgx",
            title: "HTML 5 Video. This is for testing.",
            duration: 74
          },
          {
            id: "1_fsj13mxl",
            title: "Javascript Video. This is for testing.",
            duration: 1114
          },
          {
            id: "1_e56uvn1b",
            title: "ReactJS Video. This is for testing.",
            duration: 683
          }
        ])}
        states={object("States", [
          {
            id: "1_qysmwsgx",
            state: "PAUSED"
          },
          {
            id: "1_fsj13mxl",
            state: "PLAYING"
          },
          {
            id: "1_e56uvn1b",
            state: "VIEWED"
          }
        ])}
        url={text("Url", "https://www.example.com?")}
        label={object("Playlist label", label)}
        activeItemId={text("Active item id", "1_fsj13mxl")}
      />
    </StaticRenderer>
  ));
