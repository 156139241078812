/* eslint-disable no-magic-numbers */

import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, number } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import { anchorPoints } from "@emcm-ui/component-image";

import VideoLoopREADME from "../README.md";
import VideoLoop from "./index";
import "./VideoLoop.css";

import Section from "@emcm-ui/component-section";

const stories = storiesOf("Components/VideoLoop", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(VideoLoopREADME));

stories.add("default", () => (
  <Section>
    <div style={{ width: "100%", height: "75vh", padding: "30px" }}>
      <VideoLoop
        anchor={select("anchor", anchorPoints, "C")}
        fit={select("fit", ["contain", "cover"], "cover")}
        src="video-loop/player.html"
        width={number("display width", 0)}
        height={number("display height", 0)}
        videoWidth={1280}
        videoHeight={720}
      />
    </div>
  </Section>
));
