import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const VerticalSpacing = ({ children, size }) => {
  const getClassName = getClassNameFactory(VerticalSpacing.displayName);

  return <div className={getClassName({ modifiers: size })}>{children}</div>;
};

VerticalSpacing.displayName = "VerticalSpacing";

VerticalSpacing.sizes = ["xs", "s", "m", "l", "xl"];

VerticalSpacing.propTypes = {
  /**
   * Markup elements to render in VerticalSpacing
   */
  children: PropTypes.node.isRequired,

  /**
   * Spacing t-shirt size
   */
  size: PropTypes.oneOf(VerticalSpacing.sizes)
};

VerticalSpacing.defaultProps = {
  size: "m"
};

export default VerticalSpacing;
