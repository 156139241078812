/* eslint-disable */
import React from "react";
import Icon from "./Icon";

const mapIcons = (subFolders, size) => (
  <React.Fragment>
    {subFolders.children.map(icons => {
      return (
        <React.Fragment>
          {icons.name !== size &&
            icons.children.map(icon => {
              return (
                <div
                  key={icon.name}
                  onClick={() =>
                    navigator.clipboard.writeText(icon.name) || null
                  }
                  className={`story-iconSpecimen ${icon.name}`}
                >
                  <Icon path={icon.path} size="l" />
                  <code>{icon.name}</code>
                </div>
              );
            })}
        </React.Fragment>
      );
    })}
  </React.Fragment>
);

const mapSubFolder = (rootFolder, size) => (
  <React.Fragment>
    {rootFolder.children.map(subFolders => {
      return (
        <React.Fragment>
          <h2 className="story-themeSubHeading">
            <code>{subFolders.name}</code>
          </h2>
          {mapIcons(subFolders, size)}
        </React.Fragment>
      );
    })}
  </React.Fragment>
);

const NucleoIconList = ({ iconList, size }) => {
  return (
    <div className="story-themeSection">
      {iconList.children.map(rootFolder => (
        <React.Fragment>
          {rootFolder.name === "nucleo" && (
            <div key={rootFolder.name} className={rootFolder.name}>
              <div className="story-iconSpecimens">
                <h2 className="story-themeSubHeading">
                  <code>{rootFolder.name}</code>
                  <div className="story-themeSubHeadingNote">
                    {rootFolder.path}
                  </div>
                </h2>
                {mapSubFolder(rootFolder, size)}
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NucleoIconList;
