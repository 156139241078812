import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "../Link";

const SelectedGroup = ({ children, title }) => {
  const getClassName = getClassNameFactory("SearchFiltersSelectedGroup");

  return (
    <li className={getClassName()}>
      <div className={getClassName({ descendantName: "title" })}>
        <Link>{title}</Link>
      </div>

      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </li>
  );
};

SelectedGroup.displayName = "SearchFilters.SelectedGroup";

SelectedGroup.propTypes = {
  /**
   * The children of this SelectedGroup. Should be a `SearchFilters.FilterItem` or `SearchFilters.FilterGroup`.
   */
  children: PropTypes.node,

  /**
   * The title to display
   */
  title: PropTypes.string
};

export default SelectedGroup;
