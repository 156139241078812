import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Anchor = ({ id }) => {
  const getClassName = getClassNameFactory("PageNavAnchor");

  return <div className={getClassName()} id={id} />;
};

Anchor.displayName = "PageNav.Anchor";

Anchor.propTypes = {
  /**
   * ID for the element. Use with PageNav for anchor links and scroll tracking.
   */
  id: PropTypes.string.isRequired
};

export default Anchor;
