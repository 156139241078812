import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Tag = ({ children }) => {
  const getClassName = getClassNameFactory("SearchResults");

  return (
    <li className={getClassName({ descendantName: "tagFooterTag" })}>
      {children}
    </li>
  );
};

Tag.propTypes = {
  /**
   * The tag name to show
   */
  children: PropTypes.string
};

export default Tag;
