import campaignTracking from "./services/campaign-tracking";
import { options } from "./reactFromMarkup";
import rehydrate from "react-from-markup";
import { initModals } from "./modals";

const firePageLoad = () => {
  if (window.PubSub && !window.pausePageLoadTracking) {
    window.PubSub.publish("analytics.pageLoad", {});
  }
};

const doRehydrate = async () => {
  await rehydrate(document.body, options.extra.rehydrators, options);
};

export default async () => {
  window.ewp = window.ewp || {};
  window.ewp.campaignTracking = campaignTracking;

  // rehydrate on Adobe Target DOM change
  document.addEventListener("at-content-rendering-succeeded", () =>
    doRehydrate()
  );

  await doRehydrate();

  initModals();

  firePageLoad();
};
