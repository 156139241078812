import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import FilterGroup from "./components/FilterGroup";
import FilterItem from "./components/FilterItem";
import FilterSection from "./components/FilterSection";
import FilterSectionOverflow from "./components/FilterSectionOverflow";
import Filters from "./components/Filters";
import Link from "./components/Link";
import Section from "./components/Section";
import Selected from "./components/Selected";
import SelectedGroup from "./components/SelectedGroup";
import SelectedItem from "./components/SelectedItem";

class SearchFilters extends Component {
  static displayName = "SearchFilters";

  static propTypes = {
    /**
     * The contents of this `SearchFilters` element.
     */
    children: PropTypes.node
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(SearchFilters.displayName);
  }

  render() {
    return <div className={this.getClassName()}>{this.props.children}</div>;
  }
}

SearchFilters.FilterGroup = FilterGroup;
SearchFilters.FilterItem = FilterItem;
SearchFilters.FilterSection = FilterSection;
SearchFilters.FilterSectionOverflow = FilterSectionOverflow;
SearchFilters.Filters = Filters;
SearchFilters.Link = Link;
SearchFilters.Section = Section;
SearchFilters.Selected = Selected;
SearchFilters.SelectedGroup = SelectedGroup;
SearchFilters.SelectedItem = SelectedItem;

export default SearchFilters;
