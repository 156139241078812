/* eslint-disable no-console */
import PropTypes from "prop-types";
import React, { Component } from "react";
import loadKaltura from "../apiLoaders/loadKaltura";
import {
  firePause,
  firePlay,
  fireEnd,
  fireChangedClosedCaptions
} from "../analytics";

class KalturaPlayerEmbed extends Component {
  static propTypes = {
    kaltura: PropTypes.string,
    analytics: PropTypes.func
  };
  state = { elementId: null };

  handleFrameReady = async () => {
    const { script, ...getDatasets } = this.props.kaltura
      ? JSON.parse(this.props.kaltura)
      : null;

    await loadKaltura(script);
    await window.kWidget.embed(getDatasets);
    await this.analytics({
      elementId: getDatasets.targetId,
      analytics: this.props.analytics
    });
    this.setState({ elementId: getDatasets.targetId });
  };

  analytics = ({ elementId, analytics }) => {
    window.kWidget.addReadyCallback(() => {
      const kdp = document.getElementById(elementId);

      if (kdp) {
        const videoMetadata = { id: elementId, type: "Kaltura" };

        kdp.kBind("doPlay", () => {
          firePlay({ ...videoMetadata }, analytics);
        });

        kdp.kBind("doPause", () => {
          firePause({ ...videoMetadata }, analytics);
        });

        kdp.kBind("playerPlayEnd", () => {
          fireEnd({ ...videoMetadata }, analytics);
        });

        kdp.kBind("changedClosedCaptions", event => {
          fireChangedClosedCaptions({ ...videoMetadata, ...event }, analytics);
        });
      }
    });
  };

  componentDidMount() {
    this.handleFrameReady();
  }

  componentWillUnmount() {
    window.kWidget.destroy(this.state.elementId);
  }

  render() {
    const getDatasets = this.props.kaltura
      ? JSON.parse(this.props.kaltura)
      : null;

    return (
      <div
        style={getDatasets.style || null}
        data-kaltura={this.props.kaltura}
        id={getDatasets.targetId}
      />
    );
  }
}

export default KalturaPlayerEmbed;
