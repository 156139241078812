import PropTypes from "prop-types";
import React, { Component } from "react";

class BasePlayerEmbed extends Component {
  static propTypes = {
    getClassName: PropTypes.func.isRequired,
    id: PropTypes.string,
    onFrameReady: PropTypes.func,
    src: PropTypes.string.isRequired
  };

  componentDidMount() {
    this.loadVideo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.loadVideo();
    }
  }

  loadVideo() {
    this.iframe.src = this.props.src;

    if (this.props.onFrameReady) {
      this.props.onFrameReady(this.iframe);
    }
  }

  handleIframeRef = ref => {
    this.iframe = ref;
  };

  render() {
    return (
      <iframe
        allowFullScreen
        allow="autoplay; fullscreen"
        className={this.props.getClassName({ descendantName: "frame" })}
        frameBorder="0"
        height={281}
        id={this.props.id}
        ref={this.handleIframeRef}
        width={500}
      />
    );
  }
}

export default BasePlayerEmbed;
