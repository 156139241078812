import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Section from "@emcm-ui/component-section";
import Eyebrow from "./index";
import "./Eyebrow.css";
import EyebrowREADME from "../README.md";

const stories = storiesOf("Components/Eyebrow", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(EyebrowREADME));

stories.add("Default", () => {
  return (
    <Section
      background={
        select("Section background", [null].concat(Section.backgrounds)) || null
      }
    >
      <Eyebrow text={text("text", "Financial")} />
    </Section>
  );
});
