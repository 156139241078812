import classNames from "classnames";

export const toggleInvalidClass = (elementClassName, required, hasError) => {
  return classNames(elementClassName, { "is-invalid": required && hasError });
};

export const toggleSelectedClass = (initiated, hasError, value) => {
  return classNames({
    "is-selected":
      /* eslint-disable */
      (initiated && !hasError) || (value !== undefined && value !== "")
    /* eslint-enable */
  });
};
