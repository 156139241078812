import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Loader = ({ label }) => {
  const getClassName = getClassNameFactory(Loader.displayName);

  return (
    <div className={getClassName()}>
      <div
        className={getClassName({
          descendantName: "spinner"
        })}
        role="status"
        aria-live="polite"
      />
      <div
        className={getClassName({
          descendantName: "label",
          utilities: "hiddenVisually"
        })}
      >
        {label}
      </div>
    </div>
  );
};

Loader.displayName = "Loader";

Loader.propTypes = {
  /**
   * label: spinning label when in loading state.
   */
  label: PropTypes.string.isRequired
};

Loader.defaultProps = {
  label: "loading"
};

export default Loader;
