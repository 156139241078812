import React from "react";
import PropTypes from "prop-types";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { AjaxContentContext } from "../AjaxContentContainer/AjaxContentContainer";
import AjaxContent from "../../AjaxContent.js";

const AjaxContentWithContext = props => {
  return (
    <AjaxContentContext.Consumer>
      {ajaxContext => (
        <div data-rehydratable={getRehydratableName(AjaxContent.displayName)}>
          <AjaxContent
            {...props}
            location={
              ajaxContext && ajaxContext.href !== ""
                ? ajaxContext.href
                : props.location
            }
          >
            {props.children}
          </AjaxContent>
        </div>
      )}
    </AjaxContentContext.Consumer>
  );
};

AjaxContentWithContext.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  location: PropTypes.string
};

export default AjaxContentWithContext;
