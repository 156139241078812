import mountScript from "@emcm-ui/utility-mount-script";
import { VIDYARD_SCRIPT_URL } from "./config";

export default async () => {
  if (window.Vidyard) {
    return window.Vidyard;
  }

  try {
    await mountScript(VIDYARD_SCRIPT_URL);
  } catch (e) {
    const vidyardException = new Error("Unable to load the Vidyard API.", e);

    vidyardException.name = "VidyardLoadError";

    throw vidyardException;
  }

  return window.Vidyard;
};
