import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const TitleTextAssetContentItem = ({ children }) => {
  const getClassName = getClassNameFactory("TitleTextAssetContentItem");

  return <div className={getClassName()}>{children}</div>;
};

TitleTextAssetContentItem.displayName = "TitleTextAsset.ContentItem";

/* eslint-disable max-len */
TitleTextAssetContentItem.propTypes = {
  /**
   * Button component
   */
  children: PropTypes.node.isRequired
};
/* eslint-enable max-len */

export default TitleTextAssetContentItem;
