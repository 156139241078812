import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, number } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Block from "@emcm-ui/component-block";
import Button from "@emcm-ui/component-button";
import DateTime from "@emcm-ui/component-date-time";
import Duration from "@emcm-ui/component-duration";
import Icon from "@emcm-ui/component-icon";

import { Language } from "@emcm-ui/component-theme";
import Group from "./index";
import "./Group.css";
import GroupREADME from "../README.md";

const minimumNumberOfItems = 2;
const stories = storiesOf("Components/Group", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GroupREADME));

stories
  .add("Default", () => (
    <Group
      compact={boolean("compact", false)}
      vertical={boolean("vertical", false)}
    >
      {[...Array(number("Number of items", minimumNumberOfItems)).keys()].map(
        index => (
          <Group.Item key={index}>
            <Block text="Item" />
          </Group.Item>
        )
      )}
    </Group>
  ))
  .add("Buttons", () => (
    <Group
      compact={boolean("compact", false)}
      vertical={boolean("vertical", false)}
    >
      {[...Array(number("Number of items", minimumNumberOfItems)).keys()].map(
        index => (
          <Group.Item key={index}>
            <Button color="secondary" kind="button">
              Button
            </Button>
          </Group.Item>
        )
      )}
    </Group>
  ))
  .add("Icons", () => (
    <Group
      compact={boolean("compact", true)}
      vertical={boolean("vertical", false)}
    >
      <Group.Item>
        <Icon path="./icons/ui/grey/arrow-down-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/arrow-left-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/arrow-right-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/arrow-up-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/cart-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/calendar-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/caret-down-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/caret-left-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/caret-right-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/caret-up-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/close-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/red/decrease.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/document-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/external-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/excel.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/image.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/PDF.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/powerpoint.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/text.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/word.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/doctype/zip.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/filter-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/download-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/email-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/red/error.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/fullscreen-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/green/increase.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/locked-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/logos/apple.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/logos/google.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/logos/outlook.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/signout-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/menu-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/minus-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/notification-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/notification-new-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/question-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/playtriangle-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/playoutline-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/plus-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/print-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/search-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/share-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/green/success.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/tick-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/time-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/unlocked-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/user-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/useravatar-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/zoomin-07.svg" size="m" />
      </Group.Item>
      <Group.Item>
        <Icon path="./icons/ui/grey/zoomout-07.svg" size="m" />
      </Group.Item>
    </Group>
  ))
  .add("Arbitrary", () => (
    <Group
      compact={boolean("compact", false)}
      vertical={boolean("vertical", false)}
    >
      <Group.Item>Progressive Media Group, Inc.</Group.Item>
      <Group.Item>
        <DateTime dateTime="2016-03-02 13:57" format="PP" />
      </Group.Item>
      <Group.Item>
        <Duration
          duration="P15M"
          icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
        >
          15 min
        </Duration>
      </Group.Item>
    </Group>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Group
        compact={boolean("compact", false)}
        vertical={boolean("vertical", false)}
      >
        {[...Array(number("Number of items", minimumNumberOfItems)).keys()].map(
          index => (
            <Group.Item key={index}>
              <Block text="بند" />
            </Group.Item>
          )
        )}
      </Group>
    </Language>
  ));
