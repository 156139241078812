import React from "react";

import Heading from "@emcm-ui/component-heading";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import Grid from "@emcm-ui/component-grid";
import LanguageSwitcher from "@emcm-ui/component-language-switcher";
import MarkupContainer from "@emcm-ui/component-markup-container";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Table from "@emcm-ui/component-table";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { Language } from "@emcm-ui/component-theme";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const PrivacyZh = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Language code="zh-Hans">
          <Section>
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <VerticalSpacing size="xl">
                  <Heading rank="1" type="l">
                    汤森路透 (Thomson Reuters) 隐私声明
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="xl">
                  <LanguageSwitcher heading="Available in other languages">
                    <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="nl"
                      title="Nederlands"
                    />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="fr"
                      title="Français"
                    />
                    <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="it"
                      title="Italiano"
                    />
                    <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
                    <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="pt"
                      title="Português"
                    />
                    <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
                    <LanguageSwitcher.Item href="#" lang="es" title="Español" />
                    <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
                  </LanguageSwitcher>
                </VerticalSpacing>
                <RichText large>
                  <h2>简介</h2>

                  <p>
                    您的隐私和信任对我们而言非常重要，本隐私声明（“声明”）提供了关于汤森路透
                    (Thomson Reuters)
                    及其位于世界各地的关联企业和子公司（以下简称“汤森路透”或“我们”）如何处理个人信息方面的重要信息。本声明适用于任何汤森路透网站、应用程序、产品、软件或与之相关的服务（以下统称为“服务”）。偶尔会有某个服务列出该服务具体隐私实践相关的的不同隐私声明。
                  </p>

                  <p>
                    请仔细阅读本声明，如果您对我们的隐私实践或您的个人信息选择存在任何问题，请联系我们的<a href="#contact-us">
                      数据保护专员
                    </a>。请务必经常访问以了解本声明的更新，这非常重要。如果我们做出了我们认为重要的变更，我们会发出关于相关服务的通知以通知您，或使用电子邮件等其他方式联系您。
                  </p>

                  <p>
                    <strong>本声明最后一次更新于2017年6月1日。</strong>
                  </p>

                  <p>您可以在下文找到关于以下方面的有用信息：</p>
                  <ul>
                    <li>
                      <a href="#personal-information">个人信息</a>
                      <ul>
                        <li>
                          <a href="#personal-information-collected">
                            我们收集的个人信息的类型
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-used">
                            我们如何使用个人信息
                          </a>
                        </li>
                        <li>
                          <a href="#when-personal-information-shared">
                            我们何时共享个人信息
                          </a>
                        </li>
                        <li>
                          <a href="#where-personal-information-stored">
                            我们在什么地方存储和处理个人信息
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-secured">
                            我们如何确保个人信息的安全
                          </a>
                        </li>
                        <li>
                          <a href="#time-personal-information-kept">
                            我们会将个人信息保存多久
                          </a>
                        </li>
                        <li>
                          <a href="#personal-information-access">
                            您访问和更正个人信息的权利
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#cookies">Cookie和类似技术</a>
                      <ul>
                        <li>
                          <a href="#what-is-a-cookie">什么是Cookie？</a>
                        </li>
                        <li>
                          <a href="#cookie-types">
                            Cookie的类型以及我们为什么使用它们：
                          </a>
                        </li>
                        <li>
                          <a href="#managing-cookies">管理 Cookie</a>
                        </li>
                        <li>
                          <a href="#other-tracking-tech">其它追踪技术：</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#advertising">基于兴趣的广告(IBA)</a>
                      <ul>
                        <li>
                          <a href="#opting-out-iba">Opting out of IBA</a>
                        </li>
                        <li>
                          <a href="#mobile-advertising">
                            Advertising on mobile devices
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#dnt">请勿追踪(DNT)</a>
                    </li>
                    <li>
                      <a href="#social-networks">通过社交网络保持联系</a>
                    </li>
                    <li>
                      <a href="#third-party-services">
                        与第三方服务的链接和连接
                      </a>
                    </li>
                    <li>
                      <a href="#children-privacy">儿童隐私</a>
                    </li>
                    <li>
                      <a href="#contact-us">如何联系我们</a>
                    </li>
                  </ul>

                  <h2 id="personal-information">个人信息</h2>
                  <p>汤森路透承诺致力于对个人信息进行负责任的处理和保护。</p>
                  <p>
                    个人信息是指与已识别或可识别的自然人有关的任何信息；可识别的人是指可以通过参照姓名、身份证号码、位置数据、在线标识符，或参照一个或多个特定于物理、生理、基因、精神、经济、文化或社会身份的因素进行直接或间接识别的人。
                  </p>
                  <p>
                    当需要提供我们的服务时，以及出于本声明所述的运营和业务目的，我们会收集、使用、披露、转移和存储个人信息。我们希望清晰地阐述我们的隐私实践，以便您能够在您信息的使用方面做出明智的选择；如果您存在问题或疑虑，我们鼓励您随时<a href="#contact-us">
                      联系我们
                    </a>。
                  </p>

                  <h3 id="personal-information-collected">
                    我们收集的个人信息的类型
                  </h3>
                  <p>
                    我们会收集您的个人信息，例如：如果您注册参加活动，索取信息，购买或使用我们的服务或者申请获得客户支持。我们可能会要求您提供您的姓名、地址、电话号码、电子邮件、用户名和密码等信息以及关于您的设备的信息。汤森路透持有的关于您的个人信息并非都直接来自于您。这些信息可能来自，例如您的雇主、您所属的其他组织或您的税务或会计专业人士或律师等专业服务提供者（如果他们使用我们的服务）。我们也会从我们的合作伙伴、服务提供者和可公开访问的网站等第三方收集个人信息，以提供我们可能感兴趣的服务，以及帮助我们维护数据的准确性，从而提供和强化服务。
                  </p>

                  <p>
                    此外，我们的服务器、日志和其它技术<a href="#other-tracking-tech">
                      会自动收集部分信息
                    </a>，以帮助我们管理、保护和改善我们的服务，分析使用情况，以及改善用户体验。我们仅会依据本声明之描述，或在我们认为法律允许或要求时，向其他人共享个人信息。
                  </p>

                  <p>我们偶尔会收集和处理可能被视为敏感个人信息的信息。</p>
                  <p>
                    敏感个人信息隶属于个人信息，通常被定义为与下列方面相关的任何信息：种族/民族血统、政治观点、宗教信仰、工会会员资格、身心健康，以及包括生物特征辨识数据、基因数据、性生活或偏好在内的其他医疗信息。在有些情况下，个人敏感信息可能还包括犯罪指控或判决、精确的地理位置信息、财务和银行账号，以及政府签发的社会保障号码、驾照和护照号码等唯一标识符。
                  </p>
                  <p>
                    例如，如果您向我们购买产品或订购我们的服务，我们会收集财务或银行卡信息等支付信息以及我们处理交易所需的其他信息。如果您或您的专业服务人士使用我们的税务及会计服务，则我们会收集和处理财务和税务信息。我们的部分服务会要求您分享您的精确地理位置，以便我们能定制化您的体验，并提高服务的准确性。如果您使用我们的电子招聘(eRecruitment)服务申请工作，我们还会收集政府签发的身份证号码等信息。适用法律视为敏感的信息将会按照此类适用法律进行处理。
                  </p>

                  <h3 id="how-personal-information-used">
                    我们如何使用个人信息
                  </h3>
                  <p>我们会出于以下服务和业务相关的目的处理个人信息：</p>
                  <ul>
                    <li>
                      <strong>账户设置和管理：</strong>{" "}
                      我们使用您的姓名、电子邮件地址、电话号码等个人信息以及关于您设备的信息，以设置和管理您的账户，提供技术和客户支持及培训，验证您的身份，以及发送重要的账户、订阅和服务信息。
                    </li>
                    <li>
                      <strong>个性化：</strong>{" "}
                      我们将个人信息用于发送和建议新闻、研究、报告和商业信息等定制化内容，以及个性化您对我们服务的体验。我们的部分服务会要求您分享您的精确地理位置，以便我们能定制化您的体验，并提高服务的准确性。如果您同意与我们分享您的精确地理位置，您将可以通过您的移动设备或在线方式打开隐私设置，随时关闭这一功能。
                    </li>
                    <li>
                      <strong>市场营销及活动：</strong>{" "}
                      我们使用个人信息通过电子邮件、电话、短信息、直邮和在线等各种平台向您提供营销和活动通知。如果我们向您发送市场营销电子邮件，邮件中会包含未来如何停止接收此类邮件方面的操作说明。我们还设有电子邮件偏好中心，以便您管理您的信息和营销偏好。请记住，即使您选择停止接收营销电子邮件，我们仍然会向您发送与您的账户和订阅内容相关的重要服务信息。
                    </li>
                    <li>
                      <strong>调查与投票：</strong>{" "}
                      如果您选择参加调查或投票活动，您提供的个人信息可能会被用于市场营销或市场调查目的。
                    </li>
                    <li>
                      <strong>研发：</strong>{" "}
                      我们会将个人信息用于内部研发目的，以及改善和测试我们服务的特点和功能。
                    </li>
                    <li>
                      <strong>聊天室、消息、社区和活动论坛：</strong>{" "}
                      我们的一系列服务提供包括聊天室、消息服务以及社区和活动论坛等用于协作、同行交流、游戏和信息交换用途的功能。取决于具体服务，您选择发布、分享、上传或提供的个人信息均系公开，并对使用这些服务的其他人可见。您不得发布或分享任何保密信息或关于其他人的任何信息，除非您拥有这种权限。我们可能会使用您在社区和活动资料以及论坛中提供的信息，以个性化您的体验，以及创建内容和作出同行交流推荐。此类服务可能具有各自的使用条款，以及若适用的话，各自的隐私声明。我们会规范此类服务，以遵守我们的使用条款。
                    </li>
                    <li>
                      <strong>托管服务：</strong>{" "}
                      我们的部分服务提供数据和文档存储，作为产品或解决方案的一个组成部分。例如，我们的客户存储的文档和数据可能会包含公司和个人税务表格中的个人信息、工资表和财务数据以及与法律和诉讼相关的文件。我们的客户或代表我们的客户存储的任何信息由此类客户或客户不时授权的其他人控制和管理，且只能由此类客户或客户不时授权的其他人访问。该信息的访问权限仅限于拥有重大业务理由的汤森路透员工，例如技术支持。请<a href="#where-personal-information-stored">
                        点击此处
                      </a>了解更多关于我们处理和存储信息的位置的信息。{" "}
                    </li>
                    <li>
                      <strong>法律义务：</strong>{" "}
                      出于犯罪预防、侦查或调查、预防损失或欺诈等法律和合规原因，可能会要求我们使用并保留个人信息。我们还可能使用个人信息来满足我们的内部和外部审核要求、信息安全目的以及我们认为必要或适当的情形：(a)根据适用法律，可能包括您所居住的国家以外的法律；(b)回应法庭、执法机构、监管机构和其他公共和政府机构的请求，可能包括您所居住的国家以外的此类机构；(c)执行我们的条款和条件；以及(d)保护我们以及其他人的权利、隐私、安全或财产。
                    </li>
                  </ul>
                  <h3 id="when-personal-information-shared">
                    我们何时共享个人信息
                  </h3>
                  <p>
                    汤森路透在需要提供服务或开展下文所述的业务运营时会共享或披露个人信息。当我们分享个人信息时，我们会严格遵循数据隐私和安全方面的要求。我们偶尔可能会与第三方分享非个人、匿名和统计数据。以下是我们可能与之分享个人信息的第三方以及原因。
                  </p>
                  <ul>
                    <li>
                      <strong>在汤森路透内部：</strong>{" "}
                      我们在世界各地的业务由汤森路透的各个团队和职能部门提供支持，为了提供服务、账户管理、销售和营销、客户和技术支持以及业务和产品开发的需要，我们会向他们提供个人信息。我们的所有员工和承包商在处理个人信息时，都必须遵循我们的数据隐私和安全政策。请<a href="#where-personal-information-stored">
                        点击此处
                      </a>查看我们可能处理个人数据的汤森路透位置列表。
                    </li>
                    <li>
                      <strong>我们的业务合作伙伴：</strong>{" "}
                      我们偶尔与其它组织合作提供共同品牌的服务，提供内容或举办活动、会议和研讨会。作为这些安排的一部分，您可能是汤森路透和我们合作伙伴的客户，我们和我们的合作伙伴可能会收集和分享关于您的信息。汤森路透将根据本声明来处理个人信息，我们鼓励您查阅我们合作伙伴的隐私声明，以进一步了解他们是如何收集、使用和分享个人信息的。
                    </li>
                    <li>
                      <strong>我们的第三方服务提供商：</strong>{" "}
                      我们与世界各地的服务提供商建立了合作，并获得了他们的支持。只有在需要履行他们向我们提供的服务时，例如软件、系统和平台支持、直接营销服务、云托管服务、广告、数据分析和订单履行及交付，我们才会向此类第三方提供个人信息。我们的第三方服务商不得以向我们提供服务之外的任何其它目的分享或使用我们向其提供的个人信息。
                    </li>
                    <li>
                      <strong>出于法律原因的第三方：</strong>{" "}
                      当我们认为在以下情形下有必要时，我们将分享个人信息：
                      <ul>
                        <li>
                          遵守法律义务以及回应包括执法和其他公共机构在内的政府机构（可能包括您所居住的国家之外的此类机构）的请求。
                        </li>
                        <li>
                          发生全部或部分业务、资产或股份兼并、出售、重组、收购、合资、让与、转让或其它处置时（包括与任何破产或类似程序相关的情形）。
                        </li>
                        <li>保护我们的权利、用户、系统和服务。</li>
                      </ul>
                    </li>
                    <li>
                      <strong>电子招聘(eRecruitment)合作伙伴：</strong>{" "}
                      如果您注册成为我们的电子招聘服务的用户以申请工作，您的个人信息，包括您提供的任何敏感个人信息，将被提供给您申请工作的组织。为了让您的申请得到充分的考虑，您的个人信息可能会被转发给您所申请的组织位于全球各地的办事处。
                    </li>
                  </ul>
                  <h3 id="where-personal-information-stored">
                    我们在什么地方存储和处理个人信息
                  </h3>
                  <p>
                    汤森路透是一家全球性组织，您的个人信息可能会在您所在的国家之外进行存储和处理。我们会采取措施确保我们根据本隐私声明以及数据存储的位置的适用法律的要求处理我们收集的信息。
                  </p>
                  <p>
                    汤森路透在世界各地的办事处均拥有网络、数据库、服务器、系统、支持和咨询服务台。我们与世界各地的云托管服务、供应商和技术支持等第三方开展合作，以满足我们的业务、人力和客户方面的需求。我们会采取适当的措施确保按照适用法律对个人信息进行处理、安全防护和转移。某些情况下，我们可能需要在汤森路透内部或向您所在的国家之外的地区披露或转移您的个人信息。接收者所在的地区会不时变更，但可能会包括美国、欧洲、加拿大、亚洲、澳大利亚、印度以及汤森路透开展业务或使用承包商的其他国家。关于汤森路透办事处清单，详见以下的“<a href="#contact-us">
                      联系我们
                    </a>”部分。
                  </p>
                  <p>
                    当我们从欧洲经济区向适用法律不提供与您所在的国家同等程度的数据隐私保护的其它国家转移个人信息时，我们会采取措施提供适当程度的数据隐私保护。换言之，您的权利和您的数据都会得到保护。例如，我们采用经过审批的合同条款、多方数据转移协议、集团内部协议和其他旨在确保个人信息接收者保护此类信息的措施。如果您希望了解我们数据转移做法的更多信息，请联系我们的<a href="#contact-us">
                      数据保护专员
                    </a>。
                  </p>

                  <h3 id="how-personal-information-secured">
                    我们如何确保个人信息的安全{" "}
                  </h3>
                  <p>
                    汤森路透对待数据安全非常严肃，我们采用恰当的技术和程序来保护个人信息。我们的信息安全政策和程序与被广泛接受的国际标准密切吻合，并会定期进行审核和视需要更新，以满足我们的业务需求、技术变更和监管要求。
                  </p>
                  <p>例如：</p>
                  <ul>
                    <li>
                      政策与程序
                      <ul>
                        <li>
                          我们采取各项措施防止数据意外丢失以及未经授权的数据访问、使用、销毁和披露
                        </li>
                        <li>
                          我们制定了业务连续性和灾难恢复策略，旨在保障我们向客户提供的服务的连续性并保护我们的员工和资产
                        </li>
                        <li>我们对个人信息的访问权限实施了恰当的限制</li>
                        <li>
                          我们实施了包括监控和物理措施在内的相关措施和控制手段，以便安全地存储和转移数据
                        </li>
                        <li>我们根据法律要求和业务政策开展隐私影响评估</li>
                      </ul>
                    </li>
                    <li>
                      员工和承包商培训
                      <ul>
                        <li>
                          我们要求定期对有权访问个人信息和其他敏感数据的员工和承包商进行隐私、信息安全和其他适用的培训
                        </li>
                        <li>
                          我们采取措施确保我们的员工和承包商根据我们的信息安全政策和程序以及任何适用的合同条款开展运营
                        </li>
                      </ul>
                    </li>
                    <li>
                      供应商风险管理
                      <ul>
                        <li>
                          我们要求我们的第三方供应商和提供商通过采用合同和安全审核，根据我们的安全政策和程序，保护任何托付给他们的个人信息
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h3 id="time-personal-information-kept">
                    我们会将个人信息保存多久
                  </h3>
                  <p>
                    汤森路透组建了一支记录管理团队，与隐私办公室配合实施与个人信息保存相关的政策和规则。我们根据由业务职能部门、记录级别和记录类型构成的分类方案制定了企业记录保留时间表。只要是法律或业务上合理要求的，我们便会将个人信息保留尽量长的时间。在决定数据保留周期时，汤森路透考虑了本地法律、合同义务以及我们客户的期望和要求。当我们不再需要个人信息时，我们会安全地删除和销毁这些信息。
                  </p>
                  <h3 id="personal-information-access">
                    您访问和更正个人信息的权利
                  </h3>
                  <p>
                    我们尊重您访问和控制您信息的权利，我们会响应信息请求，适用时，我们还会更正、修改或删除您的个人信息。
                  </p>
                  <ul>
                    <li>
                      <strong>个人信息的访问权限：</strong>{" "}
                      如果您申请访问您个人信息的权限，我们会非常高兴地遵守任何相关法律要求和豁免条款，包括身份验证程序。在向您提供数据之前，我们会要求提供您的身份证明以及您与我们互动方面的充足信息，以便我们确定任何相关数据。向您提供数据副本时，我们还可能会向您收取费用（法律禁止的情形除外）。
                    </li>
                    <li>
                      <strong>更正和删除：</strong>{" "}
                      在部分司法管辖区域，如果您的个人信息不准确或需要更新，您有权更正或修订您的个人信息。您还有权申请删除您的个人信息，但是由于法律要求和其它义务和因素，这并非都能实现。请记住，您可以使用相关服务内的“联系我们”选项来更新您的账户信息。
                    </li>
                    <li>
                      <strong>营销偏好：</strong>{" "}
                      如需退订电子邮件营销，您可以使用我们发送的电子邮件通讯函中的取消订阅链接，或访问相关电子邮件偏好中心。关于其他营销偏好，您可以使用相关服务中的“联系我们”选项。与控制Cookie相关的信息可<a href="#cookies">
                        在此
                      </a>查询，与基于兴趣和移动广告相关的信息则可<a href="#advertising">
                        在此
                      </a>查询。
                    </li>
                    <li>
                      <strong>提交投诉：</strong>{" "}
                      如果您对汤森路透管理您个人数据的方式不满意，您有权向数据保护监管机构提出投诉。全国数据保护管理机构的名单可在此查询：{" "}
                      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                      </a>.
                    </li>
                  </ul>
                  <p>
                    如果您有任何与个人信息相关的申请，请联系我们的<a href="#contact-us">
                      数据保护专员
                    </a>。关于与PeopleMap、Westlaw的Public
                    Records以及CLEAR相关的申请，请点击<a href="https://legalsolutions.thomsonreuters.com/law-products/about/legal-notices/privacy/records">
                      此处
                    </a>。
                  </p>
                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>
                  <h2 id="cookies">Cookie和类似技术</h2>
                  <p>
                    汤森路透和我们的第三方提供商设置和使用Cookie和类似技术，以存储和管理用户偏好，提供定位广告，启用内容，以及收集分析及使用数据等。Cookie和其它追踪技术的使用属于网站和应用程序的标准做法，通过此类技术可收集与您在各个应用程序、网站或其他服务上的在线活动相关的信息。下面列出了更多关于我们如何使用Cookie和类似技术以及<a href="#managing-cookies">
                      您可以如何控制和管理
                    </a>它们的信息。
                  </p>

                  <h3 id="what-is-a-cookie">什么是Cookie？</h3>
                  <p>
                    Cookie是存放在计算机或其他设备上的一个小型文本文件，可用于识别用户或设备以及收集信息。取决于功能和用途，Cookie通常可划分为以下四个类别：绝对必需的Cookie、性能Cookie、功能性Cookie和用于营销目的的Cookie。
                  </p>
                  <h3 id="cookie-types">
                    Cookie的类型以及我们为什么使用它们：
                  </h3>
                  <ul>
                    <li>
                      <strong>绝对必需的Cookie：</strong>{" "}
                      此类Cookie是支持您浏览网站并使用其功能所必需的。若没有此类Cookie，将不能提供您所要求的服务，例如添加物品至在线购物车。
                    </li>
                    <li>
                      <strong>性能Cookie：</strong> 此类 cookie
                      收集关于访问者使用网站方式的信息。所收集的信息包括：所使用的浏览器和操作系统、之前访问过的网站域名、访问次数、平均访问时长和查看的页面等。此类Cookie不收集可识别个人身份的信息，只收集综合及匿名的信息。性能Cookie用于改善网站的用户友好程度，强化您的体验。
                    </li>
                    <li>
                      <strong>功能性Cookie：</strong>{" "}
                      此类Cookie允许网站记住您做出的选择（例如您的用户名或ID、语言偏好或您所在的地区或区域），并提供更加个性化的强化功能。此类Cookie还可用于记住您对文本大小、字体和其他可自定义的网页部分做出的更改。此外，还可用于提供您要求的服务，例如观看视频或评论一篇博文。此类Cookie收集的信息为匿名信息，不能追踪您在其它网站上的浏览活动。
                    </li>
                    <li>
                      <strong>定位和广告Cookie：</strong>{" "}
                      此类Cookie会追踪浏览习惯，并用于提供（基于兴趣的）定位广告。它们还用于限制您观看广告的次数，以及衡量广告活动的有效性。此类Cookie通常由广告网络在获得网站运营商的许可后投放。它们会记住您访问过某个网站，这一信息会被分享给广告商等其他组织。
                    </li>
                  </ul>
                  <h3 id="managing-cookies">管理 Cookie</h3>
                  <p>
                    您可以在浏览器设置中管理网站Cookie，您始终可以选择接受、拒绝或删除Cookie，从而更改此类设置。如果您选择更改您的设置，您可能会发现服务的部分功能和特性将不再有效。不同浏览器的Cookie管理设置略有不同，您应该参考您所用浏览器的相关设置。
                  </p>
                  <p>
                    我们理解您可能希望了解更多关于Cookie的信息。以下是部分非常有用的资源，这些资源提供了Cookie类型、如何使用Cookie以及如何管理Cookie偏好方面的详细信息：<a href="https://www.aboutcookies.org">
                      www.aboutcookies.org
                    </a>或<a href="https://www.allaboutcookies.org">
                      www.allaboutcookies.org
                    </a>。请点击下面的链接，详细地了解如何在常用浏览器中禁用和删除Cookie：
                  </p>
                  <ul>
                    <li>
                      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                        Mozilla Firefox®
                      </a>
                    </li>
                    <li>
                      <a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">
                        Microsoft® Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=en">
                        Google Chrome™
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&locale=de_DE">
                        Safari®
                      </a>
                    </li>
                  </ul>
                  <h3 id="other-tracking-tech">其它追踪技术：</h3>
                  <ul>
                    <li>
                      <strong>本地共享对象/Flash Cookie：</strong> Flash
                      Cookie，亦称为本地共享对象，专门设计用于支持Adobe
                      Flash支持的浏览器内容。此类Cookie通常用于支持网站上的广告和视频内容。与其它Cookie一样，此类Cookie会将信息存储在您的设备上，部分对Flash支持的内容则是特定的。Flash
                      Cookie只能在Adobe
                      Flash中删除，不能通过浏览器删除。请访问以下帮助页面，了解如何管理您的隐私设置以及如何删除Flash
                      Cookie：{" "}
                      <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                        https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
                      </a>
                    </li>
                    <li>
                      <strong>网络信标：</strong>{" "}
                      我们的网页可能含有被称为网络信标的电子图像（亦称为单像素gif和透明图像），我们用其帮助在我们的网站上发送Cookie，记录用户访问这些站点的次数，提供服务以及分析我们广告活动的有效性等。我们还会在我们的营销电子邮件消息或新闻通讯稿中使用网络信标，以确定电子邮件是否被打开或链接是否被点击。
                    </li>
                    <li>
                      <strong>网络服务器与应用日志：</strong>{" "}
                      我们的服务器会自动收集部分信息，以帮助我们管理和保护服务，分析使用情况，以及改善用户体验。所收集的信息包括：
                      <ul>
                        <li>IP地址和浏览器类型</li>
                        <li>
                          设备信息包括唯一设备标识符(UDID)、MAC地址、广告商标识符(IFA)以及我们或其他人可能分配的类似标识符
                        </li>
                        <li>设备操作系统和其他技术事实</li>
                        <li>您访问我们的网站时所在的城市、省份/州和国家</li>
                        <li>访问的网页和查看、存储及购买的内容</li>
                        <li>输入的信息或文本</li>
                        <li>点击的链接和按钮</li>
                        <li>您在使用我们的服务之前和之后访问的URL</li>
                      </ul>
                    </li>
                  </ul>

                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>

                  <h2 id="advertising">基于兴趣的广告(IBA)</h2>
                  <p>
                    基于兴趣的广告(IBA)允许我们向使用我们服务的用户提供定位广告。IBA的工作方式是根据您访问或阅读的内容向您展示广告。例如，当您浏览我们的服务时，存放在您设备上的Cookie之一属于广告Cookie，这样我们能够更好地理解您对哪些类型的网页或内容感兴趣。所收集的关于您设备的信息能够帮助我们将您与具有类似兴趣的其他设备归类在一起。我们然后会向基于共同兴趣的用户类别展示广告。关于IBA的更多信息，请访问：{" "}
                    <a href="https://www.iab.net/public_policy/behavioral-advertisingprinciples">
                      https://www.iab.net/public_policy/behavioral-advertisingprinciples
                    </a>.
                  </p>
                  <h3 id="opting-out-iba">退订IBA：</h3>

                  <p>
                    如果您希望停止接收基于兴趣的广告，这并不意味着您在使用我们的服务时不会再收到广告。这仅意味着我们将不会把收集到的关于您的信息用于IBA，而且您看到的任何广告均不是定制化或与您相关的广告。您可以点击<a href="https://optout.aboutads.info">
                      此处
                    </a>或点击广告中的广告选项(AdChoices)图标并遵循相关操作说明来设置在线广告选项。您还可以通过网络广告促进协会(NAI)的退订工具（<a href="https://www.networkadvertising.org/choices">
                      https://www.networkadvertising.org/choices
                    </a>）,欧盟的地址为：<a href="https://www.youronlinechoices.com/">
                      https://www.youronlinechoices.com/
                    </a>，来停止接收基于兴趣的广告。请记住，如果您删除Cookie，使用不同的设备或更改网络浏览器，您可能需要再次退订。
                  </p>

                  <h3 id="mobile-advertising">移动设备上的广告</h3>
                  <p>
                    移动设备拥有赋予各个公司向特定移动设备提供定位广告的标识符。许多情况下，您可以关闭移动设备的广告追踪，或者您可以随时在您的移动设备隐私设置中重置广告标识符。您可用于控制移动设备上广告的另一个工具是AppChoices应用程序：<a href="https://youradchoices.com/appchoices">
                      https://youradchoices.com/appchoices
                    </a>。您还可以选择关闭您移动设备上的位置追踪。
                    通过关闭广告追踪或位置追踪，我们将不再把从您设备的广告标识符中收集的信息用于广告目的。您可能仍然会看到相同数量的广告，但它们的相关性会更差，因为它们不是根据您的兴趣来提供的。
                  </p>

                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>

                  <h2 id="dnt">请勿追踪</h2>
                  <p>
                    部分浏览器会传输请勿追踪(DNT)信号给网站。由于整个行业缺乏对DNT信号的通用解释，汤森路透目前不会更改、变更或响应来自这些浏览器的DNT请求或信号。我们会继续监控这个领域的行业活动，并在必要时重新评估我们的DNT实践。
                  </p>
                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>

                  <h2 id="social-networks">通过社交网络保持联系</h2>
                  <p>
                    我们的部分服务可能会包含社交网络功能，例如Facebook的“赞”按钮和功能、“分享”按钮以及互动小程序。此外，您还可选择使用您自己的社交网络（如Facebook或LinkedIn）登录名来登录我们的部分服务。如果您选择使用社交网络或类似设备连接，我们可能会接收和存储该服务的验证信息以支持您登录，以及当您连接这些服务后您可能会选择分享的其他信息。这些服务可能会收集您访问的网页和IP地址等信息，而且可能会设置Cookie，以便使功能正常运行。我们不负责此类第三方收集的任何信息的安全性和隐私。您应该查阅您连接、使用或访问的第三方服务所适用的隐私声明或政策。如果您不希望您的个人信息被分享给您的社交媒体账户提供商或其他使用社交媒体服务的用户，请勿使用您的社交媒体账户关联您的服务账户，且不要参与有关该服务的社交分享。
                  </p>

                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>

                  <h2 id="third-party-services">与第三方服务的链接和连接</h2>
                  <p>
                    我们的服务可能会包含链接，您可能会将其用于非我方附属或受我方控制或管理的第三方应用、服务、工具和网站，例如：Facebook、LinkIn、Twitter以及语音软件和阅读器等第三方应用程序。这些第三方的隐私实践受其自身隐私声明的约束。我们不负责此类第三方收集的任何信息的安全性和隐私。您应该查阅这些第三方服务所适用的隐私声明或政策。
                  </p>
                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>

                  <h2 id="children-privacy">儿童隐私</h2>
                  <p>
                    汤森路透为专业人士提供信息解决方案，我们的服务一般不面向儿童。但是，如果我们收集和使用关于儿童的信息，例如用于开发教育资源，我们将遵循行业指南和适用法律的规定。
                  </p>
                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>

                  <h2 id="contact-us">如何联系我们</h2>
                  <p>
                    我们理解您可能会对本声明或我们的隐私实践存在问题或疑虑，或者可能希望提出投诉。请通过以下方式随时联系我们：
                  </p>
                  <h3>汤森路透数据保护专员：</h3>
                </RichText>
                <MarkupContainer>
                  <HorizontalOverflow>
                    <Table>
                      <table>
                        <tbody>
                          <tr>
                            <td>电子邮件：</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="mailto:Privacy.enquiries@thomsonreuters.com">
                                Privacy.enquiries@thomsonreuters.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>地址：</td>
                          </tr>
                          <tr>
                            <td>
                              收件人：数据保护专员 <br /> Thomson Reuters <br />{" "}
                              D5 S1866 <br /> 610 Opperman Dr. <br /> Eagan, MN
                              55123 <br /> USA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Table>
                  </HorizontalOverflow>
                </MarkupContainer>
                <RichText>
                  <h3>营销偏好和服务支持：</h3>
                  <p>
                    如需更新您的账户信息、电子邮件偏好或获得关于您服务的技术支持或其它帮助，请访问相关电子邮件偏好中心或使用相关服务的“联系我们”选项。
                  </p>
                  <p>
                    <a href="https://www.thomsonreuters.com/en/locations.html">
                      如需汤森路透的位置列表，请点击
                    </a>
                  </p>
                  <a href="#top">
                    <strong>返回页首</strong>
                  </a>
                </RichText>
              </Grid.Item>
            </Grid>
          </Section>
        </Language>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default PrivacyZh;
