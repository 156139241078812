import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import DismissibleBanner from "./index";
import "./DismissibleBanner.css";
import DismissibleBannerREADME from "../README.md";

const stories = storiesOf("Components/DismissibleBanner", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(DismissibleBannerREADME));

stories
  .add("Default", () => (
    <DismissibleBanner
      fixed={boolean("Fixed position")}
      heading={text("Heading", "Our Privacy Statement and Cookie Policy")}
      hideIfAlreadySeen={
        false /* When true, this doesn't show well in Storybook */
      }
      text={text(
        "Text",
        "All Thomson Reuters websites use cookies to improve your online experience. They were placed on your computer when you launched this website. You can change your cookie settings through your browser."
      )}
    >
      <DismissibleBanner.LinkItem href="#" text="Privacy Statement" />
      <DismissibleBanner.LinkItem href="#" text="Cookie Policy" />
    </DismissibleBanner>
  ))
  .add("Without links", () => (
    <DismissibleBanner
      fixed={boolean("Fixed position")}
      heading={text("Heading", "Our Privacy Statement and Cookie Policy")}
      hideIfAlreadySeen={
        false /* When true, this doesn't show well in Storybook */
      }
      text={text(
        "Text",
        "All Thomson Reuters websites use cookies to improve your online experience. They were placed on your computer when you launched this website. You can change your cookie settings through your browser."
      )}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <DismissibleBanner
        dismissButtonText={text("Dismiss button text", "ل الثعلب ملكي")}
        fixed={boolean("Fixed position")}
        heading={text("Heading", "مرحبا بالعالم")}
        hideIfAlreadySeen={
          false /* When true, this doesn't show well in Storybook */
        }
        text={text("Text", "بطيئة الكلب الأسود الانحناء قبل الثعلب ملكي")}
      >
        <DismissibleBanner.LinkItem href="#" text="الانحناء" />
        <DismissibleBanner.LinkItem href="#" text="بطيئة" />
      </DismissibleBanner>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <DismissibleBanner
        fixed
        heading="Our Privacy Statement & Cookie Policy"
        hideIfAlreadySeen={
          false /* When true, this doesn't show well in Storybook */
        }
        text="All Thomson Reuters websites use cookies to improve your online experience. They were placed on your computer when you launched this website. You can change your cookie settings through your browser."
      >
        <DismissibleBanner.LinkItem href="#" text="Privacy Statement" />
        <DismissibleBanner.LinkItem href="#" text="Cookie Policy" />
      </DismissibleBanner>
    </StaticRenderer>
  ));
