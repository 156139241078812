import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import Block from "@emcm-ui/component-block";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";

import Longform from "../../index";
import "../../Longform.css";
import LongformREADME from "../../../README.md";

const stories = storiesOf("Components/Longform/ChapterNav", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(LongformREADME));

const chapterPlaceholderImages = [
  "test-images/4-1/chapter-divider-288.jpg 288w",
  "test-images/4-1/chapter-divider-368.jpg 368w",
  "test-images/4-1/chapter-divider-576.jpg 576w",
  "test-images/4-1/chapter-divider-768.jpg 768w",
  "test-images/4-1/chapter-divider-1168.jpg 1168w",
  "test-images/4-1/chapter-divider-1536.jpg 1536w",
  "test-images/4-1/chapter-divider-2336.jpg 2336w",
  "test-images/4-1/chapter-divider-3504.jpg 3504w"
];

stories
  .add("Default", () => (
    <Longform>
      <Longform.ChapterNav sticky={boolean("sticky", true)}>
        <Longform.ChapterNav.Chapter title="Longform Demo" href="#" />
        <Longform.ChapterNav.Chapter
          title="Chapter One"
          href="#chapter-one"
          prefix="1."
        />
        <Longform.ChapterNav.Chapter
          title="Chapter Two"
          href="#chapter-two"
          prefix="2."
        />
        <Longform.ChapterNav.Chapter
          title="Chapter Three"
          href="#chapter-three"
          prefix="3."
        />
      </Longform.ChapterNav>

      <Longform.Child>
        <Block height="600px" />
      </Longform.Child>

      <Longform.Child width="full">
        <Longform.ChapterDivider
          backgroundAnchor="C"
          backgroundGradient={true}
          backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
          backgroundSrcSet={chapterPlaceholderImages.join(", ")}
          chapterText="Chapter 1"
          titleText="Chapter 1"
          id="chapter-one"
        />
      </Longform.Child>

      <Longform.Child>
        <Block height="600px" />
      </Longform.Child>

      <Longform.Child width="full">
        <Longform.ChapterDivider
          backgroundAnchor="C"
          backgroundGradient={true}
          backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
          backgroundSrcSet={chapterPlaceholderImages.join(", ")}
          chapterText="Chapter 2"
          titleText="Chapter 2"
          id="chapter-two"
        />
      </Longform.Child>

      <Longform.Child>
        <Block height="600px" />
      </Longform.Child>

      <Longform.Child width="full">
        <Longform.ChapterDivider
          backgroundAnchor="C"
          backgroundGradient={true}
          backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
          backgroundSrcSet={chapterPlaceholderImages.join(", ")}
          chapterText="Chapter 3"
          titleText="Chapter 3"
          id="chapter-three"
        />
      </Longform.Child>

      <Longform.Child>
        <Block height="600px" />
      </Longform.Child>
    </Longform>
  ))
  .add("Static", () => (
    <Longform>
      <Longform.ChapterNav sticky={boolean("sticky", true)} spy={false}>
        <Longform.ChapterNav.Chapter title="Page One" href="#" prefix="1." />
        <Longform.ChapterNav.Chapter
          title="Page Two"
          href="#"
          prefix="2."
          active
        />
        <Longform.ChapterNav.Chapter title="Page Three" href="#" prefix="3." />
      </Longform.ChapterNav>

      <Longform.Child>
        <Block height="600px" />
      </Longform.Child>
    </Longform>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Longform>
        <Longform.ChapterNav sticky={boolean("sticky", true)}>
          <Longform.ChapterNav.Chapter title="Longform Demo" href="#" />
          <Longform.ChapterNav.Chapter
            title="Chapter One"
            href="#chapter-one"
            prefix="1."
          />
          <Longform.ChapterNav.Chapter
            title="Chapter Two"
            href="#chapter-two"
            prefix="2."
          />
          <Longform.ChapterNav.Chapter
            title="Chapter Three"
            href="#chapter-three"
            prefix="3."
          />
        </Longform.ChapterNav>

        <Longform.Child>
          <Block height="600px" />
        </Longform.Child>

        <Longform.Child width="full">
          <Longform.ChapterDivider
            backgroundAnchor="C"
            backgroundGradient={true}
            backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
            backgroundSrcSet={chapterPlaceholderImages.join(", ")}
            chapterText="Chapter 1"
            titleText="Chapter 1"
            id="chapter-one"
          />
        </Longform.Child>

        <Longform.Child>
          <Block height="600px" />
        </Longform.Child>

        <Longform.Child width="full">
          <Longform.ChapterDivider
            backgroundAnchor="C"
            backgroundGradient={true}
            backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
            backgroundSrcSet={chapterPlaceholderImages.join(", ")}
            chapterText="Chapter 2"
            titleText="Chapter 2"
            id="chapter-two"
          />
        </Longform.Child>

        <Longform.Child>
          <Block height="600px" />
        </Longform.Child>

        <Longform.Child width="full">
          <Longform.ChapterDivider
            backgroundAnchor="C"
            backgroundGradient={true}
            backgroundSrc="test-images/4-1/chapter-divider-1168.jpg"
            backgroundSrcSet={chapterPlaceholderImages.join(", ")}
            chapterText="Chapter 3"
            titleText="Chapter 3"
            id="chapter-three"
          />
        </Longform.Child>

        <Longform.Child>
          <Block height="600px" />
        </Longform.Child>
      </Longform>
    </StaticRenderer>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Longform>
        <Longform.ChapterNav>
          <Longform.ChapterNav.Chapter title="شكل طويل تجريبي" href="#" />
          <Longform.ChapterNav.Chapter
            title="حقبة جديدة للثقة"
            href="#chapter-one"
            prefix="1."
          />
        </Longform.ChapterNav>
      </Longform>
    </Language>
  ));
