import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const DoughnutLegendWrapper = ({ label, type, color, getClassName }) => {
  return (
    <div
      className={getClassName({
        descendantName: classNames(`${type}Legend`)
      })}
    >
      <div
        className={getClassName({
          descendantName: classNames(`${type}LegendBox`)
        })}
        style={{
          backgroundColor: color
        }}
      />
      <span>{label}</span>
    </div>
  );
};

DoughnutLegendWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  getClassName: PropTypes.func.isRequired
};

export default DoughnutLegendWrapper;
