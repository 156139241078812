import React from "react";

import SemanticFooter from "@emcm-ui/component-semantic-footer";

import SampleGlobalFooter from "./SampleGlobalFooter";
import SampleSiteFooter from "./SampleSiteFooter";

const SampleFooter = () => (
  <SemanticFooter>
    <SampleSiteFooter />
    <SampleGlobalFooter />
  </SemanticFooter>
);

export default SampleFooter;
