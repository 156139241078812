import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, boolean, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import LinkCardFooter from "./index";
import "./LinkCardFooter.css";
import Icon from "@emcm-ui/component-icon";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Footers/LinkCardFooter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

stories
  .add("Default", () => (
    <LinkCardFooter
      href={text("href", "https://example.com")}
      title={text("title", "Primary")}
      type={select("type", ["icon", "inline"])}
      icon={
        boolean("External Icon", false) ? (
          <Icon path="./icons/ui/blue/external.svg" size="s" />
        ) : (
          <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
        )
      }
    />
  ))
  .add("Action", () => (
    <LinkCardFooter
      href={text("href", "https://example.com")}
      title={text("title", "Primary")}
      type={select("type", ["icon", "inline"])}
      icon={
        boolean("External Icon", false) ? (
          <Icon path="./icons/ui/blue/external.svg" size="s" />
        ) : (
          <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
        )
      }
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <LinkCardFooter
        href={text("href", "https://example.com")}
        title={text("title", "ابتدائي")}
        type={select("type", ["icon", "inline"])}
        icon={
          boolean("External Icon", false) ? (
            <Icon path="./icons/ui/blue/external.svg" size="s" />
          ) : (
            <Icon path="./icons/ui/blue/arrow-right.svg" size="s" />
          )
        }
      />
    </Language>
  ));
