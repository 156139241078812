/* eslint-disable no-magic-numbers */

import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, number, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import ImageREADME from "../README.md";
import Image, { anchorPoints } from "./index";
import "./Image.css";

import Grid from "@emcm-ui/component-grid";
import Section from "@emcm-ui/component-section";

const stories = storiesOf("Components/Image", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ImageREADME));

stories
  .add("srcSet: Section-width (Default)", () => (
    <Section>
      <Image
        alt={text("alt", "Alt attribute")}
        overlay={select("overlay", [null].concat(Image.overlays)) || null}
        sizes={text("sizes", Image.sizes.section)}
        src="test-images/16-9/large_1.jpg"
        srcSet={text(
          "srcSet",
          "test-images/16-9/large_1.jpg 2000w, test-images/16-9/medium_1.jpg 800w, test-images/16-9/small_1.jpg 400w"
        )}
      />
    </Section>
  ))
  .add("srcSet: Edge-to-edge", () => (
    <Image
      alt={text("alt", "Alt attribute")}
      overlay={select("overlay", [null].concat(Image.overlays)) || null}
      sizes={text("sizes", Image.sizes.edgeToEdge)}
      src="test-images/16-9/large_1.jpg"
      srcSet={text(
        "srcSet",
        "test-images/16-9/large_1.jpg 2000w, test-images/16-9/medium_1.jpg 800w, test-images/16-9/small_1.jpg 400w"
      )}
    />
  ))
  .add("srcSet: 2/3 image", () => (
    <Section>
      <Grid variant="2/3,1/3">
        <Grid.Item>
          <Image
            alt={text("alt", "Alt attribute")}
            overlay={select("overlay", [null].concat(Image.overlays)) || null}
            sizes={text("sizes", Image.sizes.twoThirds)}
            src="test-images/16-9/large_1.jpg"
            srcSet={text(
              "srcSet",
              "test-images/16-9/large_1.jpg 2000w, test-images/16-9/medium_1.jpg 800w, test-images/16-9/small_1.jpg 400w"
            )}
          />
        </Grid.Item>
      </Grid>
    </Section>
  ))
  .add("srcSet: 1/3 image", () => (
    <Section>
      <Grid variant="1/3,2/3">
        <Grid.Item>
          <Image
            alt={text("alt", "Alt attribute")}
            overlay={select("overlay", [null].concat(Image.overlays)) || null}
            sizes={text("sizes", Image.sizes.oneThird)}
            src="test-images/16-9/large_1.jpg"
            srcSet={text(
              "srcSet",
              "test-images/16-9/large_1.jpg 2000w, test-images/16-9/medium_1.jpg 800w, test-images/16-9/small_1.jpg 400w"
            )}
          />
        </Grid.Item>
      </Grid>
    </Section>
  ))
  .add("Sized", () => (
    <Image
      alt={text("alt", "Alt attribute")}
      height={number("height", 200)}
      src={text("src", "headshot.jpg")}
      width={number("width", 200)}
    />
  ))
  .add("Background color fill", () => (
    <Image
      alt={text("alt", "Alt attribute")}
      fillColor={text("fill color", "#0000ff")}
      height={number("height", 450)}
      src={text("src", "test-images/original/large_1.jpg")}
      width={number("width", 800)}
    />
  ))
  .add("Cover fit", () => (
    <Image
      anchor={select("anchor", anchorPoints, "C")}
      alt={text("alt", "Alt attribute")}
      fit={select("fit", ["", "cover"], "cover")}
      fillColor={text("fill color", "#0000ff")}
      overlay={select("overlay", [null].concat(Image.overlays)) || null}
      height={number("height", 400)}
      src={text("src", "test-images/original/large_1.jpg")}
      width={number("width", 400)}
    />
  ))
  .add("Cover fit with implicit sizing", () => (
    <div
      style={{
        width: `${number("implicit width", 400)}px`,
        height: `${number("implicit height", 400)}px`
      }}
    >
      <Image
        anchor={select("anchor", anchorPoints, "C")}
        alt={text("alt", "Alt attribute")}
        fit={select("fit", ["", "cover"], "cover")}
        fillColor={text("fill color", "#0000ff")}
        overlay={select("overlay", [null].concat(Image.overlays)) || null}
        src={text("src", "test-images/original/large_1.jpg")}
      />
    </div>
  ));
