import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SemanticMain = ({ children, role }) => {
  const getClassName = getClassNameFactory(SemanticMain.displayName);

  return (
    <main className={getClassName()} role={role}>
      {children}
    </main>
  );
};

SemanticMain.displayName = "SemanticMain";

SemanticMain.propTypes = {
  children: PropTypes.node,
  role: PropTypes.oneOf(["main", "presentation"])
};

SemanticMain.defaultProps = {
  role: "main"
};

export default SemanticMain;
