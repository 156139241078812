import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import Playlist from "@emcm-ui/component-playlist";
import { findIndexByList } from "./utilities";
import Store from "./store";

class KalturaVideoPlaylist extends React.Component {
  constructor(props) {
    super(props);

    this.video = {
      id: props.videoId,
      url: props.url,
      userId: props.userId,
      states: {
        PLAYING: "PLAYING",
        VIEWED: "VIEWED",
        PAUSED: "PAUSED"
      }
    };

    this.playlist = {
      id: props.playlistId,
      lineItems: props.playlist,
      allowedStates: {
        id: "",
        state: ""
      },
      storageKey: "KalturaPlaylist",
      storageName: `${props.userId}_${props.playlistId}`
    };

    this.player = {
      id: props.playerId,
      ref: null,
      refDelay: 300,
      events: {
        playerPlayed: "playerPlayed",
        playerPaused: "playerPaused",
        playerPlayEnd: "playerPlayEnd",
        bufferEndEvent: "bufferEndEvent"
      }
    };

    const playlist = this.generateInitialPlaylist(
      this.playlist.allowedStates,
      this.playlist.storageKey,
      this.playlist.storageName
    );

    this.updatePlaylistState(playlist);
    this.state = { playlist };
  }

  componentDidMount() {
    this.initializePlayerRef();
  }

  componentWillUnmount() {
    const playerRef = this.player.ref;

    if (playerRef) {
      const events = this.player.events;

      playerRef.kUnbind(events.playerPlayed);
      playerRef.kUnbind(events.playerPaused);
      playerRef.kUnbind(events.playerPlayEnd);
      playerRef.kUnbind(events.bufferEndEvent);
    }
  }

  initializePlayerRef = () => {
    const playerRefTimerId = setInterval(() => {
      const playerRef = document.getElementById(this.player.id);

      if (playerRef && playerRef.evaluate) {
        this.player.ref = playerRef;
        this.attachPlayerEvent(this.state.playlist);
        clearInterval(playerRefTimerId);
      }
    }, this.player.refDelay);
  };

  generateInitialPlaylist = (allowedStates, storageKey, storageName) => {
    const existingStates = Store.get(storageKey);
    const emptyItem = -1;
    let playlist =
      existingStates && existingStates[storageName]
        ? existingStates[storageName]
        : [];

    this.playlist.lineItems.forEach(item => {
      const itemIndex = findIndexByList(playlist, "id", item.id);

      if (itemIndex === emptyItem) {
        return playlist.push({
          ...allowedStates,
          id: item.id
        });
      }

      playlist = this.getUpdatedPlaylist(playlist, itemIndex, item.id);

      return playlist;
    });

    return playlist;
  };

  getUpdatedPlaylist = (playlist, itemIndex, id) => {
    switch (playlist[itemIndex].state) {
      case this.video.states.PLAYING:
        playlist[itemIndex].state = this.video.states.PAUSED;
        break;
      case this.video.states.VIEWED:
        if (this.video.id === id) {
          playlist[itemIndex].state = this.video.states.PLAYING;
        }
        break;
    }

    return playlist;
  };

  updatePlaylistState = playlist => {
    const existingStorageData = Store.get(this.playlist.storageKey);
    const setPlaylistStorage = {
      ...existingStorageData,
      [this.playlist.storageName]: playlist
    };

    Store.set(this.playlist.storageKey, setPlaylistStorage);
  };

  attachPlayerEvent = playlist => {
    const playerRef = this.player.ref;
    const events = this.player.events;
    const id = playerRef.evaluate("{mediaProxy.entry.id}");
    const itemIndex = findIndexByList(playlist, "id", id);
    const playerEvent = state => {
      playlist[itemIndex].state = state;
      this.setState({ playlist }, function() {
        this.updatePlaylistState(playlist);
      });
    };

    playerRef.kBind(events.playerPlayed, () => {
      playerEvent(this.video.states.PLAYING);
    });

    playerRef.kBind(events.playerPaused, () => {
      playerEvent(this.video.states.PAUSED);
    });

    playerRef.kBind(events.playerPlayEnd, () => {
      playerEvent(this.video.states.VIEWED);
    });

    playerRef.kBind(events.bufferEndEvent, () => {
      playerEvent(this.video.states.PLAYING);
    });
  };

  playerActionManager = (state, playerRef) => {
    switch (state) {
      case "PLAYING":
        playerRef.sendNotification("doPause");
        break;
      case "PAUSED":
      case "VIEWED":
        playerRef.sendNotification("doPlay");
        break;
    }
  };

  render() {
    const getClassName = getClassNameFactory(KalturaVideoPlaylist.displayName);

    return (
      <div
        className={getClassName()}
        data-player-id={this.player.id}
        data-playlist-id={this.playlist.id}
        data-playlist={JSON.stringify(this.playlist.lineItems)}
        data-user-id={this.video.userId}
        data-video-id={this.video.id}
        data-url={this.video.url}
        data-rehydratable={getRehydratableName(
          KalturaVideoPlaylist.displayName
        )}
      >
        <Playlist
          lineItems={this.playlist.lineItems}
          url={this.video.url}
          activeItemId={this.video.id}
          states={this.state.playlist}
          callback={{
            func: this.playerActionManager,
            ref: this.player.ref
          }}
        />
      </div>
    );
  }
}

KalturaVideoPlaylist.propTypes = {
  /**
   * Video player id, in which playlist video will be played
   */
  playerId: PropTypes.string.isRequired,

  /**
   * Array of objects data structure to view the list of playlist items
   */
  playlist: PropTypes.array.isRequired,

  /**
   * Video playlist id, which would be the root/parent of playlist items
   */
  playlistId: PropTypes.string.isRequired,

  /**
   * Video id, to highlight the video into playlist
   */
  videoId: PropTypes.string.isRequired,

  /**
   * user id used to maintain the local storage list of playlist videos state
   */
  userId: PropTypes.string.isRequired,

  /**
   * video page url, to add/update the selected video into url querystring parameters
   */
  url: PropTypes.string.isRequired
};

KalturaVideoPlaylist.displayName = "KalturaVideoPlaylist";

export default KalturaVideoPlaylist;
