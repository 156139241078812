import ECommLoginServiceConfiguration from "./ECommLoginServiceConfiguration";

export default class extends ECommLoginServiceConfiguration {
  constructor() {
    super();

    this.estoreLocation = "https://qa.store.tax.thomsonreuters.com/accounting";
    this.siteLocation =
      "https://tax-tr-com-uat-ams.ewp.thomsonreuters.com/site";
    this.nameCookies.push(
      "cs_thomsonreuters_com_astate_qa",
      "tax_thomsonreuters_com_astate_qa"
    );
  }
}
