import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Fieldset = ({ children, legend }) => {
  const getClassName = getClassNameFactory(Fieldset.displayName);

  return (
    <fieldset className={getClassName()}>
      {legend && (
        <legend className={getClassName({ descendantName: "legend" })}>
          {legend}
        </legend>
      )}
      {children}
    </fieldset>
  );
};

Fieldset.displayName = "Form.Fieldset";

Fieldset.propTypes = {
  /**
   * Form controls composition
   */
  children: PropTypes.node.isRequired,
  /**
   * Optional legend for the fieldset (bolding supported with `<b>`)
   */
  legend: PropTypes.node
};

export default Fieldset;
