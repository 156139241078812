export const getValidatedDate = date => {
  let validatedDate = date.replace(/ /g, "T"); // ipad specific check to handle invalid date issue

  if (validatedDate.charAt(0) === "-") {
    validatedDate = validatedDate.substr(1, validatedDate.length); // firefox specific check to handle invalid date issue
  }

  return validatedDate; // YYYY-MM-DD HH:mm
};

export const getTimezoneByRegion = (timezones, region) => {
  let timezone = "";
  const regExp = /\(([^)]+)\)/;
  const emptyCheck = -1;

  if (timezones) {
    const timezoneIndex = timezones.findIndex(timezoneItem => {
      return timezoneItem.value === region;
    });

    if (timezoneIndex !== emptyCheck) {
      const timezoneMatches = regExp.exec(timezones[timezoneIndex].label);

      timezone = timezoneMatches instanceof Array ? timezoneMatches[0] : "";
    }
  }

  return timezone;
};

export const getPropertyByName = (itemName, propertyName) => {
  const item = Object.assign({}, ...itemName);

  return item[propertyName] ? item[propertyName] : null;
};

export const getTableRows = table => {
  const rowIndex = 0;

  return table.bodies[rowIndex].rows;
};

export const getTableCellIndexByLabel = (table, label) => {
  let key = null;
  const emptyCheck = -1;

  getTableRows(table).forEach(row => {
    const tableCell = row.cells.find(
      cell => cell.content.indexOf(label) !== emptyCheck
    );

    if (tableCell && key === null) {
      key = tableCell.key;
    }
  });

  return key;
};
