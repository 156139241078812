import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import ChapterNavInner from "./ChapterNavInner";
import Chapter from "./components/Chapter";

const ChapterNav = ({ sticky, ...props }) => {
  const getClassName = getClassNameFactory("LongformChapterNav");

  return (
    <div className={getClassName({ modifiers: classNames({ sticky }) })}>
      {/* Use a separate Inner component so we can use position:sticky on the
        root. This is a workaround for rehydration, which wraps the component
        in a div, intefering with position:sticky.*/}
      <ChapterNavInner {...props} />
    </div>
  );
};

ChapterNav.displayName = "Longform.ChapterNav";

ChapterNav.propTypes = {
  /**
   * Child nodes. Should be `ChapterNav.Chapter` components.
   */
  children: PropTypes.node.isRequired,
  /**
   * Enable sticky behaviour to make the ChapterNav stick to the top of the screen.
   */
  sticky: PropTypes.bool,
  /**
   * Enable scroll spy to update the current chapter as you scroll the page.
   */
  spy: PropTypes.bool
};

ChapterNav.defaultProps = {
  sticky: true,
  spy: true
};

ChapterNav.Chapter = Chapter;

export default ChapterNav;
