import React from "react";

import Heading from "@emcm-ui/component-heading";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import Grid from "@emcm-ui/component-grid";
import LanguageSwitcher from "@emcm-ui/component-language-switcher";
import MarkupContainer from "@emcm-ui/component-markup-container";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Table from "@emcm-ui/component-table";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { Language } from "@emcm-ui/component-theme";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const PrivacyRu = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Language code="ru">
          <Section>
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <VerticalSpacing size="xl">
                  <Heading rank="1" type="l">
                    Положение о конфиденциальности Томсон Рейтер
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="xl">
                  <LanguageSwitcher heading="Available in other languages">
                    <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="nl"
                      title="Nederlands"
                    />
                    <LanguageSwitcher.Item href="#" lang="en" title="English" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="fr"
                      title="Français"
                    />
                    <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="it"
                      title="Italiano"
                    />
                    <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
                    <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="pt"
                      title="Português"
                    />
                    <LanguageSwitcher.Item href="#" lang="es" title="Español" />
                    <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
                  </LanguageSwitcher>
                </VerticalSpacing>
                <RichText large>
                  <h2>Введение</h2>
                  <p>
                    Ваша личная информация и доверие имеет важное значение для
                    нас и настоящее Положение о конфиденциальности («Положение»)
                    содержит важную информацию о том как Томсон Рейтер и
                    аффилированные и дочерние компании Томсон Рейтер по всему
                    миру («Томсон Рейтер», «мы» или «нас») оперируют
                    персональными данными. Настоящее Положение применимо по
                    отношению к любому сайту, приложению, продукту, программному
                    обеспечению или связанной с ним услугой (совместно именуемые
                    как наши «Услуги»). В ряде случаев, в отношении Услуги
                    применяется другое Положение о конфиденциальности, которое
                    обозначает частные правила в отношении такой Услуги.
                  </p>
                  <p>
                    Пожалуйста, внимательно прочитайте настоящее Положение и
                    свяжитесь с нашим{" "}
                    <a href="#contact-us">Директором по Защите Данных</a> в
                    случае, если у Вас возникнут вопросы в отношении отдельных
                    положений о конфиденциальности либо персональных данных. Мы
                    также рекомендуем периодически следить за обновлением
                    данного Положения. В случае, если мы вносим изменения в
                    Положение, которые мы считаем важными, мы проинформируем Вас
                    о них, разместив уведомление в рамках соответствующей Услуги
                    и/или свяжемся с Вами иным способом, например, посредством
                    электронной почты.
                  </p>
                  <p>
                    <strong>
                      Последнее обновление данного Положения датировано 01 Июня
                      2017
                    </strong>
                  </p>
                  <p>Ниже Вы обнаружите следующую полезную информацию:</p>
                  <ul>
                    <li>
                      <a href="#personal-information">Персональные Данные</a>
                      <ul>
                        <li>
                          <a href="#personal-information-collected">
                            Разновидности персональных данных, сбор которых мы
                            осуществляем
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-used">
                            Как мы используем персональные данные
                          </a>
                        </li>
                        <li>
                          <a href="#when-personal-information-shared">
                            Когда мы разглашаем персональные данные{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#where-personal-information-stored">
                            Где мы храним и обрабатываем персональные данные
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-secured">
                            Как мы обеспечиваем защиту персональных данных
                          </a>
                        </li>
                        <li>
                          <a href="#time-personal-information-kept">
                            Как долго мы храним персональные данные
                          </a>
                        </li>
                        <li>
                          <a href="#personal-information-access">
                            Ваше право на доступ к персональным данным и их
                            корректировку
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#cookies">Кукис и Аналогичные Технологии</a>
                      <ul>
                        <li>
                          <a href="#what-is-a-cookie">Что такое Кукис?</a>
                        </li>
                        <li>
                          <a href="#cookie-types">
                            Типы кукис и причина их использования нами
                          </a>
                        </li>
                        <li>
                          <a href="#managing-cookies">Управление Кукис</a>
                        </li>
                        <li>
                          <a href="#other-tracking-tech">
                            Другие трекинговые технологии
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#advertising">
                        Персонализированная Реклама (IBA)
                      </a>
                      <ul>
                        <li>
                          <a href="#opting-out-iba">Отказ от IBA</a>
                        </li>
                        <li>
                          <a href="#mobile-advertising">
                            Реклама на Мобильных Устройствах
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#dnt">Технология Do Not Track (DNT)</a>
                    </li>
                    <li>
                      <a href="#social-networks">
                        Доступ посредством социальных сетей{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#third-party-services">
                        Ссылки на Сторонние Сервисы и Доступ к ним
                      </a>
                    </li>
                    <li>
                      <a href="#children-privacy">Личная Информация Детей</a>
                    </li>
                    <li>
                      <a href="#contact-us">Наши контактные данные</a>
                    </li>
                  </ul>
                  <h2 id="personal-information">Персональные Данные</h2>
                  <p>
                    Томсон Рейтер ответственен за оперирование персональной
                    данныеией и ее защиту.
                  </p>
                  <p>
                    Персональные данные– любая информация, которая относится к
                    любому определенному или определяемому физическому лицу;
                    определяемое физическое лицо – физическое лицо, которое
                    можно идентифицировать напрямую или косвенно, в частности,
                    путем отсылки на имя, идентификационный номер, данные о
                    местонахождении, онлайн идентификатор или на один более
                    частных параметров, таких как физическая, психологическая,
                    генетическая, ментальная, экономическая, культурная или
                    социальная идентичность такого физического лица{" "}
                  </p>
                  <p>
                    Мы собираем, используем, раскрываем, пересылаем и храним
                    персональные данные, когда это необходимо для предоставления
                    наших Услуг , в наших операционных целях и целях бизнеса в
                    соответствиии с настоящим Положении. Мы хотим разъяснить
                    наши практики в отношении конфиденциальности для того, чтобы
                    вы могли совершать осмысленный выбор относительно
                    использования Ваших данных. Мы также рекомендуем Вам{" "}
                    <a href="#contact-us">связываться с нами</a> в любое время в
                    случае вопросов в связи с настоящим Положением.{" "}
                  </p>
                  <div id="personal-information-collected"> </div>
                  <h3>Что такое Кукис?</h3>
                  <p>
                    Мы осуществляем сбор Ваших персональных данных, к примеру :
                    если вы осуществляетке регистрацию на мероприятие,
                    осуществляете запрос информации, приобретаете или
                    используете наши Услуги или запрашиваете клиентскую
                    поддержку. Мы имеем возможность попросить Вас предоставить
                    такую информацию как Ваше имя, адрес, телефонный номер,
                    адрес электронной почты, имя пользователя и пароль, а ткже
                    информацию о Вашем устройстве. Не всякие Ваши персональные
                    данные , которые хранит Томсон Рейтер, могут быть получены
                    только напрямую от Вас. Такие данные могут получены,
                    например, от Вашего работодателя, иных организаций, в
                    которых Вы состоите, или от профессионального провайдера
                    услуг - профессионального налогового консультанта,
                    бухгалтера или юриста, использующиих наши Услуги. Мы также
                    получаем персональные данные от третьих сторон – наших
                    партнеров, провайдеров услуг и всеобще доступных
                    интернет-сайтов для того, чтобы предоставлять Услуги,
                    которые, как мы полагаем, могут представлять интерес, а
                    также для того, чтобы мы могли следить за точностью данных,
                    предоставлять и улучшать Услуги.
                  </p>
                  <p>
                    Дополнительно, наши серверы, регистры и иные технологии{" "}
                    <a href="#other-tracking-tech">
                      собирают определенную информацию автоматическим образом
                    </a>{" "}
                    длы администрирования, защиты и улучшения наших Услуг,
                    анализа использования и улучшения пользовательского опыта.
                    Мы разглашаем персгнальные данные только в пределах,
                    обозначаемых настоящим Положением, либо когда это допустимо
                    или обязательно в силу закона.
                  </p>
                  <p>
                    В отдельнвх случаях мы собираем и осуществлеям обработку
                    персональных данных ограниченного доступа.{" "}
                  </p>
                  <p>
                    Персональные данные ограниченного доступа- вид персональных
                    данных , определяемый как любая информация в отношении
                    рассовой/этнической принадлежности, политических взглядов,
                    религиозных верований, членства в торговых объединениях,
                    физического или психического здоровья, иная информация
                    медицинского характера, включая биометирические и
                    генетические данные, информацию о сексуальной жизнь или
                    сексуальных предпочтениях. В некотрых случаях к персональным
                    данным ограниченного доступа относится факт обвинения в
                    совершении преступления, информация о точной геолокации,
                    финансовая информация и банковские данные, а также
                    уникальные идентификаторы, такие как государственный номер
                    полиса социального страхования, данные водительского
                    удостоверения или данные паспорта.{" "}
                  </p>
                  <p>
                    Например, в случае если Вы что-то приобретаете у нас либо
                    осуществляете подписку на наши Услуги, мы собираем
                    информацию о платежах – финансовую информацию или Ваши
                    банковские данные или иную информацию, которая необходима
                    для осуществления соответствующего платежа. Если Вы или Ваш
                    сотрудник использует наши Услуги Группы Налоги &amp;
                    Бухгалтерские решения (Tax &amp; Accounting), мы собираем и
                    осуществляем обработку финансовой и налоговой информации.
                    Некоторые из наших Услуг запрашивают Вашу точную геолокацию
                    для того, чтобы мы могли повышать точность Услуги и улучшать
                    ваше взаимодействие с Услугой. Мы также можем собирать такую
                    информацию как присвоенный Вам государственный
                    идентификационный номер, в случае, если вы используете наши
                    Услуги электронного рекрутинга для подачи заявки на
                    трудоустройство. Информация, которая считается информацией
                    ограниченного доступа в соответствиии с применимым
                    законодательством, будет использоваться в соответствии с
                    таким законодательством.
                  </p>
                  <div id="how-personal-information-used"> </div>
                  <h3>Типы кукис и причина их использования нами</h3>
                  <p>
                    Мы осуществляем обработку персонльных данных в отношении
                    следующих Услуг и следующих деловых целей:
                  </p>
                  <ul>
                    <li>
                      <strong>Настройка и администрирование аккакунта:</strong>{" "}
                      Мы используем персональные данные, такие как: Ваше имя,
                      адрес электронной почты, телефонный номер и информацию о
                      Вашем устройстве для настройки и администрирования Вашего
                      аккаунта, предоставления технической и клиентской
                      поддержки и треннингов, проверки Вашей личности и рассылки
                      важной информации в отношении аккаунта, подписки, а также
                      информацию в отношении Услуги.
                    </li>
                    <li>
                      <strong>Персонализация:</strong> Мы используем
                      персональные данные для предоставления новостей,
                      исследований, отчетов и бизнес информации, а также для
                      персонализации использования Вами Услуги. Некотрые наши
                      Услуги потребуют разглашения геолокации для того, чтобы мы
                      могли совершенствовать использование Вами Услуг и повышать
                      их точность. Если Вы соглашаетесь разгласить нам Вашу
                      геолокацию, Вы сможете отключить ее в любое время в
                      настройках конфиденциальности на Вашем мобильном
                      устройстве или в режиме онлайн.{" "}
                    </li>
                    <li>
                      <strong>Маркетинг и Мероприятия: </strong> Мы используем
                      персональные данные для информирования Вас в отношении
                      маркетинга и организуемых мероприятий посредством
                      множественных платформ, таких как электронная почта,
                      телефон, текстовые сообщения, режим онлайн и обычная
                      почта. Маркетинговое электронное сообщение, которое мы
                      отсылаем Вам, будет содержать инструкции по исключению Вас
                      из рассылки подобных сообщений в будущем. Мы также создаем
                      центр приоритета электронных сообщений для того, чтобы Вы
                      могли управлять своей информацией и приоритезировать
                      маркетинговые материалы. Помните, что даже если Вы
                      отказываетесь от получения маркетинговых электронных
                      сообщений, мы все равно сможем отправлять Вам важную
                      информацию об Услуге касаемо Ваших аккаунтов и подписок.
                    </li>
                    <li>
                      <strong>Опросы:</strong> В случае, если Вы принимете
                      решение принять участие в опросе, любые персональные
                      данные, котррые Вы предоставляете, могут быть использованы
                      для маркетинговых целей или в целях исследования рынка.
                    </li>
                    <li>
                      <strong>Научные исследования и разработки:</strong> Мы
                      используем персональные данные для целей проведения
                      внутренних исследований и осуществления разработок, а
                      также в целях улучшения и тестирования функционала наших
                      Услуг.{" "}
                    </li>
                    <li>
                      <strong>
                        Чаты, Обмен сообщениями, Сообщество и Форумы:
                      </strong>{" "}
                      Некоторые наши Услуги предоставляют возможность
                      использования чатов, сервисов по обмену сообщениями, а
                      также форумов для общения, осуществления соединение (peer
                      connection), игр и обмена информацией. В зависимости от
                      Услуги персональные данные, которые Вы опубликовываете,
                      разглашаете, загружаете, являются общественно – доступными
                      и видимыми другим пользователям в рамках Услуги. Вам не
                      следует публиковать или распространять любую
                      конфиденциальную информацию или информацию в отношении
                      других лиц, если у Вас нет разрешения на это. Мы можем
                      использовать предоставленную Вами информацию в комьюнити
                      профилях и форумах для персонализации Вашего использования
                      Услуг и реккомендаций в отношении контента и соединения
                      (peer communication). Такие Услуги могут подчиняться
                      специальным Условиям Использования и, если применимо,
                      своим собственным положениям о конфиденциальности. Мы
                      осуществляем модерацию данных Услуг для исполнения наших
                      Условий Использования.{" "}
                    </li>
                    <li>
                      <strong>Хостируемые Услуги:</strong> Некоторые наши Услуги
                      предоставляют возможность хранения данных и документов в
                      качестве составной части такой Услуги. Документы и данные
                      , которые хранятся нашими клиентами, могут содержать
                      персональные данные в рамках налоговых форм, финансовой,
                      платежной информации, а также юридических документов.
                      Любые данные, которые хранятся нашими клиентами или
                      контролируются и управляются от имени наших клиентов
                      доступны таким клиентам или тем, кому это время от времени
                      поручается со стороны клиентов. Доступ к таким данным со
                      стороны нас могут осуществлять только наши сотрудники по
                      обоснованной причине, например, в целях технической
                      поддержки. Пожалуйста, нажмите{" "}
                      <a href="#where-personal-information-stored">сюда</a>,
                      чтобы узнать где мы храним и обрабатываем персональные
                      данные.{" "}
                    </li>
                    <li>
                      <strong>Юридические обязательства:</strong> Мы можем быть
                      обязаны использовать и сохранять персональные данные в
                      юридических и комплаенс целях, таких как предотвращение,
                      обнаружение или расследование преступления, предотвращения
                      ущерба, или мошенничества. Мы также можем использовать
                      персональные данные для соблюдения требований внутреннего
                      и внешнего аудита, целях информационной безопасности, и в
                      следующих случаях, когда это по нашему мнению необходимо
                      или возможно: (a) в соответствии с применимым правом, что
                      может подразумевать в том числе зарубежное право; (b)
                      ответ на запросы судов, законо-исполнительных учреждений,
                      правительственных агенств и иных государственных органов,
                      в том числе зарубежных; (c) исполнение наших условий и
                      положений; и (d) в целях защиты наших прав,
                      конфиденциальности, безопасности или прав собственности, а
                      также перечисленных прав других лиц.{" "}
                    </li>
                  </ul>
                  <div id="when-personal-information-shared"> </div>
                  <h3>Управление Кукис</h3>
                  <p>
                    В случае необходимости Томсон Рейтер разглашает персональные
                    данные для предоставления Услуг или осуществления
                    нижеописанных деловыъ операций. Мы разглашаем персональные
                    данные в соответствии с требованиями безопасности и
                    конфиденциальности. Периодически, мы можем разглашать
                    третьим сторонам анонимные и статистические данные. Ниже
                    перечислены лица, которым и в связи с какимии
                    обстоятельствами мы можем разглашать персональные данные.
                  </p>
                  <ul>
                    <li>
                      <strong>Томсон Рейтер:</strong> Наша деятельность по всему
                      миру осуществляется поддержки множественных департаментов
                      Томсон Рейтер и персональные данные становятся доступными
                      им в случае, когда такие данные необходимы например: для
                      оказания Услуг, администрирования аккаунта, в торговых и
                      маркетинговых целях, в целях технической и клиентской
                      поддержки и в целях развития услуг и бизнеса.Все
                      сотрудники и контрактные работники должны следовать
                      положениям политик о конфиденциальности данных и
                      безопасности при оперированиие персональными данными.
                      Пожалуйста нажмите{" "}
                      <a href="#where-personal-information-stored">сюда</a>,
                      чтобы увидеть список местоположений Томсон Рейтер, в
                      которых может осууществляться обработка персональных
                      данных.{" "}
                    </li>
                    <li>
                      <strong>Наши бизнес-партнеры:</strong> мы периодически
                      сотрудничаем с другими организациями для предоставления
                      совместных Услуг, предоставления контента, проведения
                      совместных мероприятий, конференций и семинаров. В рамках
                      данных инициатив Вы можете выступать в качестве клиента
                      Томсон Рейтер и такой организации, а мы и наши партнеры
                      можем собирать и разглашать информацию о Вас. Томсон
                      Рейтер будет оперировать персональными данными в
                      соответствии с данным Положением и мы просим Вас
                      ознакомиться с положениями о конфиденциальности наших
                      партнеров, чтобы узнать больше о том, какаим образом они
                      осуществляют сбор, использование и разглашение
                      персональных данных.
                    </li>
                    <li>
                      <strong>Наши Сторонние Провайдеры Услуг:</strong> Мы
                      сотрудничаем со сторонними провайдерами услуг по всему
                      миру. Персональные данные будут разглашены только таким
                      провайдерам исключительно для предоставления ими услуг в
                      наш адрес, в частности: программного обеспечения,
                      системной поддержки или поддержки платформ,маркетинговых
                      услуг, услуг клауд-хостинга,рекламы, аналитики данных и
                      исполнения и доставки заказов. Нашим сторонним провайдерам
                      услуг запрещено разглашать или использовать персональные
                      данные , которые мы предоставляем, кроме как для оказания
                      нам услуг.{" "}
                    </li>
                    <li>
                      <strong>
                        Третьи Стороны, обращающиеся к нам в связи с исполненим
                        положений законодательства:
                      </strong>{" "}
                      Мы будем разглашать персональные данные, когда мы
                      посчитаем это необходимым в связи с:
                      <ul>
                        <li>
                          Соблюдение законных обязанностей и ответ на запросы
                          государственных органов, включая правоохранительные
                          органы и другие органы власти, включая иностранные
                        </li>
                        <li>
                          В случае слияния, продажи, реструктуризации,
                          поглощения, совместного предприятия, передачи или иной
                          формы отчуждения бизнеса полностью или частично,
                          отчуждения акций или активов (в том числе в связи с
                          банкротством или аналогичной процедурой)
                        </li>
                        <li>
                          В целях защиты наших прва, пользователей, систем или
                          Услуг
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>
                        Партнеры по электронному рекрутингу (eRecruitment):
                      </strong>{" "}
                      В случае, если Вы регистрируетесь в качестве пользователя
                      наших Услуг электронного рекрутинга для подачи заявки на
                      трудосустройство, Ваши персональные данные, включая любые
                      персональные данные ограниченного доступа, которые Вы
                      предоставляете, будут доступны организации, в которую Вы
                      подаете такую заявку. Для целей рассмотрения Вашей заявки
                      Ваши персональные данные могут быть переданы в
                      международный офис такой организации.
                    </li>
                  </ul>
                  <div id="where-personal-information-stored"> </div>
                  <h3>Другие трекинговые технологии</h3>
                  <p>
                    Томсон Рейтер является глобальной компанией и Ваши
                    персоанльные данные могут храниться и обрабатываться вне
                    пределов Вашей страны. Мы предпринимаем меры для того, чтобы
                    персональные данные, сбор которых мы осуществляем,
                    обрабатывались в соответствии с Положением о
                    Конфиденциальности и требованиями применимого
                    законодательства независимо от местосположения таких данных.{" "}
                  </p>
                  <p>
                    Томсон Рейтер располагает сетями, базами данных ,серверами,
                    системами, службами поддержки в наших офисах по всему миру.
                    Мы взаимодействуем с сервисами клауд-хостинга, поставщиками
                    и службами технологической поддержки, расположенными по
                    всему миру для целяй нашего бизнеса,наших сотрудников и
                    клиентов. Мы предпринимаем должные меры дабы удостовериться,
                    что персональные данные обрабатываются, охраняются и
                    передаются в соответствиии с применимым законодательством. В
                    некоторых случаях нам необходимо разгласить или передать
                    Ваши персональные данные в пределах Томсон Рейтер или
                    третьим сторонам вне пределов Вашей страны. Местоположения,
                    в которых находятся данные получатели, могут меняться время
                    от времени, однако могут включать США, Европу, Канаду, Азию,
                    Австралию, Индию и любые страны, в которых Томсон Рейтер
                    имеет присутствие или в которых оперируют поставщики Томсон
                    Рейтер. Для того, чтобы ознакомиться со списком офисов
                    Томсон Рейтер, пожалуйста, перейдите в раздел{" "}
                    <a href="#contact-us">«Наши контактные данные»</a> ниже.
                  </p>
                  <p>
                    В случае, когда мы передаем персональные данные за границы
                    Европейской Экономической Зоны другим странам, в которых
                    применимое законодательство не предоставляет тот же уровень
                    защиты конфиденциальных данных, что и в Вашей стране, мы
                    предпринимаем меры для того, чтобы предоставить надлежащий
                    уровень защиты конфиденциальности данных. Другими словами,
                    на Ваши данные распространяются те же права и привелегии
                    защиты. Например, мы используем согласованные положения
                    контрактов, многосторнних соглашений по передаче данных,
                    внутригрупповых соглашений и иные средства, направленные на
                    подтверждение защищенности Ваших персональных данных
                    получателями. Если Вы желаете получить более подробную
                    информацию о наших практиках в отношении передачи данных,
                    пожалуйста, обратитесь с нашим{" "}
                    <a href="#contact-us">Директором по защите данных</a>.
                  </p>
                  <div id="how-personal-information-secured"> </div>
                  <h3>Как мы обеспечиваем защиту персональных данных</h3>
                  <p>
                    Томсон Рейтер серьезным образом относится к безопасности
                    данных и использует необходимые технологии и процедуры для
                    защиты персональных данных. Наши политики и процедуры
                    безопасности информации соответствуют широко применимым
                    международным стандартам и регулярно анализируются и
                    обновляются, когда это необходимо для наших деловых нужд, в
                    случае технологических изменений и регуляторных требований.
                  </p>
                  <p>Например:</p>
                  <ul>
                    <li>
                      Политики и процедуры
                      <ul>
                        <li>
                          Мы предпринимаем необходимые меры для предотвращения
                          непреднамеренной утери и неразрешенного доступа к ,
                          использования, уничтожения или разглашения данных
                        </li>
                        <li>
                          У нас есть стратегия Непрерывности Бизнеса и стратегия
                          Восстановления от Непредвиденных ситуаций.
                        </li>
                        <li>
                          Мы накладываем ограничения на доступ к персональным
                          данным
                        </li>
                        <li>
                          Мы применяем необходимые меры, включая мониторинг и
                          применение натуральных показателей для безопасного
                          хранения и передачи данных.
                        </li>
                        <li>
                          Мы осуществляем процедуру Оценки Влияния
                          Конфиденциальности (Privacy Impact Assessment) в
                          соответствии с требованиями законодательства и нашими
                          деловыми политиками.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Тренинги для Сотрудников и Контрактных работников
                      <ul>
                        <li>
                          Мы предусматриваем проведение тренингов по
                          конфиденциальности, информационной безопасности и
                          других тренингов на регулярной основе для наших
                          сотрудников и контрактных работников, которые имеют
                          доступ к персональным данным и другим данным
                          ограниченного доступа.{" "}
                        </li>
                        <li>
                          Мы предпринимаем действия для того, чтобы наши
                          сотрудники и контрактные работники руководствовались
                          политиками и процедурами информационной безопасности и
                          любых применимых контрактных условий.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Управление Торговыми Рисками
                      <ul>
                        <li>
                          Мы побуждаем наших сторонних торговых партнеров и
                          сторонних провайдеров осуществлять действия по защите
                          любых персональных данных, которые им вверяются в
                          соответствии с нашими политиками и процедурами
                          безопасности посредством анализа контрактных условий и
                          обзоров положений о безопасности.{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <div id="time-personal-information-kept"> </div>
                  <h3>Как долго мы храним персональные данные</h3>
                  <p>
                    Отдел Ведения Делопроизводства Томсон Рейтер работает с
                    Директром по Вопрсам Конфиденциальности в целях применения
                    политик и правил в отношении персональных данных. Мы создали
                    График Хранения Документации в Организации, основанный на
                    классификационной схеме, состоящей из Бизнес Функций,
                    Классов Документов и Типов Документов. Мы храним
                    персональные данные в течении периода, необходимого для
                    целей соблюдения законодательства. В процессе установления
                    срока хранения данных Томсон Рейтер принимет во внимание
                    местное законодательство, контрактные обязательства и
                    ожидания и требования наших клиентов. Когда необходимость
                    дальнейшего хранения персональных данных исчерпывает себя мы
                    безопасным образом удаляем или уничтожаем их.
                  </p>
                  <div id="personal-information-access"> </div>
                  <h3>
                    Ваше право на доступ к персональным данным и их
                    корректировку
                  </h3>
                  <p>
                    Мы уважаем ваше право на доступ и контроль Вашей информации
                    и мы ответим на Ваш запрос в отношении информации и если
                    применимо, скорректируем, изменим или удалим Ваши
                    пероснальные данные.
                  </p>
                  <ul>
                    <li>
                      <strong>Доступ к персональным данным:</strong> В случае,
                      если Вы запрашиваете доступ к Вашим персональным данным,
                      мы с радостью предоставим его в соответствии с любыми
                      применимыми требованиями законодательства, включая
                      процедуры установления личности. Перед предоставлением
                      данных в Ваш адрес мы запросим подтверждение личности и
                      предоставление существенной информации относительно нашего
                      взаимодействия с нами, чтобы мы могли обнаружить любые
                      соответствующие данные. Мы также можем взимать с Вас
                      платеж за предоставление Вам копии Ваших данных (за
                      исключением случаев, когда это не разрешено местным
                      законодательством).{" "}
                    </li>
                    <li>
                      <strong>Корректировка и Удаление:</strong> В некоторых
                      юрисдикциях у Вас есть право корректировать или изменять
                      Ваши персональные данные, если они не являются
                      достоверными или если их необходимо обновить. У Вас также
                      может возникнуть право запросить удаление Ваших
                      персональных данных, однако, это не всегда представляется
                      возможным в связи с требованиями законодательства или
                      иными обязанностями и факторами. Помните, что Вы можете
                      обновлять информацию о Вашем аккаунте посредством опции
                      «Контакты» («Contact Us») в рамках соответствующей Услуги.
                    </li>
                    <li>
                      <strong>Маркетинговые предпочтения:</strong> Дял того,
                      чтобы отказаться от маркетинговых электронных сообщений
                      Вам необходимо воспользоваться ссылкой отписки, которую
                      можно обнаружить в электронной рассылке, которую вы
                      получаете от нас либо перейдите по адресу центра email
                      приоритета. В отношении иных маркетинговых приоритетов вы
                      можете воспользоваться опцией «Контакты» («Contact us») в
                      рамках применимой Услуги. С информацией в отношения
                      контроля Кукис можно ознакомиться{" "}
                      <a href="#cookies">здесь</a>, а с информацией в отношении
                      Персонализированной Рекламы и Мобильной Рекламы можно
                      ознакомиться <a href="#advertising">здесь</a>.
                    </li>
                    <li>
                      <strong>Подача Жалобы:</strong> Если Вы не довольны тем,
                      каким образом Томсон Рейтер оперирует Вашими персональными
                      данными, у Вас есть право подать жалобу органу,
                      ответственному за защиту персональных данных. С перечнем
                      Национальных Органов по защите Персональных Данных можно
                      ознакомиться по следующей ссылке:{" "}
                      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                      </a>.
                    </li>
                  </ul>
                  <p>
                    Пожалуйста свяжитесь с Директором по Защите Данных в случае
                    любых запросов в отношении Ваших персональных данных. В
                    случае запросов относительно PeopleMap, Общедоступных
                    источников в рамках Услуги Westlaw и CLEAR, пожалуйста
                    перейдите по этой ссылке.
                  </p>
                  <p>
                    Пожалуйста свяжитесь с{" "}
                    <a href="#contact-us">Директором по Защите Данных</a> в
                    случае любых запросов в отношении Ваших персональных данных.
                    В случае запросов относительно PeopleMap, Общедоступных
                    источников в рамках Услуги Westlaw и CLEAR, пожалуйста
                    перейдите по{" "}
                    <a href="https://legalsolutions.thomsonreuters.com/law-products/about/legal-notices/privacy/records">
                      этой ссылке
                    </a>.
                  </p>
                  <div id="cookies" />
                  <h2>Кукис и Аналогичные Технологии</h2>
                  <p>
                    Томсон Рейтер и наши сторонние поставщики используют кукис и
                    аналогичные технологии, например, для хранения и контроля
                    предпочтений пользователей, доставки целевой рекламы,
                    предоставления контента и сбора аналитических данных и
                    данных об использовании. Использование кукис и других
                    трекинговых технологий является стандартом для сайтов и
                    приложений, посредством которых осуществляется сбор
                    информации относительно Вашей онлайн-активности в рамках
                    приложений, вэб-сайтов, и других Услуг. Дополнительная
                    информация о том, каким образом мы используем кукис и
                    аналогичные технологии и{" "}
                    <a href="#managing-cookies">
                      каким образом мы можем управлять ими
                    </a>{" "}
                    располагается ниже.
                  </p>
                  <div name="what-is-a-cookie" id="what-is-a-cookie">
                    {" "}
                  </div>
                  <h3>Что такое Кукис?</h3>
                  <p>
                    Кукис представляет собой маленький текстовый файл,
                    располагаемый на компъютре или другом устройсте и
                    используемый для идентификации пользователя или устройства
                    для сбора информации. Кукис обычно отнесены к одной из
                    четырех категорий, в зависимости от их функций и назначения:
                    абсолютно необходимые кукис, эксплутационные кукис,
                    функциональные кукис и кукис для маркетинговых целей.
                  </p>
                  <div id="cookie-types"> </div>
                  <h3>Типы кукис и причина их использования нами</h3>
                  <ul>
                    <li>
                      <strong>Абсолютно необходимые кукис:</strong> Такие кукис
                      являются необходимыми для того, чтобы Вы могли
                      пользоваться сайтом и использовать его функции. Без кукис,
                      действия , которые Вы пытаетесь совершить, например,
                      добавление товаров корзину покупок онлайн-магазина, не
                      будет представлять возможным.{" "}
                    </li>
                    <li>
                      <strong>Эксплуатационные кукис:</strong> Эти кукис
                      собирают информацию о Вашем использовании сайтов.
                      Собранная информация включает, например, данные
                      интернет-браузеров используемых операционных систем,
                      доменное имя ранее посещенного сайта, количество
                      посещений, средняя продолжительность визита и
                      просмотренные страницы. Эти кукис не собирают персональные
                      данные, а собирают только агрегированную и ананонимную
                      информацию. Эксплуатационные кукис используются для
                      улучшения удобства использования сайтом.{" "}
                    </li>
                    <li>
                      <strong>Функциональные кукис:</strong> Эти кукис позволяют
                      вэб-сайту запомиаить информацию, определяемую Вами
                      (например имя пользователя или ID, языковые предпочтнения
                      или регион в котором вы находитесь) и предосталяют
                      улучшенные, более персонализированные функции. Эти кукис
                      также могут запоминать изменения шрифта текста, его размер
                      и иные изменяемые элементы вэб-страниц. Они также могут
                      быть использованы для реализации Ваших запросов, таких как
                      просмотр видео или комментирование в блоге. Информация,
                      котрую собирают данные кукис может быть анонимизирована и
                      кукис не смогут отслеживать Вашу интернет-поисковую
                      активность на других сайтах.{" "}
                    </li>
                    <li>
                      <strong>Кукис Таргетированиия и Маркетинга:</strong> Эти
                      кукис отслеживают поисковую активность и используются для
                      предоставления таргетированной рекламы (реклама на основе
                      частых поисковых запросов). Они также используются для
                      ограничения времени, в течение которого отображается
                      реклама а также для анализа эффективности рекламы. Обычно,
                      эти кукис размещаются рекламными сетями по разрешению
                      оператора сайта. Они запоминают , какой сайт Вы посетили и
                      такая информация разглашается в пользу иных организаций, в
                      частности рекламодателями.{" "}
                    </li>
                  </ul>
                  <div id="managing-cookies"> </div>
                  <h3>Управление Кукис</h3>
                  <p>
                    Вы можете управлять кукис сайтов в настройках браузера и у
                    Вас также есть возможность изменить эти настройки путем
                    принятия, отклонения или удаления кукис. Если Вы принимаете
                    решение об изменении настроек, некоторые функции могут
                    перестать работать должным образом. Все настройки браузера
                    несколько отличаются, соответственно, для управления кукис
                    Вам следует перейти по соответствующим ссылкам браузера.
                  </p>
                  <p>
                    Мы понимаем, что Вы хотите узнать более подробную информацию
                    о кукис. Ниже, мы перечислили полезные ссылки,
                    предоставляющие полезную информацию о типах кукси, каким
                    образом они используются и как Вы можете управлять
                    приоритетом кукис:{" "}
                    <a href="https://www.aboutcookies.org">
                      www.aboutcookies.org
                    </a>{" "}
                    и{" "}
                    <a href="https://www.allaboutcookies.org">
                      www.allaboutcookies.org
                    </a>. Пожалуйста, перейдите по ссылкам ниже, чтобы узнать
                    подробную информацию относительно того, каким образом можно
                    заблокировать или удалить кукис в часто используемых
                    браузера:{" "}
                  </p>
                  <ul>
                    <li>
                      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                        Mozilla Firefox®
                      </a>
                    </li>
                    <li>
                      <a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">
                        Microsoft® Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=en">
                        Google Chrome™
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&amp;locale=de_DE">
                        Safari®
                      </a>
                    </li>
                  </ul>
                  <h3>Другие трекинговые технологии</h3>
                  <div id="other-tracking-tech"> </div>
                  <h3>Другие трекинговые технологии</h3>
                  <ul>
                    <li>
                      <strong>
                        Локально Передаваемые Объекты/Flash кукис:
                      </strong>{" "}
                      Flash кукис,также известные как локально передаваемые
                      объекты предназначены для контента браузера поддерживающим
                      Adobe Flash. Обычно, они используются для включения
                      рекламы и видео- контента на сайтах. Как и другие кукис,
                      они хранят информацию на Вашем устройстве, некотрые части
                      которой будут специальными по отношению к Flash контенту.
                      Flash кукис могут быть удалены только в рамках Adobe
                      Flash. Пожалуйста, перейдите по ссылке ниже на следующую
                      страницу для получения информации относительно управления
                      настройками конфиденциальности и удаления Flash кукис:{" "}
                      <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                        https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html{" "}
                      </a>
                    </li>
                    <li>
                      <strong>Вэб-маяки:</strong> Наши вэб-страницы могут
                      содержать электронные изображения, называемые вэб-маяками
                      (также именуемые однопиксельные GIF.-изображния и
                      прозрачные графические изображения) , которые мы
                      используем для доставки сообщений на наших сайтах,
                      определять количество пользователей, посетивших эти сайты,
                      осуществлять доставку услуг и анализировать эффективность
                      наших промо-кампаний. Мы также можем включать вэюб-маяки в
                      наши маркетинговые электронные сообщения или новостные
                      рассылки дабы определить, открывается ли приложение или
                      осуществляется ли переход по ссылке.{" "}
                    </li>
                    <li>
                      <strong>
                        Журнал регистрации вэб-сервера и приложения:
                      </strong>{" "}
                      Наши серверы автоматически собирают определенную
                      информацию для администрирования и защиты Услуг, анализа
                      использования и улучшения пользовательского опыта.
                      Собранная информация включает в себя:
                      <ul>
                        <li>IP адрес и тип браузера</li>
                        <li>
                          Информацию об устройстве, включая Уникальный
                          Идентификатор Устройств (UDID), MAC адрес,
                          Идентификатор Рекламы (IFA) и аналогичные
                          идентификаторы, котрые мы или другие компании могут
                          имплиментировать
                        </li>
                        <li>
                          Операционная система устройства и иные технические
                          особенности{" "}
                        </li>
                        <li>
                          Город, регион, и страна , с территории которой Вы
                          осуществляете доступ к нашему сайту.{" "}
                        </li>
                        <li>
                          Просмотренные, хранимые и приобретаемые страницы и
                          контент{" "}
                        </li>
                        <li>Информацию или вводимый текст </li>
                        <li>Ссылки и нажимаемые конопки </li>
                        <li>URL’ы до и после использования Вами</li>
                      </ul>
                    </li>
                  </ul>
                  <div id="advertising"> </div>
                  <h2>Персонализированная Реклама (IBA)</h2>
                  <p>
                    Персонализированная реклама позаоляет предоставлять
                    таргетированную рекламу пользователям наших Услуг. IBA
                    показывает Вам рекламу на основе потребляемого Вами
                    контента. Например, в то время как Вы используете наши
                    Услуги, один из кукис Вашего устройства будет рекламным куки
                    для того, чтобы мы лучше понимали какой контент Вам
                    интересен. Собранная информация о Вашем устройстве побуждает
                    нас объединить Ваш запрос с другими устройствами,указавшими
                    на схожую сферу интересов. Далее, мы можем показать рекламу
                    пользователям, которая основана на общем интересе. Для
                    получения более подробной информации о (IBA), пожалуйста
                    посетите страницу:{" "}
                    <a href="https://www.iab.net/public_policy/behavioral-advertisingprinciples">
                      https://www.iab.net/public_policy/behavioral-advertisingprinciples
                    </a>.
                  </p>
                  <div id="opting-out-iba"> </div>
                  <h3>Отказ от IBA</h3>
                  <p>
                    Ваш отказ от получения персонализированной рекламы не
                    означаеет , что Вы не будете получать рtкламу в период
                    использования наших Услуг. Это означает, что мы не будем
                    использовать информацию, полученную от Вас для целей IBA и
                    что любая реклама , котрую Вы просматриваете, не будет
                    изменена, либо связана с Вами. Вы можете сделать свой выбор
                    в отношении рекламы, перейдя по этой{" "}
                    <a href="https://optout.aboutads.info">ссылке</a> либо
                    перейти по иконке AdChoices в соответствующем рекламном
                    материале и следовать инструкции. Вы также можете отказаться
                    от получения интернет-рекламы многих сайтов посредством
                    Фунукции отмены в пределах Сетевой Рекламной Инициативы
                    (NAI) (<a href="https://www.networkadvertising.org/choices">
                      https://www.networkadvertising.org/choices
                    </a>), а в пределах ЕС на сайте{" "}
                    <a href="https://www.youronlinechoices.com/">
                      https://www.youronlinechoices.com/
                    </a>{" "}
                    Помните, что в случае, если Вы удаляете кукис, используете
                    другое устройство или изменяете вэб-браузер, Вам потребуется
                    отказаться от IBA еще раз.
                  </p>
                  <div id="mobile-advertising"> </div>
                  <h3>Реклама на Мобильных Устройствах</h3>
                  <p>
                    Мобильные устройства обладают идентификатором, позволяющим
                    компаниям размещать таргетированную рекламу на конкретном
                    мобильном устройстве. Во многих случаях Вы можете отключить
                    трекер рекламы с мобильного устройства либо сбросить
                    настройки рекламного идентификатора в любое время в
                    настройках конфиденциальности Вашего мобильного устройства.
                    Другим способом контроля рекламы на вашем мобильном
                    устройстве является Приложение AppChoices:{" "}
                    <a href="https://youradchoices.com/appchoices">
                      https://youradchoices.com/appchoices
                    </a>. Вы также можете отключить отслеживание местоположения
                    на Вашем мобильном устройстве. В случае отключения
                    определения местоположения мы более не сможем использовать
                    информацию Вашего идентификатора мобильного устройства для
                    целей рекламы. Возможно, Вы будте видеть то же колическтво
                    рекламы, но сама реклама не будет основана на анализе Ваших
                    предпочтений.
                  </p>
                  <h2 id="dnt">Технология Do Not Track </h2>
                  <p>
                    Некоторые браузеры передают сигналы Do Not Track (DNT)
                    вэб-сайтам. По причине отсутствия в индустрии общепринятой
                    интерпретации сигналов DNT Томсон Рейтер в настоящий момент
                    не преобразует, не изменяет или не отвечает на запросы DNT
                    или сигналы от таких браузеров. Мы продолжим осуществлять
                    мониторинг активности индустрии в данной сфере и в случае
                    необходимости пересмотрим наши DNT практики.
                  </p>
                  <h2 id="social-networks">
                    Доступ посредством Социальных Сетей
                  </h2>
                  <p>
                    Некотрые наши Услуги иогут содержать функции социальных
                    сетей, такие как кнопка и виджет «Лайк» («Like»), кнопка и
                    «Поделиться» («Share») и интерактивные мини-программы.
                    Дополнительно, Вы можете использовать Ваши логины социальных
                    сетей, таких как Facebook или LinkedIn для доступа к нашим
                    Услугам. Если Вы осуществляете подключение через социальные
                    сети или аналогичные сервисы, мы имеем возможность получения
                    или хранения информации об аутентификации из данного сервиса
                    для того, чтобы Вы могли осуществить вход, а также иной
                    информации, которую Вы разглашаете, используя данные
                    сервисы. Данные сервисы могут осуществлять сбор информации о
                    вэб-страницах, которые Вы посещали, IP адрес, а также могут
                    устанавливать кукис для надлежащей работы функций данных
                    сервисов. Мы не несем ответственность за безопасность или
                    конфиденциальность информации, собранной такими сервисами.
                    Мы рекомендуем Вам изучить политики и положения о
                    конфиденциальности, применимые в отношении сторонних
                    сервисов, которые Вы используете. Если Вы не хотите, чтобы
                    Ваши персональные данные были разглашены в пользу Вашего
                    провайдера аккаунта социальной сети или в пользу иных
                    пользователей социальной сети, не осуществляйте привязку
                    Вашего аккаунта в социальных сетях к аккаунту в рамках Услуг
                    и не разглашайте социальную информацию в рамках Услуг .
                  </p>
                  <h2 id="third-party-services">
                    Ссылки на Сторонние Сервисы и Доступ к ним
                  </h2>
                  <p>
                    Наши Услуги могут содержать ссылки на сторонние приложения,
                    услуги, функции и интернет-сайты, и использоваться совместно
                    с ними, но мы не осуществляем контроль за ними. Примерами
                    таких Сервисов являются Facebook, LinkedIn, Twitter и
                    сторонние приложения (например голосовое программное
                    обеспечение, приложения для чтения текста ) Практики
                    конфиденциальности сторонних организаций подчиняются
                    положениям о конфиденциальности таких организаций. Мы не
                    несем ответственность за конфиденциальность или сохранность
                    любой информации, собираемой такими сторонними
                    организациями. Вам следует ознакомиться с положениями о
                    конфиденциальности или соответствующими политиками,
                    применимыми к сторонним сервисам.{" "}
                  </p>
                  <h2 id="children-privacy">Личная Информация Детей</h2>
                  <p>
                    Томсон Рейтер предоставляет информацию профессионалам и наши
                    Услуги, главным образом, не нацелены на нужды детей. Однако,
                    в случае, если мы осуществляем сбор и испольование
                    информации о детях, например, в целях разработки
                    образовательных ресурсов, мы будем соблюдать применимые в
                    индустрии правила и применимое законодательство.
                  </p>
                  <h2 id="contact-us">Наши контактные данные</h2>
                  <p>
                    Мы понимаем, что у Вас могут возникнуть вопросы относительно
                    содержания данного Положения или касательно наших практик
                    конфиденциальности, либо Вы пожелаете направить жалобу. В
                    этих случае Вы можете связаться с нами следующим образом:
                  </p>
                  <h3>Директор по Защите Данных Томсон Рейтер:</h3>
                </RichText>
                <MarkupContainer>
                  <HorizontalOverflow>
                    <Table>
                      <table>
                        <tbody>
                          <tr>
                            <td>Email:</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="mailto:Privacy.enquiries@thomsonreuters.com">
                                Privacy.enquiries@thomsonreuters.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Адрес:</td>
                          </tr>
                          <tr>
                            <td>
                              Вниманию: Директор по Защите Данных
                              <br /> Thomson Reuters
                              <br /> D5 S1866
                              <br /> 610 Opperman Dr.
                              <br /> Eagan, MN 55123
                              <br /> USA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Table>
                  </HorizontalOverflow>
                </MarkupContainer>
                <RichText>
                  <h3>Маркетинговые Предпочтения и Сервисная поддержка:</h3>
                  <p>
                    В целях обновления Вашей информации об аккаунте,
                    предпочтений в отношении email маркетинга или в случае
                    необходимости технической поддержки и иной помощи в связи с
                    использованием Услуги, пожалуйста, посетите соответствующий
                    центр приоритета электронных сообщений, либо воспользуйтесь
                    опцией «Связаться с нами» («Contact us») в рамках
                    соответствующей Услуги
                  </p>
                  <p>
                    <a href="https://www.thomsonreuters.com/en/locations.html">
                      Для просмотра Местоположений офисов Томсон Рейтер,
                      пожалуйста, перейдите по ссылке :{" "}
                    </a>
                  </p>
                </RichText>
              </Grid.Item>
            </Grid>
          </Section>
        </Language>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default PrivacyRu;
