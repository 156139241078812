import React from "react";
import PropTypes from "prop-types";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const SelectControl = ({
  defaultValue,
  disabled,
  id,
  labelText,
  name,
  onBlur,
  onChange,
  options,
  placeholder,
  placeholderText,
  required,
  value
}) => {
  const getClassName = getClassNameFactory(SelectControl.displayName);

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "label" })} htmlFor={id}>
        <span className={getClassName({ descendantName: "labelText" })}>
          {labelText}{" "}
          {!required && (
            <span className={getClassName({ descendantName: "optional" })}>
              (Optional)
            </span>
          )}
        </span>
        <span className={getClassName({ descendantName: "input" })}>
          <select
            className={getClassName({ descendantName: "select" })}
            defaultValue={defaultValue}
            disabled={disabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            required={required}
            value={value}
          >
            {placeholder && (
              <option
                className={getClassName({ descendantName: "option" })}
                value=""
              >
                {placeholderText}
              </option>
            )}
            {options.map(option => (
              <option
                className={getClassName({ descendantName: "option" })}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </select>
          <span className={getClassName({ descendantName: "arrow" })}>
            <SVGIcon name="caret" size="s" />
          </span>
        </span>
      </div>
    </div>
  );
};

/* eslint-disable max-len */
SelectControl.propTypes = {
  /**
   * Initialize <select> with a value
   */
  defaultValue: PropTypes.string,
  /**
   * Disable the <select>
   */
  disabled: PropTypes.bool,
  /**
   * Id attribute for <select> (and <label> `for` atrribute)
   */
  id: PropTypes.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Name attribute for <select>, required for serialisation
   */
  name: PropTypes.string.isRequired,
  /**
   * Listen for blur on the <select>.
   */
  onBlur: PropTypes.func,
  /**
   * Listen for changes on the <select>
   *
   * N.B. onChange should only be used for storing the value. Any action related to the value of the <select> should be handled onBlur. See https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/no-onchange.md
   */
  onChange: PropTypes.func,
  /**
   * Array of objects with `value` and `label` properties
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  /**
   * Show a placeholder <option>
   */
  placeholder: PropTypes.bool,
  /**
   * Text for placeholder <option>
   */
  placeholderText: PropTypes.string,
  /**
   * Set <select> to be required
   */
  required: PropTypes.bool,
  /**
   * Explicitly set the value of the <select>. Will switch to controlled mode
   */
  value: PropTypes.string
};
/* eslint-enable max-len */

SelectControl.defaultProps = {
  placeholder: true,
  placeholderText: "Select…"
};

SelectControl.displayName = "Form.SelectControl";

export default SelectControl;
