import React from "react";
import CampaignControl from "./CampaignControl";

// eslint-disable-next-line require-await
export default async (domNode, { campaignTracking }) => {
  const props = {
    isRehydrating: true,
    defaultValue: domNode.getAttribute("data-default-value"),
    name: domNode.getAttribute("name"),
    parameterToTrack: domNode.getAttribute("data-parameter-to-track"),
    service: campaignTracking
  };

  return <CampaignControl {...props} />;
};
