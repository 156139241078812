import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SectionBridge = ({ children, backgroundTop, backgroundBottom }) => {
  const getClassName = getClassNameFactory(SectionBridge.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames(
          `${backgroundTop}Top`,
          `${backgroundBottom}Bottom`
        )
      })}
    >
      <div className={getClassName({ descendantName: "inner" })}>
        {children}
      </div>
    </div>
  );
};

SectionBridge.backgrounds = ["grayLighter", "grayLightest", "white"];

SectionBridge.defaultProps = {
  backgroundBottom: "grayLightest",
  backgroundTop: "white"
};

SectionBridge.displayName = "SectionBridge";

SectionBridge.propTypes = {
  /**
   * Markup elements to render in SectionBridge
   */
  children: PropTypes.node,

  /**
   * Set background color for top half of SectionBridge
   */
  backgroundTop: PropTypes.oneOf(SectionBridge.backgrounds),

  /**
   * Set background color for bottom half of SectionBridge
   */
  backgroundBottom: PropTypes.oneOf(SectionBridge.backgrounds)
};

export default SectionBridge;
