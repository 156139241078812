import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Standfirst from "./index";
import "./Standfirst.css";
import StandfirstREADME from "../README.md";

const stories = storiesOf("Components/Standfirst", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(StandfirstREADME));

stories
  .add("Default", () => (
    <Standfirst>
      {text("Text", "The slow black dog bows before the regal fox")}
    </Standfirst>
  ))
  .add("Inline markup", () => (
    <Standfirst>
      The <em>slow black dog</em>{" "}
      <a href="https://www.example.com">bows down before</a> the{" "}
      <strong>regal fox</strong>
    </Standfirst>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Standfirst>بطيئة الكلب الأسود الانحناء قبل الثعلب ملكي</Standfirst>
    </Language>
  ));
