export default {
  /**
   * The user is logged out, or has not logged in.
   */
  LOGGED_OUT: 1,

  /**
   * The user is logged in.
   */
  LOGGED_IN: 2,

  /**
   * The auth state is unknown.
   *
   * This likely means that the auth state will be changing. For example, the
   * service may be awaiting a response from a server.
   */
  UNKNOWN: 3
};
