class VideoPlayerRegistry {
  constructor() {
    this.players = [];
  }

  register(videoPlayer) {
    this.players.push(videoPlayer);
  }

  deregister(videoPlayer) {
    this.players = this.players.filter(p => p !== videoPlayer);
  }

  deactivateAll(videoPlayer = null) {
    const players =
      videoPlayer === null
        ? this.players
        : this.players.filter(p => p !== videoPlayer);

    for (const player of players) {
      player.deactivate();
    }
  }
}

export default new VideoPlayerRegistry();
export { VideoPlayerRegistry };
