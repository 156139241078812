import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import SearchForm from "./index";
import "./SearchForm.css";
import SearchFormREADME from "../README.md";

const stories = storiesOf("Components/SearchForm", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SearchFormREADME));

stories
  .add("Default", () => (
    <SearchForm
      action={text("Action", "https://www.example.com")}
      method={select("Method", SearchForm.methods, "get")}
      searchInput={{ typeahead: false }}
    />
  ))
  .add("Initial values", () => (
    <SearchForm
      action={text("Action", "https://www.example.com")}
      initialValues={{ q: "My value" }}
      method={select("Method", SearchForm.methods, "get")}
      searchInput={{ typeahead: false, value: "My value" }}
    />
  ))
  .add("Suggestions", () => (
    <SearchForm
      action={text("Action", "https://www.example.com")}
      suggestion={{
        text: "new term",
        href: "https://example.com"
      }}
      method={select("Method", SearchForm.methods, "get")}
      searchInput={{ typeahead: false, value: "new tern" }}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <SearchForm
        action={text("Action", "https://www.example.com")}
        method={select("Method", SearchForm.methods, "get")}
        searchInput={{ typeahead: false }}
      />
    </Language>
  ));
