import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, boolean } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import DownloadCardFooter from "./index";
import "./DownloadCardFooter.css";
import Icon from "@emcm-ui/component-icon";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Footers/DownloadCardFooter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

stories
  .add("Default", () => (
    <DownloadCardFooter
      dateTime={text("dateTime", "2016-03-02 13:57")}
      disabled={boolean("disabled", false)}
      callToAction={text("Call to Action", "")}
      callToActionHref={text("Call to Action Href", "")}
      fileLabel={text("fileLabel", "Download")}
      size={text("size", "20.54KB")}
      href={text(
        "href",
        "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      )}
      icon={
        boolean("disabled", false) ? (
          <Icon path="./icons/ui/grey/locked-07.svg" size="s" />
        ) : (
          <Icon path="./icons/ui/grey/download-07.svg" size="s" />
        )
      }
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <DownloadCardFooter
        dateTime={text("dateTime", "2016-03-02 13:57")}
        disabled={boolean("disabled", false)}
        fileLabel={text("fileLabel", "دقائق.zip")}
        size={text("size", "دقائقKB")}
        href={text(
          "href",
          "https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
        )}
        icon={
          boolean("disabled", false) ? (
            <Icon path="./icons/ui/grey/locked-07.svg" size="s" />
          ) : (
            <Icon path="./icons/ui/grey/download-07.svg" size="s" />
          )
        }
      />
    </Language>
  ));
