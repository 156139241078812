import React from "react";

export default {
  ar: {
    name: "Arabic",
    sample: (
      <span>
        بطيئة الكلب الأسود الانحناء قبل <em>الثعلب ملكي</em>
      </span>
    )
  },
  de: {
    name: "German",
    sample: (
      <span>
        Der langsame schwarze Hund verbeugt sich vor dem{" "}
        <em>königlichen Fuchs</em>
      </span>
    )
  },
  en: {
    name: "English (Generic)",
    sample: (
      <span>
        The slow black dog bows before the <em>regal fox</em>
      </span>
    )
  },
  "en-AU": {
    name: "English (Australia)",
    sample: (
      <span>
        The slow black dog bows before the <em>regal fox</em>
      </span>
    )
  },
  "en-CA": {
    name: "English (Canada)",
    sample: (
      <span>
        The slow black dog bows before the <em>regal fox</em>
      </span>
    )
  },
  "en-GB": {
    name: "English (United Kingdom)",
    sample: (
      <span>
        The slow black dog bows before the <em>regal fox</em>
      </span>
    )
  },
  "en-NZ": {
    name: "English (New Zealand)",
    sample: (
      <span>
        The slow black dog bows before the <em>regal fox</em>
      </span>
    )
  },
  "en-US": {
    name: "English (United States)",
    sample: (
      <span>
        The slow black dog bows before the <em>regal fox</em>
      </span>
    )
  },
  "es-AR": {
    name: "Spanish (Argentina)",
    sample: (
      <span>
        El lento perro negro se inclina ante el <em>majestuoso zorro</em>
      </span>
    )
  },
  "es-ES": {
    name: "Spanish (Spain)",
    sample: (
      <span>
        El lento perro negro se inclina ante el <em>majestuoso zorro</em>
      </span>
    )
  },
  "es-PE": {
    name: "Spanish (Peru)",
    sample: (
      <span>
        El lento perro negro se inclina ante el <em>majestuoso zorro</em>
      </span>
    )
  },
  fr: {
    name: "French",
    sample: (
      <span>
        Le chien noir lent s’incline devant le <em>renard royal</em>
      </span>
    )
  },
  "fr-CA": {
    name: "French (Canada)",
    sample: (
      <span>
        Le chien noir lent s’incline devant le <em>renard royal</em>
      </span>
    )
  },
  it: {
    name: "Italian",
    sample: (
      <span>
        Il cane nero lento lascia davanti alla <em>volpe regale</em>
      </span>
    )
  },
  ja: {
    name: "Japanese",
    sample: <span>遅い黒い犬はリーガルのキツネの前に弓をする</span>
  },
  ko: {
    name: "Korean",
    sample: <span>느리게 검은 개는 리갈 여우 앞에 활을니다.</span>
  },
  nl: {
    name: "Dutch",
    sample: (
      <span>
        De langzame zwarte hond buigt voor de <em>regenvos</em>
      </span>
    )
  },
  "pt-BR": {
    name: "Portuguese (Brazil)",
    sample: (
      <span>
        O cão preto lento se curva diante da <em>raposa real</em>
      </span>
    )
  },
  ru: {
    name: "Russian",
    sample: (
      <span>
        Медленная черная собака кланяется перед <em>царской лисой</em>
      </span>
    )
  },
  tr: {
    name: "Turkish",
    sample: (
      <span>
        Yavaş siyah köpek bir <em>muhteşem tilki</em> boyun eğmek
      </span>
    )
  },
  "zh-Hans": {
    name: "Chinese (simple)",
    sample: <span>慢的黑狗在豪华狐狸之前鞠躬</span>
  },
  "zh-Hant": {
    name: "Chinese (traditional)",
    sample: <span>慢的黑狗在豪華狐狸之前鞠躬</span>
  }
};
