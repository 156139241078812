import ECommLoginServiceConfiguration from "./ECommLoginServiceConfiguration";

export default class extends ECommLoginServiceConfiguration {
  constructor() {
    super();

    this.estoreLocation = "https://store.tax.thomsonreuters.com/accounting";
    this.siteLocation = "https://tax.thomsonreuters.com/site";
    this.nameCookies.push(
      "cs_thomsonreuters_com_astate_prod",
      "tax_thomsonreuters_com_astate_prod"
    );
  }
}
