import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import SemanticArticle from "./index";
import "./SemanticArticle.css";
import SemanticArticleREADME from "../README.md";

const stories = storiesOf("Components/SemanticArticle", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SemanticArticleREADME));

stories
  .add("Default", () => (
    <SemanticArticle>This is the article content.</SemanticArticle>
  ))
  .add("With schema.org", () => (
    <SemanticArticle
      schema={{
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        author: {
          "@type": "Person",
          name: "John Doe"
        },
        dateModified: "2017-06-29T08:00:00+08:00",
        datePublished: "2017-06-29T08:00:00+08:00",
        description: "A brief description of the article.",
        headline: "Sample article headline",
        image: {
          "@type": "ImageObject",
          height: 768,
          url: "https://www.example.com/image.jpg",
          width: 1024
        },
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": "https://example.com"
        },
        publisher: {
          "@type": "Organization",
          name: "Thomson Reuters",
          logo: {
            "@type": "ImageObject",
            url: "tr_logo.jpg",
            width: 360,
            height: 60
          }
        }
      }}
    >
      This is the article content.
    </SemanticArticle>
  ));
