import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import { Language } from "@emcm-ui/component-theme";
import Breadcrumb from "./index";
import "@emcm-ui/component-theme/lib/index.css";
import "./Breadcrumb.css";

const stories = storiesOf("Components/Breadcrumb", module);

stories.addDecorator(withKnobs);

stories
  .add("Default", () => (
    <Breadcrumb>
      <Breadcrumb.Item text={text("Home text", "Home")} href="#1" />
      <Breadcrumb.Item text={text("First page text", "Page 1")} href="#2" />
      <Breadcrumb.Item text={text("Second page text", "Page 2")} href="#3" />
      <Breadcrumb.Item
        current
        text={text("Current page text", "Current page")}
        href="#4"
      />
    </Breadcrumb>
  ))
  .add("Right to left", () => (
    <Language code="ar">
      <Breadcrumb>
        <Breadcrumb.Item
          text={text("Home text", "الصفحة الرئيسية")}
          href="#1"
        />
        <Breadcrumb.Item
          text={text("First page text", "الصفحة الأولى")}
          href="#2"
        />
        <Breadcrumb.Item
          text={text("Second page text", "الصفحة الثانية")}
          href="#3"
        />
        <Breadcrumb.Item
          current
          text={text("Current page text", "الصفحة الحالية")}
          href="#4"
        />
      </Breadcrumb>
    </Language>
  ))
  .add("Long item titles", () => (
    <Breadcrumb>
      <Breadcrumb.Item
        text={text(
          "Home text",
          "The quick brown fox jumped over the incredibly lazy dog that was taking a nap"
        )}
        href="#1"
      />
      <Breadcrumb.Item
        text={text(
          "First page text",
          "The quick brown fox jumped over the incredibly lazy dog that was taking a nap"
        )}
        href="#2"
      />
      <Breadcrumb.Item
        text={text(
          "Second page text",
          "The quick brown fox jumped over the incredibly lazy dog that was taking a nap"
        )}
        href="#3"
      />
      <Breadcrumb.Item
        current
        text={text(
          "Current page text",
          "The quick brown fox jumped over the incredibly lazy dog that was taking a nap"
        )}
        href="#4"
      />
    </Breadcrumb>
  ));
