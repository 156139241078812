import { callIfDefined, safePublish } from "./utilities";

export const getGtmLabel = videoObj =>
  `${videoObj.id}${videoObj.title ? ` | ${videoObj.title}` : ""}`;

export const firePlay = (videoObj, analytics = () => {}) => {
  safePublish("analytics.videoPlay", videoObj);
  callIfDefined(analytics, "Video", "Play", getGtmLabel(videoObj));
};

export const firePause = (videoObj, analytics = () => {}) => {
  safePublish("analytics.videoPause", videoObj);
  callIfDefined(analytics, "Video", "Pause", getGtmLabel(videoObj));
};

export const fireBuffer = videoObj => {
  // GTM doesn't expect a buffer event, so we only send this to PubSub.
  safePublish("analytics.videoBuffer", videoObj);
};

export const fireEnd = (videoObj, analytics = () => {}) => {
  safePublish("analytics.videoEnd", videoObj);
  callIfDefined(analytics, "Video", "Finish", getGtmLabel(videoObj));
};

export const fireChangedClosedCaptions = (videoObj, analytics = () => {}) => {
  safePublish("analytics.changedClosedCaptions", videoObj);
  callIfDefined(
    analytics,
    "Video",
    "changedClosedCaptions",
    getGtmLabel(videoObj)
  );
};
