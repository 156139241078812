import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Grid from "@emcm-ui/component-grid";
import Icon from "@emcm-ui/component-icon";

import {
  ImageCard,
  PairedCard,
  PairedHorizontalCard,
  TextCard,
  TwitterCard,
  ArticleCardFooter,
  LinkCardFooter,
  VideoCardFooter
} from "./index.js";

import CardREADME from "../README.md";

const stories = storiesOf("Components/Card/Grid", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

stories
  .add("2/3,1/3", () => (
    <div style={{ maxWidth: 1164 }}>
      <Grid variant="2/3,1/3">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="large"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/white/time.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_2.jpg"
            imgSrcSet="test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
            imgAnchor="C"
            imgGradient
            largeHeading
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            href="https://www.example.com"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_3.jpg"
            imgSrcSet="test-images/16-9/large_3.jpg 2000w, test-images/16-9/medium_3.jpg 800w, test-images/16-9/small_3.jpg 400w"
            imgAnchor="C"
          />
        </Grid.Item>
      </Grid>
    </div>
  ))
  .add("collage-1", () => (
    <div style={{ maxWidth: 1164 }}>
      <Grid variant="collage-1">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/white/time.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_4.jpg"
            imgSrcSet="test-images/16-9/large_4.jpg 2000w, test-images/16-9/medium_4.jpg 800w, test-images/16-9/small_4.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/white/time.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_5.jpg"
            imgSrcSet="test-images/16-9/large_5.jpg 2000w, test-images/16-9/medium_5.jpg 800w, test-images/16-9/small_5.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="large"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/white/time.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_6.jpg"
            imgSrcSet="test-images/16-9/large_6.jpg 2000w, test-images/16-9/medium_6.jpg 800w, test-images/16-9/small_6.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedHorizontalCard
            href="https://www.example.com"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_7.jpg"
            imgSrcSet="test-images/16-9/large_7.jpg 2000w, test-images/16-9/medium_7.jpg 800w, test-images/16-9/small_7.jpg 400w"
            imgAnchor="C"
          />
        </Grid.Item>
      </Grid>
    </div>
  ))
  .add("collage-2", () => (
    <div style={{ maxWidth: 1164 }}>
      <Grid variant="collage-2">
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Products"
            heading="Power your enterprise workflow with Elektron"
            footer={
              <LinkCardFooter
                title="Learn more about Elektron"
                type="icon"
                icon={<Icon path="./icons/ui/white/arrow-right.svg" size="s" />}
              />
            }
            imgSrc="test-images/16-9/large_4.jpg"
            imgSrcSet="test-images/16-9/large_4.jpg 2000w, test-images/16-9/medium_4.jpg 800w, test-images/16-9/small_4.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <ImageCard
            href="https://www.example.com"
            size="small"
            eyebrow="Our experts"
            heading="Dr. Tharindi Hapuarachchi"
            footer={
              <LinkCardFooter
                title="View profile"
                type="icon"
                icon={<Icon path="./icons/ui/white/arrow-right.svg" size="s" />}
              />
            }
            imgSrc="test-images/16-9/large_5.jpg"
            imgSrcSet="test-images/16-9/large_5.jpg 2000w, test-images/16-9/medium_5.jpg 800w, test-images/16-9/small_5.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            href="https://www.example.com"
            eyebrow="Enterprise Risk Management"
            heading="One global organization’s risk exposure to slavery. "
            footer={
              <VideoCardFooter
                runningTime="1 min"
                icon={
                  <Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />
                }
              />
            }
            imgSrc="test-images/16-9/large_6.jpg"
            imgSrcSet="test-images/16-9/large_6.jpg 2000w, test-images/16-9/medium_6.jpg 800w, test-images/16-9/small_6.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            href="https://www.example.com"
            eyebrow="Articles"
            heading="Mercury rising. The heavy social and environmental costs of illegal mining"
            footer={
              <ArticleCardFooter
                attributionName="Sophia P."
                dateTime="2016-02-05 13:57"
                readingTime="1 min"
                icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_7.jpg"
            imgSrcSet="test-images/16-9/large_7.jpg 2000w, test-images/16-9/medium_7.jpg 800w, test-images/16-9/small_7.jpg 400w"
            imgAnchor="C"
          />
        </Grid.Item>
      </Grid>
    </div>
  ))
  .add("even-3", () => (
    <div style={{ maxWidth: 1164 }}>
      <Grid variant="even-3">
        <Grid.Item>
          <TextCard
            size="medium"
            eyebrow="Financial"
            heading="Eikon"
            description="Gain access to trusted news, data, analytics and investment decisions, all filtered to your exact needs in one dedicated space"
            href="https://www.example.com"
            footer={
              <LinkCardFooter
                title="Expand details"
                type="icon"
                icon={<Icon path="./icons/ui/blue/plus.svg" size="s" />}
              />
            }
          />
        </Grid.Item>
        <Grid.Item>
          <TextCard
            size="medium"
            eyebrow="Financial"
            heading="Elektron"
            description="Get real time market data, analytics, news, and more with our low-latency feed"
            href="https://www.example.com"
            footer={
              <LinkCardFooter
                title="Expand details"
                type="icon"
                icon={<Icon path="./icons/ui/blue/plus.svg" size="s" />}
              />
            }
          />
        </Grid.Item>
        <Grid.Item>
          <TextCard
            size="medium"
            eyebrow="Legal"
            heading="Westlaw"
            description="Most advanced legal research technology combined with market-leading content"
            href="https://www.example.com"
            footer={
              <LinkCardFooter
                title="Expand details"
                type="icon"
                icon={<Icon path="./icons/ui/blue/plus.svg" size="s" />}
              />
            }
          />
        </Grid.Item>
        <Grid.Item>
          <TextCard
            size="medium"
            eyebrow="Tax & Accounting"
            heading="OneSource"
            description="Address the complexities of tax and compliance on every level"
            href="https://www.example.com"
            footer={
              <LinkCardFooter
                title="Expand details"
                type="icon"
                icon={<Icon path="./icons/ui/blue/plus.svg" size="s" />}
              />
            }
          />
        </Grid.Item>
        <Grid.Item>
          <TextCard
            size="medium"
            eyebrow="Tax & Accounting"
            heading="Checkpoint"
            description="Blending cutting-edge technology with intelligent linking to related content"
            href="https://www.example.com"
            footer={
              <LinkCardFooter
                title="Expand details"
                type="icon"
                icon={<Icon path="./icons/ui/blue/plus.svg" size="s" />}
              />
            }
          />
        </Grid.Item>
        <Grid.Item>
          <TextCard
            size="medium"
            eyebrow="Risk management"
            heading="Compliance learning"
            description="Engaging, comprehensive, training programs in over 30 different languages"
            href="https://www.example.com"
            footer={
              <LinkCardFooter
                title="Expand details"
                type="icon"
                icon={<Icon path="./icons/ui/blue/plus.svg" size="s" />}
              />
            }
          />
        </Grid.Item>
      </Grid>
    </div>
  ))
  .add("even-3, large", () => (
    <div style={{ maxWidth: 1164 }}>
      <Grid variant="even-3">
        <Grid.Item>
          <PairedCard
            href="https://www.example.com"
            eyebrow="Enterprise Risk Management"
            heading="One global organization’s risk exposure to slavery. "
            footer={
              <VideoCardFooter
                runningTime="1 min"
                icon={
                  <Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />
                }
              />
            }
            imgSrc="test-images/16-9/large_6.jpg"
            imgSrcSet="test-images/16-9/large_6.jpg 2000w, test-images/16-9/medium_6.jpg 800w, test-images/16-9/small_6.jpg 400w"
            imgAnchor="C"
            imgGradient
          />
        </Grid.Item>
        <Grid.Item>
          <PairedCard
            href="https://www.example.com"
            eyebrow="Eyebrow"
            heading="Heading"
            footer={
              <ArticleCardFooter
                attributionName="Sophie P."
                dateTime="2016-03-02 13:57"
                readingTime="5 min"
                icon={<Icon path="./icons/ui/grey/time-07.svg" size="xs" />}
              />
            }
            imgSrc="test-images/16-9/large_3.jpg"
            imgSrcSet="test-images/16-9/large_3.jpg 2000w, test-images/16-9/medium_3.jpg 800w, test-images/16-9/small_3.jpg 400w"
            imgAnchor="C"
          />
        </Grid.Item>
        <Grid.Item>
          <TwitterCard
            timelineDataSource={{
              sourceType: "profile",
              screenName: "thomsonreuters"
            }}
            eyebrow="@thomsonreuters on Twitter"
          />
        </Grid.Item>
      </Grid>
    </div>
  ));
