window.filter = {
  getSavedFilters: () => {
    return {
      status: true,
      // eslint-disable-next-line camelcase
      status_code: 1,
      message: "All filters returned successfully",
      data: [
        {
          tableId: "1",
          name: "Filter1",
          filters: [
            {
              title: "Country",
              type: "list",
              column: "country",
              handle: "bodyfilter_select_country",
              values: "china"
            },
            {
              title: "Account",
              type: "list",
              column: "accounts",
              handle: "bodyfilter_select_account",
              values: "contact"
            },
            {
              title: "Duration",
              type: "list",
              column: "duration",
              handle: "bodyfilter_select_duration",
              values: "6"
            }
          ],
          uuid: "799"
        },
        {
          tableId: "2",
          name: "Filter2",
          filters: [
            {
              title: "Country",
              type: "list",
              column: "country",
              handle: "bodyfilter_select_country",
              values: "uk"
            },
            {
              title: "Account",
              type: "list",
              column: "accounts",
              handle: "bodyfilter_select_account",
              values: "support"
            },
            {
              title: "Duration",
              type: "list",
              column: "duration",
              handle: "bodyfilter_select_duration",
              values: "12"
            }
          ],
          uuid: "799"
        }
      ]
    };
  }
};
