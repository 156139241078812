import className from "classnames";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Section from "@emcm-ui/component-section";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class HorizontalOverflow extends Component {
  static displayName = "HorizontalOverflow";

  static propTypes = {
    background: PropTypes.oneOf(Section.backgrounds),
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(HorizontalOverflow.displayName);

    this.resizeDebounceTimeout = 200;
    this.toggleOverflowIndicators = this.toggleOverflowIndicators.bind(this);
    this.toggleOverflowIndicatorsDebounced = debounce(
      this.toggleOverflowIndicators,
      this.resizeDebounceTimeout
    ).bind(this);

    this.state = { overflowEnd: false, overflowStart: false };
  }

  componentDidMount() {
    window.addEventListener("resize", this.toggleOverflowIndicatorsDebounced);
    this.toggleOverflowIndicators();
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.toggleOverflowIndicatorsDebounced
    );
  }

  toggleOverflowIndicators() {
    if (this.innerEl.offsetWidth >= this.innerEl.scrollWidth) {
      this.setState({
        overflowEnd: false,
        overflowStart: false
      });

      return;
    }

    if (this.innerEl.scrollLeft === 0) {
      this.setState({ overflowStart: false });
    } else {
      this.setState({ overflowStart: true });
    }

    if (
      this.innerEl.scrollLeft >=
      this.innerEl.scrollWidth - this.innerEl.offsetWidth
    ) {
      this.setState({ overflowEnd: false });
    } else {
      this.setState({ overflowEnd: true });
    }
  }

  render() {
    const { background, children } = this.props;

    return (
      <div
        className={this.getClassName({
          modifiers: className(background),
          states: className({
            overflowEnd: this.state.overflowEnd,
            overflowStart: this.state.overflowStart
          })
        })}
        data-background={background}
        data-rehydratable={getRehydratableName(HorizontalOverflow.displayName)}
        ref={ref => {
          this.outerEl = ref;
        }}
      >
        <div
          className={this.getClassName({ descendantName: "inner" })}
          onScroll={this.toggleOverflowIndicators}
          ref={ref => {
            this.innerEl = ref;
          }}
          data-rehydratable-children
        >
          {children}
        </div>
      </div>
    );
  }
}

export default HorizontalOverflow;
