import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { rehydrateChildren } from "react-from-markup";

import Alert from "@emcm-ui/component-alert";
import Loader from "@emcm-ui/component-loader";

const fetchContent = async location => {
  const fetchResponse = await fetch(location, {
    credentials: "same-origin"
  });

  if (!fetchResponse.ok) {
    throw new Error(`Fetch failed for location: ${location}`);
  }

  return fetchResponse.text();
};

class AjaxContent extends Component {
  static displayName = "AjaxContent";

  static propTypes = {
    /**
     * children: whether to load the location by default
     */
    autoLoad: PropTypes.bool,
    /**
     * children: default content to display.
     */
    children: PropTypes.node,

    /**
     * failed content: content to display when in failed state.
     */
    failedContent: PropTypes.node.isRequired,

    /**
     * loading label: spinning loader label when in loading state.
     */
    loadingLabel: PropTypes.string.isRequired,

    /**
     * content location: path or URL to the content to fetch, rehydrate and
     * display.
     */
    location: PropTypes.string,

    /**
     * Override default minimum height of the content by a number in pixels
     */
    minHeight: PropTypes.number,

    /**
     * Add an onChange callback. Called on loading new content
     */
    onChange: PropTypes.func,

    /**
     * Rehydration options
     */
    reactFromMarkupOptions: PropTypes.object,

    /**
     * A list of rehydrators that are available. Used to rehydrate markup from
     * the server.
     */
    reactFromMarkupRehydrators: PropTypes.object
  };

  static defaultProps = {
    autoLoad: true,
    reactFromMarkupRehydrators: {},
    reactFromMarkupOptions: {}
  };

  static conditions = {
    UNLOADED: "unloaded",
    LOADING: "loading",
    LOADED: "loaded",
    FAILED: "failed"
  };

  state = {
    currentLocation: null,
    loadingState: AjaxContent.conditions.UNLOADED,
    retrievedChildren: null
  };

  getClassName = getClassNameFactory(AjaxContent.displayName);

  async componentDidMount() {
    this.componentIsMounted = true;
    if (this.props.autoLoad) {
      await this.loadContent();
    }
  }

  async componentDidUpdate() {
    await this.loadContent();
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  static getDerivedStateFromProps(nextProps, state) {
    // if we have no content to load, we are done
    if (!nextProps.location && state.retrievedChildren) {
      return {
        currentLocation: null,
        loadingState: AjaxContent.conditions.LOADED,
        retrievedChildren: null,
        content: null
      };
    }

    // if location changes, kick off loading
    if (nextProps.location && nextProps.location !== state.currentLocation) {
      return {
        loadingState: AjaxContent.conditions.LOADING,
        retrievedChildren: null
      };
    }

    return null;
  }

  loadContent = async () => {
    const {
      location,
      onChange,
      reactFromMarkupOptions,
      reactFromMarkupRehydrators
    } = this.props;

    if (
      this.state.loadingState !== AjaxContent.conditions.LOADING ||
      this.beganLoading
    ) {
      return;
    }

    this.beganLoading = true;
    try {
      const content = await fetchContent(location);
      const domNode = document.createElement("div");

      domNode.innerHTML = content;

      // if component is not mounted after fetch, abort
      if (!this.componentIsMounted) {
        return;
      }

      const retrievedChildren = await rehydrateChildren(
        domNode,
        reactFromMarkupRehydrators,
        reactFromMarkupOptions
      );

      // Find any script tags that need executing
      const nodes = domNode.querySelectorAll("[data-forge-execute-in-ajax]");

      if (nodes) {
        nodes.forEach(node => {
          if (node.src) {
            // Using a promise allows us to actually download and execute the script.
            new Promise((resolve, reject) => {
              const script = document.createElement("script");

              document.body.appendChild(script);
              script.onload = resolve;
              script.onerror = reject;
              script.defer = true;
              script.src = node.src;
            });
          }
        });
      }

      this.setState({
        retrievedChildren,
        currentLocation: location,
        loadingState: AjaxContent.conditions.LOADED
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch content", e);

      this.setState({
        retrievedChildren: null,
        currentLocation: null,
        loadingState: AjaxContent.conditions.FAILED
      });
    }
    this.beganLoading = false;

    if (onChange) {
      onChange(this.state.loadingState);
    }
  };

  render() {
    const { loadingState, retrievedChildren } = this.state;
    const { children, failedContent, loadingLabel, minHeight } = this.props;
    const showContent = loadingState !== AjaxContent.conditions.FAILED;
    const showLoader = loadingState === AjaxContent.conditions.LOADING;
    const contentClassName = this.getClassName({ descendantName: "content" });

    const baseFontSize = 16;

    return (
      <div
        className={this.getClassName()}
        style={
          minHeight ? { minHeight: `${minHeight / baseFontSize}rem` } : null
        }
      >
        {showContent &&
          retrievedChildren && (
            <div className={contentClassName}>{retrievedChildren}</div>
          )}

        {showContent &&
          !retrievedChildren && (
            <div className={contentClassName}>{children}</div>
          )}

        {failedContent &&
          loadingState === AjaxContent.conditions.FAILED && (
            <div
              className={this.getClassName({
                descendantName: "failed"
              })}
            >
              <Alert state="failure">{failedContent}</Alert>
            </div>
          )}

        {showLoader && (
          <div
            className={this.getClassName({
              descendantName: "loading"
            })}
          >
            <Loader label={loadingLabel} />
          </div>
        )}
      </div>
    );
  }
}

export default AjaxContent;
