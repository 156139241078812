import React from "react";

import Header from "@emcm-ui/component-header";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import ListOfLinks from "@emcm-ui/component-list-of-links";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

import Icon from "@emcm-ui/component-icon";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const Icons = {
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg"
};

const Sitemap = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Section background="grayLightest">
          <VerticalSpacing>
            <Heading type="l">Sitemap</Heading>
          </VerticalSpacing>
          <VerticalSpacing>
            <Header
              heading={
                <Heading rank="2" type="m">
                  Products and Services
                </Heading>
              }
              callToAction={
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  See all products
                </Link>
              }
            />
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <ListOfLinks columns={3} topBorder>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Financial"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Commodities
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Company data
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Emerging Markets
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Equities markets
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Financial analytics
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Financial benchmarks
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Financial market research
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Financial Income Markets
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Foreign exchange market
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Hedge funds
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Investment banking & advisory
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Investment management
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Islamic finance
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Thomson Reuters Lipper
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Market data
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Market indices
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    News & insight
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Open Platform
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Post trade services
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Pricing & valuation data
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Quantitative research & trading
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Thomson Reuters Electron
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Trading platforms
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Venture capital & private equity
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Wealth management solutions
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Government solutions"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax and legal research
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Government conference
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Audit & internal controls
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Food security
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Healthcare fraud detection and prevention
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Illicit finance
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Insider threat
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Land & revenue management
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Law enforcement and investigative solutions
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Market data and analytics
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Regulatory insights
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Screening and due diligence
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Supply chain risk
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Legal"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Large law firm and practice management
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Large law firm business development
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Small law firm and practice management
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Small law firm business development
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Continuing legal education
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Legal academic resources
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Corporate and government practice
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Corporate and government management
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    United States
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    United Kingdom
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Canada
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Latin America
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Europe, Middle East, Africa
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Asia Pacific
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Reuters new agency"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Reuters for broadcasters
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Reuters for brands & agencies
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Reuters for publishers
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Risk management solutions"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Compliance risk
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Corporate governance & controls
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Customer & third party risk
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Enterprise risk
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Financial risk
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Tax & accounting"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Accounting & audit
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax information reporting
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Payroll, pensions and benefits
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Trust & estate planning
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Taxation for individuals
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    International trade
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Continuing professional education (CPE)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax, accounting and practice management for accountants
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax & accounting for coporations
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax & accounting for accounting firms
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax & accounting for government
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax & accounting for financial institutions
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Tax & accounting for law firms
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Corporate taxation
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
            </ListOfLinks>
          </VerticalSpacing>
          <VerticalSpacing>
            <Heading type="m">About us</Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <ListOfLinks columns={3} topBorder>
              <ListOfLinks.Column>
                <ListOfLinks.Link href="https://www.example.com">
                  About us overview
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Awards & recognition
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Board of directors
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Company History
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Executive team
                </ListOfLinks.Link>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Link href="https://www.example.com">
                  Diversity & inclusion
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Thomson Reuters Foundation
                </ListOfLinks.Link>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="The trust principles"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Thomson Reuters founders share company directors
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Corporate responsibilities & inclusion"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Environmental, social, and governance performance
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Our people
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Our markets
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Our World
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
            </ListOfLinks>
          </VerticalSpacing>
          <VerticalSpacing>
            <Heading type="m">Careers</Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <ListOfLinks columns={3} topBorder>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Our jobs"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Sales
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Experienced professionals
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Industry experts
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Technology
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Research and development
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Cognitive computing
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Temporary jobs
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="What we do"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Work that matters
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Our story app
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
                <ListOfLinks.Link href="https://www.example.com">
                  Accomodations for applicants
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Who we are
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Connect with us
                </ListOfLinks.Link>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Recent graduates"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Internships
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Recent graduates
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Recent graduates - Business and finance
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Recent graduates - Journalism
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Recent graduates - Technology
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Recent graduates - Sales
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Internships - Business and finance
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Internships - Journalism
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Internships - Technology
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Internships - Sales
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Internships - Other
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
            </ListOfLinks>
          </VerticalSpacing>
          <VerticalSpacing>
            <Heading type="m">Policies</Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <ListOfLinks columns={3} topBorder>
              <ListOfLinks.Column>
                <ListOfLinks.Link href="https://www.example.com">
                  Cookie policy
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Privacy statement
                </ListOfLinks.Link>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Link href="https://www.example.com">
                  Terms of use
                </ListOfLinks.Link>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Link href="https://www.example.com">
                  Copyright
                </ListOfLinks.Link>
              </ListOfLinks.Column>
            </ListOfLinks>
          </VerticalSpacing>
          <VerticalSpacing>
            <Heading type="m">Resources</Heading>
          </VerticalSpacing>
          <VerticalSpacing size="l">
            <ListOfLinks columns={3} topBorder>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Known 360 publications"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Exchange magazine
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Innovation magazine
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Forum magazine
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Informer magazine
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Dividends magazine
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Answers magazine
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
                <ListOfLinks.Link href="https://www.example.com">
                  Media Contacts
                </ListOfLinks.Link>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Financial disclosures"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    Austria
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Belgium
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Denmark
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Finland
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Greece
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Hungary
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    India
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Ireland
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Italy
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Luxembourg
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Netherlands
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Norway
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Pakistan
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Poland
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Portugal
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Spain
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Sri Lanka
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Sweden
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Switzerland
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Thailand
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    United Kingdom
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Vietnam
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Group
                  href="https://www.example.com"
                  title="Third party restrictions"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  <ListOfLinks.Link href="https://www.example.com">
                    American Petroleum Institute (API)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Australian Financial Markets Association Incorporated (AFMA)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Australian Securities Exchange (ASX)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Barclays Capital (formerly Lehman Brothers)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Bloomberg Commodities Index
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Bloomberg Finance L.P. disclaimer applicable to the Qatar
                    Interbank Offered Rate (QIBOR)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Bloomberg Finance L.P. disclaimer applicable to the SIFMA
                    Municipal Swap Index
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Borsa Istanbul
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    British Bankers’ Association
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Budapest Stock Exchange
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Buzz Analytics
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    CBOE Futures Exchange: Derivative works – limitation of Use
                    of Information
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Canadian Dollar Offered Rate (“CDOR”)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    China Investment Information Services Ltd (“CIIS”)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Chi-X Australia
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Citigroup
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Canadian Overnight Repo Rate Average (“CORRA”)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Credit Analysis & Research Limited (CARE)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    CUSIP
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Daiwa Institute of Research and Daiwa Securities
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Derivex
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Deutsche Börse
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Dow Jones Indexes the marketing name of CME Group Index
                    Services LLC (“Dow Jones Indexes”)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Dun & Bradstreet
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Fannie Mae
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Financial Supervisory Service (FSS) Korea
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    FINRA
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Fitch
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    FOEX Indexes Ltd
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Interactive Data Corp.
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Global Industry Classification Standard (GICS)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Hang Seng Indexes Company Limited (HSIL)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Hedge Fund Research Indices
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    HKEx Information Services Limited (HKEx)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    ICB Classification
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Interfax Information Services BV
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Japan Credit Rating Agency, Ltd.
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    JP Morgan Embargoed Research
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Land Transport Authority Singapore
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    LBMA Silver Price
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    LBMA Silver Price (CME)
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Liberum Capital Limited Research
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Lehman Brothers Research
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    London Stock Exchange/SEDOL
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Louisiana Department of Insurance
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Markit – iBoxx Indices
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Markit – Credit and Structured Finance Indices
                  </ListOfLinks.Link>
                  <ListOfLinks.Link href="https://www.example.com">
                    Merrill Lynch Indices
                  </ListOfLinks.Link>
                </ListOfLinks.Group>
              </ListOfLinks.Column>
              <ListOfLinks.Column>
                <ListOfLinks.Link href="https://www.example.com">
                  Morningstar® Document Research and Morningstar® Data
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Moscow Exchange (MOEX) – previously MICEX & RTS
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  National Association of Real Estate Investment Trusts
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Newspaper Licensing Agency (NLA)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Nikkei – Indices
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  OECD
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Rating and Investment Information, Inc. (Japanese Credit
                  Rating Agency)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Nordic Growth Market
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  S&P Ratings
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  UBS AG (Australia)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Thai Bond Market Association (ThaiBMA)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  TASS Database
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Sankei Online News
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Standard & Poor’s
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Wilshire Indices
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Yieldbroker Pty Ltd.
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  FTSE TMX Fixed Income Indices
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  UBS Embargoed Research
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  China Exchanges Services Company Limited (CESC)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Seoul Money Brokerage Company (SMBS) and Korea Money Brokerage
                  Company (KMBC)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Moody’s Embargoed Research
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  CFETS Data Client Usage Restrictions
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Metal Bulletin Limited
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  FISCO News
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  FISCO R Selection
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  FMC Asia Oil Swaps Marker
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  FX Analytics
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  IDEAGlobal
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  JIJI News for TRKD Premium
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  JIJI Press Limited
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Kabudas Express
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  RIM Intelligence Co Limited
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Technical Research
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Trendsetter Financial Services
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Totan Information
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  WM Company
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  FTSE Russell – RussellTick
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  NYSE Short Interest Data
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  NYSE Group Security Master File Reference Data
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Nomura BPI Index (Nomura Research Institute Ltd.)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  National Oilseed Processors Association (NOPA)
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  Wood Mackenzie
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  KOSCOM Corporation
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  DTCC - Depository Trust Clearing Corporation
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  NYSE Corporate Actions
                </ListOfLinks.Link>
                <ListOfLinks.Link href="https://www.example.com">
                  ASX Benchmark Data
                </ListOfLinks.Link>
              </ListOfLinks.Column>
            </ListOfLinks>
          </VerticalSpacing>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default Sitemap;
