import PropTypes from "prop-types";
import React from "react";

import Icon from "@emcm-ui/component-icon";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const SavedFilters = ({
  name,
  iconType,
  onFilterClick,
  onDeleteFilterClick
}) => {
  const getClassName = getClassNameFactory(SavedFilters.displayName);

  return (
    <li className={getClassName({ descendantName: "item" })}>
      <div
        className={getClassName({ descendantName: "savedFilterBodyContent" })}
      >
        {iconType && (
          <div className={getClassName({ descendantName: "savedFilterIcon" })}>
            <Icon
              path="./icons/nucleo/business-finance/small/math.svg"
              size="small"
            />
          </div>
        )}
        <button
          className={getClassName({ descendantName: "savedFilterButton" })}
          onClick={onFilterClick}
        >
          {name}
        </button>
        {iconType && (
          <div
            className={getClassName({ descendantName: "deleteFilterIcon" })}
            onClick={onDeleteFilterClick}
            onKeyDown={onDeleteFilterClick}
            role="button"
          >
            <Icon
              path="./icons/nucleo/business-finance/small/math.svg"
              size="small"
            />
          </div>
        )}
      </div>
    </li>
  );
};

SavedFilters.displayName = "SavedFilters";

SavedFilters.propTypes = {
  /**
   * name.
   */
  name: PropTypes.string.isRequired,
  /**
   * name.
   */
  iconType: PropTypes.string,
  /**
   * onFilterClick.
   */
  onFilterClick: PropTypes.func,
  /**
   * onDeleteFilterClick.
   */
  onDeleteFilterClick: PropTypes.func
};

export default SavedFilters;
