import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SemanticFooter = ({ children }) => {
  const getClassName = getClassNameFactory(SemanticFooter.displayName);

  return <footer className={getClassName()}>{children}</footer>;
};

SemanticFooter.displayName = "SemanticFooter";

/* eslint-disable max-len */
SemanticFooter.propTypes = {
  /**
   * Child footer nodes.
   */
  children: PropTypes.node
};
/* eslint-enable max-len */

export default SemanticFooter;
