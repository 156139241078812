import PropTypes from "prop-types";
import React from "react";
import BasePlayerEmbed from "./BasePlayerEmbed";

const QQPlayerEmbed = ({ videoId, ...extra }) => {
  const frameUrl = `https://v.qq.com/iframe/player.html?vid=${videoId}&tiny=1&auto=1`;

  return <BasePlayerEmbed src={frameUrl} {...extra} />;
};

QQPlayerEmbed.propTypes = {
  videoId: PropTypes.string.isRequired
};

export default QQPlayerEmbed;
