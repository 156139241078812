import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import Asset from "./components/Asset";
import BlockQuote from "./components/BlockQuote";
import ChapterDivider from "./components/ChapterDivider";
import ChapterNav from "./components/ChapterNav";
import Child from "./components/Child";
import RichText from "./components/RichText";

const Longform = ({ children, id }) => {
  const getClassName = getClassNameFactory(Longform.displayName);

  return (
    <div className={getClassName()} id={id}>
      {children}
    </div>
  );
};

Longform.displayName = "Longform";

/* eslint-disable max-len */
Longform.propTypes = {
  /**
   * Child nodes to wrap in Longform. Should typically be Longform.Child.
   */
  children: PropTypes.node,
  /**
   * Element id.
   */
  id: PropTypes.string
};
/* eslint-enable max-len */

Longform.defaultProps = {
  id: "longform"
};

Longform.Asset = Asset;
Longform.BlockQuote = BlockQuote;
Longform.ChapterDivider = ChapterDivider;
Longform.ChapterNav = ChapterNav;
Longform.Child = Child;
Longform.RichText = RichText;

export default Longform;
