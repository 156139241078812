/* eslint-disable no-console */
import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Dropdown from "@emcm-ui/component-dropdown";
import Filter from "./index";
import "./Filter.css";
import FilterREADME from "../README.md";
import "../be/integration";

const stories = storiesOf("Components/Filter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(FilterREADME));

stories.add("Default", () => {
  const onApplyFilter = (filtersSelected, saveFilterChecked) => {
    console.log(
      "filters selected ",
      filtersSelected,
      " and checkbox selected is ",
      saveFilterChecked
    );
  };

  const savedFilters = {
    data: []
  };

  const onDropdownChange = (dropdownId, value) => {
    console.log(`${value} is selected for ${dropdownId}`);
  };

  const onShowFilterBody = () => {
    console.log(
      " this function is called right after the filter filter icon is clicked."
    );
  };

  const deleteFilter = () => {
    console.log(" This function is called right after saved filter is deleted");
  };

  const updateResetFilters = () => {
    console.log(
      " This function is called to reset the filters values in the dropdowns"
    );
  };

  return (
    <Filter
      header="Europe"
      filterLabel={"Saved filters"}
      filterCloseLabel={"Close"}
      filterOpenLabel={"Filters"}
      filtersHeader={"Usage Filter"}
      applyFilterButtonLabel={"Submit"}
      noSavedFiltersLabel={"There are no saved filters"}
      dropdownKeys={["country", "accounts", "duration"]}
      gridProps={{
        variant: "even-3",
        gutterVerticalSmall: true
      }}
      onApplyFilter={onApplyFilter}
      onDropdownChange={onDropdownChange}
      onShowFilterBody={onShowFilterBody}
      saveFilterChecked={false}
      savedFilters={savedFilters}
      showSaveFilter={true}
      resetFilters={false}
      updateResetFilters={updateResetFilters}
      deleteFilter={deleteFilter}
      path={"filter"}
      disableFilter={false}
      filterChildrenStatus={false}
      updateFilterChildrenStatus={() => {}}
    >
      <Dropdown
        block={true}
        labelText={"Countries"}
        items={[
          {
            value: "usa",
            label: "United States Of America"
          },
          {
            value: "uk",
            label: "United Kingdom"
          },
          {
            value: "china",
            label: "China"
          },
          {
            value: "india",
            label: "India"
          }
        ]}
        size={"large"}
        value="usa"
        placeholder={"placeholder1"}
      />
      <Dropdown
        block={true}
        labelText={"Accounts"}
        items={[
          {
            value: "A-12345",
            label: "A-12345 Acc 1"
          },
          {
            value: "B-345",
            label: "B-345 Acc 2"
          },
          {
            value: "C-6789",
            label: "C-6789 Acc 3"
          }
        ]}
        size={"large"}
        value={"product"}
        placeholder={"placeholder2"}
      />
      <Dropdown
        block={true}
        labelText={"Duration"}
        items={[
          {
            value: "12",
            label: "12 months"
          },
          {
            value: "6",
            label: "6 Months"
          },
          {
            value: "3",
            label: "3 Months"
          }
        ]}
        size={"large"}
        value={"12"}
        placeholder={"placeholder3"}
      />
    </Filter>
  );
});
