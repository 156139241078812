import React, { Component } from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";
import Link from "../Link";

class SelectedItem extends Component {
  static displayName = "SearchFilters.SelectedItem";

  static propTypes = {
    /**
     * The destination of this `SelectedItem`.
     */
    href: PropTypes.string,

    /**
     * The title to display
     */
    children: PropTypes.node
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory("SearchFiltersSelectedItem");
  }

  render() {
    return (
      <li className={this.getClassName()}>
        <div className={this.getClassName({ descendantName: "title" })}>
          <Link>{this.props.children}</Link>
        </div>

        <a
          href={this.props.href}
          className={this.getClassName({ descendantName: "remove" })}
        >
          <SVGIcon name="close" size="xs" />
        </a>
      </li>
    );
  }
}

export default SelectedItem;
