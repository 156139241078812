import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import Link from "@emcm-ui/component-link";

const LinkCardFooter = ({ href, type, title, icon }) => {
  const getClassName = getClassNameFactory(LinkCardFooter.displayName);

  return (
    <div className={getClassName()}>
      {type === "icon" ? (
        <Link href={href} type={type} icon={icon} iconPosition={"right"}>
          {title}
        </Link>
      ) : (
        <Link href={href} type={type}>
          {title}
        </Link>
      )}
    </div>
  );
};

LinkCardFooter.displayName = "LinkCardFooter";

/* eslint-disable max-len */
LinkCardFooter.propTypes = {
  /**
   * Title text
   */
  href: PropTypes.string,
  /**
   * Type of link
   */
  type: PropTypes.oneOf(["icon", "inline"]),
  /**
   * Title text
   */
  title: PropTypes.string.isRequired,
  /**
   * Icon
   */
  icon: PropTypes.node
};
/* eslint-enable max-len */

LinkCardFooter.defaultProps = {
  type: "icon"
};

export default LinkCardFooter;
