export default (path, formData) => {
  const fetchOptions = {
    body: formData,
    credentials: "same-origin",
    method: "POST",
    headers: {
      Accept: "application/json"
    }
  };

  return fetch(path, fetchOptions);
};
