import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const NavList = ({ children }) => {
  const getClassName = getClassNameFactory("SiteHeaderNavList");

  return (
    <div className={getClassName()}>
      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </div>
  );
};

NavList.propTypes = {
  /**
   * The contents of this `NavList`. These should be `SiteHeader.NavItem`s.
   */
  children: PropTypes.node,

  /**
   * The title to display above the list.
   */
  title: PropTypes.string
};

NavList.displayName = "SiteHeader.NavList";

export default NavList;
