import React from "react";
import { storiesOf } from "@storybook/react";
import { text, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Svg from "@emcm-ui/component-svg";
import "./Svg.css";
import SvgREADME from "../README.md";

const Hat = (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      d="M137.746 499.538c35.372 5.563 75.595 8.707 118.254 8.707 141.384 0 256-34.545 256-77.164 0-24.947-39.288-47.124-100.216-61.229l-2.492 21.884c0 25.518-68.631 46.204-153.292 46.204-.237 0-.471-.003-.706-.003s-.469.003-.706.003c-84.662 0-153.292-20.686-153.292-46.204l-2.455-21.568C38.694 384.288 0 406.322 0 431.081c0 26.528 44.407 49.928 112.037 63.816"
      fill="#5b3013"
    />
    <path
      d="M411.784 369.852l-2.492 21.884c0 14.838-23.206 28.04-59.272 36.494 39.057 21.181 83.799 33.212 131.352 33.212 3.465 0 6.911-.086 10.346-.213C504.774 451.969 512 441.78 512 431.081c0-24.947-39.288-47.124-100.216-61.229z"
      fill="#703c19"
    />
    <path
      d="M350.309 15.482C218.764-18.242 110.86 30.23 86.147 42.758c-29.302 12.96-19.7 44.971-19.7 44.971l34.85 306.213c0 25.518 68.631 46.204 153.292 46.204.237 0 .471-.003.706-.003s.469.003.706.003c84.662 0 153.292-20.686 153.292-46.204l34.85-306.213s10.079-33.627-21.982-45.905c-15.654-7.55-31.139-13.772-46.371-18.842"
      fill="#935b2a"
    />
    <path
      d="M125.254 406.763L88.029 79.682s-8.773-29.318 14.794-44.648c-7.313 3.121-12.948 5.832-16.677 7.723-29.302 12.961-19.7 44.972-19.7 44.972l34.85 306.213c0 10.682 12.033 20.516 32.225 28.341-5.358-4.881-8.267-10.097-8.267-15.52z"
      fill="#844719"
    />
    <path
      d="M422.16 41.824c-15.654-7.55-31.139-13.772-46.371-18.842l-25.481-7.499C320.649 7.879 292.2 4.465 265.627 3.863c-40.165 47.927-64.361 109.691-64.361 177.114 0 108.931 63.13 203.105 154.794 247.966 32.595-8.473 53.231-21.011 53.231-35.003l34.85-306.213c.002.001 10.08-33.627-21.981-45.903z"
      fill="#a06335"
    />
    <path
      d="M344.15 357.709c-25.905 4.513-55.973 7.095-88.044 7.095-.271 0-.54-.003-.811-.003s-.54.003-.811.003c-70.924 0-132.052-12.629-160.012-30.831l6.824 59.966c0 25.518 68.631 46.204 153.292 46.204.237 0 .471-.003.706-.003s.469.003.706.003c84.662 0 153.292-20.686 153.292-46.204l6.826-59.966c-10.747 6.996-26.393 13.168-45.612 18.116"
      fill="#f7941c"
    />
    <path
      d="M101.38 395.4c.025.251.056.501.095.75.022.138.047.274.072.411.044.234.091.466.148.698.032.138.065.276.101.415.065.238.135.476.21.714.041.126.077.251.12.378.104.301.218.603.343.901.023.059.043.118.068.176.15.351.313.703.487 1.051.064.126.133.251.2.376.12.228.243.455.375.682.086.146.174.293.264.437a21.858 21.858 0 0 0 1.131 1.673c.11.149.217.296.333.443.171.22.351.439.532.66.104.123.2.249.306.372.276.323.567.644.866.964.143.154.295.305.443.458a24.974 24.974 0 0 0 1.059 1.042 41.902 41.902 0 0 0 1.646 1.469c.207.174.412.348.626.522.177.145.362.289.545.433a52.65 52.65 0 0 0 2.692 1.985c.171.118.341.238.516.355.284.192.574.381.865.571.181.118.363.237.549.354a59.638 59.638 0 0 0 1.452.899c.327.197.656.394.991.589.142.083.289.164.431.247a87.733 87.733 0 0 0 3.957 2.129l.301.153c.417.209.844.417 1.271.625.158.075.314.152.471.227.424.203.854.403 1.287.604l.484.223c.449.206.903.41 1.364.614l.338.146a137.981 137.981 0 0 0 5.201 2.142c-5.359-4.881-8.268-10.096-8.268-15.519l-6.987-61.384c-9.442-3.465-17.474-7.291-23.796-11.406l6.824 59.966c0 .37.018.738.046 1.106l.041.349z"
      fill="#f4741e"
    />
    <path
      d="M344.15 357.709c-21.779 3.794-46.507 6.216-72.888 6.894 23.709 26.569 52.477 48.522 84.799 64.34 32.595-8.473 53.231-21.011 53.231-35.003l6.826-59.966c-10.747 6.996-26.393 13.168-45.612 18.116l-26.356 5.619z"
      fill="#fc9f3a"
    />
    <path
      d="M314.728 504.63c-142.037 0-257.184-39.839-257.184-88.981 0-15.72 11.801-30.487 32.481-43.305C34.949 386.497 0 407.555 0 431.081c0 26.528 44.407 49.928 112.037 63.816l25.709 4.639c35.372 5.563 75.595 8.707 118.254 8.707 28.338 0 55.598-1.391 81.078-3.953-7.367.221-14.818.34-22.35.34z"
      fill="#49260e"
    />
  </svg>
);

const stories = storiesOf("Components/Svg", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SvgREADME));

stories
  .add("Default", () => (
    <Svg
      height={parseInt(text("Height"))}
      width={parseInt(text("Width", "364"))}
    >
      {Hat}
    </Svg>
  ))
  .add("Accessible", () => (
    <Svg
      label={text("label", "Hat")}
      height={parseInt(text("Height"))}
      width={parseInt(text("Width", "364"))}
    >
      {Hat}
    </Svg>
  ))
  .add("Button", () => (
    <button>
      <Svg
        label={text("label", "Hat")}
        height={parseInt(text("Height"))}
        width={parseInt(text("Width", "364"))}
      >
        {Hat}
      </Svg>
    </button>
  ))
  .add("Link", () => (
    <a href="/">
      <Svg
        label={text("label", "Hat")}
        height={parseInt(text("Height"))}
        width={parseInt(text("Width", "364"))}
      >
        {Hat}
      </Svg>
    </a>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Svg
        height={parseInt(text("Height"))}
        width={parseInt(text("Width", "364"))}
      >
        {Hat}
      </Svg>
    </Language>
  ));
