import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@emcm-ui/component-button";

/**
 * For demo purposes: Returns a button that shows `props.children`
 * when clicked.
 *
 * Useful for cases like showing <Modal>, which should not appear initially
 * when the page loads, but after a user interaction.
 *
 */
class DemoLauncher extends Component {
  static propTypes = {
    buttonLabel: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired
  };

  state = {
    active: false
  };

  /**
   * Simply relaunch `props.children` every time the button is clicked.
   */
  onClick = () => {
    if (this.state.active) {
      this.setState({ active: false }, () => this.setState({ active: true }));
    } else {
      this.setState({ active: true });
    }
  };

  render() {
    const { active } = this.state;

    return (
      <React.Fragment>
        <Button onClick={this.onClick} color="primary" kind="submit">
          {this.props.buttonLabel}
        </Button>

        {active && this.props.children}
      </React.Fragment>
    );
  }
}

export default DemoLauncher;
