import React from "react";

const getIcon = (name, props) => {
  switch (name) {
    case "play":
      return (
        <path
          {...props}
          d="M4.5,2.89478221 L16.0459789,10 L4.5,17.1052178 L4.5,2.89478221 Z"
        />
      );
    case "pause":
      return (
        <path
          {...props}
          d="M16,2 L16,18 L12,18 L12,2 L16,2 Z M8,2 L8,18 L4,18 L4,2 L8,2 Z"
        />
      );
    default:
      return <path />;
  }
};

export { getIcon };
