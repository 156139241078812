import React from "react";
import Item from "./Item";
import kebabCase from "lodash.kebabcase";

// eslint-disable-next-line require-await
const rehydrator = async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };
  const findAnchor = anchorDomNode => anchorDomNode.querySelector("a");
  const transformDatasetToString = datasets => {
    Object.fromEntries = arr =>
      Object.assign(
        {},
        ...Array.from(arr, ([key, value]) => ({ [key]: value }))
      );

    return Object.fromEntries(
      Object.entries(datasets).map(([key, value]) => [
        `data-${kebabCase(key)}`,
        value
      ])
    );
  };
  const props = {
    active: readData("active"),
    href: readData("href"),
    title: readData("title"),
    datasets: JSON.stringify(
      transformDatasetToString(findAnchor(domNode).dataset)
    )
  };

  return <Item {...props} />;
};

export default rehydrator;
