import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Callout from "./index";
import "./Callout.css";
import CalloutREADME from "../README.md";
import Image from "@emcm-ui/component-image";
import { Language } from "@emcm-ui/component-theme";

const stories = storiesOf("Components/Callout", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CalloutREADME));

stories
  .add("Image", () => (
    <Callout
      eyebrowText={
        boolean("Show eyebrow?", true)
          ? text("eyebrow text", "Hello world!")
          : null
      }
      asset={<Image alt="callout image" src="placeholder-images/55x55.png" />}
      assetWidth={select("Asset style", ["square", "wide"], "square")}
    >
      {text(
        "Rich text",
        "Lorem ipsum dolor sit amet, consectetur elit proin tincidunt iaculis metus in porta. Ut tempus, velit dapibus feugiat rocus, purus lectus tempus arcu."
      )}
    </Callout>
  ))
  .add("Image, wide", () => (
    <Callout
      eyebrowText={
        boolean("Show eyebrow?", true)
          ? text("eyebrow text", "Hello world!")
          : null
      }
      asset={
        <Image
          alt="callout image"
          src="https://via.placeholder.com/110x55/ff8000/fff?text=TR"
        />
      }
      assetWidth={select("Asset style", ["square", "wide"], "wide")}
    >
      {text(
        "Rich text",
        "Lorem ipsum dolor sit amet, consectetur elit proin tincidunt iaculis metus in porta. Ut tempus, velit dapibus feugiat rocus, purus lectus tempus arcu."
      )}
    </Callout>
  ))
  .add("Image, right to left", () => (
    <Language code="ar">
      <Callout
        eyebrowText={
          boolean("Show eyebrow?", true)
            ? text("eyebrow text", "مرحبا بالعالم")
            : null
        }
        asset={<Image alt="callout image" src="placeholder-images/55x55.png" />}
        assetWidth={select("Asset style", ["square", "wide"], "square")}
      >
        {text(
          "Rich text",
          "كان غضون قِبل ا قد. بـ كلا جورج بقسوة. قررت استعملت واستمرت بحق ثم, عرفها نتيجة الفترة كل حدى. تم أخرى انتهت عسكرياً أسر. عن فقد بشكل شرسة التقليدية."
        )}
      </Callout>
    </Language>
  ));
