import matchSorter from "match-sorter";

const getItems = ({ filter, options }) => {
  return filter
    ? matchSorter(options, filter, {
        keys: ["value"]
      })
    : options;
};

export { getItems };
