import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SandwichContentItem = ({ children }) => {
  const getClassName = getClassNameFactory("SandwichContentItem");

  return <div className={getClassName()}>{children}</div>;
};

SandwichContentItem.displayName = "Sandwich.ContentItem";

/* eslint-disable max-len */
SandwichContentItem.propTypes = {
  /**
   * Button component
   */
  children: PropTypes.node.isRequired
};
/* eslint-enable max-len */

export default SandwichContentItem;
