import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import Grid from "@emcm-ui/component-grid";

const ListOfLinksColumn = ({ children }) => {
  const getClassName = getClassNameFactory(ListOfLinksColumn.displayName);

  // NB here the root element does not possess the root classname, as it needs
  // to be a Grid.Item. This complicates CSS selectors somewhat, but is
  // necessary to reduce duplicating Grid implementation.
  return (
    <Grid.Item>
      <ul className={getClassName()}>{children}</ul>
    </Grid.Item>
  );
};

ListOfLinksColumn.displayName = "ListOfLinks.Column";

ListOfLinksColumn.propTypes = {
  /**
   * LinkOfList Links, BlockLinks or Groups to render.
   */
  children: PropTypes.node.isRequired
};

export default ListOfLinksColumn;
