import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const LinkItem = ({ href, text }) => {
  const getClassName = getClassNameFactory("DismissibleBannerLinkItem");

  return (
    <li className={getClassName()}>
      <a className={getClassName({ descendantName: "link" })} href={href}>
        {text}
      </a>
    </li>
  );
};

LinkItem.displayName = "DismissibleBanner.LinkItem";

LinkItem.propTypes = {
  /**
   * The link destination
   */
  href: PropTypes.string.isRequired,

  /**
   * The title of the link
   */
  text: PropTypes.string.isRequired
};

export default LinkItem;
