import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Item from "./components/Item";

const LanguageSwitcher = ({ children, heading }) => {
  const getClassName = getClassNameFactory(LanguageSwitcher.displayName);

  return (
    <div className={getClassName()}>
      <h2
        className={getClassName({
          descendantName: "heading",
          utilities: "typographySmallCaps"
        })}
      >
        {heading}
      </h2>

      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </div>
  );
};

LanguageSwitcher.displayName = "LanguageSwitcher";

/* eslint-disable max-len */
LanguageSwitcher.propTypes = {
  /**
   * The items to display in this `LanguageSwitcher`. Should be of type `LanguageSwitcher.Item`.
   */
  children: PropTypes.node,

  /**
   * The title to display on this component. Should be localized to the current page.
   */
  heading: PropTypes.string
};
/* eslint-enable max-len */

LanguageSwitcher.Item = Item;

export default LanguageSwitcher;
