import React from "react";
import PropTypes from "prop-types";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const TextareaControl = ({
  defaultValue,
  disabled,
  id,
  labelText,
  name,
  onChange,
  required,
  value
}) => {
  const getClassName = getClassNameFactory(TextareaControl.displayName);

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "label" })} htmlFor={id}>
        <span className={getClassName({ descendantName: "labelText" })}>
          {labelText}{" "}
          {!required && (
            <span className={getClassName({ descendantName: "optional" })}>
              (Optional)
            </span>
          )}
        </span>
        <textarea
          className={getClassName({ descendantName: "input" })}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={required}
          value={value}
        />
      </div>
    </div>
  );
};

/* eslint-disable max-len */
TextareaControl.propTypes = {
  /**
   * Initialize <textarea> with a value
   */
  defaultValue: PropTypes.string,
  /**
   * Disable the <textarea>
   */
  disabled: PropTypes.bool,
  /**
   * Id attribute for <textarea> (and <label> `for` attribute)
   */
  id: PropTypes.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Name attribute for <textarea>, required for serialisation
   */
  name: PropTypes.string.isRequired,
  /**
   * Listen for changes on the <textarea>.
   */
  onChange: PropTypes.func,
  /**
   * Set <textarea> to be required
   */
  required: PropTypes.bool,
  /**
   * Explicitly set the value of the <textarea>. Will switch to controlled mode
   */
  value: PropTypes.string
};
/* eslint-enable max-len */

TextareaControl.displayName = "Form.TextareaControl";

export default TextareaControl;
