import React from "react";
import SelectControl from "./SelectControl";

// eslint-disable-next-line require-await
export default async domNode => {
  const defaultValue = domNode.getAttribute("data-default-value");

  const props = {
    errorMessage: domNode.getAttribute("data-error-message"),
    isRehydrating: true,
    disabled: domNode.getAttribute("data-disabled") === "true",
    id: domNode.getAttribute("data-id"),
    labelText: domNode.getAttribute("data-label-text"),
    optionalText: domNode.getAttribute("data-optional-text"),
    name: domNode.getAttribute("data-name"),
    options: JSON.parse(domNode.getAttribute("data-options")),
    placeholder: domNode.getAttribute("data-placeholder") === "true",
    placeholderText: domNode.getAttribute("data-placeholder-text"),
    required: domNode.getAttribute("data-required") === "true",
    tabIndex: domNode.getAttribute("data-tabindex"),
    ...(defaultValue && {
      value: defaultValue
    }),
    helpText: domNode.getAttribute("data-help-text")
  };

  return <SelectControl {...props} />;
};
