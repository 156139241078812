import React from "react";
import Tooltip from "./Tooltip";
import getClassNameFactory from "@emcm-ui/utility-class-names";

// eslint-disable-next-line require-await
export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory(Tooltip.displayName);
  const className = getClassName({ descendantName: "inner" });
  const childrenNode = domNode.querySelector(`.${className}`);

  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    tooltipText: domNode.getAttribute("data-tool-tip-text"),
    position: domNode.getAttribute("data-position")
  };

  return <Tooltip {...props}>{reactifiedChildren}</Tooltip>;
};
