import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Section = ({ children, background, fullWidth }) => {
  const getClassName = getClassNameFactory(Section.displayName);

  return (
    <div className={getClassName({ modifiers: classNames(background) })}>
      {fullWidth ? (
        children
      ) : (
        <div className={getClassName({ descendantName: "inner" })}>
          {children}
        </div>
      )}
    </div>
  );
};

Section.displayName = "Section";

Section.backgrounds = [
  "white",
  "black",
  "blue",
  "grayDark",
  "grayLighter",
  "grayLightest",
  false
];

Section.propTypes = {
  /**
   * Markup elements to render in Section
   */
  children: PropTypes.node,

  /**
   * If true, set the Section to be full width, no Section-inner div.
   * Valid Options: True or False.
   */
  fullWidth: PropTypes.bool,

  /**
   * Modify Section with background colors.
   * Valid Options: grayDark, grayLighter, grayLightest, or false.
   */
  background: PropTypes.oneOf(Section.backgrounds)
};

Section.defaultProps = {
  fullWidth: false,
  background: false
};

export default Section;
