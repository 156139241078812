import get from "lodash/get";

class Environment {
  constructor(path) {
    this.path = path;
  }

  callFunction = funcName => {
    const path = `${this.path}.${funcName}`;
    const getResultByFunction = get(window, path, null);

    return getResultByFunction();
  };
}

export default Environment;
