import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import LoginManager, {
  ECommLoginService,
  ECommLoginServiceConfigurationUAT,
  ECommLoginServiceConfigurationPPE,
  ECommLoginServiceConfigurationProd,
  LegalECommLoginServiceConfigurationUAT,
  LegalECommLoginServiceConfigurationPPE,
  LegalECommLoginServiceConfigurationProd,
  FakeLoginService
} from "@emcm-ui/utility-authentication";
import "./GlobalHeader.css";
import GlobalHeader from "./index";
import GlobalHeaderREADME from "../README.md";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/GlobalHeader", module);

const loginServices = {
  Fake: new FakeLoginService(),
  "EComm - UAT": new ECommLoginService(new ECommLoginServiceConfigurationUAT()),
  "EComm - PPE": new ECommLoginService(new ECommLoginServiceConfigurationPPE()),
  "EComm - prod": new ECommLoginService(
    new ECommLoginServiceConfigurationProd()
  ),
  "Legal EComm - UAT": new ECommLoginService(
    new LegalECommLoginServiceConfigurationUAT()
  ),
  "Legal EComm - PPE": new ECommLoginService(
    new LegalECommLoginServiceConfigurationPPE()
  ),
  "Legal EComm - prod": new ECommLoginService(
    new LegalECommLoginServiceConfigurationProd()
  )
};

const getSelectableLoginManager = () => {
  const loginServiceName = select(
    "Login Service",
    Object.keys(loginServices),
    Object.keys(loginServices)[0]
  );
  const loginService = loginServices[loginServiceName];

  loginService.activate();

  return new LoginManager(loginService);
};

const getFakeLoginManager = () => {
  const service = loginServices.Fake;
  const loginManager = new LoginManager(service);

  service.activate();

  return loginManager;
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GlobalHeaderREADME));

/* eslint-disable react/prop-types */
const GlobalHeaderContent = ({
  loginManager,
  brandColorBar,
  gradientBackground
}) => (
  /* eslint-enable react/prop-types */

  <GlobalHeader
    leftItems={
      <React.Fragment>
        <GlobalHeader.Item
          narrow
          label="explore"
          longLabel="Explore Refinitiv"
          href="/directory"
          gradientBackground={gradientBackground}
        >
          <GlobalHeader.Column>
            <GlobalHeader.NavList>
              <GlobalHeader.NavItem
                title="Contact item 1"
                href="#"
                icon={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
              />
              <GlobalHeader.NavItem
                title="Contact item 2"
                href="#"
                icon={<Icon path="./icons/ui/grey/document-07.svg" size="s" />}
              />
              <GlobalHeader.NavItem
                title="Contact item 3 with more text"
                href="#"
                icon={<Icon path="./icons/ui/grey/unlocked-07.svg" size="s" />}
              />
            </GlobalHeader.NavList>
          </GlobalHeader.Column>
        </GlobalHeader.Item>
      </React.Fragment>
    }
    rightItems={
      <React.Fragment>
        <GlobalHeader.Login
          accountLabel="Account"
          accountOverviewLabel="Account overview"
          accountOverviewIcon={
            <Icon path="./icons/ui/grey/user-07.svg" size="s" />
          }
          helloLabel="Hello"
          id="login"
          loginLabel="Login"
          loginManager={loginManager}
          logoutLabel="Logout"
        />
        <GlobalHeader.Item
          narrow
          label="Contact"
          href="/contact"
          gradientBackground={gradientBackground}
        >
          <GlobalHeader.Column>
            <GlobalHeader.NavList title="explore our sites">
              <GlobalHeader.NavItem
                title="Contact item 1"
                href="#"
                icon={<Icon path="./icons/ui/grey/tick-07.svg" size="s" />}
              />
              <GlobalHeader.NavItem
                title="Contact item 2"
                href="#"
                icon={<Icon path="./icons/ui/grey/document-07.svg" size="s" />}
              />
              <GlobalHeader.NavItem
                title="Contact item 3 with more text"
                href="#"
                icon={<Icon path="./icons/ui/grey/unlocked-07.svg" size="s" />}
              />
            </GlobalHeader.NavList>
          </GlobalHeader.Column>
        </GlobalHeader.Item>
      </React.Fragment>
    }
    loginManager={loginManager}
    brandColorBar={brandColorBar}
    gradientBackground={gradientBackground}
  />
);

stories
  .add("Default", () => {
    const loginManager = getSelectableLoginManager();

    return <GlobalHeaderContent loginManager={loginManager} />;
  })
  .add("Branded", () => {
    const loginManager = getSelectableLoginManager();

    return (
      <GlobalHeaderContent
        loginManager={loginManager}
        brandColorBar={true}
        gradientBackground={true}
      />
    );
  })
  .add("Language: Arabic (RTL)", () => {
    const loginManager = getFakeLoginManager();

    return (
      <Language code="ar">
        <GlobalHeader
          leftItems={
            <React.Fragment>
              <GlobalHeader.Item
                label="دليل"
                longLabel="الدليل العالمي"
                href="/directory"
              >
                <GlobalHeader.Column>
                  <GlobalHeader.NavList title="مواقع إقليمية">
                    <GlobalHeader.NavItem title="أمريكا الشمالية" href="#" />
                    <GlobalHeader.NavItem title="APAC" href="#" />
                    <GlobalHeader.NavItem
                      title="عرض جميع المواقع"
                      href="#"
                      summary
                    />
                  </GlobalHeader.NavList>
                </GlobalHeader.Column>
              </GlobalHeader.Item>
            </React.Fragment>
          }
          rightItems={
            <React.Fragment>
              <GlobalHeader.Item label="اتصل" href="/contact">
                <GlobalHeader.Column>
                  <GlobalHeader.NavList>
                    <GlobalHeader.NavItem
                      title="اتصل بنا"
                      href="#"
                      icon={
                        <Icon path="./icons/ui/grey/tick-07.svg" size="s" />
                      }
                    />
                    <GlobalHeader.NavItem
                      title="الدعم"
                      href="#"
                      icon={
                        <Icon path="./icons/ui/grey/document-07.svg" size="s" />
                      }
                    />
                  </GlobalHeader.NavList>
                </GlobalHeader.Column>
              </GlobalHeader.Item>
              <GlobalHeader.Login
                accountLabel="نظر"
                accountOverviewLabel="نظرة عامة على الحساب"
                helloLabel="مرحبا"
                id="login"
                loginLabel="تسجيل الدخول"
                loginManager={loginManager}
                logoutLabel="الخروج"
              />
            </React.Fragment>
          }
          loginManager={loginManager}
        />
      </Language>
    );
  })
  .add("Rehydrated", () => {
    return (
      <StaticRenderer>
        <GlobalHeaderContent />
      </StaticRenderer>
    );
  })
  .add("Branded Rehydrated", () => {
    return (
      <StaticRenderer>
        <GlobalHeaderContent brandColorBar={true} gradientBackground={true} />
      </StaticRenderer>
    );
  });
