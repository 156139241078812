class Store {
  get = key => {
    return JSON.parse(window.localStorage.getItem(key));
  };

  set = (key, items) => {
    window.localStorage.setItem(key, JSON.stringify(items));
  };
}

export default new Store();
