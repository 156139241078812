import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import TickerItem from "./components/TickerItem";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Ticker extends Component {
  static displayName = "Ticker";

  static propTypes = {
    /**
     * Items to display in this ticker. Each should be a `Ticker.Item`.
     */
    children: PropTypes.node,
    /**
     * An ISO 8601, RFC 2822, or `new Date(str)` compatible date string, which is used to compare relative time.
     */
    initialTime: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      currentTime: this.props.initialTime,
      isAnimating: false,
      isClosed: false,
      isPaused: false
    };

    this.pixelsPerSecond = 60;

    this.getClassName = getClassNameFactory(Ticker.displayName);
    this.handleCloseClick = this.handleCloseClick.bind(this);
    this.handleContentEnter = this.handleContentEnter.bind(this);
    this.handleContentLeave = this.handleContentLeave.bind(this);
    this.handleItemsBlur = this.handleItemsBlur.bind(this);
    this.handleItemsFocus = this.handleItemsFocus.bind(this);
    this.updateTime = this.updateTime.bind(this);
  }

  componentDidMount() {
    // We're in the browser - let's animate.
    this.setState({
      isAnimating: true,
      animationDuration: this.itemsInnerRef.offsetWidth / this.pixelsPerSecond
    });
  }

  handleCloseClick() {
    this.setState({
      isClosed: true
    });
  }

  handleContentEnter() {
    this.setState({
      isPaused: true
    });
  }

  handleContentLeave() {
    this.setState({
      isPaused: false
    });
  }

  handleItemsFocus() {
    this.setState({
      isPaused: true
    });
  }

  handleItemsBlur() {
    this.itemsRef.scrollLeft = 0;
    this.setState({
      isPaused: false
    });
  }

  updateTime() {
    this.setState({
      currentTime: new Date().toISOString()
    });
  }

  static getItems(children) {
    const items = [];

    React.Children.forEach(children, child => {
      if (child.type === TickerItem) {
        items.push(child.props);
      } else if (child.type === React.Fragment) {
        items.push(...Ticker.getItems(child.props.children));
      }
    });

    return items;
  }

  render() {
    const { currentTime } = this.state;

    const itemsStyle = {
      animationDuration: `${this.state.animationDuration || 0}s`
    };

    const items = Ticker.getItems(this.props.children);

    return (
      <div
        className={this.getClassName({
          states: classNames({
            animating: this.state.isAnimating,
            closed: this.state.isClosed,
            paused: this.state.isPaused
          })
        })}
        data-initial-time={this.props.initialTime}
        data-rehydratable={getRehydratableName(Ticker.displayName)}
      >
        <div className={this.getClassName({ descendantName: "logo" })}>
          <div className={this.getClassName({ descendantName: "logoInner" })} />
        </div>

        <div
          className={this.getClassName({ descendantName: "content" })}
          onMouseEnter={this.handleContentEnter}
          onMouseLeave={this.handleContentLeave}
        >
          <div
            className={this.getClassName({ descendantName: "items" })}
            ref={ref => {
              this.itemsRef = ref;
            }}
          >
            <ul
              className={this.getClassName({ descendantName: "itemsInner" })}
              onAnimationIteration={this.updateTime}
              onFocus={this.handleItemsFocus}
              onBlur={this.handleItemsBlur}
              ref={ref => {
                this.itemsInnerRef = ref;
              }}
              style={itemsStyle}
            >
              {items.map(item => {
                return (
                  <TickerItem
                    key={item.dateTime + item.title}
                    {...item}
                    currentTime={currentTime}
                  />
                );
              })}
            </ul>
          </div>
          <div className={this.getClassName({ descendantName: "fade" })} />
        </div>

        <button
          className={this.getClassName({ descendantName: "close" })}
          onClick={this.handleCloseClick}
          aria-label="Close news ticker"
        >
          <span className={this.getClassName({ descendantName: "closeIcon" })}>
            <SVGIcon name="close" size="s" />
          </span>
        </button>
      </div>
    );
  }
}

Ticker.Item = TickerItem;

export default Ticker;
