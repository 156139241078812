import PropTypes from "prop-types";
import React from "react";
import Eyebrow from "@emcm-ui/component-eyebrow";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Callout = ({ eyebrowText, children, asset, assetWidth }) => {
  const getClassName = getClassNameFactory(Callout.displayName);

  let assetEl;

  if (asset) {
    assetEl = (
      <div className={getClassName({ descendantName: "asset" })}>{asset}</div>
    );
  }

  return (
    <div className={getClassName({ modifiers: assetWidth })}>
      {assetEl}
      <div className={getClassName({ descendantName: "body" })}>
        {eyebrowText && eyebrowText.length > 0 ? (
          <Eyebrow text={eyebrowText} />
        ) : null}
        {children}
      </div>
    </div>
  );
};

Callout.displayName = "Callout";

/* eslint-disable max-len */
Callout.propTypes = {
  /**
   * Used for the title of this section
   */
  eyebrowText: PropTypes.string,

  /**
   * Rich text content, intended to be kept simple, such as <a>, <strong>, <i>, etc
   * Do not use wrapping <p> or similar tags to avoid spacing issues
   */
  children: PropTypes.node,

  /**
   * The asset held within the component, such as an image
   */
  asset: PropTypes.node,

  /**
   * Style of asset
   */
  assetWidth: PropTypes.oneOf(["square", "wide"])
};
/* eslint-enable max-len */

Callout.defaultProps = {
  assetWidth: "square"
};

export default Callout;
