import React from "react";

import MarkupContainer from "@emcm-ui/component-markup-container";
import Section from "@emcm-ui/component-section";
import TableWithFilters from "@emcm-ui/component-table-with-filters";

const data = {
  filter: {
    cells: [
      {
        display: true,
        displayOrder: 4
      },
      {
        display: true,
        displayOrder: 1
      },
      {
        display: true,
        displayOrder: 2
      },
      {
        display: true,
        displayOrder: 3
      }
    ]
  },
  table: {
    caption: {
      content: "Table of brokers"
    },
    head: {
      rows: [
        {
          cells: [
            {
              content: "Broker",
              width: 0
            },
            {
              content: "Asset",
              width: 0
            },
            {
              content: "Region",
              width: 0
            },
            {
              content: "Algo",
              width: 0
            }
          ]
        }
      ]
    },
    bodies: [
      {
        rows: [
          {
            cells: [
              {
                content: "ABN AMRO",
                link: {
                  href: "https://www.abnamro.com/"
                }
              },
              {
                content: "Futures"
              },
              {
                content: "EMEA"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "ABN AMRO"
              },
              {
                content: "Futures"
              },
              {
                content: "US"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "ADM Investor Services Inc."
              },
              {
                content: "Futures"
              },
              {
                content: "EMEA"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "ADM Investor Services Inc."
              },
              {
                content: "Futures"
              },
              {
                content: "US"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "ADM Investor Services Inc."
              },
              {
                content: "Options on Futures"
              },
              {
                content: "US"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "Americas Executions, LLC"
              },
              {
                content: "Equities"
              },
              {
                content: "US"
              },
              {
                content: "Desk"
              }
            ]
          },
          {
            cells: [
              {
                content: "Apex"
              },
              {
                content: "Equities"
              },
              {
                content: "Canada"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "Apex"
              },
              {
                content: "Equities"
              },
              {
                content: "US"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Apex"
              },
              {
                content: "Equities"
              },
              {
                content: "US"
              },
              {
                content: "Desk"
              }
            ]
          },
          {
            cells: [
              {
                content: "B. Riley & Co. LLC"
              },
              {
                content: "Equities"
              },
              {
                content: "US"
              },
              {
                content: "Desk"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "Asia"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "Asia"
              },
              {
                content: "Desk"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "Asia"
              },
              {
                content: "DMA"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "Canada"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "EMEA"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "EMEA"
              },
              {
                content: "Desk"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "EMEA"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "LATAM"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Bank of America Merrill Lynch"
              },
              {
                content: "Equities"
              },
              {
                content: "US"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 0"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 1"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 2"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 3"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 4"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 5"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 6"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 7"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 8"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 9"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 10"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 11"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 12"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 13"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 14"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 15"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 16"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 17"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 18"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 19"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          },
          {
            cells: [
              {
                content: "Broker"
              },
              {
                content: "Asset 20"
              },
              {
                content: "Region"
              },
              {
                content: "Algo"
              }
            ]
          }
        ]
      }
    ]
  }
};

const Table = () => (
  <MarkupContainer>
    <Section>
      <TableWithFilters
        data={data}
        filtersNoOptionsMessage="No results found that match your search term. Please try another or check your spelling."
        filtersPlaceholder="Add filter(s)..."
        infoText="Find a broker by asset, region, route or name."
        noResultsMessage="No results have been found as a result of the table being over constrained. To see results, please try removing filters or try and different combination of filters to find the information you’re looking for."
        pagesNavigableOnSide={2}
        paginationResultSummaryText="Pagination summary"
        rowsPerPage={12}
        subtitleHeadingRank="3"
        title="Search Our Network"
        titleHeadingRank="2"
      />
    </Section>
  </MarkupContainer>
);

export default Table;
