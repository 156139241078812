import PropTypes from "prop-types";
import React, { Component } from "react";
import { Timeline } from "react-twitter-widgets";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

/*
 * This is broken out from TwitterCard, so that we don't have an extra
 * `<div>` wrapping the whole card - which would interfere with Grid, and
 * result in a card that has a different height from the other cards.
 *
 * It also means that rehydration is scoped to only the part of the TwitterCard
 * that actually needs rehydrating.
 */
class TwitterCardTimeline extends Component {
  static displayName = "TwitterCardTimeline";

  static propTypes = {
    /**
     * Data source for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
     */
    timelineDataSource: PropTypes.object.isRequired,
    /**
     * Display options for the timeline. Conforms to the [Twitter specification for createTimeline](https://dev.twitter.com/web/javascript/creating-widgets#timelines)
     */
    timelineOptions: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      isMounted: false
    };
  }

  componentDidMount() {
    this.setState({
      isMounted: true
    });
  }

  render() {
    const { timelineDataSource, timelineOptions } = this.props;

    return (
      <div
        data-rehydratable={getRehydratableName(TwitterCardTimeline.displayName)}
        data-timeline-data-source={JSON.stringify(timelineDataSource)}
        data-timeline-options={JSON.stringify(timelineOptions)}
      >
        {/*
          * We shouldn't output anything for the card until we have a DOM. This
          * ensures that minimal markup is present prior to rehydration.
          */}
        {this.state.isMounted ? (
          <Timeline dataSource={timelineDataSource} options={timelineOptions} />
        ) : null}
      </div>
    );
  }
}

export default TwitterCardTimeline;
