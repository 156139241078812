import React from "react";

import GlobalFooter, {
  GlobalFooterItem
} from "@emcm-ui/component-global-footer";
import MarkupContainer from "@emcm-ui/component-markup-container";

const SampleGlobalFooter = () => (
  <MarkupContainer>
    <GlobalFooter logoHref="index.html">
      <GlobalFooterItem href="#" title="Cookie policy" />
      <GlobalFooterItem href="#" title="Privacy statement" />
      <GlobalFooterItem href="#" title="Terms of use" />
      <GlobalFooterItem href="#" title="Copyright" />
      <GlobalFooterItem href="#" title="Sitemap" />
      <GlobalFooterItem href="#" title="Disclosures" />
      <GlobalFooterItem href="#" title="Third-party restrictions" />
    </GlobalFooter>
  </MarkupContainer>
);

export default SampleGlobalFooter;
