import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import GlobalHeader from "./GlobalHeader";

export default async (domNode, rehydrateChildren, extra) => {
  const getClassName = getClassNameFactory("GlobalHeader");

  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const leftItemsNode = domNode.querySelector(
    `.${getClassName({
      descendantName: "leftItems"
    })}`
  );

  const rightItemsNode = domNode.querySelector(
    `.${getClassName({
      descendantName: "rightItems"
    })}`
  );

  const leftItems = leftItemsNode
    ? await rehydrateChildren(leftItemsNode)
    : null;
  const rightItems = rightItemsNode
    ? await rehydrateChildren(rightItemsNode)
    : null;

  const rehydratedProps = {
    leftItems,
    rightItems,
    brandColorBar: readData("brand-color-bar"),
    gradientBackground: readData("gradient-background")
  };

  if (extra && extra.loginManager) {
    rehydratedProps.loginManager = extra.loginManager;
  }

  return <GlobalHeader {...rehydratedProps} />;
};
