import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Block from "@emcm-ui/component-block";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import Standfirst from "@emcm-ui/component-standfirst";
import Tabs from "@emcm-ui/component-tabs";
import VerticalSpacing from "./index";
import "./VerticalSpacing.css";
import VerticalSpacingREADME from "../README.md";

const stories = storiesOf("Components/VerticalSpacing", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(VerticalSpacingREADME));

stories
  .add("Default", () => {
    return (
      <div>
        <VerticalSpacing size={select("size", VerticalSpacing.sizes, "m")}>
          <Block height="6rem" text="Children" />
        </VerticalSpacing>
        <VerticalSpacing size={select("size", VerticalSpacing.sizes, "m")}>
          <Block height="6rem" text="Children" />
        </VerticalSpacing>
      </div>
    );
  })
  .add("Section", () => {
    return (
      <Section>
        <VerticalSpacing size="xl">
          <Heading rank="2" type="l">
            <b>Section</b> vertical spacing
          </Heading>
        </VerticalSpacing>
        <VerticalSpacing size="l">
          <Block height="6rem" text="Block" />
        </VerticalSpacing>
        <Heading rank="3" type="l">
          The slow black dog bows before the regal fox.
        </Heading>
        <VerticalSpacing size="m">
          <Standfirst>The slow black dog bows before the regal fox.</Standfirst>
        </VerticalSpacing>
        <VerticalSpacing size="l">
          <RichText>
            <p>The slow black dog bows before the regal fox.</p>
            <p>The slow black dog bows before the regal fox.</p>
          </RichText>
        </VerticalSpacing>
        <VerticalSpacing size="l">
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <Block height="6rem" text="Grid item" />
            </Grid.Item>
            <Grid.Item>
              <Block height="6rem" text="Grid item" />
            </Grid.Item>
          </Grid>
        </VerticalSpacing>
        <VerticalSpacing size="l">
          <Grid variant="2/3,1/3">
            <Grid.Item>
              <Block height="6rem" text="Grid item" />
            </Grid.Item>
            <Grid.Item>
              <Block height="6rem" text="Grid item" />
            </Grid.Item>
          </Grid>
        </VerticalSpacing>
      </Section>
    );
  })
  .add("Grid item", () => {
    return (
      <div>
        <Section>
          <VerticalSpacing size="l">
            <Grid variant="3/4,1/4">
              <Grid.Item>
                <VerticalSpacing size="m">
                  <Heading rank="3" type="l">
                    <b>Grid item</b> vertical spacing
                  </Heading>
                </VerticalSpacing>
                <Heading rank="4" type="l">
                  The slow black dog bows before the regal fox.
                </Heading>
                <VerticalSpacing size="m">
                  <Standfirst>
                    The slow black dog bows before the regal fox.
                  </Standfirst>
                </VerticalSpacing>
                <VerticalSpacing size="m">
                  <RichText>
                    <p>The slow black dog bows before the regal fox.</p>
                    <p>The slow black dog bows before the regal fox.</p>
                  </RichText>
                </VerticalSpacing>
              </Grid.Item>
              <Grid.Item />
            </Grid>
          </VerticalSpacing>
        </Section>
      </div>
    );
  })
  .add("Tabs panel", () => {
    return (
      <Section>
        <Tabs>
          <Tabs.Panel id="tab1" tabTitle="Tab 1">
            <VerticalSpacing size="m">
              <Heading rank="3" type="l">
                <b>Tabs panel</b> vertical spacing
              </Heading>
            </VerticalSpacing>
            <Heading rank="4" type="l">
              The slow black dog bows before the regal fox.
            </Heading>
            <VerticalSpacing size="m">
              <Standfirst>
                The slow black dog bows before the regal fox.
              </Standfirst>
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <RichText>
                <p>The slow black dog bows before the regal fox.</p>
                <p>The slow black dog bows before the regal fox.</p>
              </RichText>
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <Grid variant="1/3,2/3">
                <Grid.Item>
                  <Block height="6rem" text="Grid item" />
                </Grid.Item>
                <Grid.Item>
                  <Block height="6rem" text="Grid item" />
                </Grid.Item>
              </Grid>
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <Grid variant="2/3,1/3">
                <Grid.Item>
                  <Block height="6rem" text="Grid item" />
                </Grid.Item>
                <Grid.Item>
                  <Block height="6rem" text="Grid item" />
                </Grid.Item>
              </Grid>
            </VerticalSpacing>
          </Tabs.Panel>
        </Tabs>
      </Section>
    );
  })
  .add("Language: Arabic (RTL)", () => {
    return (
      <Language code="ar">
        <VerticalSpacing size={select("size", VerticalSpacing.sizes, "m")}>
          <Block height="6rem" text="الأطفال" />
        </VerticalSpacing>
        <VerticalSpacing size={select("size", VerticalSpacing.sizes, "m")}>
          <Block height="6rem" text="الأطفال" />
        </VerticalSpacing>
      </Language>
    );
  });
