import PropTypes from "prop-types";

const SchemaSameAsOrganization = () => null;

SchemaSameAsOrganization.displayName = "SchemaSameAsOrganization";

SchemaSameAsOrganization.propTypes = {
  /**
   * A URL, other than the main website, that represents the organization.
   */
  url: PropTypes.string.isRequired
};

export default SchemaSameAsOrganization;
