import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";

class MultiDownshift extends React.Component {
  state = { selectedItems: [] };

  stateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          highlightedIndex: state.highlightedIndex,
          isOpen: true
        };
      default:
        return changes;
    }
  };

  handleSelection = (selectedItem, downshift) => {
    const callOnChange = () => {
      const { onChange } = this.props;
      const { selectedItems } = this.state;

      if (onChange) {
        onChange(selectedItems, this.getStateAndHelpers(downshift));
      }
    };

    if (
      this.state.selectedItems.some(
        i => this.clean(i) === this.clean(selectedItem)
      )
    ) {
      this.removeItem(selectedItem, callOnChange);
    } else {
      this.addSelectedItem(selectedItem, callOnChange);
    }
  };

  clean = item => {
    const { itemToString } = this.props;

    return itemToString ? itemToString(item) : item;
  };

  removeItem = (item, cb) => {
    const { onChange } = this.props;
    const callback = () => {
      const { selectedItems } = this.state;

      if (cb) {
        cb();

        return;
      }

      // try to call onChange
      if (onChange) {
        onChange(selectedItems);
      }
    };

    this.setState(({ selectedItems }) => {
      return {
        selectedItems: selectedItems.filter(
          i => this.clean(i) !== this.clean(item)
        )
      };
    }, callback);
  };

  addSelectedItem(item, cb) {
    this.setState(
      ({ selectedItems }) => ({
        selectedItems: [...selectedItems, item]
      }),
      cb
    );
  }

  getRemoveButtonProps = ({ onClick, item, ...props } = {}) => {
    return {
      onClick: e => {
        if (onClick) {
          onClick(e);
        }
        e.stopPropagation();
        this.removeItem(item);
      },
      ...props
    };
  };

  getStateAndHelpers(downshift) {
    const { selectedItems } = this.state;
    const { getRemoveButtonProps, removeItem } = this;

    return {
      getRemoveButtonProps,
      removeItem,
      selectedItems,
      ...downshift
    };
  }

  render() {
    const { render, children = render, ...props } = this.props;
    const { selectedItems } = this.state;

    return (
      <Downshift
        {...props}
        stateReducer={this.stateReducer}
        onChange={this.handleSelection}
        selectedItem={selectedItems}
      >
        {downshift => children(this.getStateAndHelpers(downshift))}
      </Downshift>
    );
  }
}

MultiDownshift.displayName = "TagFilter.MultDownshift";

MultiDownshift.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  itemToString: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  render: PropTypes.func
};

export default MultiDownshift;
