import { cart as cartRequest } from "./requests/estore";
import createFetchRejection from "./utilities/createFetchRejection";

export const cart = async apiBase => {
  const response = await cartRequest(apiBase);
  const json = await response.json();

  if (response.ok) {
    return {
      itemsInCart: json.totalUnitCount || 0,
      userName: json.userName
    };
  }

  return createFetchRejection(response, json);
};
