import React from "react";

import Button from "@emcm-ui/component-button";
import Form from "@emcm-ui/component-form";
import Grid from "@emcm-ui/component-grid";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Panel from "@emcm-ui/component-panel";
import Icon from "@emcm-ui/component-icon";
import RichText from "@emcm-ui/component-rich-text";
import Section, { SectionBridge } from "@emcm-ui/component-section";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

import CampaignTrackingParameterService from "@emcm-ui/utility-campaign";

const Icons = {
  phone: "./icons/nucleo/home-building/small/phone.svg",
  arrowRightBlue: "./icons/ui/blue/arrow-right.svg"
};

const countries = [
  { value: "AF", label: "Afghanistan" },
  { value: "AX", label: "Aland Islands" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AS", label: "American Samoa" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AI", label: "Anguilla" },
  { value: "AQ", label: "Antarctica" },
  { value: "AG", label: "Antigua And Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AW", label: "Aruba" },
  { value: "AU", label: "Australia" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BM", label: "Bermuda" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia And Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BV", label: "Bouvet Island" },
  { value: "BR", label: "Brazil" },
  { value: "IO", label: "British Indian Ocean Territory" },
  { value: "BN", label: "Brunei Darussalam" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CA", label: "Canada" },
  { value: "CV", label: "Cape Verde" },
  { value: "KY", label: "Cayman Islands" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CX", label: "Christmas Island" },
  { value: "CC", label: "Cocos (Keeling) Islands" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo, Democratic Republic" },
  { value: "CK", label: "Cook Islands" },
  { value: "CR", label: "Costa Rica" },
  { value: "CI", label: "Cote D'Ivoire" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "ET", label: "Ethiopia" },
  { value: "FK", label: "Falkland Islands (Malvinas)" },
  { value: "FO", label: "Faroe Islands" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GF", label: "French Guiana" },
  { value: "PF", label: "French Polynesia" },
  { value: "TF", label: "French Southern Territories" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GI", label: "Gibraltar" },
  { value: "GR", label: "Greece" },
  { value: "GL", label: "Greenland" },
  { value: "GD", label: "Grenada" },
  { value: "GP", label: "Guadeloupe" },
  { value: "GU", label: "Guam" },
  { value: "GT", label: "Guatemala" },
  { value: "GG", label: "Guernsey" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HM", label: "Heard Island & Mcdonald Islands" },
  { value: "VA", label: "Holy See (Vatican City State)" },
  { value: "HN", label: "Honduras" },
  { value: "HK", label: "Hong Kong" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran, Islamic Republic Of" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IM", label: "Isle Of Man" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JE", label: "Jersey" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KR", label: "Korea" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Lao People's Democratic Republic" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libyan Arab Jamahiriya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macedonia" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MQ", label: "Martinique" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia, Federated States Of" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MS", label: "Montserrat" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "AN", label: "Netherlands Antilles" },
  { value: "NC", label: "New Caledonia" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk Island" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestinian Territory, Occupied" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "PR", label: "Puerto Rico" },
  { value: "QA", label: "Qatar" },
  { value: "RE", label: "Reunion" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russian Federation" },
  { value: "RW", label: "Rwanda" },
  { value: "BL", label: "Saint Barthelemy" },
  { value: "SH", label: "Saint Helena" },
  { value: "KN", label: "Saint Kitts And Nevis" },
  { value: "LC", label: "Saint Lucia" },
  { value: "MF", label: "Saint Martin" },
  { value: "PM", label: "Saint Pierre And Miquelon" },
  { value: "VC", label: "Saint Vincent And Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome And Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },
  { value: "GS", label: "South Georgia And Sandwich Isl." },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard And Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syrian Arab Republic" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad And Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TC", label: "Turks And Caicos Islands" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom" },
  { value: "US", label: "United States" },
  { value: "UM", label: "United States Outlying Islands" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Viet Nam" },
  { value: "VG", label: "Virgin Islands, British" },
  { value: "VI", label: "Virgin Islands, U.S." },
  { value: "WF", label: "Wallis And Futuna" },
  { value: "EH", label: "Western Sahara" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" }
];

const compliantCountries = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
  "GB"
];

const Forms = () => {
  const campaignService = new CampaignTrackingParameterService([]);

  return (
    <div>
      <Section>
        <Grid variant="1/6,2/3,1/6">
          <Grid.Item />
          <Grid.Item>
            <MarkupContainer>
              <Form
                action="https://demo1129453.mockable.io/form/succeed/inline-message"
                header={
                  <span>All fields required unless otherwise noted.</span>
                }
              >
                <VerticalSpacing size="s">
                  <Grid variant="even-2" gutterVerticalSmall>
                    <Grid.Item>
                      <Form.TextControl
                        id="firstName"
                        labelText="First name"
                        name="firstName"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="lastName"
                        labelText="Last name"
                        name="lastName"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        hashableIdentifier
                        id="emailAddress"
                        labelText="Email address"
                        name="emailAddress"
                        type="email"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="phoneNumber"
                        labelText="Phone number"
                        name="phoneNumber"
                        type="tel"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="city"
                        labelText="City"
                        name="city"
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.SelectControl
                        id="country"
                        labelText="Country"
                        name="country"
                        options={countries}
                        required
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="company"
                        labelText="Company"
                        name="company"
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="companyWebsite"
                        labelText="Company website"
                        name="companyWebsite"
                        type="url"
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.TextControl
                        id="jobTitle"
                        labelText="Job title"
                        name="jobTitle"
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.SelectControl
                        id="howDidYouHearAboutUs"
                        labelText="How did you hear about us?"
                        name="howDidYouHearAboutUs"
                        options={[
                          { label: "Friend recommendation", value: "friend" },
                          { label: "Google search", value: "google" },
                          { label: "Newspaper advert", value: "newspaper" },
                          { label: "TV advert", value: "tv" }
                        ]}
                      />
                    </Grid.Item>
                  </Grid>
                </VerticalSpacing>
                <VerticalSpacing size="s">
                  <Form.TextareaControl
                    id="message"
                    labelText="Message"
                    name="message"
                    required
                  />
                </VerticalSpacing>
                <VerticalSpacing size="s">
                  <Form.CheckboxControl
                    id="requestDemo"
                    labelText="Request a demo"
                    name="requestDemo"
                    value="demoRequested"
                  />
                </VerticalSpacing>
                <VerticalSpacing size="s">
                  <Form.PrivacyControl
                    consentName="consent"
                    consentText={
                      <span>
                        By submitting this form you are acknowledging that you
                        have read and agree to our{" "}
                        <a href="https://not-visited.com">Privacy Policy</a>
                      </span>
                    }
                    compliantCountries={compliantCountries}
                    dependsOn="country"
                    explicitConsentText="I have read and agree to your Privacy Policy"
                  />
                </VerticalSpacing>
                <Form.CampaignControl
                  name="utm_campaign"
                  parameterToTrack="utm_campaign"
                  service={campaignService}
                />
              </Form>
            </MarkupContainer>
          </Grid.Item>
        </Grid>
      </Section>
      <Section background="grayLightest">
        <Panel>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m" rank="3">
                  <strong>Ready to start a conversation?</strong>
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <p>
                    Need more information about how our solutions can work for
                    you? Drop us a line and someone from our sales team will get
                    back to you.
                  </p>
                  <p>
                    Or, you can call a representative in you region at{" "}
                    <a href="tel:+1(844) 226 9831">+1(844) 226 9831</a>
                  </p>
                </RichText>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <Form
                  action="https://demo1129453.mockable.io/form/succeed/inline-message"
                  submitButtonText="Send"
                >
                  <VerticalSpacing size="s">
                    <Grid variant="even-2" gutterVerticalSmall>
                      <Grid.Item>
                        <Form.TextControl
                          id="firstName01"
                          labelText="First name"
                          name="firstName01"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="lastName01"
                          labelText="Last name"
                          name="lastName01"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          hashableIdentifier
                          id="emailAddress01"
                          labelText="Email address"
                          name="emailAddress01"
                          type="email"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="phoneNumber01"
                          labelText="Phone number"
                          name="phoneNumber01"
                          type="tel"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="company01"
                          labelText="Company"
                          name="company01"
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="jobTitle01"
                          labelText="Job title"
                          name="jobTitle01"
                        />
                      </Grid.Item>
                    </Grid>
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.SelectControl
                      id="country01"
                      labelText="Country"
                      name="country01"
                      options={countries}
                      defaultValue="US"
                      required
                    />
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.TextareaControl
                      id="message01"
                      labelText="Message"
                      name="message01"
                      required
                    />
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.PrivacyControl
                      consentName="consent01"
                      consentText={
                        <span>
                          By submitting this form you are acknowledging that you
                          have read and agree to our{" "}
                          <a href="https://not-visited.com">Privacy Policy</a>
                        </span>
                      }
                      compliantCountries={compliantCountries}
                      dependsOn="country01"
                      explicitConsentText="I have read and agree to your Privacy Policy"
                    />
                  </VerticalSpacing>
                  <Form.CampaignControl
                    name="utm_campaign"
                    parameterToTrack="utm_campaign"
                    service={campaignService}
                  />
                </Form>
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Panel>
      </Section>
      <SectionBridge backgroundBottom="white" backgroundTop="grayLightest">
        <Panel>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m" rank="3">
                  Keep learning with <strong>ONESOURCE University</strong>
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <p>
                    ONESOURCE University is an online training platform for our
                    Income Tax, Tax Provision, and WorkFlow Manager solutions.
                    Using our centralized platform, you can sign up for
                    different types of training, manage your schedule and
                    progress, and get certifications. Choose the training format
                    that works for you—from webinars and e-learning courses, to
                    hands-on workshops and live consulting services.
                  </p>
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing>
                <Button
                  color="secondary"
                  href="https://example.com"
                  kind="link"
                >
                  Returning user? Sign in.
                </Button>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <Form
                  action="https://demo1129453.mockable.io/form/succeed/inline-message"
                  header={
                    <Heading rank="4" type="xs">
                      <b>Registration for new members</b>
                    </Heading>
                  }
                  submitButtonText="Submit request"
                >
                  <VerticalSpacing size="s">
                    <Grid variant="even-2" gutterVerticalSmall>
                      <Grid.Item>
                        <Form.TextControl
                          id="fullName"
                          labelText="Full name"
                          name="fullName"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="companyName"
                          labelText="Company name"
                          name="companyName"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          hashableIdentifier
                          id="corporateEmailAddress"
                          labelText="Corporate email address"
                          name="corporateEmailAddress"
                          type="email"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="fourDigitAccountNumber"
                          labelText="Four-digit account number"
                          name="fourDigitAccountNumber"
                          required
                        />
                      </Grid.Item>
                    </Grid>
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.CheckboxControl
                      id="requestAccessForOneOrMoreTeamMembers"
                      labelText="Request access for one or more team members"
                      name="requestAccessForOneOrMoreTeamMembers"
                      value="teamMemberAccessRequested"
                      required
                    />
                  </VerticalSpacing>
                  <Form.CampaignControl
                    name="utm_campaign"
                    parameterToTrack="utm_campaign"
                    service={campaignService}
                  />
                </Form>
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Panel>
      </SectionBridge>
      <Section>
        <Panel>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m" rank="3">
                  <strong>Still have questions?</strong> Contact our ONESOURCE
                  support team
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <Icon path={Icons.phone} size="s" />
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <h4>Get in touch</h4>
                  <p>Find the support call number in your region below.</p>
                  <p>
                    <strong>North America</strong> <br />M-F, 7 a.m. to 7 p.m.
                    Eastern Time +1 201 356 6409
                  </p>
                  <p>
                    <strong>EMEA</strong> <br />+44 (0)207 375 6869
                  </p>
                  <p>
                    <strong>Australia/New Zealand</strong> <br />+1800 074 333
                  </p>
                </RichText>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <Form action="https://demo1129453.mockable.io/form/succeed/inline-message">
                  <VerticalSpacing size="s">
                    <Grid variant="even-2" gutterVerticalSmall>
                      <Grid.Item>
                        <Form.TextControl
                          id="firstName02"
                          labelText="First name"
                          name="firstName02"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="lastName02"
                          labelText="Last name"
                          name="lastName02"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          hashableIdentifier
                          id="emailAddress02"
                          labelText="Email address"
                          name="emailAddress02"
                          type="email"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="phoneNumber02"
                          labelText="Phone number"
                          name="phoneNumber02"
                          type="tel"
                          required
                        />
                      </Grid.Item>
                    </Grid>
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.SelectControl
                      id="country02"
                      labelText="Country"
                      name="country02"
                      options={countries}
                      defaultValue="US"
                      required
                    />
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.TextareaControl
                      id="message02"
                      labelText="Message"
                      name="message02"
                      required
                    />
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.PrivacyControl
                      consentName="consent02"
                      consentText={
                        <span>
                          By submitting this form you are acknowledging that you
                          have read and agree to our{" "}
                          <a href="https://not-visited.com">Privacy Policy</a>
                        </span>
                      }
                      compliantCountries={compliantCountries}
                      dependsOn="country02"
                      explicitConsentText="I have read and agree to your Privacy Policy"
                    />
                  </VerticalSpacing>
                  <Form.CampaignControl
                    name="utm_campaign"
                    parameterToTrack="utm_campaign"
                    service={campaignService}
                  />
                </Form>
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Panel>
      </Section>
      <Section background="grayLightest">
        <Panel>
          <Grid variant="1/3,2/3">
            <Grid.Item>
              <VerticalSpacing>
                <Heading type="m" rank="3">
                  <strong>Still have questions?</strong> Contact our CS
                  Professional Suite support team
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing>
                <Icon path={Icons.phone} size="s" />
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <h4>Get in touch</h4>
                  <p>
                    Support hours can vary, depending on the time of year and
                    the specific products you use. Call +1 (800) 968 0600.
                  </p>
                </RichText>
              </VerticalSpacing>
              <VerticalSpacing>
                <Link
                  href="https://example.com"
                  type="icon"
                  icon={<Icon path={Icons.arrowRightBlue} size="s" />}
                  iconPosition={"right"}
                >
                  See support hours
                </Link>
              </VerticalSpacing>
              <VerticalSpacing>
                <RichText>
                  <p>
                    <strong>Sales</strong> <br />M-F, 8:30 a.m. - 8:00 p.m.
                    Eastern Time <br />
                    Phone: +1 800 968 8900 <br />
                    Fax: +1 800 326 1040
                  </p>
                  <p>
                    <strong>Accounts Receivable</strong> <br />M-F, 9 am - 5 pm
                    Eastern Time <br />
                    Phone: +1 800 249 7348 <br />
                    Fax: +1 734 426 4109
                  </p>
                  <p>
                    <strong>Human Resources</strong> <br />M-F, 9 am - 5 pm
                    Eastern Time <br />
                    Phone: +1 800 607 5100
                  </p>
                  <p>
                    <strong>Administration</strong> <br />M-F, 9 am - 5 pm
                    Eastern Time <br />
                    Phone: +1 800 607 5100, +1 734 426 5860 <br />
                    Fax: +1 800 326 1040, +1 734 426 3750
                  </p>
                </RichText>
              </VerticalSpacing>
            </Grid.Item>
            <Grid.Item>
              <MarkupContainer>
                <Form
                  action="https://demo1129453.mockable.io/form/succeed/inline-message"
                  footer={
                    <span>
                      According to the terms of your product license agreement,
                      fees for Product Support via email are billed according to
                      the same pricing schedule as telephone support.
                    </span>
                  }
                >
                  <VerticalSpacing size="s">
                    <Grid variant="even-2" gutterVerticalSmall>
                      <Grid.Item>
                        <Form.TextControl
                          id="firstName03"
                          labelText="First name"
                          name="firstName03"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="lastName03"
                          labelText="Last name"
                          name="lastName03"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          hashableIdentifier
                          id="emailAddress03"
                          labelText="Email address"
                          name="emailAddress03"
                          type="email"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.TextControl
                          id="phoneNumber03"
                          labelText="Phone number"
                          name="phoneNumber03"
                          type="tel"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.SelectControl
                          id="preferredMethodOfResponse"
                          labelText="Preferred method of response"
                          name="preferredMethodOfResponse"
                          options={[
                            { label: "Email", value: "email" },
                            { label: "Phone", value: "phone" }
                          ]}
                          placeholderText="Select"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.SelectControl
                          id="product"
                          labelText="Product"
                          name="product"
                          options={[
                            {
                              label: "Transfer Pricing Documenter",
                              value: "transfer-pricing-documenter"
                            },
                            { label: "UltraTax", value: "ultra-tax" }
                          ]}
                          placeholderText="Select"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.SelectControl
                          id="workstationOperatingSystem"
                          labelText="Workstation operating system"
                          name="workstationOperatingSystem"
                          options={[
                            { label: "MacOS", value: "mac-os" },
                            { label: "Windows 10", value: "windows-10" }
                          ]}
                          placeholderText="Select"
                          required
                        />
                      </Grid.Item>
                      <Grid.Item>
                        <Form.SelectControl
                          id="networkOperatingSystem"
                          labelText="Network operating system"
                          name="networkOperatingSystem"
                          options={[
                            { label: "Cisco", value: "cisco" },
                            { label: "Windows NT", value: "windows-nt" }
                          ]}
                          placeholderText="Select"
                          required
                        />
                      </Grid.Item>
                    </Grid>
                  </VerticalSpacing>
                  <VerticalSpacing size="s">
                    <Form.TextareaControl
                      id="describeYourProblemOrQuestion"
                      labelText="Describe your problem or question"
                      name="describeYourProblemOrQuestion"
                      required
                    />
                  </VerticalSpacing>
                  <Form.CampaignControl
                    name="utm_campaign"
                    parameterToTrack="utm_campaign"
                    service={campaignService}
                  />
                </Form>
              </MarkupContainer>
            </Grid.Item>
          </Grid>
        </Panel>
      </Section>
    </div>
  );
};

export default Forms;
