import React from "react";
import { storiesOf } from "@storybook/react";
import addStandardDecorators from "./addStandardDecorators";
import "@emcm-ui/component-markup-container/index.css";

import AboutUs from "./compositions/design/AboutUs";
import Article from "./compositions/design/Article";
import GridSystem from "./compositions/design/GridSystem";
import EventDetail from "./compositions/design/EventDetail";
import Home from "./compositions/design/Home";
import Industries from "./compositions/design/Industries";
import ProductDetailPage from "./compositions/design/ProductDetailPage";
import Search from "./compositions/design/Search";
import SearchNoResults from "./compositions/design/SearchNoResults";
import Sitemap from "./compositions/design/Sitemap";

const designStories = storiesOf("Compositions/Design", module);

addStandardDecorators(designStories);

designStories.add("About Us", () => <AboutUs />);
designStories.add("Article", () => <Article />);
designStories.add("Grid System", () => <GridSystem />);
designStories.add("EventDetail", () => <EventDetail />);
designStories.add("Home", () => <Home />);
designStories.add("Industries", () => <Industries />);
designStories.add("Product Detail Page", () => <ProductDetailPage />);
designStories.add("Search", () => <Search />);
designStories.add("Search no results", () => <SearchNoResults />);
designStories.add("Sitemap", () => <Sitemap />);
