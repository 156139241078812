import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import VideoPlayerCore from "./VideoPlayerCore";

const VideoPlayer = ({
  description,
  duration,
  name,
  thumbnailUrl,
  transcript,
  uploadDate,
  ...videoProps
}) => {
  const getClassName = getClassNameFactory(VideoPlayer.displayName);

  const jsonLd = (description ||
    duration ||
    name ||
    thumbnailUrl ||
    uploadDate) && {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    description,
    duration,
    name,
    thumbnailUrl,
    uploadDate,
    transcript
  };

  return (
    <div className={getClassName()}>
      {jsonLd && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(jsonLd)
          }}
        />
      )}
      <VideoPlayerCore duration={duration} {...videoProps} />
    </div>
  );
};

VideoPlayer.propTypes = {
  /**
   * A short description of the video.  Schema.org only - not surfaced
   * visually. For more details, see https://schema.org/video.
   */
  description: PropTypes.string,

  /**
   * The length of the video, as an ISO 8601 duration (see
   * https://en.wikipedia.org/wiki/ISO_8601). Schema.org and surfaced
   * visually in a (hh:)mm:ss format. For more details, see https://schema.org/video.
   */
  duration: PropTypes.string.isRequired,

  /**
   * The name of the video. Schema.org only - not surfaced visually.
   * For more details, see https://schema.org/video.
   */
  name: PropTypes.string,

  /**
   * The URL for the thumbnail. Schema.org only - not surfaced visually.
   * For more details, see https://schema.org/video.
   */
  thumbnailUrl: PropTypes.string,

  /**
   * The date the video was uploaded. Schema.org only - not surfaced visually.
   * For more details, see https://schema.org/video.
   */
  uploadDate: PropTypes.string,

  /**
   * The date the video was uploaded. Schema.org only - not surfaced visually.
   * For more details, see https://schema.org/video.
   */

  kaltura: PropTypes.string,

  ...VideoPlayerCore.propTypes
};

VideoPlayer.displayName = "VideoPlayer";

export default VideoPlayer;
