const modalDecorator = story => {
  // append modal root for component-modal
  const modalRoot = document.createElement("div");

  modalRoot.id = "modalRoot";
  document.body.appendChild(modalRoot);

  return story();
};

export default modalDecorator;
