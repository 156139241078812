import React from "react";
import PropTypes from "prop-types";
import Image, { anchorPoints } from "@emcm-ui/component-image";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const FeaturedItem = ({ children, imageAnchor, imageSrc, imageSrcSet }) => {
  const getClassName = getClassNameFactory(FeaturedItem.displayName);

  return (
    <div className={getClassName()}>
      <div className={getClassName({ descendantName: "image" })}>
        <Image
          anchor={imageAnchor}
          alt=""
          fit="cover"
          height={120}
          sizes={Image.sizes.oneThird}
          src={imageSrc}
          srcSet={imageSrcSet}
        />
      </div>
      <div className={getClassName({ descendantName: "content" })}>
        {children}
      </div>
    </div>
  );
};

FeaturedItem.defaultProps = {
  imageAnchor: "C"
};

FeaturedItem.propTypes = {
  /**
   * Content of the featured item
   */
  children: PropTypes.node.isRequired,

  /**
   * Anchor to pass through to Image
   */
  imageAnchor: PropTypes.oneOf(anchorPoints),

  /**
   * Src to pass through to Image
   */
  imageSrc: PropTypes.string.isRequired,

  /**
   * SrcSet to pass through to Image
   */
  imageSrcSet: PropTypes.string
};

FeaturedItem.displayName = "SiteHeader.FeaturedItem";

export default FeaturedItem;
