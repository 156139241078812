import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import supportedLanguages from "./supportedLanguages";
import rtlLanguages from "./rtlLanguages";

const Language = ({ children, code }) => {
  const getClassName = getClassNameFactory(Language.displayName);
  let dir = "ltr";

  if (rtlLanguages.includes(code)) {
    dir = "rtl";
  }

  return (
    <div className={getClassName()} dir={dir} lang={code}>
      {children}
    </div>
  );
};

Language.displayName = "Language";

Language.propTypes = {
  children: PropTypes.node.isRequired,
  code: PropTypes.oneOf(Object.keys(supportedLanguages))
};

Language.defaultProps = {
  code: "en"
};

export default Language;
