/**
 * Calls every function in the first argument, using all the arguments that
 * follow.
 *
 * eg.
 *   callEach([(arg1, arg2) => { console.log(arg1, arg2); }], "hello", "world");
 * would log "hello", "world" to the console.
 */
export default (functions, ...args) => {
  for (const cb of functions) {
    cb(...args);
  }
};
