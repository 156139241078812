import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const SemanticAside = ({ children, role }) => {
  const getClassName = getClassNameFactory(SemanticAside.displayName);

  return (
    <aside className={getClassName()} role={role}>
      {children}
    </aside>
  );
};

SemanticAside.displayName = "SemanticAside";

/* eslint-disable max-len */
SemanticAside.propTypes = {
  /**
   * Child nodes passed to the component
   */
  children: PropTypes.node,
  /**
   * ARIA permitted role
   */
  role: PropTypes.oneOf(["complementary", "note", "search", "presentation"])
};
/* eslint-enable max-len */

SemanticAside.defaultProps = {
  role: "complementary"
};

export default SemanticAside;
