import PropTypes from "prop-types";
import React, { Component } from "react";
import Button from "@emcm-ui/component-button";
import Datamaps from "datamaps/dist/datamaps.all.hires.js";
import Legend from "./components/legend";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Datamap extends Component {
  static displayName = "Datamap";
  static propTypes = {
    data: PropTypes.object,
    fillKey: PropTypes.string,
    fills: PropTypes.object,
    height: PropTypes.string,
    legendProps: PropTypes.object,
    popupTemplate: PropTypes.func,
    projection: PropTypes.string,
    width: PropTypes.string
  };
  static defaultProps = {
    fillKey: "fillHighlight",
    fills: {
      fillHighlight: "#ff5900",
      defaultFill: "#cfcfcf",
      mouseOverFill: "#ff5900"
    },
    height: "60%",
    popupTemplate: geography => <strong>{geography.properties.name}</strong>,
    projection: "mercator",
    width: "100%"
  };
  getClassName = getClassNameFactory(Datamap.displayName);

  state = { popupBody: null, country: "" };

  selectedCountry = "";
  previousColor = "";

  constructor(props) {
    super(props);
    window.addEventListener("resize", this.resize);
  }

  resize = () => {
    if (this.map) {
      this.map.resize();
    }
  };

  // this will create the map when the component mounts
  componentDidMount() {
    this.drawMap();
  }

  // this will update the map with the latest props
  componentDidUpdate() {
    this.clear();
    this.drawMap();
    if (this.state.country && this.map) {
      this.map.updateChoropleth(
        {
          ...this.props.data,
          [this.state.country]: { fillKey: this.props.fillKey }
        },
        { reset: true }
      );
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.country = "";
    }
  }

  componentWillUnmount() {
    this.clear();
    window.removeEventListener("resize", this.resize);
  }

  clear = () => {
    const container = this.container;

    for (const child of Array.from(container.childNodes)) {
      container.removeChild(child);
    }
  };

  onItemClick = (geography, datamap) => {
    /* eslint-disable no-undef */
    /* eslint-disable no-magic-numbers */
    const position = d3.mouse(datamap.options.element);
    const popupClassName = this.getClassName({
      descendantName: "popup"
    });
    const popupElm = d3.select(`.${popupClassName}`);
    const popupBody = this.props.popupTemplate(geography);

    popupElm.attr("hidden", null);

    popupElm.style({
      left: `${position[0] + 20}px`,
      top: `${position[1] - 20}px`
    });
    this.setState({
      popupBody,
      country: geography.id
    });
    this.selectedCountry = geography.id;
  };

  onItemHover = ({ geography, type, elm, color = "" }) => {
    const fill =
      type === "over"
        ? this.props.fills.mouseOverFill || this.props.fills.fillHighlight
        : this.previousColor;

    this.previousColor = color;
    if (this.selectedCountry !== geography.id) {
      elm.style("fill", fill);
    }
  };

  closePopup = () => {
    const popupClassName = this.getClassName({
      descendantName: "popup"
    });

    this.map.updateChoropleth(
      {
        ...this.props.data
      },
      { reset: true }
    );
    d3.select(`.${popupClassName}`).attr("hidden", true);
    this.selectedCountry = "";
  };

  drawMap = () => {
    const { data, fills, projection } = this.props;
    const { onItemClick, onItemHover } = this;
    const datamapsConfig = {
      data: { ...data },
      done(datamap) {
        datamap.svg
          .selectAll(".datamaps-subunit")
          .on("click", geography => onItemClick(geography, datamap))
          .on("mouseover", function(geography) {
            const elm = d3.select(this);
            const color = elm.style("fill") || "";

            onItemHover({ geography, type: "over", elm, color });
          })
          .on("mouseout", function(geography) {
            const elm = d3.select(this);

            onItemHover({ geography, type: "out", elm });
          });
      },
      element: this.container, // this is the place where the react dom and the Datamaps dom will be wired
      fills,
      geographyConfig: {
        highlightOnHover: false,
        popupOnHover: false
      },
      projection,
      responsive: true,
      scope: "world"
    };
    const map = new Datamaps({
      ...datamapsConfig
    });

    this.map = map;
  };

  render() {
    const {
      data,
      fillKey,
      fills,
      height,
      legendProps,
      projection,
      width
    } = this.props;
    const style = {
      width,
      minHeight: height
    };

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(Datamap.displayName)}
        data-data={JSON.stringify(data)}
        data-fill-key={JSON.stringify(fillKey)}
        data-fills={JSON.stringify(fills)}
        data-height={JSON.stringify(height)}
        data-legend-props={JSON.stringify(legendProps)}
        data-projection={JSON.stringify(projection)}
        data-width={JSON.stringify(width)}
      >
        <div
          style={style}
          className={this.getClassName({
            descendantName: "body"
          })}
          ref={datamap => (this.container = datamap)}
        />
        {legendProps && <Legend fills={fills} legendProps={legendProps} />}
        <div
          className={this.getClassName({
            descendantName: "popup"
          })}
          hidden
        >
          <div
            className={this.getClassName({
              descendantName: "popupText"
            })}
          >
            {this.state.popupBody}
          </div>

          <Button
            onClick={this.closePopup}
            icon={<SVGIcon name="close" />}
            iconHover={<SVGIcon name="close" />}
            iconOnly={true}
            kind="button"
          >
            close
          </Button>
        </div>
      </div>
    );
  }
}

export default Datamap;
