import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import { Consumer as CurrentOpenTabPanelConsumer } from "../../currentOpenTabPanelContext";
import { Consumer as DefaultOpenTabPanelConsumer } from "../../defaultOpenTabPanelContext";
import { Consumer as SetOpenTabPanelConsumer } from "../../setOpenTabPanelContext";

import SiteHeaderPanel from "../../../Panel";

class TabPanel extends Component {
  static propTypes = {
    /**
     * The contents of this panel.
     */
    children: PropTypes.node,

    /**
     * The id of this panel. Should be unique on the current page.
     */
    id: PropTypes.string.isRequired,

    /**
     * The title of this panel, which will be shown in its associated tab.
     */
    tabTitle: PropTypes.string.isRequired
  };

  static displayName = "SiteHeader.Flyouts.TabPanel";

  render() {
    const { children, id, tabTitle } = this.props;
    const getClassName = getClassNameFactory(TabPanel.displayName);

    return (
      <SetOpenTabPanelConsumer>
        {setOpenPanel => (
          <CurrentOpenTabPanelConsumer>
            {currentOpenPanel => (
              <DefaultOpenTabPanelConsumer>
                {defaultOpenPanel => (
                  <div
                    aria-labelledby={`tab-${id}`}
                    role="tabpanel"
                    className={getClassName({
                      states: classNames({
                        visibleAtSiteHeaderViewport:
                          currentOpenPanel === id ||
                          (currentOpenPanel === null && defaultOpenPanel === id)
                      })
                    })}
                    data-rehydratable={getRehydratableName(
                      TabPanel.displayName
                    )}
                    data-tab-title={tabTitle}
                    id={id}
                    tabIndex="-1"
                  >
                    <SiteHeaderPanel
                      onBack={() => setOpenPanel(null)}
                      open={currentOpenPanel === id}
                      title={tabTitle}
                    >
                      <div
                        className={getClassName({
                          descendantName: "children"
                        })}
                      >
                        {children}
                      </div>
                    </SiteHeaderPanel>
                  </div>
                )}
              </DefaultOpenTabPanelConsumer>
            )}
          </CurrentOpenTabPanelConsumer>
        )}
      </SetOpenTabPanelConsumer>
    );
  }
}

export default TabPanel;
