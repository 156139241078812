import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

import Item from "./components/Item";

const Grid = ({
  children,
  flex,
  gutterBorder,
  gutterless,
  gutterlessAtNarrow,
  gutterVerticalSmall,
  variant,
  setRoleAttribute
}) => {
  const getClassName = getClassNameFactory(Grid.displayName);
  const cleanVariant = variant.replace(/,/g, "");

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          [cleanVariant]: true,
          flex,
          gutterBorder,
          gutterless,
          gutterlessAtNarrow,
          gutterVerticalSmall
        })
      })}
    >
      <div
        className={getClassName({ descendantName: "items" })}
        {...(setRoleAttribute ? { role: "list" } : {})}
      >
        {children}
      </div>
    </div>
  );
};

Grid.displayName = "Grid";

const variants = [
  "1/3,2/3",
  "1/4,3/4",
  "1/4,2/4,1/4",
  "1/4,1/4,2/4",
  "2/4,1/4,1/4",
  "1/6,2/3,1/6",
  "10/12,2/12",
  "2/3,1/3",
  "3/4,1/4",
  "7/12,5/12",
  "collage-1",
  "collage-2",
  "even-2",
  "even-3",
  "even-4",
  "even-5",
  "even-6",
  "even-12",
  "vertical"
];

/* eslint-disable max-len */
Grid.propTypes = {
  /**
   * The contents of the Grid. Should always be `<Grid.Item />` components.
   */
  children: PropTypes.node,
  /**
   * Use CSS flex rather than CSS grid. Useful for debugging.
   */
  flex: PropTypes.bool,
  /**
   * Add a border to the gutter.
   */
  gutterBorder: PropTypes.bool,
  /**
   * Gutterless mode.
   */
  gutterless: PropTypes.bool,
  /**
   * Gutterless mode for stacked/narrow only.
   */
  gutterlessAtNarrow: PropTypes.bool,
  /**
   * Reduce vertical gutter size
   */
  gutterVerticalSmall: PropTypes.bool,
  /**
   * The grid variant to use.
   */
  variant: PropTypes.oneOf(variants).isRequired,
  /**
   * The role attribute append in div
   */
  setRoleAttribute: PropTypes.bool
};
/* eslint-enable max-len */

Grid.defaultProps = {
  flex: false,
  gutterBorder: false,
  gutterVerticalSmall: false,
  gutterless: false,
  gutterlessAtNarrow: false,
  setRoleAttribute: false
};

Grid.Item = Item;
Grid.variants = variants;

export default Grid;
