import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import className from "classnames";

const Alignment = ({ children, horizontal, vertical }) => {
  const getClassName = getClassNameFactory(Alignment.displayName);

  const horizontalCapitalized =
    horizontal.charAt(0).toUpperCase() + horizontal.slice(1);
  const verticalCapitalized =
    vertical.charAt(0).toUpperCase() + vertical.slice(1);

  return (
    <div
      className={getClassName({
        modifiers: className({
          [`horizontal${horizontalCapitalized}`]: true,
          [`vertical${verticalCapitalized}`]: true
        })
      })}
    >
      <div className={getClassName({ descendantName: "inner" })}>
        {children}
      </div>
    </div>
  );
};

Alignment.displayName = "Alignment";

Alignment.horizontalAlignments = ["center", "left", "right"];
Alignment.verticalAlignments = ["bottom", "center", "top"];

Alignment.propTypes = {
  /**
   * Child nodes to align. Will be wrapped in a div.
   */
  children: PropTypes.node.isRequired,
  /**
   * Horizontal alignment of the content, using flexbox.
   */
  horizontal: PropTypes.oneOf(Alignment.horizontalAlignments),
  /**
   * Vertical alignment of the content, using flexbox.
   */
  vertical: PropTypes.oneOf(Alignment.verticalAlignments)
};

Alignment.defaultProps = {
  horizontal: "left",
  vertical: "top"
};

export default Alignment;
