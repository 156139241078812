import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "../Link";

const FilterGroup = ({ children, href, title }) => {
  const getClassName = getClassNameFactory("SearchFiltersFilterGroup");

  return (
    <li className={getClassName()}>
      <div className={getClassName({ descendantName: "title" })}>
        <Link href={href}>{title}</Link>
      </div>

      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </li>
  );
};

FilterGroup.displayName = "SearchFilters.FilterGroup";

FilterGroup.propTypes = {
  /**
   * This group's children. Should be `SearchFilters.FilterItem`.
   */
  children: PropTypes.node,

  /**
   * The destination for the title of this group.
   */
  href: PropTypes.string,

  /**
   * The title of this group.
   */
  title: PropTypes.string
};

export default FilterGroup;
