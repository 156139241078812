import React from "react";
import GlobalHeader from "@emcm-ui/component-global-header";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Icon from "@emcm-ui/component-icon";
import tickIcon from "./icons/tick-07.svg";
import documentIcon from "./icons/document-07.svg";
import unlockedIcon from "./icons/unlocked-07.svg";

const SampleGlobalHeader = () => (
  <MarkupContainer>
    <GlobalHeader
      leftItems={
        <React.Fragment>
          <GlobalHeader.Item
            narrow
            label="Directory"
            longLabel="Global Directory"
            href="/directory"
          >
            <GlobalHeader.Column>
              <GlobalHeader.NavList>
                <GlobalHeader.NavItem
                  title="Contact item 1"
                  href="#"
                  icon={<Icon path={tickIcon} size="s" />}
                />
                <GlobalHeader.NavItem
                  title="Contact item 2"
                  href="#"
                  icon={<Icon path={documentIcon} size="s" />}
                />
                <GlobalHeader.NavItem
                  title="Contact item 3 with more text"
                  href="#"
                  icon={<Icon path={unlockedIcon} size="s" />}
                />
              </GlobalHeader.NavList>
            </GlobalHeader.Column>
          </GlobalHeader.Item>
        </React.Fragment>
      }
      rightItems={
        <React.Fragment>
          <GlobalHeader.Item label="Contact" href="/contact" />
          <GlobalHeader.Item label="Support" href="/support" />
          <GlobalHeader.Item label="Login" href="/login" />
        </React.Fragment>
      }
    />
  </MarkupContainer>
);

export default SampleGlobalHeader;
