import React from "react";
import { storiesOf } from "@storybook/react";
import LinkTo from "@storybook/addon-links/react";
import { withKnobs, number, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Language from "./components/Language";
import ColorTable from "./stories/ColorTable";
import Space from "./stories/Space";
import Typography from "./stories/Typography";
import "./Theme.css";
import "./stories/Stories.css";
import ThemeREADME from "../README.md";

import colorPairs from "!css-variables-loader!./styles/vars/ColorsDescriptive.css";
import supportedLanguages from "./components/Language/supportedLanguages";

const typographyWidth = 18; /* in ch units */

const stories = storiesOf("Theme", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ThemeREADME));

stories
  .add("Index", () => (
    /* eslint-disable no-script-url */
    <div className="story-themeSection">
      <h1 className="story-themeHeading">System</h1>
      <ul className="story-themeSubHeading">
        <li>
          <LinkTo className="story-themeLink" story="Color">
            Color
          </LinkTo>
        </li>
        <li>
          <LinkTo className="story-themeLink" story="Language">
            Language
          </LinkTo>
        </li>
        <li>
          <LinkTo className="story-themeLink" story="Typography">
            Typography
          </LinkTo>
        </li>
        <li>
          <LinkTo className="story-themeLink" story="Space">
            Space
          </LinkTo>
        </li>
      </ul>
    </div>
    /* eslint-enable no-script-url */
  ))
  .add("Color", () => (
    <div className="story-themeSection">
      <h1 className="story-themeHeading">Color</h1>
      <ColorTable colorPairs={colorPairs} />
    </div>
  ))
  .add("Language", () => (
    <div className="story-themeSection">
      <h1 className="story-themeHeading">Language</h1>
      {Object.keys(supportedLanguages).map(code => {
        return (
          <Language code={code} key={code}>
            <h2 className="story-themeSubHeading">
              {supportedLanguages[code].name}
            </h2>
            <div className="story-typographySpecimen">
              {supportedLanguages[code].sample}
            </div>
          </Language>
        );
      })}
    </div>
  ))
  .add("Typography", () => (
    <Typography
      text={text("Text", "The slow black dog bows before the")}
      boldText={text("Bold text", "regal fox")}
      width={number("Width (ch)", typographyWidth)}
    />
  ))
  .add("Space", () => (
    <div className="story-themeSection">
      <div>
        <h1 className="story-themeHeading">
          Space
          <div className="story-themeHeadingLink">
            Reference:{" "}
            <a href="https://medium.com/eightshapes-llc/space-in-design-systems-188bcbae0d62">
              Space In Design Systems
            </a>
          </div>
        </h1>
        <Space />
      </div>
    </div>
  ));
