import LoginManager, {
  ECommLoginService,
  ECommLoginServiceConfigurationUAT,
  ECommLoginServiceConfigurationPPE,
  ECommLoginServiceConfigurationProd,
  FakeLoginService,
  LegalECommLoginServiceConfigurationUAT,
  LegalECommLoginServiceConfigurationPPE,
  LegalECommLoginServiceConfigurationProd
} from "@emcm-ui/utility-authentication";
import siteProps from "./utilities/siteProps";

let service = null;

if (siteProps.current.loginService === "legal-ecomm") {
  switch (siteProps.current.environment) {
    case "uat":
      service = new ECommLoginService(
        new LegalECommLoginServiceConfigurationUAT()
      );
      break;
    case "ppe":
      service = new ECommLoginService(
        new LegalECommLoginServiceConfigurationPPE()
      );
      break;
    case "prod":
      service = new ECommLoginService(
        new LegalECommLoginServiceConfigurationProd()
      );
      break;
    default:
      throw Error("siteProps environment variable unrecognised or not set.");
  }
} else if (siteProps.current.loginService === "tax-ecomm") {
  switch (siteProps.current.environment) {
    case "uat":
      service = new ECommLoginService(new ECommLoginServiceConfigurationUAT());
      break;
    case "ppe":
      service = new ECommLoginService(new ECommLoginServiceConfigurationPPE());
      break;
    case "prod":
      service = new ECommLoginService(new ECommLoginServiceConfigurationProd());
      break;
    default:
      throw Error("siteProps environment variable unrecognised or not set.");
  }
} else if (siteProps.current.loginService === "fake") {
  service = new FakeLoginService();
}

let loginManager = null;

if (service) {
  loginManager = new LoginManager(service);
  service.activate();
}

export default loginManager;
