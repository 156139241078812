import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "../Link";

const FilterItem = ({ borderless, children, disabled, href }) => {
  const getClassName = getClassNameFactory("SearchFiltersFilterItem");

  return (
    <li
      className={getClassName({
        modifiers: borderless ? "borderless" : ""
      })}
    >
      <div
        className={getClassName({
          descendantName: "link"
        })}
      >
        <Link href={disabled ? null : href} disabled={disabled}>
          {children}
        </Link>
      </div>
    </li>
  );
};

FilterItem.displayName = "SearchFilters.FilterItem";

FilterItem.propTypes = {
  /**
   * Whether this item should have a border
   */
  borderless: PropTypes.bool,

  /**
   * The title of this group.
   */
  children: PropTypes.string,

  /**
   * Show as disabled
   */
  disabled: PropTypes.bool,

  /**
   * The destination for this item.
   */
  href: PropTypes.string
};

export default FilterItem;
