import AuthState from "../AuthState";
import callEach from "../utilities/callEach";

const namespace = "tr.auth.FakeLoginService.";

const getItem = (key, defaultValue = null) => {
  if (typeof window !== "undefined" && window.localStorage) {
    return window.localStorage.getItem(`${namespace}${key}`) || defaultValue;
  }
};

const setItem = (key, value) => {
  if (typeof window !== "undefined" && window.localStorage) {
    window.localStorage.setItem(`${namespace}${key}`, value);
  }
};

const removeItem = key => {
  if (typeof window !== "undefined" && window.localStorage) {
    window.localStorage.removeItem(`${namespace}${key}`);
  }
};

/**
 * A fake auth service, which maintains an auth state for a user named John
 * Smith.
 */

export default class {
  constructor() {
    this.active = false;
    this.onChangeCallbacks = [];

    this.addItemToCart = this.addItemToCart.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.removeItemFromCart = this.removeItemFromCart.bind(this);

    if (typeof window !== "undefined") {
      if (!window.localStorage) {
        // Since this is a fake login service, we don't need to be thorough in
        // supporting non-localStorage scenarios.
        // eslint-disable-next-line no-console
        console.warn(
          "localStorage is unavailable. Login may not function as expected."
        );
      }
    }
  }

  activate() {
    if (!this.active) {
      this.active = true;

      this.privateTriggerChange();
    }
  }

  addItemToCart() {
    setItem("itemsInCart", this.itemsInCart + 1);

    this.privateTriggerChange();
  }

  removeItemFromCart() {
    if (this.itemsInCart > 0) {
      setItem("itemsInCart", this.itemsInCart - 1);

      this.privateTriggerChange();
    }
  }

  login() {
    setItem("authState", AuthState.LOGGED_IN);
    setItem("itemsInCart", 2); // eslint-disable-line no-magic-numbers
    setItem("userName", "John Smith");
    setItem("userFirstName", "John");

    this.privateTriggerChange();
  }

  logout() {
    if (typeof window !== "undefined" && window.localStorage) {
      removeItem("authState");
      removeItem("itemsInCart");
      removeItem("userName");
      removeItem("userFirstName");

      this.privateTriggerChange();
    }
  }

  onChange(cb) {
    this.onChangeCallbacks.push(cb);
  }

  offChange(cb) {
    if (this.onChangeCallbacks.includes(cb)) {
      this.onChangeCallbacks.splice(this.onChangeCallbacks.indexOf(cb), 1);

      return true;
    }

    return false;
  }

  privateTriggerChange() {
    callEach(this.onChangeCallbacks, this);
  }

  get authState() {
    if (this.active) {
      return parseInt(getItem("authState", AuthState.LOGGED_OUT));
    }

    return AuthState.UNKNOWN;
  }

  get itemsInCart() {
    if (this.active) {
      return parseInt(getItem("itemsInCart", 0));
    }

    return 0;
  }

  get userName() {
    if (this.active) {
      return getItem("userName");
    }

    return null;
  }

  get userFirstName() {
    if (this.active) {
      return getItem("userFirstName");
    }

    return null;
  }
}
