import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";

const Block = ({ color, height, text, rounded }) => {
  const getClassName = getClassNameFactory(Block.displayName);

  return (
    <div
      className={getClassName({
        modifiers: classNames(color, { rounded }, { noContent: !text })
      })}
      style={{
        height
      }}
    >
      <div className={getClassName({ descendantName: "text" })}>{text}</div>
    </div>
  );
};

Block.displayName = "Block";

Block.colors = ["blue", "orange", "red", "purple", "green", "gray"];

Block.propTypes = {
  /**
   * The color for this `Block`
   */
  color: PropTypes.oneOf(Block.colors),

  /**
   * Desired height. Can be any valid CSS size. Defaults to `"auto"`
   */
  height: PropTypes.string,

  /**
   * Text to show inside this `Block`
   */
  text: PropTypes.string,

  /**
   * Rounded corners for this `Block`
   */
  rounded: PropTypes.bool
};

Block.defaultProps = {
  color: "blue",
  height: "auto",
  rounded: false
};

export default Block;
