import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import PrivacyControl from "./PrivacyControl";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("FormNew.PrivacyControl");

  const consentTextNode = domNode.querySelector(
    `.${getClassName({ descendantName: "consent" })}`
  );

  const consentText = await rehydrateChildren(consentTextNode);

  const props = {
    compliantCountries: JSON.parse(
      domNode.getAttribute("data-compliant-countries")
    ),
    customCompliants: JSON.parse(
      domNode.getAttribute("data-custom-consent-channel")
    ),
    customCompliantsLabel: domNode.getAttribute("data-custom-consent-label"),
    consentName: domNode.getAttribute("data-consent-name"),
    consentText,
    dependsOn: domNode.getAttribute("data-depends-on"),
    explicitConsentId: domNode.getAttribute("data-explicit-consent-id"),
    explicitConsentText: domNode.getAttribute("data-explicit-consent-text"),
    optionalLabel: domNode.getAttribute("data-optional-label"),
    isRehydrating: true
  };

  return <PrivacyControl {...props} />;
};
