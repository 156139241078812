import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Alert from "@emcm-ui/component-alert";
import Heading from "@emcm-ui/component-heading";
import loadRecaptcha from "./apiLoader/loadRecaptcha";

import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

class Recaptcha extends Component {
  static displayName = "Recaptcha";

  static propTypes = {
    /**
     * Label of the recaptcha
     */
    label: PropTypes.string,
    /**
     * Actual API Key for recaptcha to be coming from AEM side
     */
    apiKey: PropTypes.string,
    /**
     * Validation message for recaptcha
     */
    validationMessage: PropTypes.string,
    /**
     * ID for recaptcha
     */
    captchaId: PropTypes.string
  };

  componentDidMount() {
    loadRecaptcha();
  }

  getClassName = getClassNameFactory(Recaptcha.displayName);

  render() {
    const { label, apiKey, validationMessage, captchaId } = this.props;

    return (
      <div
        className={this.getClassName()}
        data-rehydratable={getRehydratableName(Recaptcha.displayName)}
        data-label={label}
        data-captcha-id={captchaId}
        data-sitekey={apiKey}
        data-validation-message={validationMessage}
      >
        <div
          className={this.getClassName({
            descendantName: "outer"
          })}
        >
          <Heading type="xs">{label}</Heading>
          <div
            className={this.getClassName({
              descendantName: "inner"
            })}
          >
            <div className="g-recaptcha" data-sitekey={apiKey} />
          </div>
          <div
            className={this.getClassName({
              descendantName: "required"
            })}
          >
            <Alert state="failure">{validationMessage}</Alert>
          </div>
          <input
            type="hidden"
            id="captchaResponse"
            name="captchaResponse"
            value=""
          />
        </div>
      </div>
    );
  }
}

export default Recaptcha;
