import React from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";

import TabPanel from "./TabPanel";

const rehydrator = async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("SiteHeaderFlyoutsTabPanel");
  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "children" })}`
  );
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const rehydratedProps = {
    tabTitle: domNode.getAttribute("data-tab-title"),
    id: domNode.getAttribute("id")
  };

  return <TabPanel {...rehydratedProps}>{reactifiedChildren}</TabPanel>;
};

export default rehydrator;
