export const dedupeParams = paramList => {
  const output = [];

  paramList.forEach(listItem => {
    if (output.indexOf(listItem) < 0) {
      output.push(listItem);
    }
  });

  return output.length === 1 ? output[0] : output;
};
