import { submitRequest } from "./requests";

const base = ({ state }) => ({
  state
});

// Success object factories
const success = ({ action }) => ({
  ...base({ state: "success" }),
  action
});

const inlineMessage = ({ message }) => ({
  ...success({ action: "inlineMessage" }),
  message
});

const redirect = ({ location }) => ({
  ...success({ action: "redirect" }),
  location
});

const unknown = ({ exception }) => ({
  ...success({ action: "unknown" }),
  exception
});

// Fail object factories
const failure = ({ failType }) => ({
  ...base({ state: "failure" }),
  failType
});

const failException = ({ exception }) => ({
  ...failure({ failType: "exception" }),
  exception
});

const failMessage = ({ message }) => ({
  ...failure({ failType: "message" }),
  failMessage: message
});

/**
 * Submits a form asynchronously.
 *
 * Arguments:
 * `path`: where the form should be submitted
 * `formData`: the `FormData` representing the form. See https://developer.mozilla.org/en-US/docs/Web/API/FormData
 *
 * Will always return an object with the following structure:
 *
 * {
 *   state: string(success|failure) // always set
 *
 *   action?: string(inlineMessage|redirect|unknown), // always set when state == success
 *   location?: string, // always set when action == redirect
 *   message?: string, // always set when action == inlineMessage. sometimes set when state == fail
 *
 *   failMessage?: string, // always set when failType == message
 *   failType?: string(exception|message), // always set when state == failure
 *
 *   exception?: Error|string, // always set when failType == exception, may be set when action == unknown
 * }
 */
export default async (path, formData) => {
  let jsonData;
  let response;

  try {
    response = await submitRequest(path, formData);
  } catch (exception) {
    return failException({ exception });
  }

  try {
    jsonData = await response.json();
  } catch (exception) {
    if (response.ok) {
      // Server says the submission completed, but we couldn't parse the response.
      // We treat this as success, but with unknown actions.
      return unknown({ exception });
    }

    // Server returned an error status with an unparseable response.
    return failException({ exception });
  }

  if (response.ok) {
    switch (jsonData.action) {
      case "redirect":
        return redirect({ location: jsonData.location });

      case "inlineMessage":
        return inlineMessage({ message: jsonData.message });

      default:
        return unknown({});
    }
  }

  return failMessage({ message: jsonData.message });
};
