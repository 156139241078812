import React from "react";
import GlobalHeader from "@emcm-ui/component-global-header";
import MarkupContainer from "@emcm-ui/component-markup-container";
import LoginManager, {
  FakeLoginService
} from "@emcm-ui/utility-authentication";

const SampleGlobalHeader = () => {
  const loginService = new FakeLoginService();
  const loginManager = new LoginManager(loginService);

  return (
    <MarkupContainer>
      <GlobalHeader
        leftItems={
          <React.Fragment>
            <GlobalHeader.Item
              label="Directory"
              longLabel="Global Directory"
              href="/directory"
            >
              <GlobalHeader.Column>
                <GlobalHeader.NavList title="Regional Sites">
                  <GlobalHeader.NavItem title="North America" href="#" />
                  <GlobalHeader.NavItem title="Latin America" href="#" />
                  <GlobalHeader.NavItem title="AMEA" href="#" />
                  <GlobalHeader.NavItem title="APAC" href="#" />
                  <GlobalHeader.NavItem
                    title="View all sites"
                    href="#"
                    summary
                  />
                </GlobalHeader.NavList>
              </GlobalHeader.Column>
              <GlobalHeader.Column>
                <GlobalHeader.NavList title="Sectors">
                  <GlobalHeader.NavItem title="Automotive" href="#" />
                  <GlobalHeader.NavItem title="Financial" href="#" />
                  <GlobalHeader.NavItem title="Energy" href="#" />
                  <GlobalHeader.NavItem title="Government" href="#" />
                  <GlobalHeader.NavItem title="Legal" href="#" />
                  <GlobalHeader.NavItem title="News & media" href="#" />
                  <GlobalHeader.NavItem title="Risk management" href="#" />
                  <GlobalHeader.NavItem title="Tax & accounting" href="#" />
                  <GlobalHeader.NavItem title="Technology" href="#" />
                  <GlobalHeader.NavItem
                    title="thomsonreuters.com"
                    href="#"
                    summary
                  />
                </GlobalHeader.NavList>
              </GlobalHeader.Column>
            </GlobalHeader.Item>
          </React.Fragment>
        }
        rightItems={
          <React.Fragment>
            <GlobalHeader.Item label="Contact" href="/contact">
              <GlobalHeader.Column>
                <GlobalHeader.NavList>
                  <GlobalHeader.NavItem title="Contact us" href="#" />
                  <GlobalHeader.NavItem
                    title="Locate your account manager"
                    href="#"
                  />
                  <GlobalHeader.NavItem title="Support" href="#" />
                  <GlobalHeader.NavItem title="Locations" href="#" />
                </GlobalHeader.NavList>
              </GlobalHeader.Column>
            </GlobalHeader.Item>
            <GlobalHeader.Login
              accountLabel="Account"
              accountOverviewLabel="Account overview"
              helloLabel="Hello"
              id="login"
              loginLabel="Login"
              logoutLabel="Logout"
              loginManager={loginManager}
            />
          </React.Fragment>
        }
      />
    </MarkupContainer>
  );
};

export default SampleGlobalHeader;
