import Cookies from "js-cookie";
import React from "react";
import ReactDOMServer from "react-dom/server";
import fetchMock from "fetch-mock";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { withKnobs, boolean, date, text } from "@storybook/addon-knobs";
import PropTypes from "prop-types";
import Button from "@emcm-ui/component-button";
import Figure from "@emcm-ui/component-figure";
import Form, { formRehydrator } from "@emcm-ui/component-form";
import Image from "@emcm-ui/component-image";
import Modal from "@emcm-ui/component-modal";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import getAnalytics from "@emcm-ui/utility-analytics";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";
import VideoPlayer from "./index";
import "./index.css";

import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const analytics = getAnalytics("storybook", action);

const stories = storiesOf("Components/VideoPlayer", module);

stories.addDecorator(withKnobs);

const httpDelay = 500;
const ewpFormCookieName = "tr_ewp_submitted_forms";
const gatedFormContentLocation = "./gatedFormContentLocation.html";
const formSubmitLocation = "./formSubmitLocation";
const gatedFormId = "MY-FORM-ID";

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

const rehydrators = { [getRehydratableName(Form.displayName)]: formRehydrator };

const gateVideoMockFetches = () => {
  fetchMock.restore();

  fetchMock.get(gatedFormContentLocation, async () => {
    await delay(httpDelay);

    return ReactDOMServer.renderToStaticMarkup(
      <Form action={formSubmitLocation}>
        <p>Submit this form to set the cookie.</p>
      </Form>
    );
  });

  fetchMock.post(formSubmitLocation, async () => {
    await delay(httpDelay);

    const formCookie = Cookies.getJSON(ewpFormCookieName) || { forms: [] };

    if (!formCookie.forms.includes(gatedFormId)) {
      formCookie.forms.push(gatedFormId);
    }
    Cookies.set(ewpFormCookieName, formCookie, { secure: true });

    return JSON.stringify({
      action: "inlineMessage",
      message: "The form was submitted successfully."
    });
  });
};

const unsetCookie = () => {
  Cookies.remove(ewpFormCookieName);
};

const images = [
  "placeholder-images/960x540.png 960w",
  "placeholder-images/1920x1080.png 1920w",
  "placeholder-images/3840x2160.png 3840w",
  "/video-loop/test-video.mp4 960w"
];

class ModalStory extends React.Component {
  state = {
    isOpen: false
  };

  static propTypes = {
    autoplayInModal: PropTypes.bool
  };

  render() {
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        <Button
          color="secondary"
          kind="button"
          icon={<SVGIcon name="playtriangle" size="s" />}
          onClick={() => this.setState({ isOpen: !isOpen })}
        >
          Play video
        </Button>
        {isOpen ? (
          <Modal
            ariaLabel="Full screen video player"
            closeThisComponent={() => this.setState({ isOpen: !isOpen })}
          >
            <VideoPlayer
              autoplayInModal={this.props.autoplayInModal}
              analytics={analytics}
              duration={text("Duration", "PT1M35S")}
              kind="vimeo"
              playLabel="play video"
              videoId={text("Video ID", "114765613")}
            >
              <Image
                alt="Placeholder image"
                fit="cover"
                src="placeholder-images/1920x1080.png"
                srcSet={images.join(", ")}
              />
            </VideoPlayer>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

stories
  .add("Vidyard", () => (
    <VideoPlayer
      analytics={analytics}
      duration={text("Duration", "PT2M36S")}
      kind="vidyard"
      playLabel="play video"
      videoId={text("Video ID", "xGpaFxx3p6YH8wysQsZPPj")}
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
        srcSet={images.join(", ")}
      />
    </VideoPlayer>
  ))
  .add("DAM", () => (
    <VideoPlayer
      analytics={analytics}
      duration={text("Duration", "PT5S")}
      kind="dam"
      playLabel="play video"
      videoId={text("Video ID", "/video-loop/test-video.mp4")}
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="/video-loop/test-image.jpg"
      />
    </VideoPlayer>
  ))
  .add("Vimeo", () => (
    <VideoPlayer
      analytics={analytics}
      duration={text("Duration", "PT1M35S")}
      kind="vimeo"
      playLabel="play video"
      videoId={text("Video ID", "114765613")}
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
        srcSet={images.join(", ")}
      />
    </VideoPlayer>
  ))
  .add("YouTube", () => (
    <VideoPlayer
      analytics={analytics}
      duration={text("Duration", "PT30S")}
      kind="youtube"
      playLabel="play video"
      videoId={text("Video ID", "JcaRpzbWxi8")}
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
        srcSet={images.join(", ")}
      />
    </VideoPlayer>
  ))
  .add("Kaltura", () => (
    <VideoPlayer
      analytics={analytics}
      duration={text("Duration", "PT1M56S")}
      kind="kaltura"
      kaltura={`{
        "style": {"width":"100%","height":"500px"},
        "script":"https://cdnapisec.kaltura.com/p/2591911/sp/259191100/embedIframeJs/uiconf_id/45593341/partner_id/2591911",
        "targetId":"playerId",
        "wid":"_2591911",
        "uiconf_id":45593341,
        "flashvars":{
          "autoPlay":true,
          "ks":"djJ8MjU5MTkxMXxh5TE2NDgYVd4_DWjbvUXboKikYHNMsebTpftutLkGbm9TBegMDHgWNVT7siY2rs5GAz5VaiFZwtCzAgaOwMey-vnLh572FUymjH3-UDhADQW2F6avSxmIBryyiVkfeTTezgTabJVuG76e71S1X4ZHw2razDHNST_QfMMnQs91tg==",
          "closedCaptions":{
            "defaultLanguageKey":"en"
          }
        },
        "cache_st":1588770095,
        "entry_id":"0_0b2a5dj5"
      }`}
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
        srcSet={images.join(", ")}
      />
    </VideoPlayer>
  ))
  .add("QQ", () => (
    <VideoPlayer
      duration={text("Duration", "PT2M27S")}
      kind="qq"
      playLabel="play video"
      videoId={text("Video ID", "v01696pgoik")}
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
        srcSet={images.join(", ")}
      />
    </VideoPlayer>
  ))
  .add("Modal", () => (
    <ModalStory autoplayInModal={boolean("Autoplay in modal", true)} />
  ))
  .add("With schema.org", () => (
    <VideoPlayer
      analytics={analytics}
      description={text("Description", "Eikon overview (1:36)")}
      duration={text("Duration", "PT1M36S")}
      kind="vimeo"
      name={text("Name", "Eikon Promo 2015 -  V7 - Long Edit")}
      playLabel="play video"
      thumbnailUrl={text(
        "Thumbnail URL",
        "https://www.example.com/placeholder-images/960x540.png"
      )}
      uploadDate={date("Upload date", new Date()).toString()}
      videoId={text("Video ID", "114765613")}
      transcript="video captions string"
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
        srcSet={images.join(", ")}
      />
    </VideoPlayer>
  ))
  .add("With figure", () => (
    <Figure
      caption={
        <span>
          <strong>Hello:</strong> I am a caption for an video. I can also{" "}
          <a href="https://www.example.com/">link off to a separate page</a>.
        </span>
      }
    >
      <VideoPlayer
        analytics={analytics}
        duration={text("Duration", "PT1M36S")}
        description={text("Description", "Eikon overview (1:36)")}
        kind="youtube"
        playLabel="play video"
        videoId={text("Video ID", "JcaRpzbWxi8")}
      >
        <Image
          alt="Placeholder image"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
          fit="cover"
        />
      </VideoPlayer>
    </Figure>
  ))
  .add("Multiple video players", () => (
    <div>
      <VideoPlayer
        analytics={analytics}
        duration="PT30S"
        kind="youtube"
        playLabel="play video"
        videoId="JcaRpzbWxi8"
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
      <VideoPlayer
        analytics={analytics}
        duration="PT1M35S"
        kind="vimeo"
        playLabel="play video"
        videoId="114765613"
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
      <VideoPlayer
        duration="PT2M27S"
        kind="qq"
        playLabel="play video"
        videoId="v01696pgoik"
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="test-images/original/large_2.jpg"
        />
      </VideoPlayer>
    </div>
  ))
  .add("Gated content video player", () => {
    gateVideoMockFetches();

    return (
      <Section>
        <VerticalSpacing size="xl">
          <RichText>
            <h2>Gated Video Content Sandbox</h2>
            <p>
              Here you are able to explore the gated functionality of the Video
              component:
            </p>
            <ul>
              <li>
                When a cookie is set for this particular form, the video will
                play straight away on click.
              </li>
              <li>
                When no cookie is present, a modal containing a form will
                appear.
              </li>
              <li>
                After successfully submitting the form, the video will play
                automatically.
              </li>
            </ul>
          </RichText>
        </VerticalSpacing>
        <VerticalSpacing size="xl">
          <Button color="secondary" kind="button" onClick={unsetCookie}>
            Un-set cookie
          </Button>
        </VerticalSpacing>

        <VideoPlayer
          analytics={analytics}
          duration="PT30S"
          kind="youtube"
          playLabel="play video"
          videoId="JcaRpzbWxi8"
          gatedFormContentLocation={gatedFormContentLocation}
          gatedFormId={gatedFormId}
          reactFromMarkupOptions={{ extra: {} }}
          reactFromMarkupRehydrators={rehydrators}
        >
          <Image
            alt="Placeholder image"
            fit="cover"
            src="placeholder-images/1920x1080.png"
            srcSet={images.join(", ")}
          />
        </VideoPlayer>
      </Section>
    );
  })
  .add("Vidyard - Multiple", () => (
    <div>
      <p>
        The Vidyard API docs suggest that the API script should only be loaded
        after all the player iframes are present on the page. This story
        demonstrates that this is not the case: you can press play on the first
        video, then play the second one, and still receive analytics events
        through the API.
      </p>
      <VideoPlayer
        analytics={analytics}
        duration={text("Duration", "PT2M36S")}
        kind="vidyard"
        playLabel="play video"
        videoId={text("Video ID", "xGpaFxx3p6YH8wysQsZPPj")}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
      <VideoPlayer
        analytics={analytics}
        duration={text("Duration", "PT3M22S")}
        kind="vidyard"
        playLabel="play video"
        videoId={text("Video 2 ID", "6hcM6qg2LLiKgEJBsq6yoA")}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
    </div>
  ))
  .add("Gated content video player - Multiple", () => {
    gateVideoMockFetches();

    return (
      <Section>
        <VerticalSpacing size="xl">
          <Button color="secondary" kind="button" onClick={unsetCookie}>
            Un-set cookie
          </Button>
        </VerticalSpacing>
        <VerticalSpacing size="xl">
          <VideoPlayer
            duration="PT30S"
            kind="youtube"
            playLabel="play video"
            videoId="JcaRpzbWxi8"
            gatedFormContentLocation={gatedFormContentLocation}
            gatedFormId={gatedFormId}
            reactFromMarkupOptions={{ extra: {} }}
            reactFromMarkupRehydrators={rehydrators}
          >
            <Image
              alt="Placeholder image"
              fit="cover"
              src="placeholder-images/1920x1080.png"
              srcSet={images.join(", ")}
            />
          </VideoPlayer>
        </VerticalSpacing>
        <VideoPlayer
          duration="PT30S"
          kind="youtube"
          playLabel="play video"
          videoId="JcaRpzbWxi8"
          gatedFormContentLocation={gatedFormContentLocation}
          gatedFormId={gatedFormId}
          reactFromMarkupOptions={{ extra: {} }}
          reactFromMarkupRehydrators={rehydrators}
        >
          <Image
            alt="Placeholder image"
            fit="cover"
            src="placeholder-images/1920x1080.png"
            srcSet={images.join(", ")}
          />
        </VideoPlayer>
      </Section>
    );
  })
  .add("Vidyard - Rehydrated", () => (
    <StaticRenderer>
      <VideoPlayer
        duration={text("Duration", "PT2M36S")}
        kind="vidyard"
        playLabel="play video"
        videoId={text("Video ID", "xGpaFxx3p6YH8wysQsZPPj")}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
    </StaticRenderer>
  ))
  .add("Vimeo - Rehydrated", () => (
    <StaticRenderer>
      <VideoPlayer
        duration={text("Duration", "PT1M35S")}
        kind="vimeo"
        playLabel="play video"
        videoId={text("Video ID", "114765613")}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
    </StaticRenderer>
  ))
  .add("YouTube - Rehydrated", () => (
    <StaticRenderer>
      <VideoPlayer
        duration={text("Duration", "PT30S")}
        kind="youtube"
        playLabel="play video"
        videoId={text("Video ID", "JcaRpzbWxi8")}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
    </StaticRenderer>
  ))
  .add("Kaltura - Rehydrated", () => (
    <StaticRenderer>
      <VideoPlayer
        kind="kaltura"
        duration={text("Duration", "PT1M56S")}
        kaltura={`{
          "style": {"width":"100%","height":"500px"},
          "script":"https://cdnapisec.kaltura.com/p/2591911/sp/259191100/embedIframeJs/uiconf_id/45593341/partner_id/2591911",
          "targetId":"playerId",
          "wid":"_2591911",
          "uiconf_id":45593341,
          "flashvars":{
            "autoPlay":true,
            "ks":"djJ8MjU5MTkxMXxh5TE2NDgYVd4_DWjbvUXboKikYHNMsebTpftutLkGbm9TBegMDHgWNVT7siY2rs5GAz5VaiFZwtCzAgaOwMey-vnLh572FUymjH3-UDhADQW2F6avSxmIBryyiVkfeTTezgTabJVuG76e71S1X4ZHw2razDHNST_QfMMnQs91tg==",
            "closedCaptions":{
              "defaultLanguageKey":"en"
            }
          },
          "cache_st":1588770095,
          "entry_id":"0_0b2a5dj5"
        }`}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
    </StaticRenderer>
  ))
  .add("QQ - Rehydrated", () => (
    <StaticRenderer>
      <VideoPlayer
        duration={text("Duration", "PT2M27S")}
        kind="qq"
        playLabel="play video"
        videoId={text("Video ID", "v01696pgoik")}
      >
        <Image
          alt="Placeholder image"
          fit="cover"
          src="placeholder-images/1920x1080.png"
          srcSet={images.join(", ")}
        />
      </VideoPlayer>
    </StaticRenderer>
  ))
  .add("Gated content video player - Rehydrated", () => {
    gateVideoMockFetches();

    return (
      <Section>
        <VerticalSpacing size="xl">
          <Button color="secondary" kind="button" onClick={unsetCookie}>
            Un-set cookie
          </Button>
        </VerticalSpacing>
        <StaticRenderer>
          <VideoPlayer
            duration="PT30S"
            kind="youtube"
            playLabel="play video"
            videoId="JcaRpzbWxi8"
            gatedFormContentLocation={gatedFormContentLocation}
            gatedFormId={gatedFormId}
            reactFromMarkupOptions={{ extra: {} }}
            reactFromMarkupRehydrators={rehydrators}
          >
            <Image
              alt="Placeholder image"
              fit="cover"
              src="placeholder-images/1920x1080.png"
              srcSet={images.join(", ")}
            />
          </VideoPlayer>
        </StaticRenderer>
      </Section>
    );
  });
