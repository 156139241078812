import React from "react";
import Playlist from "./Playlist";

// eslint-disable-next-line require-await
export default async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };
  const props = {
    lineItems: readData("line-items"),
    url: domNode.getAttribute("data-url"),
    activeItemId: domNode.getAttribute("data-active-item-id"),
    label: {
      playlist: domNode.getAttribute("data-playlist-label"),
      playing: domNode.getAttribute("data-playing-label"),
      paused: domNode.getAttribute("data-paused-label"),
      viewed: domNode.getAttribute("data-viewed-label")
    },
    states: readData("states")
  };
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const children = await rehydrateChildren(childrenNode);

  return <Playlist {...props}>{children}</Playlist>;
};
