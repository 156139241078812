import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Loader from "./index";
import "./Loader.css";
import Alignment from "@emcm-ui/component-alignment";
import LoaderREADME from "../README.md";

const stories = storiesOf("Components/Loader", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(LoaderREADME));

const styles = {
  width: "100%",
  height: "500px"
};

stories.add("Default", () => {
  return (
    <div style={styles}>
      <Alignment horizontal="center" vertical="center">
        <Loader label={text("label", "Loading")} />
      </Alignment>
    </div>
  );
});
