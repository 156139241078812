import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import SchemaOrganization, {
  SchemaSameAsOrganization,
  SchemaParentOrganization
} from "./index";
import SchemaOrganizationREADME from "../README.md";

const stories = storiesOf("Components/SchemaOrganization", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SchemaOrganizationREADME));

stories
  .add("Default", () => (
    <SchemaOrganization
      name={text("Name", "Thomson Reuters Legal")}
      url={text(
        "URL",
        "https://thomsonreuters.com/en/products-services/legal.html"
      )}
      logo={text("Logo", "tr_logo.jpg")}
    />
  ))
  .add("With SchemaSameAsOrganization", () => (
    <SchemaOrganization
      name={text("Name", "Thomson Reuters Legal")}
      url={text(
        "URL",
        "https://thomsonreuters.com/en/products-services/legal.html"
      )}
      logo={text("Logo", "tr_logo.jpg")}
    >
      <SchemaSameAsOrganization
        url={text("Same As URL", "https://facebook.com/thomsonreuters")}
      />
    </SchemaOrganization>
  ))
  .add("With SchemaParentOrganization", () => (
    <SchemaOrganization
      name={text("Name", "Thomson Reuters Legal")}
      url={text(
        "URL",
        "https://thomsonreuters.com/en/products-services/legal.html"
      )}
      logo={text("Logo", "tr_logo.jpg")}
    >
      <SchemaParentOrganization
        name={text("Parent Name", "Thomson Reuters Corporation")}
        url={text("Parent URL", "https://www.thomsonreuters.com/")}
      />
    </SchemaOrganization>
  ));
