import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const validRoles = ["application", "document", "main"];

const SemanticArticle = ({ children, role, schema }) => {
  const getClassName = getClassNameFactory(SemanticArticle.displayName);

  return (
    <article className={getClassName()} role={role}>
      {typeof schema === "undefined" ? null : (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
      {children}
    </article>
  );
};

SemanticArticle.displayName = "SemanticArticle";

/* eslint-disable max-len */
SemanticArticle.propTypes = {
  /**
   * Child nodes passed to the component
   */
  children: PropTypes.node,
  /**
   * The ARIA role for this article. See https://developer.mozilla.org/en/docs/Web/HTML/Element/article.
   */
  role: PropTypes.oneOf(validRoles),
  /**
   * The schema object for this component. Should conform to https://schema.org/Article.
   *
   * Documentation provided by Global Strategies. More details can be found here: https://thehub.thomsonreuters.com/docs/DOC-2312334
   */
  schema: PropTypes.shape({
    /**
     * Schema.org base URL.  This value should always be "https://schema.org".
     */
    "@context": PropTypes.oneOf(["https://schema.org"]).isRequired,
    /**
     * The type of Schema.org model to validate against.
     */
    "@type": PropTypes.oneOf(["Article", "NewsArticle", "BlogPosting"])
      .isRequired,
    /**
     * The author of the article.
     *
     * For content that has no author, this should be specified as an `"Organization"` type, with the name set to `"LSEG"`.
     */
    author: PropTypes.shape({
      /**
       * The type of Schema.org model to validate against.
       */
      "@type": PropTypes.oneOf(["Organization", "Person"]).isRequired,
      /**
       * The name of the author.
       */
      name: PropTypes.string.isRequired
    }).isRequired,
    /**
     * The date and time the article was most recently modified, in ISO 8601 format.
     */
    dateModified: PropTypes.string,
    /**
     * The date and time the article was first published, in ISO 8601 format.
     */
    datePublished: PropTypes.string.isRequired,
    /**
     * A short description of the article.
     */
    description: PropTypes.description,
    /**
     * Title of the blog post / article.
     */
    headline: PropTypes.string.isRequired,
    /**
     * The representative image of the article. Only a marked-up image that directly belongs to the article should be specified.
     *
     * - Images should be at least 696 pixels wide.
     * - Images should be in .jpg, .png, or. gif format.
     * - Image URLs should be crawlable and indexable.
     */
    image: PropTypes.shape({
      /**
       * The type of Schema.org model to validate against.
       */
      "@type": PropTypes.oneOf(["ImageObject"]).isRequired,
      /**
       * The height of the image, in pixels.
       */
      height: PropTypes.number,
      /**
       * The URL of the logo.
       */
      url: PropTypes.string.isRequired,
      /**
       * The width of the logo, in pixels.
       */
      width: PropTypes.number
    }),
    /**
     * Specify the canonical URL of the current page. This is only needed on AMP HTML pages and is ignored on non-AMP pages.
     */
    mainEntityOfPage: PropTypes.shape({
      /**
       * The type of Schema.org model to validate against.
       */
      "@type": PropTypes.oneOf(["WebPage"]).isRequired,
      /**
       * The canonical URL of the article.
       */
      "@id": PropTypes.string.isRequired
    }),
    /**
     * The publisher of the article.
     */
    publisher: PropTypes.shape({
      /**
       * The type of Schema.org model to validate against.
       */
      "@type": PropTypes.oneOf(["Organization", "Person"]).isRequired,
      /**
       * The name of the publisher.
       */
      name: PropTypes.string.isRequired,
      /**
       * The logo of the publisher. The logo should be a rectangle, not a square.
       *
       * The logo should fit in a 60x600px rectangle., and either be exactly 60px high (preferred), or exactly 600px wide. For example, 450x45px would not be acceptable, even though it fits in the 600x60px rectangle.
       */
      logo: PropTypes.shape({
        /**
         * The type of Schema.org model to validate against.
         */
        "@type": PropTypes.oneOf(["ImageObject"]).isRequired,
        /**
         * The height of the logo, in pixels.
         */
        height: PropTypes.number.isRequired,
        /**
         * The URL of the logo.
         */
        url: PropTypes.string.isRequired,
        /**
         * The width of the logo, in pixels.
         */
        width: PropTypes.number.isRequired
      })
    })
  })
};
/* eslint-enable max-len */

export default SemanticArticle;
export { validRoles };
