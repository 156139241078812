import React from "react";
import Modal from "@emcm-ui/component-modal";
import Button from "@emcm-ui/component-button";
import Section from "@emcm-ui/component-section";

export default class ModalDemo extends React.Component {
  state = {
    showModal: false
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  render() {
    return (
      <div style={{ paddingTop: "24px" }}>
        <Button onClick={this.toggleModal} color="primary" kind="submit">
          Click to show a plain Modal
        </Button>

        {this.state.showModal && (
          <Modal
            ariaLabel="Demo modal"
            closeLabel="Close"
            closeThisComponent={this.toggleModal}
          >
            <Section>
              <div style={{ textAlign: "center" }}>
                A plain Modal, without content
              </div>
            </Section>
          </Modal>
        )}
      </div>
    );
  }
}
