import React from "react";
import propTypes from "prop-types";
import SampleDismissibleBanner from "./SampleDismissibleBanner";
import SampleGlobalHeader from "./SampleGlobalHeader";
import SampleSiteHeader from "./SampleSiteHeader";

const SampleHeader = ({ globalHeader, siteName }) => (
  <header>
    <SampleDismissibleBanner />
    {globalHeader ? globalHeader : <SampleGlobalHeader />}
    <SampleSiteHeader siteName={siteName} />
  </header>
);

SampleHeader.propTypes = {
  globalHeader: propTypes.node,
  siteName: propTypes.string
};

export default SampleHeader;
