import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Grid from "@emcm-ui/component-grid";
import Link from "@emcm-ui/component-link";
import Standfirst from "@emcm-ui/component-standfirst";
import classNames from "classnames";

import Profile from "./components/Profile";

const ProfileGallery = ({
  children,
  introduction,
  linkHref,
  linkTitle,
  linkIcon,
  verticalAtDesktop
}) => {
  const getClassName = getClassNameFactory(ProfileGallery.displayName);

  const renderProfiles = () => {
    return (
      <div className={getClassName({ descendantName: "profiles" })}>
        <ul className={getClassName({ descendantName: "profilesInner" })}>
          {children}
        </ul>
      </div>
    );
  };

  return (
    <div
      className={getClassName({
        modifiers: classNames(
          verticalAtDesktop ? "verticalAtDesktop" : "",
          introduction && "hasIntro"
        )
      })}
    >
      {introduction ? (
        <Grid variant="1/3,2/3">
          <Grid.Item>
            <div className={getClassName({ descendantName: "intro" })}>
              <div className={getClassName({ descendantName: "standfirst" })}>
                <Standfirst>{introduction}</Standfirst>
              </div>

              <div className={getClassName({ descendantName: "link" })}>
                <Link
                  href={linkHref}
                  type="icon"
                  icon={linkIcon}
                  iconPosition={"right"}
                >
                  {linkTitle}
                </Link>
              </div>
            </div>
          </Grid.Item>

          <Grid.Item>{renderProfiles()}</Grid.Item>
        </Grid>
      ) : (
        renderProfiles()
      )}
    </div>
  );
};

ProfileGallery.displayName = "ProfileGallery";

ProfileGallery.propTypes = {
  /**
   * Child nodes, consisting of ProfileGallery.Profile components.
   */
  children: PropTypes.node,

  /**
   * Introductory text to be shown on the left side. If not given, the profile section takes the entire space.
   */
  introduction: PropTypes.node,

  /**
   * URL for the link
   */
  linkHref: PropTypes.string,

  /**
   * Title for the link to be shown on the left side.
   */
  linkTitle: PropTypes.string,

  /**
   * Icon for the link
   */
  linkIcon: PropTypes.node,

  /**
   * Show the profiles in a vertical mode at desktop, with the text appearing beneath the image.
   */
  verticalAtDesktop: PropTypes.bool
};

ProfileGallery.defaultProps = {
  verticalAtDesktop: true
};

ProfileGallery.Profile = Profile;

export default ProfileGallery;
