import React from "react";
import HorizontalOverflow from "./HorizontalOverflow";

const rehydrator = async (domNode, rehydrateChildren) => {
  const props = {
    background: domNode.getAttribute("data-background") || null,
    isRehydrating: true
  };
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  return (
    <HorizontalOverflow {...props}>{reactifiedChildren}</HorizontalOverflow>
  );
};

export default rehydrator;
