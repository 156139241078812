import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

import BreadcrumbItem from "./components/BreadcrumbItem";

const Breadcrumb = ({ children }) => {
  const getClassName = getClassNameFactory(Breadcrumb.displayName);
  const jsonLdItems = [];

  React.Children.forEach(children, (child, i) => {
    if (child.type === BreadcrumbItem) {
      const position = i + 1;

      jsonLdItems.push({
        "@type": "ListItem",
        position,
        item: {
          "@id": child.props.href,
          name: child.props.text
        }
      });
    }
  });

  return (
    <nav aria-label="Breadcrumb" className={getClassName()}>
      <ol className={getClassName({ descendantName: "items" })}>{children}</ol>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: jsonLdItems
        })}
      </script>
    </nav>
  );
};

Breadcrumb.displayName = "Breadcrumb";

Breadcrumb.propTypes = {
  /**
   * Contents of this Breadcrumb. Should be `BreadcrumbItem`s.
   */
  children: PropTypes.node
};

Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;
