import { dedupeParams } from "./dedupeParams";

export default class CampaignTrackingParameterService {
  constructor(tracked) {
    this.params = {};
    this.tracked = tracked;
  }

  add(params) {
    const keys = Object.keys(params);

    for (const key of keys) {
      if (this.tracked.includes(key)) {
        this.params[key] = Array.isArray(params[key])
          ? dedupeParams(params[key])
          : params[key];
      }
    }
  }

  get(paramName) {
    return this.params[paramName] || null;
  }

  getAll() {
    return {
      ...this.params
    };
  }
}
