import React from "react";
import VideoPlayerCoreWithContext from "./VideoPlayerCore";

const rehydrator = async (domNode, rehydrateChildren, extra) => {
  const props = {
    autoplayInModal:
      domNode.getAttribute("data-autoplay-in-modal") === "true" || false,
    duration: domNode.getAttribute("data-duration"),
    gatedCookieName: domNode.getAttribute("data-gated-cookie-name"),
    gatedFormContentLocation: domNode.getAttribute(
      "data-gated-form-content-location"
    ),
    gatedFormId: domNode.getAttribute("data-gated-form-id"),
    videoId: domNode.getAttribute("data-video-id"),
    kind: domNode.getAttribute("data-kind"),
    playLabel: domNode.getAttribute("data-play-label"),
    analytics: extra.analytics,
    reactFromMarkupRehydrators: extra.rehydrators,
    reactFromMarkupOptions: { extra },
    isRehydrating: true,
    kaltura: domNode.getAttribute("data-kaltura")
  };
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  return (
    <VideoPlayerCoreWithContext {...props}>
      {reactifiedChildren}
    </VideoPlayerCoreWithContext>
  );
};

export default rehydrator;
