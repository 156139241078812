import ConsoleAnalytics from "./ConsoleAnalytics";
import GtmAnalytics from "./GtmAnalytics";
import StorybookAnalytics from "./StorybookAnalytics";
import NullAnalytics from "./NullAnalytics";

const getAnalytics = (engineName, ...extra) => {
  let engine = null;

  switch (engineName) {
    case "storybook":
      engine = new StorybookAnalytics(...extra);
      break;

    case "gtm":
      engine = new GtmAnalytics(...extra);
      break;

    case "console":
      engine = new ConsoleAnalytics(...extra);
      break;

    case "null":
      engine = new NullAnalytics(...extra);
      break;

    default:
      throw new Error(`Unsupported analytics engine type: ${engineName}`);
  }

  // Enforcing an interface of sorts.
  return (category, action, label) => {
    engine.sendEvent(category, action, label);
  };
};

export default getAnalytics;
