import PropTypes from "prop-types";
import React from "react";
import Heading from "@emcm-ui/component-heading";

const Typography = ({ boldText, text, width }) => {
  const double = 2;

  return (
    <div className="story-themeSection">
      <h1 className="story-themeHeading">Typography</h1>

      <h2 className="story-themeSubHeading">Drop cap</h2>

      <div
        className="story-typographyDropCap story-typographySpecimen"
        style={{ width: `${width * double}ch` }}
      >
        {text} {boldText}. {text} {boldText}. {text} {boldText}. {text}{" "}
        {boldText}. {text} {boldText}. {text} {boldText}.
      </div>

      <h2 className="story-themeSubHeading">Highlight</h2>

      <div
        className="story-typographyHighlight story-typographySpecimen"
        style={{ width: `${width}ch` }}
      >
        {text} {boldText}
      </div>

      <h2 className="story-themeSubHeading">Heading XL</h2>

      <div className="story-typographySpecimen">
        <Heading type="xl">
          <div style={{ width: `${width}ch` }}>
            {text} <strong>{boldText}</strong>
          </div>
        </Heading>
      </div>

      <h2 className="story-themeSubHeading">Heading L</h2>

      <div className="story-typographySpecimen">
        <Heading type="l">
          <div style={{ width: `${width}ch` }}>
            {text} <strong>{boldText}</strong>
          </div>
        </Heading>
      </div>

      <h2 className="story-themeSubHeading">Blockquote L</h2>

      <div
        className="story-typographyBlockquoteL story-typographySpecimen"
        style={{ width: `${width}ch` }}
      >
        {text} {boldText}
      </div>

      <h2 className="story-themeSubHeading">Heading M</h2>

      <div className="story-typographySpecimen">
        <Heading type="m">
          <div style={{ width: `${width}ch` }}>
            {text} <strong>{boldText}</strong>
          </div>
        </Heading>
      </div>

      <h2 className="story-themeSubHeading">Heading S</h2>

      <div className="story-typographySpecimen">
        <Heading type="s">
          <div style={{ width: `${width}ch` }}>
            {text} <strong>{boldText}</strong>
          </div>
        </Heading>
      </div>

      <h2 className="story-themeSubHeading">Heading XS</h2>

      <div className="story-typographySpecimen">
        <Heading type="xs">
          <div style={{ width: `${width}ch` }}>
            {text} <strong>{boldText}</strong>
          </div>
        </Heading>
      </div>

      <h2 className="story-themeSubHeading">Blockquote S</h2>

      <div
        className="story-typographyBlockquoteS story-typographySpecimen"
        style={{ width: `${width}ch` }}
      >
        {text} {boldText}
      </div>

      <h2 className="story-themeSubHeading">Paragraph M</h2>

      <div
        className="story-typographyParagraphM story-typographySpecimen"
        style={{ width: `${width}ch` }}
      >
        {text} <strong>{boldText}</strong>
      </div>

      <h2 className="story-themeSubHeading">Paragraph S</h2>

      <div
        className="story-typographyParagraphS story-typographySpecimen"
        style={{ width: `${width}ch` }}
      >
        The slow black dog bows before the <strong>{boldText}</strong>
      </div>

      <h2 className="story-themeSubHeading">Paragraph XS</h2>

      <div
        className="story-typographyParagraphXS story-typographySpecimen"
        style={{ width: `${width}ch` }}
      >
        {text} <strong>{boldText}</strong>
      </div>

      <h2 className="story-themeSubHeading">Small caps</h2>

      <div
        className="u-typographySmallCaps story-typographySpecimen"
        style={{ width: `${width * double}ch` }}
      >
        {text} {boldText}
      </div>

      <h2 className="story-themeSubHeading">Inline</h2>
      <p>
        <strong>Strong</strong> <em>emphasis</em>{" "}
        <a href="https://mnspcd.com">link</a>
      </p>
    </div>
  );
};

Typography.propTypes = {
  boldText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired
};

export default Typography;
