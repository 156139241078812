import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Section = ({ children }) => {
  const getClassName = getClassNameFactory("SearchFiltersSection");

  return <div className={getClassName()}>{children}</div>;
};

Section.displayName = "SearchFilters.Section";

Section.propTypes = {
  /**
   * The children of this section.
   */
  children: PropTypes.node
};

export default Section;
