import React from "react";
import DateTime from "./DateTime";

const rehydrator = async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    dateTime: readData("date-time"),
    format: readData("format"),
    showTime: readData("show-time")
  };

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  return <DateTime {...props}>{reactifiedChildren}</DateTime>;
};

export default rehydrator;
