import React from "react";
import Heading from "@emcm-ui/component-heading";
import Grid from "@emcm-ui/component-grid";
import MarkupContainer from "@emcm-ui/component-markup-container";
import Poster from "@emcm-ui/component-poster";
import SearchForm from "@emcm-ui/component-search-form";
import SearchResults from "@emcm-ui/component-search-results";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

import articleLadyJustice from "../../sampleAssets/Article-lady-justice.jpg";
import articleLadyJusticeMobile from "../../sampleAssets/Article-lady-justice.mob.jpg";
import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const SearchNoResults = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />

      <SemanticMain>
        <Section background="grayDark" fullWidth>
          <Poster
            height="s"
            imageOverlay="scrimBottomDark"
            imageSrc={articleLadyJustice}
            imageSrcSet={`${articleLadyJusticeMobile} 768w, ${articleLadyJustice} 2048w`}
          >
            <Grid variant="7/12,5/12" gutterlessAtNarrow>
              <Grid.Item>
                <VerticalSpacing>
                  <Heading type="xl" rank="1">
                    <strong>Products & services</strong>
                  </Heading>
                </VerticalSpacing>
                <p>
                  We offer comprehensive expert research, fully developed
                  software suites, standalone products, web-based platforms and
                  continuing education.
                </p>
              </Grid.Item>
            </Grid>
          </Poster>
        </Section>
        <Section background="grayLightest">
          <VerticalSpacing size="xl">
            <MarkupContainer>
              <SearchForm
                action="https://www.example.com"
                method="get"
                suggestion={{
                  text: "new term",
                  href: "https://example.com"
                }}
                searchInput={{
                  typeaheadUrl:
                    "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065",
                  value: "new tern"
                }}
                typeaheadUrl="//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
              />
            </MarkupContainer>
          </VerticalSpacing>
          <SearchResults title="We did not find any matches for 'new tern'">
            <SearchResults.NoResults />
          </SearchResults>
        </Section>
      </SemanticMain>

      <SampleFooter />
    </div>
  );
};

export default SearchNoResults;
