import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import VideoCardFooter from "./index";
import "./VideoCardFooter.css";
import Icon from "@emcm-ui/component-icon";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Footers/VideoCardFooter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

stories
  .add("Default", () => (
    <VideoCardFooter
      runningTime={text("runningTime", "25 min")}
      icon={<Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />}
    />
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <VideoCardFooter
        runningTime={text("runningTime", "25 دقيقة")}
        icon={<Icon path="./icons/ui/grey/playoutline-07.svg" size="xs" />}
      />
    </Language>
  ));
