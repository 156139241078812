import React from "react";

const styles = {
  margin: 0
};

const langs = {
  default: "en-US"
};

const styleDecorator = story => (
  <div style={styles} lang={langs.default}>
    {story()}
  </div>
);

export default styleDecorator;
