import getClassNameFactory from "@emcm-ui/utility-class-names";
import PropTypes from "prop-types";
import React from "react";
import TitleTextAssetContentItem from "./content/index";
import classNames from "classnames";

const TitleTextAsset = ({
  children,
  variant,
  assetSize,
  layouts,
  heading,
  subHeading,
  media,
  smallCaps
}) => {
  const getClassName = getClassNameFactory(TitleTextAsset.displayName);

  const titleTextAssetClassNames = {
    [variant]: true,
    [assetSize]: true,
    [layouts]: true
  };

  return (
    <div
      className={getClassName({
        modifiers: classNames(titleTextAssetClassNames)
      })}
    >
      <div className={getClassName({ descendantName: "lead" })}>
        {smallCaps && (
          <div className={getClassName({ descendantName: "smallCaps" })}>
            {smallCaps}
          </div>
        )}
        <div className={getClassName({ descendantName: "heading" })}>
          {heading}
        </div>
        {subHeading && (
          <div className={getClassName({ descendantName: "subHeading" })}>
            {subHeading}
          </div>
        )}
      </div>
      <div className={getClassName({ descendantName: "gap" })} />
      <div className={getClassName({ descendantName: "media" })}>{media}</div>
      {children ? (
        <div className={getClassName({ descendantName: "content" })}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

TitleTextAsset.assetSize = ["Small", "Medium", "Large"];

TitleTextAsset.layouts = ["Left", "Right"];

TitleTextAsset.displayName = "TitleTextAsset";

/* eslint-disable max-len */
TitleTextAsset.propTypes = {
  /**
   * Content, typically a RichText component along with a link or a group
   */
  children: PropTypes.node.isRequired,

  /**
   * Media Component
   */
  media: PropTypes.node.isRequired,

  /**
   * Title Text Asset Variant
   */
  variant: PropTypes.oneOf(["vertical", "horizontal"]),

  /**
   * Title Text Asset assetSize
   */
  assetSize: PropTypes.oneOf(["Small", "Medium", "Large"]),

  /**
   * Title Text Asset Layouts
   */
  layouts: PropTypes.oneOf(["Left", "Right"]),

  /**
   * Title Text Asset Heading
   */
  heading: PropTypes.node,

  /**
   * Title Text Asset SubHeading
   */
  subHeading: PropTypes.node,

  /**
   * Title Text Asset SmallCaps
   */
  smallCaps: PropTypes.node
};

TitleTextAsset.defaultProps = {
  variant: "horizontal",
  assetSize: "Small",
  layouts: "Right"
};

/* eslint-enable max-len */

TitleTextAsset.ContentItem = TitleTextAssetContentItem;

export default TitleTextAsset;
