import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import LanguageSwitcher from "./index";
import "./LanguageSwitcher.css";
import LanguageSwitcherREADME from "../README.md";

const stories = storiesOf("Components/LanguageSwitcher", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(LanguageSwitcherREADME));

stories
  .add("Default", () => (
    <LanguageSwitcher heading={text("Heading", "Available in other languages")}>
      <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
      <LanguageSwitcher.Item href="#" lang="nl" title="Nederlands" />
      <LanguageSwitcher.Item href="#" lang="fr" title="Français" />
      <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
      <LanguageSwitcher.Item href="#" lang="it" title="Italiano" />
      <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
      <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
      <LanguageSwitcher.Item href="#" lang="pt" title="Português" />
      <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
      <LanguageSwitcher.Item href="#" lang="es" title="Español" />
      <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
    </LanguageSwitcher>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <LanguageSwitcher heading={text("Heading", "متوفر بلغات أخرى")}>
        <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
        <LanguageSwitcher.Item href="#" lang="nl" title="Nederlands" />
        <LanguageSwitcher.Item href="#" lang="fr" title="Français" />
        <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
        <LanguageSwitcher.Item href="#" lang="it" title="Italiano" />
        <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
        <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
        <LanguageSwitcher.Item href="#" lang="pt" title="Português" />
        <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
        <LanguageSwitcher.Item href="#" lang="es" title="Español" />
        <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
      </LanguageSwitcher>
    </Language>
  ));
