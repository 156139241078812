import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import JsonLd from "./index";
import JsonLdREADME from "../README.md";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const stories = storiesOf("Components/JsonLd", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(JsonLdREADME));

const data = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Thomsons Routers",
  url: "myRouters.com",
  logo: "myimage.jpg"
};

stories
  .add("Default", () => (
    <div>
      <div>
        We are using a `StaticRenderer` here to test if `JsonLd` renders
        properly on the server side. Use inspect element and search for
        `json-ld` to find the rendered component.
      </div>
      <JsonLd data={data} />
    </div>
  ))
  .add("Static", () => (
    <StaticRenderer>
      <div>
        We are using a `StaticRenderer` here to test if `JsonLd` renders
        properly on the server side. Use inspect element and search for
        `json-ld` to find the rendered component.
      </div>
      <JsonLd data={data} />
    </StaticRenderer>
  ));
