import React from "react";
import { storiesOf } from "@storybook/react";
import addStandardDecorators from "./addStandardDecorators";
import "@emcm-ui/component-markup-container/index.css";

import AboutUs from "./compositions/content/AboutUs";
import ArticleBlockchain from "./compositions/content/ArticleBlockchain";
import ArticleSlavery from "./compositions/content/ArticleSlavery";
import Brexit from "./compositions/content/Brexit";
import PrivacyEn from "./compositions/content/PrivacyEn";
import PrivacyRu from "./compositions/content/PrivacyRu";
import PrivacyTr from "./compositions/content/PrivacyTr";
import PrivacyZh from "./compositions/content/PrivacyZh";
import PrivacyKo from "./compositions/content/PrivacyKo";
import PrivacyJp from "./compositions/content/PrivacyJp";

const articleStories = storiesOf("Compositions/Content/Articles", module);
const pagesStories = storiesOf("Compositions/Content/Pages", module);
const privacyStories = storiesOf("Compositions/Content/Privacy Policy", module);

addStandardDecorators(articleStories);
addStandardDecorators(pagesStories);
addStandardDecorators(privacyStories);

articleStories.add("Blockchain gets real", () => <ArticleBlockchain />);
articleStories.add("Ending modern slavery", () => <ArticleSlavery />);

pagesStories.add("About Us", () => <AboutUs />);
pagesStories.add("Brexit", () => <Brexit />);

privacyStories.add("English", () => <PrivacyEn />);
privacyStories.add("Russian", () => <PrivacyRu />);
privacyStories.add("Turkish", () => <PrivacyTr />);
privacyStories.add("Chinese", () => <PrivacyZh />);
privacyStories.add("Korean", () => <PrivacyKo />);
privacyStories.add("Japanese", () => <PrivacyJp />);
