/* eslint-disable */
import React from "react";
import Icon from "./Icon";

function copyToClipboard(text) {
  window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
}

const displayHeading = heading => (
  <h2 className="story-themeSubHeading">
    <code>{heading.name}</code>
    <div className="story-themeSubHeadingNote">{heading.path}</div>
  </h2>
);

const mapIcons = colorFolder => {
  const iconList = colorFolder.children;
  const bgcolor = colorFolder.name === "white" ? "black" : "white";
  const color = colorFolder.name === "white" ? "white" : colorFolder.name;
  return iconList.map(icon => {
    return (
      <div
        onClick={() => navigator.clipboard.writeText(icon.name) || null}
        key={icon.name}
        className={icon.name}
      >
        <div className="story-iconSpecimens">
          <div
            key={icon.name}
            style={{
              background: bgcolor,
              color: color
            }}
            className={`story-iconSpecimen ${icon.name}`}
          >
            <Icon path={icon.path} size="m" />
            <code>{icon.name}</code>
          </div>
        </div>
      </div>
    );
  });
};

const UIIconList = ({ iconList }) => {
  const isLogos = iconList.name === "logos" || iconList.name === "doctype";
  return (
    <React.Fragment>
      {isLogos && mapIcons(iconList)}
      {!isLogos && (
        <div className="story-themeSection">
          {iconList.children.map(colorFolder => (
            <div key={colorFolder.name} className={colorFolder.name}>
              <div className="story-iconSpecimens">
                {displayHeading(colorFolder)}
                {mapIcons(colorFolder)}
              </div>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default UIIconList;
