import React from "react";
import SocialDropdown from "./SocialDropdown";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const getClassName = getClassNameFactory("SocialDropdown");

const rehydrator = async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const props = {
    color: readData("color")
  };

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "items" })}`
  );
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  return <SocialDropdown {...props}>{reactifiedChildren}</SocialDropdown>;
};

export default rehydrator;
