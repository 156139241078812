import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

import Icon from "@emcm-ui/component-icon";

/**
 * Util to demo components that need the full page width.
 * Basically a custom modal that overlays the whole page.
 * Has a close button and optional title bar.
 */
export default class FullPageDemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = { active: true };
  }

  static propTypes = {
    /**
     * Adds a bar at the top, to make the close button stand out
     */
    titlebar: PropTypes.bool,
    /**
     * React children
     */
    children: PropTypes.object
  };

  static defaultProps = {
    titlebar: true
  };

  toggleActive = () => {
    this.setState({ active: !this.state.active });
  };

  render() {
    const { children, titlebar } = this.props;

    return this.state.active ? (
      <StyledOverlay>
        {titlebar && <StyledTitlebar />}
        <StyledContent id="jsx-playground-output">{children}</StyledContent>
        <StyledCloseButton onClick={this.toggleActive}>
          <Icon path="./icons/ui/grey/close-07.svg" size="xs" />
        </StyledCloseButton>
      </StyledOverlay>
    ) : null;
  }
}

/**
 * Styles
 *
 * 1. The +/- position/transform is to keep content elements
 *    with position:fixed from overlapping the title bar
 */

const keyframesMoveIn = keyframes`
  from{
    transform: scale(0.5);
  }
  to{
    transform: scale(1);
  }
`;

const keyframesFadeIn = keyframes`
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

const keyframesBgFadeIn = keyframes`
  from{
    background: #D9D9D9;
  }
  to{
    background: #FFF;
  }
`;

const StyledOverlay = styled.div`
  width: 100%;
  height: 100vh;
  animation: ${keyframesFadeIn} 0.35s linear,
    ${keyframesMoveIn} 0.65s cubic-bezier(0.05, 0.9, 0, 1),
    ${keyframesBgFadeIn} 0.65s linear 0s both;
`;

const StyledTitlebar = styled.div`
  width: 100%;
  height: 32px;
  background: #f4f4f4;
`;

const StyledCloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 32px;
  padding: 8px 8px;
  cursor: pointer;
  transition: opacity 0.1s linear;
  &:hover {
    opacity: 0.75;
  }
`;

const StyledContent = styled.div`
  position: relative;
  top: -32px; /* 1 */
  transform: translateY(32px);
  animation: ${keyframesFadeIn} 0.35s linear 0.2s both;
`;
