import PropTypes from "prop-types";
import React from "react";

const JsonLd = ({ data }) => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

JsonLd.displayName = "JsonLd";

JsonLd.propTypes = {
  /**
   * Data to be innerHTMLed
   */
  data: PropTypes.object.isRequired
};

export default JsonLd;
