import React from "react";
import PageNavInner from "./PageNavInner";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const getClassName = getClassNameFactory("PageNavInner");

const rehydrator = async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "items" })}`
  );

  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const actionsNode = domNode.querySelector(
    `.${getClassName({ descendantName: "actions" })}`
  );

  const shareNode = domNode.querySelector(
    `.${getClassName({ descendantName: "shareInner" })}`
  );

  const props = {
    shareLabel: domNode.getAttribute(`data-share-label`),
    spy: readData("spy"),
    children: reactifiedChildren,
    showPrimaryActionOnlyOnSticky: readData(
      "show-primary-action-only-on-sticky"
    ),
    sticky: readData("sticky")
  };

  if (actionsNode) {
    props.actions = await rehydrateChildren(actionsNode);
  }

  if (shareNode) {
    props.share = await rehydrateChildren(shareNode);
  }

  return <PageNavInner {...props} />;
};

export default rehydrator;
