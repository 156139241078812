/* eslint-disable no-return-await */
const supportedLanguages = {
  af: async () => await import("date-fns/locale/af/index.js"),
  arSA: async () => await import("date-fns/locale/ar-SA/index.js"),
  bn: async () => await import("date-fns/locale/bn/index.js"),
  de: async () => await import("date-fns/locale/de/index.js"),
  el: async () => await import("date-fns/locale/el/index.js"),
  enCA: async () => await import("date-fns/locale/en-CA/index.js"),
  enGB: async () => await import("date-fns/locale/en-GB/index.js"),
  enUS: async () => await import("date-fns/locale/en-US/index.js"),
  eo: async () => await import("date-fns/locale/eo/index.js"),
  es: async () => await import("date-fns/locale/es/index.js"),
  et: async () => await import("date-fns/locale/et/index.js"),
  fr: async () => await import("date-fns/locale/fr/index.js"),
  gl: async () => await import("date-fns/locale/gl/index.js"),
  he: async () => await import("date-fns/locale/he/index.js"),
  hu: async () => await import("date-fns/locale/hu/index.js"),
  it: async () => await import("date-fns/locale/it/index.js"),
  ja: async () => await import("date-fns/locale/ja/index.js"),
  lt: async () => await import("date-fns/locale/lt/index.js"),
  nb: async () => await import("date-fns/locale/nb/index.js"),
  nl: async () => await import("date-fns/locale/nl/index.js"),
  pt: async () => await import("date-fns/locale/pt/index.js"),
  ptBR: async () => await import("date-fns/locale/pt-BR/index.js"),
  ru: async () => await import("date-fns/locale/ru/index.js"),
  sv: async () => await import("date-fns/locale/sv/index.js"),
  uk: async () => await import("date-fns/locale/uk/index.js"),
  vi: async () => await import("date-fns/locale/vi/index.js"),
  zhCN: async () => await import("date-fns/locale/zh-CN/index.js")
};

export default supportedLanguages;
/* eslint-disable no-return-await */
