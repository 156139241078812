import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import MediaQuery from "./index";
import MediaQueryREADME from "../README.md";
import Tabs from "@emcm-ui/component-tabs";
import Block from "@emcm-ui/component-block";
import Dropdown from "@emcm-ui/component-dropdown";

const stories = storiesOf("Components/MediaQuery", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(MediaQueryREADME));
const tabsMenu = [
  {
    value: "tab1",
    label: "tab1",
    href: "#tab1"
  },
  {
    value: "tab2",
    label: "tab2",
    href: "#tab2"
  },
  {
    value: "tab3",
    label: "tab3",
    href: "#tab3"
  }
];

stories
  .add("Default", () => (
    <React.Fragment>
      <MediaQuery breakpoint={["desktop", "xlarge"]}>
        <Block text="Large Devices" color="purple" height="32em" />
      </MediaQuery>
      <MediaQuery breakpoint={["small", "xsmall", "medium"]}>
        <Block text="Small Devices" color="purple" height="32em" />
      </MediaQuery>
    </React.Fragment>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <MediaQuery breakpoint={["desktop", "xlarge"]}>
        <Tabs>
          <Tabs.Panel id="tab1" tabTitle="Tab 1">
            <Block text="Panel 1" color="blue" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab2" tabTitle="Tab 2">
            <Block text="Panel 2" color="gray" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3" tabTitle="Tab 3">
            <Block text="Panel 3" color="green" height="32em" />
          </Tabs.Panel>
        </Tabs>
      </MediaQuery>
      <MediaQuery breakpoint={["small", "xsmall", "medium"]}>
        <Dropdown
          block={true}
          items={tabsMenu}
          placeholder={"Select"}
          onValueChanged={() => {}}
          size={"large"}
        />
        <div id="tab1">
          <Block text="Panel 1" color="purple" height="32em" />
        </div>
        <div id="tab2">
          <Block text="Panel 2" color="green" height="32em" />
        </div>
        <div id="tab3">
          <Block text="Panel 3" color="blue" height="32em" />
        </div>
      </MediaQuery>
    </StaticRenderer>
  ));
