export default scriptSrc => {
  if (typeof document === "undefined") {
    return Promise.reject(
      new Error(
        "mountScript requires document to be defined. It can't be run on a server."
      )
    );
  }

  return new Promise((resolve, reject) => {
    const scriptElement = document.createElement("script");

    scriptElement.onload = () => {
      resolve(scriptElement);
    };

    scriptElement.onerror = () => {
      reject();
    };

    scriptElement.src = scriptSrc;
    document.body.appendChild(scriptElement);
  });
};
