import PropTypes from "prop-types";
import React from "react";

const ColorRow = ({ name, value }) => {
  const colorStyle = {
    backgroundColor: value,
    width: "6rem"
  };

  const dataStyle = {
    paddingLeft: "0.75em"
  };

  return (
    <tr>
      <td style={colorStyle} />
      <td style={dataStyle}>{name}</td>
      <td style={dataStyle}>{value}</td>
    </tr>
  );
};

ColorRow.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const ColorTable = ({ colorPairs }) => {
  const colorNames = Object.keys(colorPairs);

  return (
    <div>
      <h2 className="story-themeSubHeading">Palette</h2>
      <table className="story-themeTable">
        <tbody>
          {colorNames.map(name => (
            <ColorRow key={name} name={name} value={colorPairs[name]} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

ColorTable.propTypes = {
  colorPairs: PropTypes.object.isRequired
};

export default ColorTable;
