class GtmAnalytics {
  // eslint-disable-next-line no-console
  constructor(logger = console.log) {
    this.logger = logger;
  }

  sendEvent(category, action, label) {
    const layers = ["dataLayer"];

    if (typeof window === "undefined") {
      return;
    }

    // Iterate over all potential dataLayer variables and check if they exist

    for (const layer of layers) {
      if (typeof window[layer] !== "undefined") {
        // Send the event into GTM
        window[layer].push({
          event: "analyticsEvent",
          eventCategory: category,
          eventAction: action,
          eventLabel: label,
          eventValue: null,
          eventNonInt: false
        });
      }
    }
  }
}

export default GtmAnalytics;
