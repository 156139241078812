import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const CheckboxControl = ({
  checked,
  defaultChecked,
  disabled,
  id,
  labelText,
  name,
  onChange,
  required,
  value,
  optionalLabel
}) => {
  const getClassName = getClassNameFactory(CheckboxControl.displayName);

  return (
    <div className={getClassName()}>
      <label className={getClassName({ descendantName: "label" })} htmlFor={id}>
        <input
          className={getClassName({ descendantName: "input" })}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={required}
          type="checkbox"
          value={value}
        />
        <span className={getClassName({ descendantName: "labelText" })}>
          {labelText}
          {!required && (
            <span className={getClassName({ descendantName: "optional" })}>
              {optionalLabel ? optionalLabel : "(Optional)"}
            </span>
          )}
        </span>
      </label>
    </div>
  );
};

CheckboxControl.displayName = "Form.CheckboxControl";

CheckboxControl.propTypes = {
  /**
   * Check the <input>. Will switch into controlled mode.
   */
  checked: PropTypes.bool,
  /**
   * Set the initial checked state for the <input>.
   */
  defaultChecked: PropTypes.bool,
  /**
   * Disable the <input>
   */
  disabled: PropTypes.bool,
  /**
   * Id attribute for <input> (and <label>'s `for` attribute)
   */
  id: PropTypes.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: PropTypes.any.isRequired,
  /**
   * Name attribute for <input>, required for serialisation
   */
  name: PropTypes.string,
  /**
   * Add an onChange callback to the <input>.
   */
  onChange: PropTypes.func,
  /**
   * Set <input> to be required
   */
  required: PropTypes.bool,
  /**
   * Initialize <input> with a value
   */
  value: PropTypes.any,
  /**
   * Label to be used for dynamic optional value
   */
  optionalLabel: PropTypes.string
};

export default CheckboxControl;
