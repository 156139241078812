import React from "react";

import Heading from "@emcm-ui/component-heading";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import Grid from "@emcm-ui/component-grid";
import LanguageSwitcher from "@emcm-ui/component-language-switcher";
import MarkupContainer from "@emcm-ui/component-markup-container";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Table from "@emcm-ui/component-table";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { Language } from "@emcm-ui/component-theme";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const PrivacyJp = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Language code="ja">
          <Section>
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <VerticalSpacing size="xl">
                  <Heading rank="1" type="l">
                    プライバシーに関する声明
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="xl">
                  <LanguageSwitcher heading="Available in other languages">
                    <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="nl"
                      title="Nederlands"
                    />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="fr"
                      title="Français"
                    />
                    <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="it"
                      title="Italiano"
                    />
                    <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
                    <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="pt"
                      title="Português"
                    />
                    <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
                    <LanguageSwitcher.Item href="#" lang="es" title="Español" />
                    <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
                  </LanguageSwitcher>
                </VerticalSpacing>
                <RichText large>
                  <h2>はじめに</h2>

                  <p>
                    当社は、お客様のプライバシーとお客様からの信頼を重視しています。本プライバシーステートメント（以下、「本ステートメント」）
                    には、世界各国のトムソン・ロイター関連会社
                    （以下、「トムソン・ロイター」または「当社」）
                    の個人情報の取り扱いに関する重要な情報が記載されています。本ステートメントは、トムソン・ロイターのウェブサイト、アプリケーション、製品、ソフトウェアおよびこれに関連するサービス（以下、総称して「サービス」）
                    に適用されます。一部のサービスでは、本ステートメントとは別のそのサービス固有のプライバシー・ステートメントが適用されます。{" "}
                  </p>

                  <p>
                    本ステートメントをよくお読みいただいた上で、当社のプライバシーに関する取扱いやお客様の個人情報に関して取り得る選択肢についてご質問がある場合は、当社の{" "}
                    <a href="#contact-us">データ保護責任者</a> (Data Protection
                    Officer）までお問い合わせください。また、本ステートメントが変更されている場合もありますので、定期的に本ステートメントをご確認ください。当社は重要な変更を行う場合は、関連サービス上で掲示するか、電子メールなどを通じてお知らせします。{" "}
                  </p>

                  <p>
                    <strong>
                      本ステートメントの最終更新日は、2017 年 6 月 1 日です。{" "}
                    </strong>
                  </p>

                  <p>本ステートメントでは、以下の事項について説明します。</p>
                  <ul>
                    <li>
                      <a href="#personal-information">個人情報</a>
                      <ul>
                        <li>
                          <a href="#personal-information-collected">
                            当社が取得する個人情報の種類
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-used">
                            当社が個人情報を利用する目的
                          </a>
                        </li>
                        <li>
                          <a href="#when-personal-information-shared">
                            当社が個人情報を共有する場面
                          </a>
                        </li>
                        <li>
                          <a href="#where-personal-information-stored">
                            当社が個人情報を取り扱う場所
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-secured">
                            当社における個人情報の保護方法
                          </a>
                        </li>
                        <li>
                          <a href="#time-personal-information-kept">
                            当社が個人情報を保存する期間
                          </a>
                        </li>
                        <li>
                          <a href="#personal-information-access">
                            お客様による個人情報へのアクセスと修正
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#cookies">クッキーおよび類似のテクノロジー</a>
                      <ul>
                        <li>
                          <a href="#what-is-a-cookie">What is a cookie?</a>
                        </li>
                        <li>
                          <a href="#cookie-types">
                            クッキーの種類および当社の使用目的
                          </a>
                        </li>
                        <li>
                          <a href="#managing-cookies">クッキーの管理</a>
                        </li>
                        <li>
                          <a href="#other-tracking-tech">
                            その他のトラッキング（追跡）技術
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#advertising">インタレストベース広告: IBA</a>
                      <ul>
                        <li>
                          <a href="#opting-out-iba">IBA のオプトアウト</a>
                        </li>
                        <li>
                          <a href="#mobile-advertising">
                            モバイルデバイス上の広告
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#dnt">Do Not Track (DNT) 機能</a>
                    </li>
                    <li>
                      <a href="#social-networks">
                        ソーシャルネットワーク経由の接続{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#third-party-services">
                        第三者サービスへのリンクおよび接続
                      </a>
                    </li>
                    <li>
                      <a href="#children-privacy">子供のプライバシー</a>
                    </li>
                    <li>
                      <a href="#contact-us">問い合わせ方法</a>
                    </li>
                  </ul>

                  <h2 id="personal-information">個人情報 </h2>
                  <p>
                    トムソン・ロイターは、個人情報を保護することを重要な責務と認識しております。当社は、本ステートメントに従い、サービスを提供するため、あるいは本ステートメントに規定された当社の運営および業務目的のために必要とされる場合に、個人情報を取得、使用、開示、移転および保管します。当社は、お客様が十分な説明を受けたうえで選択を行えるよう、本ステートメントにより、当社のプライバシーに対する取り組みをご説明します。ご質問やご不明な点がございましたら、いつでも{" "}
                    <a href="#contact-us">こちらに</a> お問い合わせください。{" "}
                  </p>
                  <h3 id="personal-information-collected">
                    当社が取得する個人情報の種類
                  </h3>
                  <p>
                    当社は、お客様によるイベントの登録、当社への情報のリクエスト、当社サービスの購入や使用、カスタマーサポートの依頼等があった場合に、お客様の個人情報を取得しています。当社から、お客様のお名前、ご住所、お電話番号、メールアドレス、ユーザー名やパスワード、お使いのデバイスなどの情報の提供をお願いすることもあります。トムソン・ロイターが保有する個人情報は、お客様から直接入手していない場合があります。例えば、当社のサービスを利用している場合、お客様の雇用主や、所属する団体、税理士や会計士または弁護士のような専門職の方から情報を入手することもあります。また、関心をお持ちと思われるサービスのオファーや、データの正確性の維持、サービスの提供と強化を目的として、当社のパートナーやサービスプロバイダーの他、一般に公開されているウェブサイトなどの第三者からも個人情報を取得することがあります。{" "}
                  </p>

                  <p>
                    さらに、当社サービスの管理や保護・改善、使用状況の分析、ユーザーエクスペリエンスの向上を目的として、当社のサーバー、ログおよびその他のテクノロジーを通じて、{" "}
                    <a href="#other-tracking-tech">自動的に一定の情報を収集</a>{" "}
                    します。当社は、本ステートメントに記載されている場合や法律で認められている場合、また法律で強制される場合に限り、他者と個人情報を共有します。{" "}
                  </p>

                  <p>
                    当社は、特に配慮を要する個人情報を取り扱うことがあります。たとえば、当社のサービスを契約した場合、クレジットカードなどの金融カードや銀行カードなどの情報や決済に必要な情報が収集されます。お客様やお客様に専門的サービスを提供している会計事務所等が当社のTax
                    &
                    Accountingサービスを利用している場合は、お客様の財務および税務に関する情報を収集し、処理します。当社のサービスの中には、正確な位置情報を共有するように求めるものがあります。これはお客様のユーザーエクスペリエンスに応じてカスタマイズし、サービスの正確性を高めることを目的としています。お客様が当社の
                    eRecruitment
                    サービスを使用され求人に応募される場合、政府発行の身分証明書番号などを収集することがあります。これらの場合、適用される法律に照らして特に配慮を要する個人情報と思われる情報は、該当法令に従って取り扱われます。{" "}
                  </p>
                  <h3 id="how-personal-information-used">
                    当社が個人情報を利用する目的{" "}
                  </h3>
                  <p>
                    当社は、以下に記載されるサービスやビジネスに関連する目的で、個人情報を取り扱います。
                  </p>
                  <ul>
                    <li>
                      <strong>アカウントの設定と管理:</strong>{" "}
                      当社は、アカウントの設定・管理、サポート（技術サポートやカスタマーサポート）やトレーニングの提供、お客様の確認、アカウントや契約、サービスに関する重要な情報を送信するために、お客様のお名前、メールアドレス、お電話番号、お使いのデバイスに関する情報などの個人情報を使用します。{" "}
                    </li>
                    <li>
                      <strong>カスタマイズ:</strong>{" "}
                      当社は、個人情報をもとに、ニュース、調査、レポート、ビジネス情報などをカスタマイズするなどして、お客様一人ひとりにあったサービスを提供しています。当社のサービスの中には、正確な位置情報を共有するように求めるものがありますが、それはお客様のユーザーエクスペリエンスをカスタマイズし、サービスの正確性を高めることを目的としています。お客様は、位置情報を当社と共有することに同意された場合でも、お客様のモバイルデバイスのプライバシー設定またはオンラインでその同意をいつでもオフにすることができます。{" "}
                    </li>
                    <li>
                      <strong>マーケティングとイベント: </strong>{" "}
                      当社は、個人情報を使用して、電子メール、電話、テキストメッセージ、ダイレクトメール、オンラインなどのさまざまなプラットフォームで、お客様にマーケティングやイベントに係わる情報を送信します。当社から送信されるマーケティングメールには、マーケティングメールの受信をオプトアウトする方法が記載されています。当社は、電子メールの受領等の設定に係わるセンターを設けており、お客様は、お客様ご自身の情報やマーケティング設定を管理することができます。なお、お客様のアカウントや定期契約に関連する重要なサービス情報につきましては、マーケティングメールの受信をオプトアウトした場合であっても送信されますのでご了承ください。{" "}
                    </li>
                    <li>
                      <strong>アンケートおよび世論調査:</strong>{" "}
                      アンケートや世論調査に参加される場合、お客様が提供された個人情報は、マーケティングや市場調査の目的で使用されます。{" "}
                    </li>
                    <li>
                      <strong>研究開発:</strong>{" "}
                      当社は、社内における研究開発やサービスの機能向上とテストの目的で、個人情報を使用します。{" "}
                    </li>
                    <li>
                      <strong>
                        チャットルーム、メッセージング、コミュニティ、イベントフォーラム:
                      </strong>{" "}
                      当社のサービスの多くは、チャットルーム、メッセージングサービス、コラボレーション、ピア接続、ゲーム、情報交換のためのコミュニティフォーラムやイベントフォーラムなどの機能を提供しています。サービスによっては、お客様が投稿、共有、アップロード、または利用可能にすることを選択された個人情報が、一般に公開されたり他のユーザーに開示される場合があります。したがいまして、許可が得られていない場合、秘密情報や他人に関する情報を、決して投稿したり、共有しないでください。当社は、お客様一人ひとりにあったサービスを提供し、おすすめのコンテンツやピア接続に関する推奨事項を提案するために、お客様がコミュニティやイベントのプロフィール、フォーラム上で提供した情報を利用する場合があります。これらのサービスには、本ステートメントとは別の独自のプライバシーステートメントや利用規約が適用される場合があり、当社は、そのような利用規約に準拠してこれらのサービスを管理します。{" "}
                    </li>
                    <li>
                      <strong>ホスト・サービス:</strong>{" "}
                      当社のサービスの中には、製品やソリューションの提供に付随して、データやドキュメントのストレージサービスを提供するものがあります。お客様が保管している文書やデータには、会社や個人の納税申告書、給与計算や財務データ、法律や訴訟関連の文書などの個人情報が含まれる場合があります。お客様やお客様の代理人によって保管される情報は、お客様自身またはお客様が随時権限を与える者により、管理、アクセスが可能です。テクニカルサポートなどの重要な業務上の理由がある場合、これらの情報は、トムソン・ロイターのスタッフに限定してアクセスされることがあります。当社が個人情報を取り扱う場合の詳細は、<a href="#how-personal-information-used">
                        こちら
                      </a>をクリックしてください。
                    </li>
                    <li>
                      <strong>法令上の義務:</strong>{" "}
                      当社は、犯罪、損失や詐欺の防止、発見、調査などの法的およびコンプライアンス上の理由から、個人情報の保有や使用を求められる場合があります。社内監査や外部監査への対応、情報セキュリティの確保などの目的や、当社が以下の理由で必要または適切と判断する場合、個人情報を使用することがあります。(a)
                      適用される法令 (お客様の居住国以外の法令を含みます。)
                      に基づく場合、(b)
                      裁判所、法執行機関、規制機関、その他の公的政府機関（お客様の居住国以外のものも含みます。）からの要請に応じる場合、(c)
                      当社の利用規約を実施するため、(d)
                      当社または他人の権利、プライバシー、安全または財産を保護するため。{" "}
                    </li>
                  </ul>
                  <h3 id="when-personal-information-shared">
                    当社が個人情報を共有する場面
                  </h3>
                  <p>
                    トムソン・ロイターは、以下に記載される通り、サービスの提供または当社の事業運営のために必要な場合に個人情報を共有または開示します。当社が個人情報を共有する場合は、データのプライバシーとセキュリティの要件を遵守した上で行います。当社は、非個人的かつ匿名化された統計データを第三者と共有することがあります。当社が個人情報を共有する可能性のある相手先とその目的は以下に記載のとおりです。{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>トムソン・ロイター社内:</strong>{" "}
                      当社は世界各国でビジネスを展開しており、さまざまなチームや部門がビジネスに関与しています。そのチームや部門がサービス、アカウント管理、販売マーケティング、カスタマーテクニカルサポートおよび事業開発や製品開発などを行うために、必要に応じて個人情報を利用します。当社の従業員および委託先はすべて、個人情報を取り扱う際に当社のデータのプライバシーおよびセキュリティのポリシーに従う義務を負っています。個人情報を処理する可能性のあるトムソン・ロイターの所在地の一覧は{" "}
                      <a href="#contact-us">こちら</a> をクリックください。{" "}
                    </li>
                    <li>
                      <strong>当社のビジネスパートナー:</strong>{" "}
                      当社は、他の会社や団体と提携し、共同ブランドでサービスやコンテンツを提供したり、イベント、会議、セミナーを主催したりすることがあります。この場合、お客様はトムソン・ロイターと当社パートナーの双方の顧客であり、当社とパートナーはお客様にの情報を収集し、共有することがあります。その場合、トムソン・ロイターは、本ステートメントに従って個人情報を取り扱います。当社パートナーが個人情報を収集、使用、共有する方法の詳細については、そのパートナーのプライバシーステートメントをご確認ください。{" "}
                    </li>
                    <li>
                      <strong>当社の第三者サービスプロバイダー:</strong> •
                      当社は世界中のサービスプロバイダーと提携し、サポートサービスを受けています。当該委託先がサポートを履行する上で必要な場合に限り個人情報が提供されます。これらのサービスには、ソフトウェア、システムおよびプラットフォームのサポート、ダイレクトマーケティングサービス、クラウドホスティングサービス、広告、データ分析、注文処理および配送などが含まれます。当社の委託先は、当社へのサービス提供以外の目的で受領した個人情報を共有または使用することを許可されていません。{" "}
                    </li>
                    <li>
                      <strong>法的理由に基づく第三者:</strong>{" "}
                      当社は、以下の場合、個人情報を共有します。
                      <ul>
                        <li>
                          法的義務を遵守し、法執行機関やその他公的機関などを含めた政府機関（居住国以外の政府機関を含みます。）からの要請に応じるため。
                        </li>
                        <li>
                          合併、買収、合弁事業、当社の事業、資産または株式の全部または一部の売却、譲渡、移転またはその他の処分（破産またはこれに類する手続に関連するものを含みます。）により事業の再編
                          を行う場合。
                        </li>
                        <li>
                          当社の権利、ユーザー、システムおよびサービスを保護するため。
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>eRecruitment パートナー:</strong>{" "}
                      求人に応募するために、当社の e-recruitment
                      サービスのユーザー登録に際し提供された個人情報は、特に配慮を要する個人情報も含め、応募先に提供されます。なお、十分な審査を行う為に、お客様の個人情報はグローバルオフィスに転送されることがあります。{" "}
                    </li>
                  </ul>
                  <h3 id="where-personal-information-stored">
                    当社が個人情報を取り扱う場所
                  </h3>
                  <p>
                    トムソン・ロイターはグローバルな組織であり、お客様の個人情報はお客様の居住する国以外の国で保存および処理される場合があります。当社は、データの保管場所にかかわらず収集した情報を本プライバシーステートメントおよび適用される法令に従って処理します。{" "}
                  </p>
                  <p>
                    トムソン・ロイターは、世界中のオフィスに、ネットワーク、データベース、サーバー、システム、サポートおよびヘルプデスクを有しています。また、世界各国でクラウドホスティングサービス、サプライヤー、技術サポートの委託先と協力し、ビジネス、スタッフ、お客様のニーズに対応しています。当社は、適用される法令に従って個人情報が適切に処理、保護、移転されるよう適切な措置を講じています。必要に応じて、お客様の個人情報がお客様が居住する国以外の地域のトムソン・ロイター企業や委託先に開示または移転される場合があります。これらを受領する国は随時変更されることがありますが、米国、ヨーロッパ、カナダ、アジア、オーストラリア、インド、その他トムソン・ロイターまたは委託先が所在する地域が含まれます。トムソン・ロイターのオフィスの一覧については、下記の「<a href="#contact-us">
                      連絡先
                    </a>」セクションをご覧ください。
                  </p>
                  <p>
                    欧州経済領域 （EEA）
                    からお客様の居住国と同水準のデータプライバシー保護が与えられていないとみなされる国に個人情報が移転される場合、トムソン・ロイターは適切な水準でデータプライバシーが保護されるよう適切な措置を講じます。言い換えれば、お客様の権利とお客様のデータの保護は、お客様のデータと共に存続します。例えば、当社は、承認された契約条項、複数当事者間のデータ移転契約、グループ会社間契約の採用により、お客様の個人情報の受領者が当該情報を確実に保護する措置を講じています。当社のデータ移転に関する詳細については、<a href="#contact-us">
                      データ保護責任者
                    </a>（Data Protection Officer）にお問い合わせください。
                  </p>
                  <h3 id="how-personal-information-secured">
                    当社における個人情報の保護方法{" "}
                  </h3>
                  <p>
                    トムソン・ロイターは、データのセキュリティを重視しており、適切な技術と手順により個人情報を保護します。当社の情報セキュリティポリシーと手続きは、広く受け入れられている国際基準に合わせて定期的に見直され、必要に応じて更新されます。また、ビジネスニーズ、技術および規制要件の変更にも対応しています。{" "}
                  </p>
                  <p>例えば、</p>
                  <ul>
                    <li>
                      ポリシーおよび手順
                      <ul>
                        <li>
                          当社は、偶発的な損失および不正なアクセス、使用、破壊または開示からデータを保護するために対策を講じています。
                        </li>
                        <li>
                          当社には、顧客へのサービスの継続性、従業員や資産を保護するために策定された事業継続戦略（Business
                          Continuity and Disaster Recovery
                          strategy）および災害復旧戦略（Disaster Recovery
                          strategy）があります。
                        </li>
                        <li>
                          当社は、個人情報へのアクセスに適切な制限を設けています。
                        </li>
                        <li>
                          当社は、データを安全に保管して移転するためのモニタリングおよび物理的手段を含む適切な措置および管理を実施しています。
                        </li>
                        <li>
                          当社は、法的要件および当社の事業方針に従って、プライバシー影響度評価を実施しています。
                        </li>
                      </ul>
                    </li>
                    <li>
                      従業員および委託先に対する研修
                      <ul>
                        <li>
                          当社は、個人情報およびその他の取り扱いに注意が必要なデータへのアクセス権を有する従業員および委託先に対して、プライバシー、情報セキュリティ、およびその他の適切な研修を定期的に受けるよう義務づけています。
                        </li>
                        <li>
                          当社は、当社の従業員および委託先が当社の情報セキュリティポリシーおよび手続きならびに適用される契約条件に従って行動するよう適切な措置を講じています。{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      ベンダーの危機管理
                      <ul>
                        <li>
                          当社の第三者ベンダーおよびプロバイダー等の委託先は、連絡先の確保およびセキュリティ審査を通じて、当社のセキュリティポリシーおよび手続に従って個人情報を保護することが義務づけられています。{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <h3 id="time-personal-information-kept">
                    当社が個人情報を保存する期間
                  </h3>
                  <p>
                    トムソン・ロイターには、個人情報の保存に関するポリシーと規則を実施するために、プライバシーオフィス（Privacy
                    Office）と協力して活動するレコード・マネージメント（Record
                    Management）チームがあります。また、事業の機能、記録の分類、および記録の種類に応じた分類に基づき、Enterprise
                    Records Retention
                    Scheduleを設けています。当社は、法的な目的や事業目的のために合理的に必要とされる期間、個人情報を保存します。トムソン・ロイターは、データ保存期間を決定する際に、現地の法律、契約上の義務、および顧客のエクスペクテーションや要件を考慮しています。当社は、個人情報が不要となった場合、安全に削除または破棄します。{" "}
                  </p>
                  <h3 id="personal-information-access">
                    お客様による個人情報へのアクセスと修正
                  </h3>
                  <p>
                    当社は、お客様によるご自身の情報へのアクセスや管理する権利を尊重します。ご要望があった場合、必要に応じてお客様の個人情報を訂正、修正、または削除します。{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>個人情報へのアクセス:</strong>{" "}
                      お客様がご自身の個人情報へのアクセスを希望される場合、当社は本人確認を行うなど関連する法令に従い、お客様のご要望にお応えします。当社は、該当データをお客様にご提供する前に、関連データの特定や本人確認の為、お客様の身分証明および当社とのやりとりに関する情報の提供をお願いすることがあります。また、当社は、データをご提供するにあたり手数料を請求する場合があります（ただし、現地の法律で許可されていない場合を除きます。）。{" "}
                    </li>
                    <li>
                      <strong>訂正および削除:</strong>{" "}
                      一部の法域では、情報が不正確であったり、更新が必要な場合、お客様はご自身の個人情報を訂正する権利があります。また、お客様はご自身の個人情報の削除を要求される権利もありますが、法的要件、その他の義務や要因により、ご要望に沿えない場合もありますのでご了承ください。お客様は、関連するサービス内の「お問い合わせ」オプションを使用して、ご自身のアカウント情報を更新することができます。
                    </li>
                    <li>
                      <strong>マーケティング設定:</strong>{" "}
                      電子メールによるマーケティングをオプトアウトするには、当社から受領された電子メールに記載される配信停止リンクを使用するか、該当する電子メール設定センターにコンタクトください。その他のマーケティング設定については、該当するサービス内で「お問い合わせ」オプションをご使用いただくことができます。クッキーの制御に関する情報は<a href="#cookies">
                        こちら
                      </a>、インタレストベース広告とモバイル広告に関する情報は<a href="#advertising">
                        こちら
                      </a>をご参照ください。
                    </li>
                    <li>
                      <strong>苦情の申し立て:</strong>{" "}
                      トムソン・ロイターの個人情報の取り扱いについてご不満をお持ちの場合、お客様は該当国のデータ保護担当者（data
                      protection
                      regulator）に苦情を申し立てる権利があります。National Data
                      Protection Authoritiesのリストは、こちらをご参照ください:{" "}
                      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                      </a>.
                    </li>
                  </ul>
                  <p>
                    また、お客様の個人情報に関するお問い合わせ・苦情等は、当社の<a href="#contact-us">
                      データ保護責任者
                    </a>{" "}
                    (Data Protection
                    Officer）も対応致します。PeopleMap、WestlawのPublic
                    Records及びCLEARに関するリクエストについては、<a href="https://legalsolutions.thomsonreuters.com/law-products/about/legal-notices/privacy/records">
                      こちら
                    </a>をクリックしてください。
                  </p>
                  <a href="#top">
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="cookies">クッキーおよび類似のテクノロジー</h2>
                  <p>
                    トムソン・ロイターと当社の第三者プロバイダーは、クッキーおよび類似の技術を設定、使用して、ユーザーの好みを保存・管理し、ターゲット広告の配信や、コンテンツの有効化、分析データおよび使用データの収集をします。クッキーおよびその他の追跡（トラッキング）技術は、アプリケーション、ウェブサイトまたは他のサービス上でのお客様のオンライン活動情報を収集しているウェブサイトやアプリで標準的に使用されています。クッキーおよび類似の技術の使用方法、ならびにそれらを<a href="#managing-cookies">
                      制御および管理する方法
                    </a>に関する詳細は、以下の通りです。{" "}
                  </p>
                  <h3 id="what-is-a-cookie">クッキーとは</h3>
                  <p>
                    クッキーとは、コンピュータや他のデバイスに配置され、ユーザーまたはデバイスを識別し、情報を収集するために使用される小さなテキストファイルです。クッキーは通常、機能や目的に応じて
                    、次の4
                    つのカテゴリに分類されます。それぞれ、不可欠なクッキー（Absolutely
                    Necessary Cookies）、パフォーマンスクッキー（Performance
                    Cookies）、機能性クッキー（Functionality
                    Cookies）、ターゲティング・アドバタイジングクッキー（Targeting
                    and Advertising Cookies）があります。
                  </p>

                  <h3 id="cookie-types">クッキーの種類および当社の使用目的</h3>
                  <ul>
                    <li>
                      <strong>不可欠なクッキー:</strong>{" "}
                      このクッキーは、お客様がウェブサイトを移動してその機能を使用するために不可欠なものです。このクッキーがなければ、オンラインショッピングのカートに商品を追加するなどのサービスはご利用になれません。
                    </li>
                    <li>
                      <strong>パフォーマンスクッキー:</strong>{" "}
                      このクッキーは、お客様の当社ウェブサイト利用状況に関する情報を収集します。収集される情報には、例えば、使用されるインターネットブラウザーおよびオペレーティングシステム、以前に訪問されたウェブサイトのドメイン名、訪問回数、平均訪問時間、および閲覧されたページが含まれます。このクッキーは個人を特定する情報を収集せず、集約された匿名の情報のみを収集します。パフォーマンスクッキーは、ウェブサイトの使いやすさを向上させ、お客様のユーザーエクスペリエンスを向上させるために使用されます。
                    </li>
                    <li>
                      <strong>機能性クッキー:</strong>{" "}
                      このクッキーによって、お客様が行った選択（お客様のユーザー名や
                      ID、言語設定、お客様の地区や地域など）
                      をウェブサイトに記憶させ、よりカスタマイズされた機能を提供することができます。このクッキーは、テキストサイズ、フォント、およびウェブページのその他カスタマイズ可能な部分に対してお客様が行った変更を記憶するためにも使用できます。また、動画の視聴やブログへのコメントなど、お客様が求められたサービスを提供するために使用することもできます。このクッキーが収集する情報は匿名化することができ、他のウェブサイトでお客様が行った閲覧活動を追跡することはできません。{" "}
                    </li>
                    <li>
                      <strong>ターゲティング・アドバタイジングクッキー:</strong>{" "}
                      このクッキーは閲覧傾向を追跡し、ターゲティング（インタレストベース）広告を配信するために使用されます。また、広告が表示される回数を制限し、広告キャンペーンの効果を測定するためにも使用されます。通常、このクッキーは、ウェブサイト運営者の許可を得て広告ネットワークによって配置されます。このクッキーによってお客様のウェブサイトへのアクセスが記憶され、記憶された情報は広告主のような他の組織と共有されます。{" "}
                    </li>
                  </ul>
                  <h3 id="managing-cookies">クッキーの管理</h3>
                  <p>
                    ブラウザの設定でウェブサイトのクッキーを管理することができます。クッキーを受け入れたり、拒否されたり、削除されることにより、設定をいつでも変更することができます。設定の変更をされた場合、特定の機能が正常に動作しないことがあります。クッキーを管理するためのブラウザの設定は、ブラウザによって若干異なる場合がありますので、ブラウザ内の関連する設定をご確認ください。{" "}
                  </p>
                  <p>
                    以下に、クッキーに関してご確認いただきたい詳細な情報を記載します。次のウェブサイトは、クッキーの種類、クッキーの使用方法、クッキーの設定を管理する方法について詳しく説明されている有用なリソースです。<a href="https://www.aboutcookies.org">
                      www.aboutcookies.org
                    </a>{" "}
                    <br />{" "}
                    <a href="https://www.allaboutcookies.org">
                      www.allaboutcookies.org
                    </a>
                  </p>
                  <p>
                    一般的に使用されているブラウザでクッキーを無効にしたり、削除する方法の詳細につきましては、下記をご参照ください。
                  </p>
                  <ul>
                    <li>
                      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                        Mozilla Firefox®
                      </a>
                    </li>
                    <li>
                      <a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">
                        Microsoft® Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=en">
                        Google Chrome™
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&locale=de_DE">
                        Safari®
                      </a>
                    </li>
                  </ul>
                  <h3 id="other-tracking-tech">
                    その他のトラッキング（追跡）技術
                  </h3>
                  <ul>
                    <li>
                      <strong>ローカル共有オブジェクト / Flashクッキー:</strong>{" "}
                      Flashクッキーはローカル共有オブジェクトとも呼ばれ、Adobe
                      Flashがサポートするブラウザコンテンツをサポートします。通常、このクッキーは、ウェブサイトで広告および動画コンテンツを有効にするために使用され、他のクッキーと同様にデバイスに情報を保存します。その情報の一部は、Flash対応のコンテンツに固有のものです。Flashクッキーは、ブラウザではなくAdobe
                      Flash内でのみ削除できます。プライバシー設定とFlashクッキーを削除する方法については、次のヘルプページをご参照ください。{" "}
                      <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                        https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
                      </a>
                    </li>
                    <li>
                      <strong>Webビーコン:</strong>{" "}
                      当社のウェブページには、サイト上でのクッキーの配信、当該サイトへアクセスしたユーザーのカウント、サービス提供や当社のプロモーションキャンペーンなどの効果の分析等を行う目的で、Webビーコン（単一ピクセルの
                      GIF および透過グラフィック画像とも呼ばれます。）
                      として知られる電子イメージが含まれている場合があります。また、当社のマーケティング電子メールメッセージまたはニュースレターに、電子メールが開封されているかどうか、またはリンクがクリックされているかどうかを判断するためにWebビーコンが含まれることがあります。
                    </li>
                    <li>
                      <strong>ウェブサーバーとアプリケーションログ:</strong> •
                      当社のサーバーは、サービスを管理、保護し、また使用状況の分析、ユーザーの利便性を向上させる為、特定の情報を自動的に収集します。収集される情報には以下が含まれます
                      :
                      <ul>
                        <li>IP アドレスおよびブラウザの種類</li>
                        <li>
                          固有デバイス識別子（UDID）、MAC
                          アドレス、広告識別子（IFA）、および当社または他の者が割り当てる類似の識別子を含むデバイス情報
                        </li>
                        <li>
                          デバイスのオペレーティングシステムおよびその他の技術的な情報
                        </li>
                        <li>
                          お客様が当社のウェブサイトにアクセスされた市町村、都道府県や国の情報
                        </li>
                        <li>
                          訪問したページおよび閲覧、保存ならびに購入したコンテンツ{" "}
                        </li>
                        <li>入力した情報またはテキスト</li>
                        <li>クリックしたリンクおよびボタン </li>
                        <li>当社のサービスの利用前後に訪問した URL</li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#top">
                    {" "}
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="advertising">インタレストベース広告（IBA)</h2>
                  <p>
                    当社では、インタレストベース広告 （IBA）
                    をサービスのユーザーにターゲット広告を提供するために使用します。IBA
                    は、お客様がアクセスされたり、閲覧されるコンテンツの種類に応じて広告を表示します。例えば、お客様が当社のサービスを閲覧される際、お客様のデバイスに配置されているクッキーの
                    1
                    つが広告クッキーとなるため、当社はお客様がどのようなページやコンテンツにご興味をお持ちであるか把握することができます。お客様のデバイスについても情報が収集されますので、お客様と似たご興味を持つ他のデバイスを使用されている人とお客様をグループ化し、共通の関心事に基づくユーザーに対して広告を表示することができます。IBA
                    の詳細については、以下のウェブサイトをご参照ください。{" "}
                    <a href="https://www.iab.net/public_policy/behavioral-advertisingprinciples">
                      https://www.iab.net/public_policy/behavioral-advertisingprinciples
                    </a>
                  </p>
                  <h3 id="opting-out-iba">IBA のオプトアウト</h3>
                  <p>
                    インタレストベース広告の受け取りのオプトアウトを希望されても、広告は引き続き配信されますのでご留意ください。オプトアウトにより、収集された情報が
                    IBA
                    の目的で使用されないため、お客様のご覧になる広告はカスタマイズされることなく、お客様に関連する内容のものとはなりませんが、お客様は引き続き広告を受領されることになります。オンライン広告の選択を設定するには<a href="https://www.aboutads.info/choices/">
                      こちら
                    </a>をクリックするか、広告内の AdChoices
                    アイコンをクリックして指示に従ってください。NAI（Network
                    Advertising Initiative） のオプトアウトツール (<a href="https://www.networkadvertising.org/choices">
                      https://www.networkadvertising.org/choices
                    </a>) を介し多くのサイトから（欧州連合（EU） においては{" "}
                    <a href="https://www.youronlinechoices.com/">
                      https://www.youronlinechoices.com/
                    </a>.
                    から）インタレストベースの広告を受信しないようにすることもできます。クッキーを削除されたり、別のデバイスを使用されたり、ウェブブラウザを変更される場合は、再度オプトアウトする必要がありますのでご留意ください。{" "}
                  </p>
                  <h3 id="mobile-advertising">モバイルデバイス上の広告</h3>
                  <p>
                    モバイルデバイスには、企業が特定のモバイルデバイスにターゲットを絞った広告を配信できるようにする識別子があります。多くの場合、モバイルデバイスの広告の追跡を無効にすることや、モバイルデバイスのプライバシー設定内でいつでも広告識別子をリセットすることができます。AppChoices
                    というアプリツール (<a href="https://youradchoices.com/appchoices">
                      https://youradchoices.com/appchoices
                    </a>)
                    をご使用になりモバイルデバイス上の広告を制御することも可能です。また、お客様はモバイルデバイス上の位置追跡をオフにすることもできます。広告追跡または位置追跡を無効にされることにより、当社は広告の目的においてデバイスの広告識別子から収集した情報を使用しません。なお、広告数には変化はないかもしれませんが、お客様の興味に基づいた広告ではなくなることから、お客様に関連しない広告内容が増える場合があります。{" "}
                  </p>
                  <a href="#top">
                    {" "}
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="dnt">Do not track</h2>
                  <p>
                    一部のブラウザは、Do Not Track （DNT）
                    信号をウェブサイトに送信します。業界では、DNT
                    信号に関する共通認識が確立されていないため、当社は、現在 DNT
                    要求およびこれらのブラウザからの信号を修正、変更しておらず、またはこれらに対応していません。当社は引き続き、この分野における業界の活動を見守り、必要に応じて
                    DNT について再評価します。
                  </p>
                  <a href="#top">
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="social-networks">ソーシャルネットワーク経由の接続</h2>
                  <p>
                    当社のサービスには、Facebook
                    の「いいね！」ボタンおよびウィジェット、「共有」ボタン、インタラクティブなミニプログラムなどのソーシャルネットワーキング機能を利用しているものがあります。さらに、Facebook
                    や LinkedIn
                    などお客様がお使いになっているソーシャルネットワークのログインを使用して、当社の一部のサービスにログインすることもできます。ソーシャルネットワーキングまたは類似のサービスを使用して接続される場合、当社は、これらのサービスからの認証情報やこれらのサービスに接続する際に共有されるその他の情報を受信して保存することができます。これらのサービスでは、訪問したウェブページや
                    IP
                    アドレスなどの情報を収集し、機能が適切に動作するようにクッキーが設定されている場合があります。当社は、これらの第三者によって収集された情報のセキュリティまたはプライバシーについては責任を負いかねます。お客様は、このような第三者のサービスに適用されるプライバシーに関する声明またはポリシーをご確認ください。ソーシャルメディアアカウントのプロバイダーまたはソーシャルメディアサービスの他のユーザーと個人情報をご共有されたくない場合は、ソーシャルメディアアカウントを当社サービスのアカウントに接続したり、当社サービス上のソーシャル
                    シェアリングに参加しないようにしてください。
                  </p>

                  <a href="#top">
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="third-party-services">
                    第三者サービスへのリンクおよび接続
                  </h2>
                  <p>
                    当社のサービスには、当社が提携、制御、または管理を行っていない第三者のアプリケーション、サービス、ツールおよびウェブサイトへのリンクが含まれる場合があり、お客様はこれらリンクを使用することができます。例としては、Facebook、LinkedIn、Twitter、音声ソフトウェアやリーダーなどの第三者のアプリなどがあります。これらの第三者のプライバシーの取り組みは、当該第三者の独自のプライバシーステートメントによって管理されます。当社は、これらの第三者によって収集された情報のセキュリティまたはプライバシーについては責任を負いかねます。第三者のサービスに適用されるプライバシーに関する声明またはポリシーをご確認ください。
                  </p>
                  <a href="#top">
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="children-privacy">子供のプライバシー </h2>
                  <p>
                    トムソン・ロイターは、プロフェッショナル向けの情報ソリューションを提供しており、当社のサービスは一般に子供向けのものではありません。ただし、教育リソースの開発など、子供に関する情報を収集して使用する場合、当社は業界のガイドラインおよび適用法を遵守します。{" "}
                  </p>
                  <a href="#top">
                    <strong>先頭に戻る</strong>
                  </a>

                  <h2 id="contact-us">当社への連絡方法 </h2>
                  <p>
                    当社は、本ステートメントまたはプライバシーの取り組みに関してご質問やご懸念がある場合、または苦情の申し立てを希望される場合、以下のいずれかの方法で当社にお問い合わせください。
                  </p>
                  <h3>トムソン・ロイターデータ保護責任者</h3>
                </RichText>
                <MarkupContainer>
                  <HorizontalOverflow>
                    <Table>
                      <table>
                        <tbody>
                          <tr>
                            <td>メール </td>
                          </tr>
                          <tr>
                            <td>
                              <a href="mailto:Privacy.enquiries@thomsonreuters.com">
                                Privacy.enquiries@thomsonreuters.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>住所 : </td>
                          </tr>
                          <tr>
                            <td>
                              Attn: Data Protection Officer <br /> Thomson
                              Reuters <br /> D5 S1866 <br /> 610 Opperman Dr.{" "}
                              <br /> Eagan, MN 55123 <br /> USA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Table>
                  </HorizontalOverflow>
                </MarkupContainer>
                <RichText>
                  <h3>マーケティング設定とサービスのサポート</h3>
                  <p>
                    アカウント情報の更新、電子メールによるマーケティング設定の送信、またはテクニカルサポートもしくは本サービスに関するその他のヘルプについては、該当する電子メール設定センターにアクセスするか、該当するサービスの「お問い合わせ」オプションを使用してください。
                  </p>
                  <p>
                    <a href="https://www.thomsonreuters.com/en/locations.html">
                      トムソン・ロイターの拠点リストはこちらをクリックしてください。
                    </a>
                  </p>
                  <a href="#top">
                    <strong>先頭に戻る</strong>
                  </a>
                </RichText>
              </Grid.Item>
            </Grid>
          </Section>
        </Language>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default PrivacyJp;
