import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Filters from "./Filters";

const rehydrator = async (domNode, rehydrateChildren, extra) => {
  const getClassName = getClassNameFactory("SearchFiltersFilters");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "inner" })}`
  );

  const reactifiedChildren = await rehydrateChildren(childrenNode, extra);

  const rehydratedProps = {
    title: domNode.getAttribute("data-title")
  };

  return <Filters {...rehydratedProps}>{reactifiedChildren}</Filters>;
};

export default rehydrator;
