import React from "react";

import Heading from "@emcm-ui/component-heading";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import Grid from "@emcm-ui/component-grid";
import LanguageSwitcher from "@emcm-ui/component-language-switcher";
import MarkupContainer from "@emcm-ui/component-markup-container";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Table from "@emcm-ui/component-table";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { Language } from "@emcm-ui/component-theme";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const PrivacyTr = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Language code="tr">
          <Section>
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <VerticalSpacing size="xl">
                  <Heading rank="1" type="l">
                    Gizlilik Bildirimi
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="xl">
                  <LanguageSwitcher heading="Available in other languages">
                    <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="nl"
                      title="Nederlands"
                    />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="fr"
                      title="Français"
                    />
                    <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="it"
                      title="Italiano"
                    />
                    <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
                    <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="pt"
                      title="Português"
                    />
                    <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
                    <LanguageSwitcher.Item href="#" lang="es" title="Español" />
                    <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
                  </LanguageSwitcher>
                </VerticalSpacing>
                <RichText large>
                  <h2>Giriş</h2>

                  <p>
                    Gizliliğiniz ve güveniniz bizim için önemlidir. Bu Gizlilik
                    Bildirimi ({'"Bildirim"'}), Thomson Reuters’ın ve dünya
                    çapındaki bağlı şirketleri ve alt kuruluşlarının (“Thomson
                    Reuters”, “biz” veya “bize”) kişisel bilgileri nasıl ele
                    aldığıyla ilgili önemli bilgiler sunar. Bu Bildirim, Thomson
                    Reuters ile bağlantısı olan tüm Thomson Reuters web
                    siteleri, uygulamaları, ürünleri, yazılımları veya
                    hizmetleri (toplu olarak “Hizmetlerimiz”) için geçerlidir.
                    Bazı yerlerde bahsedilen Hizmet, o Hizmetin özel gizlilik
                    uygulamalarını özetleyen farklı bir Gizlilik Bildirimi ile
                    ilişkili olabilir.{" "}
                  </p>

                  <p>
                    Lütfen bu Bildirimi dikkatli bir şekilde okuyun ve gizlilik
                    uygulamalarımız veya kişisel bilgi seçimlerinizle ilgili
                    herhangi bir sorunuz olursa{" "}
                    <a href="#contact-us">Veri Koruma Görevlimizle</a> iletişime
                    geçin. Bu Bildirimde güncelleme yapılıp yapılmadığını sık
                    sık kontrol etmeniz gerekir. Önemli gördüğümüz bir
                    değişiklik yaparsak ilgili Hizmetlerin üstüne bir duyuru
                    yerleştirerek ve/veya e-posta gibi diğer yöntemler
                    aracılığıyla sizinle iletişime geçeceğiz.
                  </p>

                  <p>
                    <strong>
                      Bu Bildirim en son 1 Haziran 2017 tarihinde
                      güncellenmiştir.{" "}
                    </strong>
                  </p>

                  <p>
                    Aşağıda bazı konularla ilgili faydalı bilgilere
                    ulaşabilirsiniz:
                  </p>
                  <ul>
                    <li>
                      <a href="#personal-information">Kişisel bilgiler </a>
                      <ul>
                        <li>
                          <a href="#personal-information-collected">
                            Topladığımız kişisel bilgi türleri
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-used">
                            Kişisel bilgileri nasıl kullanırız?
                          </a>
                        </li>
                        <li>
                          <a href="#when-personal-information-shared">
                            Kişisel bilgileri ne zaman paylaşırız?
                          </a>
                        </li>
                        <li>
                          <a href="#where-personal-information-stored">
                            Kişisel bilgileri nerede saklar ve işleriz?
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-secured">
                            Kişisel bilgileri nasıl güvende tutarız?
                          </a>
                        </li>
                        <li>
                          <a href="#time-personal-information-kept">
                            Kişisel bilgileri ne kadar süre saklarız?
                          </a>
                        </li>
                        <li>
                          <a href="#personal-information-access">
                            Kişisel bilgilerinize erişme ve düzeltme hakkınız
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#cookies">Çerezler ve benzer teknolojiler</a>
                      <ul>
                        <li>
                          <a href="#what-is-a-cookie">Çerez nedir? </a>
                        </li>
                        <li>
                          <a href="#cookie-types">
                            Çerez türleri ve çerezleri kullanma nedenimiz
                          </a>
                        </li>
                        <li>
                          <a href="#managing-cookies">Çerezleri yönetme</a>
                        </li>
                        <li>
                          <a href="#other-tracking-tech">
                            Diğer izleme teknolojileri
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#advertising">
                        İlgi alanına dayalı reklamcılık (Interest based
                        advertising) (IBA)
                      </a>
                      <ul>
                        <li>
                          <a href="#opting-out-iba">IBA’dan çıkış</a>
                        </li>
                        <li>
                          <a href="#mobile-advertising">
                            Mobil cihazlarda reklamcılık
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#dnt">Beni izleme (Do not track) (DNT)</a>
                    </li>
                    <li>
                      <a href="#social-networks">
                        Sosyal ağlar aracılığıyla bağlanma{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#third-party-services">
                        Üçüncü taraf hizmetlerine giden bağlantılar
                      </a>
                    </li>
                    <li>
                      <a href="#children-privacy">Çocukların gizliliği</a>
                    </li>
                    <li>
                      <a href="#contact-us">Bize ulaşın</a>
                    </li>
                  </ul>

                  <h2 id="personal-information">Kişisel bilgiler </h2>
                  <p>
                    Thomson Reuters, kişisel bilgilerin sorumlu bir şekilde
                    kullanılacağının ve korunacağının taahhüdünü verir.
                  </p>
                  <p>
                    Kişisel Bilgiler, kimliği tespit edilmiş veya tespit
                    edilebilir gerçek kişi ile bağlantılı tüm bilgileri
                    kapsamaktadır. Gerçek kişi, doğrudan veya dolaylı olarak
                    özellikle ad, kimlik numarası, adres bilgileri, çevrimiçi
                    kimlik gibi tanımlayıcı bilgilerle veya fiziksel,
                    fizyolojik, genetik, ruhsal, ekonomik, kültürel veya sosyal
                    özdeşliği gibi bir veya birden fazla faktör aracılığıyla
                    teşhis edilebilir kişi anlamına gelmektedir.
                  </p>
                  <p>
                    Hizmetlerimize ihtiyaç duyulduğunda ve bu Bildirimde
                    açıklanan operasyonel ile kurumsal amaçlarımız için kişisel
                    bilgileri toplar, kullanır, ifşa eder, aktarır ve
                    kaydederiz. Bilgilerinizin kullanımına dair seçimlerinizi
                    şartları bilerek yapabilmeniz için gizlilik
                    uygulamalarımızın iyi anlaşılması bizim için önemlidir; bu
                    nedenle sorularınız veya endişeleriniz için istediğiniz
                    zaman <a href="#contact-us">bize ulaşabilirsiniz</a>.{" "}
                  </p>

                  <h3 id="personal-information-collected">
                    Topladığımız kişisel bilgi türleri
                  </h3>
                  <p>
                    Örneğin bir etkinliğe kaydolduğunuzda, bilgi talep
                    ettiğinizde, Hizmetlerimizi satın aldığınızda veya
                    kullandığınızda ya da müşteri desteği istediğinizde kişisel
                    bilgilerinizi toplarız. Sizden adınız, adresiniz, telefon
                    numaranız, e-posta adresiniz, kullanıcı adınız ve parolanız
                    gibi bilgiler ve cihazınızla ilgili bilgiler sağlamanızı
                    isteyebiliriz. Thomson Reuters’ın elinde bulunan bilgilerin
                    tümü doğrudan sizden gelmeyebilir. Örneğin bu bilgiler
                    işvereninizden, bünyesinde olduğunuz diğer kurumlardan veya
                    Hizmetlerimizi kullanıyorlarsa vergi ya da muhasebe
                    uzmanınızdan veya avukatınızdan gelebilir. Ayrıca ilginizi
                    çekebilecek Hizmetleri teklif etmek, veri doğruluğunu
                    korumak, Hizmetleri sağlamak ve geliştirmek için
                    ortaklarımız, hizmet sağlayıcılarımız ve halka açık web
                    sitelerimiz gibi üçüncü taraflardan da kişisel bilgiler
                    toplarız.{" "}
                  </p>

                  <p>
                    Buna ek olarak sunucularımız, günlüklerimiz ve diğer
                    teknolojilerimiz Hizmetlerimizi yönetmek, korumak ve
                    geliştirmek; kullanımı analiz etmek ve kullanıcı deneyimini
                    iyileştirmek için{" "}
                    <a href="#other-tracking-tech">
                      bazı bilgileri otomatik olarak toplar
                    </a>. Kişisel bilgileri yalnızca bu Bildirimde açıklandığı
                    şekilde veya yasaların izin verdiğini ya da gerektirdiğini
                    düşündüğümüzde başkalarıyla paylaşırız.{" "}
                  </p>

                  <p>
                    Zaman zaman hassas kişisel bilgi olarak nitelendirilebilecek
                    bilgileri toplar ve işleriz.{" "}
                  </p>
                  <p>
                    Hassas kişisel bilgiler, kişisel bilgilerin altkümesidir ve
                    genel olarak ırk/etnik köken, siyasi görüş, dini inançlar,
                    sendika üyeliği, beden veya ruh sağlığı, biyometrik ve
                    genetik veriler, cinsel yaşam ve tercihleri gibi diğer tıbbi
                    bilgiler ile ilgili herhangi bir bilgi olarak tanımlanır.
                    Bazı durumlarda hassas kişisel bilgiler; ceza suçlamaları
                    veya cezai hüküm, kesin coğrafi konum bilgileri, mali ve
                    banka hesap numaraları ve bunların yanı sıra devlet
                    tarafından verilen sosyal güvenlik numaraları, ehliyet ve
                    pasaport numaraları gibi benzersiz tanımlayıcı bilgileri
                    kapsayabilir.
                  </p>
                  <p>
                    Örneğin bizden bir şey satın aldığınızda veya Hizmetlerimize
                    abone olduğunuzda, finansal bilgilerinizi ya da banka kartı
                    bilgilerinizi ve işlemi gerçekleştirebilmemiz için gereken
                    diğer bilgileri toplarız. Siz veya hizmet uzmanınız Vergi ve
                    Muhasebe Hizmetlerimizi kullanırsa buradan edindiğimiz
                    finansal bilgiler ile vergi bilgilerini toplar ve işleriz.
                    Bazı Hizmetlerimiz deneyiminizi özelleştirmek ve Hizmetin
                    doğruluğunu artırmak için tam coğrafi konumunuzu
                    paylaşmanızı ister. Ayrıca iş başvurusu yapmak için
                    elektronik İşe Alım Hizmetlerimizi kullandığınızda resmi
                    kimlik numarası gibi bilgileri de toplayabiliriz. Geçerli
                    yasalar dahilinde hassas kabul edilen bilgiler bu yasalara
                    uygun olarak kullanılacaktır.{" "}
                  </p>

                  <h3 id="how-personal-information-used">
                    Kişisel bilgileri nasıl kullanırız?
                  </h3>
                  <p>
                    Kişisel bilgileri aşağıdaki Hizmetler ve işle ilgili amaçlar
                    doğrultusunda işleriz:
                  </p>
                  <ul>
                    <li>
                      <strong>Hesap Kurulumu ve Yönetimi:</strong> Adınız,
                      e-posta adresiniz, telefon numaranız ve cihazınızla ilgili
                      bilgiler gibi kişisel bilgilerinizi hesabınızı kurmak ve
                      yönetmek, teknik destek ve müşteri desteği ile eğitimi
                      sunmak, kimliğinizi doğrulamak ve önemli hesap, abonelik
                      ve Hizmet bilgilerini göndermek için kullanırız.
                    </li>
                    <li>
                      <strong>Kişiselleştirme:</strong> Haberler, araştırmalar,
                      raporlar ve iş dünyasından bilgiler gibi özel olarak bir
                      araya getirilmiş içerikleri sunmak ve önermenin yanı sıra
                      Hizmetlerimizle olan deneyiminizi kişiselleştirmek için
                      kişisel bilgileri kullanırız. Bazı Hizmetlerimiz
                      deneyiminizi özelleştirmek ve Hizmetin doğruluğunu
                      artırmak için tam coğrafi konumunuzu paylaşmanızı ister.
                      Tam coğrafi konumunuzu bizimle paylaşmayı kabul ederseniz
                      daha sonra mobil cihazınızda veya çevrimiçi olarak
                      gizlilik ayarlarınıza giderek istediğiniz zaman bunu
                      kapatabilirsiniz.{" "}
                    </li>
                    <li>
                      <strong>Pazarlama ve Etkinlikler:</strong> E-posta,
                      telefon, mesajlaşma, doğrudan posta ve çevrimiçi ortam
                      gibi çeşitli platformlardan pazar ve etkinlik haberlerini
                      size iletmek için kişisel bilgileri kullanırız. Size
                      gönderdiğimiz her ticari e-postada, bu tür e-postaları
                      abonelik listesinden nasıl çıkabileceğinize ilişkin
                      talimatlar içerecektir. Ayrıca bilgilerinizi ve pazarlama
                      tercihlerinizi yönetmeniz için e-posta tercih
                      merkezlerimiz de bulunmaktadır. Lütfen ticari e-postaları
                      almamayı seçseniz bile yine de size hesaplarınız ve
                      aboneliklerinizle ilgili önemli Hizmet bilgilerini
                      gönderebileceğimizi unutmayın.{" "}
                    </li>
                    <li>
                      <strong>Araştırmalar ve Anketler:</strong> Bir araştırmaya
                      veya ankete katılmayı tercih ederseniz verdiğiniz tüm
                      kişisel bilgiler pazarlama veya pazar araştırması amaçları
                      için kullanılabilir.{" "}
                    </li>
                    <li>
                      <strong>Araştırma ve Geliştirme:</strong> İç araştırma ve
                      geliştirme amaçları doğrultusunda ve Hizmetlerimizi
                      geliştirmek ve özellikleriyle işlevlerini test etmek için
                      kişisel bilgileri kullanırız.{" "}
                    </li>
                    <li>
                      <strong>
                        Sohbet Odaları, Mesajlaşma, Topluluk ve Etkinlik
                        Forumları:
                      </strong>{" "}
                      Hizmetlerimizin bir kısmı iş birliği, eş bağlantısı,
                      oyunlar ve bilgi alışverişi amacıyla sohbet odaları,
                      mesajlaşma servisleri, topluluk ve etkinlik forumları gibi
                      özellikler barındırır. Yayınlamayı, paylaşmayı, yüklemeyi
                      veya kullanıma sunmayı seçtiğiniz kişisel bilgiler Hizmete
                      bağlı olarak bu Hizmetleri kullananlara açık ve
                      görünürdür. İzniniz olmadığı sürece asla gizli veya
                      başkalarıyla ilgili olan bilgileri yayınlamamalı veya
                      paylaşmamalısınız. Verdiğiniz bilgileri, deneyiminizi
                      kişiselleştirmek ve içerik ile eş bağlantısı önerileri
                      yapmak için topluluklarda, etkinlik profillerinde ve
                      forumlarda kullanabiliriz. Bu Hizmetlerin kendilerine ait
                      Kullanım Şartları ve uygun olduğu koşullarda kendilerine
                      ait gizlilik bildirimleri olabilir. Kullanım Şartlarımızla
                      uyumlu olması için bu Hizmetleri denetleriz.{" "}
                    </li>
                    <li>
                      <strong>Barındırılan Hizmetler:</strong> Hizmetlerimizin
                      bazıları ürünün veya sunulan çözümün dahili bir parçası
                      olarak veri ve belge depolaması sağlar. Örneğin işle
                      ilgili ve kişisel vergi formları, maaş bordrosu, finansal
                      veriler, yasal ve davayla ilgili belgeler gibi
                      müşterilerimiz tarafından depolanan belgeler ile veriler,
                      kişisel bilgiler barındırabilir. Müşterilerimiz tarafından
                      veya müşterilerimiz adına kaydedilen tüm bilgiler bu
                      müşteriler tarafından kontrol edilir, yönetilir ve
                      yalnızca kendileri ile ara sıra yetkilendirdikleri diğer
                      kişiler tarafından erişilebilir. Teknik destek gibi önemli
                      bir iş nedeninden dolayı bu bilgilere erişimimiz yalnızca
                      Thomson Reuters personeliyle kısıtlıdır. Bilgileri nerede
                      işlediğimiz ve depoladığımızla ilgili daha fazla bilgi
                      edinmek için lütfen{" "}
                      <a href="#where-personal-information-stored">buraya</a>{" "}
                      tıklayın.
                    </li>
                    <li>
                      <strong>Yasal Yükümlülükler:</strong> Bir suçun, kaybın
                      veya dolandırıcılığın önlenmesi, tespit edilmesi veya
                      araştırılması gibi yasal nedenlerden ve uyumluluk
                      nedenlerinden dolayı kişisel bilgileri kullanmamız ve
                      tutmamız gerekebilir. Aynı zamanda iç ve dış hesap
                      denetimi gerekliliklerimizi ve bilgi güvenliği
                      amaçlarımızı karşılamak için ve gerekli veya uygun
                      olduğunu düşündüğümüz diğer durumlarda kişisel bilgileri
                      kullanabiliriz: (a) Geçerli yasalara uymak için (bu
                      yasalar, ikamet ettiğiniz ülkenin dışındaki yasalar
                      olabilir); (b) mahkemelerden, emniyet teşkilatlarından,
                      düzenleyici kuruluşlardan ve diğer kamu ve devlet
                      kurumlarından (bu kurumlar ikamet ettiğiniz ülkenin
                      dışındaki kurumlar olabilir) gelen isteklere yanıt vermek
                      için; (c) hüküm ve koşullarımızı uygulamak için ve (d)
                      bizim veya başka insanların haklarını, gizliliğini,
                      güvenliğini veya mal varlığını korumak için.{" "}
                    </li>
                  </ul>

                  <h3 id="when-personal-information-shared">
                    Kişisel bilgileri ne zaman paylaşırız?
                  </h3>
                  <p>
                    Thomson Reuters, aşağıda açıklandığı gibi Hizmetleri
                    sağlamamız veya iş operasyonlarını yürütmemiz gerektiğinde
                    kişisel bilgileri paylaşır veya ifşa eder. Kişisel bilgileri
                    yalnızca veri gizliliğine ve güvenlik gerekliliklerine uygun
                    şekilde paylaşırız. Bazı durumlarda üçüncü taraflarla
                    kişisel olmayan, anonim ve istatistiksel veriler
                    paylaşabiliriz. Kişisel bilgileri paylaşabileceğimiz
                    taraflar ve paylaşma nedenleri aşağıda verilmiştir.{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>Thomson Reuters Bünyesinde:</strong> Dünya
                      çapındaki işletmelerimiz çeşitli Thomson Reuters ekipleri
                      ve işlevleriyle desteklenir ve örneğin Hizmetlerin
                      karşılanması, hesap yönetimi, satış ve pazarlama, müşteri
                      desteği ve teknik destek, iş ve ürün gelişimi için gerekli
                      görüldüğünde kişisel bilgiler bu ekiplerin kullanımına
                      sunulur. Tüm çalışanlarımız ve yüklenicilerimiz, kişisel
                      bilgileri kullanırken veri gizliliğimize ve güvenlik
                      politikalarımıza uymak zorundadır. Kişisel verileri
                      işleyebileceğimiz Thomson Reuters konumlarının bir
                      listesini görmek için lütfen{" "}
                      <a href="#where-personal-information-stored">buraya</a>{" "}
                      tıklayın.{" "}
                    </li>
                    <li>
                      <strong>İş Ortaklarımız:</strong> Ortak markalı Hizmetler
                      sunmak, içerik sağlamak veya etkinlikler, konferanslar ve
                      seminerler düzenlemek için zaman zaman diğer kuruluşlarla
                      ortaklık yaparız. Bu düzenlemelerin bir parçası olarak hem
                      Thomson Reuters’ın hem de ortağımızın müşterisi
                      olabilirsiniz ve biz ve ortağımız, sizle ilgili bilgileri
                      toplayabilir ve paylaşabiliriz. Thomson Reuters, kişisel
                      bilgileri bu Bildirime uygun olarak kullanacaktır;
                      ortaklarımızın kişisel bilgileri nasıl topladığı,
                      kullandığı ve paylaştığı hakkında daha çok bilgi edinmek
                      için onların gizlilik bildirimlerini incelemenizi tavsiye
                      ederiz.{" "}
                    </li>
                    <li>
                      <strong>Üçüncü Taraf Hizmet Sağlayıcılarımız:</strong>{" "}
                      Dünya çapındaki hizmet sağlayıcıları tarafından
                      desteklenir ve onlarla ortaklık kurarız. Yalnızca
                      tarafımıza sağladıkları yazılım, sistem ve platform
                      desteği, doğrudan pazarlama, bulut barındırma,
                      reklamcılık, veri analizi, sipariş işleme ve teslimatı
                      gibi hizmetlerin karşılanması için gerekli olduğunda
                      kişisel bilgiler bu üçüncü tarafların kullanımına sunulur.
                      Üçüncü taraf hizmet sağlayıcılarımız, bize hizmet sağlamak
                      dışında hiçbir amaç için kullanımlarına sunduğumuz kişisel
                      bilgileri paylaşamaz veya kullanamazlar.{" "}
                    </li>
                    <li>
                      <strong>Yasal Gerekçeler için Üçüncü Taraflar:</strong>{" "}
                      Aşağıdaki gibi gerekli olduğunu düşündüğümüz durumlarda
                      kişisel bilgileri paylaşırız:
                      <ul>
                        <li>
                          Emniyet teşkilatları ve ikamet ettiğiniz ülke dışından
                          kurumları da içerebilen diğer kamu kuruluşları da
                          dahil olmak üzere hükümet kurumlarından gelen yasal
                          zorunluluklara uymak ve isteklere yanıt vermek için.
                        </li>
                        <li>
                          Birleşme, satış, yeniden yapılanma, şirket alımı,
                          ortak girişim, devretme, aktarma gibi işlemler veya
                          işletmemizin, varlıklarımızın ya da hisselerimizin
                          tamamını veya bir kısmını kapsayan başka herhangi bir
                          el değiştirme söz konusu olduğunda
                        </li>
                        <li>
                          Haklarımızı, kullanıcılarımızı, sistemlerimizi ve
                          Hizmetlerimizi korumak için.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>Elektronik İşe alım ortakları:</strong> Bir iş
                      başvurusu yapmak için elektronik İşe Alım Hizmetlerimizin
                      kullanıcısı olarak kayıt olursanız verdiğiniz tüm hassas
                      kişisel bilgiler de dahil olmak üzere kişisel
                      bilgileriniz, iş başvurusu yaptığınız kurum ile
                      paylaşılır. Bir başvurunun tam olarak değerlendirilmesi
                      için kişisel bilgileriniz bir kurumun küresel şubelerine
                      yönlendirilebilir.
                    </li>
                  </ul>
                  <h3 id="where-personal-information-stored">
                    Kişisel bilgileri nerede saklar ve işleriz?
                  </h3>
                  <p>
                    Thomson Reuters küresel bir şirkettir ve kişisel
                    bilgileriniz kendi ülkenizin dışında saklanabilir ve
                    işlenebilir. Topladığımız bilgilerin bu Gizlilik Bildirimine
                    ve verilerin bulunduğu yerdeki geçerli yasaların
                    gerekliliklerine uygun şekilde işlendiğinden emin olmak için
                    gerekli adımları uygularız.{" "}
                  </p>
                  <p>
                    Thomson Reuters’ın dünyanın dört bir yanındaki ofislerinde
                    ağları, veri tabanları, sunucuları, sistemleri ve destek ve
                    yardım masaları bulunur. İş gücü ihtiyacımızı, işletmemizin
                    ve müşterilerimizin ihtiyaçlarını karşılamak için dünyanın
                    dört bir yanındaki bulut barındırma hizmetleri, tedarikçiler
                    ve teknoloji desteği kuruluşları gibi üçüncü taraflarla iş
                    birliği yaparız. Kişisel bilgilerin geçerli yasalara uygun
                    şekilde işlendiğinden, güvence altına alındığından ve
                    aktarıldığından emin olmak için gerekli adımları uygularız.
                    Bazı durumlarda kişisel bilgilerinizi Thomson Reuters
                    içerisinde veya ikamet ettiğiniz ülke dışında bir bölgede
                    bulunan üçüncü taraflara ifşa etmemiz veya aktarmamız
                    gerekebilir. Bu alıcıların bulunduğu bölgeler değişkenlik
                    gösterebilir ancak Birleşik Devletler, Avrupa, Kanada, Asya,
                    Avustralya, Hindistan ve Thomson Reuters’ın bulunduğu veya
                    yükleniciler aracılığıyla çalıştığı diğer ülkeleri
                    içerebilir. Thomson Reuters ofislerinin bir listesi için
                    aşağıda bulunan “
                    <a href="#contact-us">Bize Ulaşın</a>
                    ” bölümüne başvurun.
                  </p>

                  <p>
                    Avrupa Ekonomik Alanı’ndan, geçerli yasaların sizin
                    ülkenizdekiyle aynı düzeyde veri gizliliği koruması
                    sunmadığı başka bir ülkeye kişisel verileri aktarırken uygun
                    düzeyde veri gizliliği koruması sağlamak için önlemler
                    alırız. Başka bir deyişle haklarınız ve korumalarınız
                    verilerinizi de kapsar. Kişisel bilgilerinizi alan kişilerin
                    bilgilerinizi koruduğundan emin olmak için onaylı sözleşme
                    maddeleri, çok taraflı veri aktarımı anlaşmaları ve grup içi
                    anlaşmalar kullanır ve başka önlemler alırız. Veri aktarımı
                    uygulamalarımızla ilgili daha fazla bilgi edinmek için
                    lütfen <a href="#contact-us">Veri Koruma Görevlimiz</a> ile
                    iletişime geçin.
                  </p>

                  <h3 id="how-personal-information-secured">
                    Kişisel bilgileri nasıl güvende tutarız?{" "}
                  </h3>
                  <p>
                    Thomson Reuters, veri koruma işini ciddiye alır ve kişisel
                    bilgileri korumak için uygun teknolojileri ve prosedürleri
                    kullanır. Bilgi güvenliği politikalarımız ve
                    prosedürlerimiz, yaygın olarak kabul gören uluslararası
                    standartlarla çok benzerdir. Teknoloji ile düzenleyici
                    gereksinimlerdeki değişiklikleri ve iş ihtiyaçlarımızı
                    karşılamak üzere düzenli olarak gözden geçirilir ve
                    gerektiğinde güncellenir.{" "}
                  </p>
                  <p>Örneğin:</p>
                  <ul>
                    <li>
                      İlkeler ve Prosedürler
                      <ul>
                        <li>
                          Verilerin kazara kaybolması ve yetkisiz erişilmesi,
                          kullanılması, imha edilmesi veya ifşa edilmesine
                          karşın önlemlerimiz bulunmaktadır
                        </li>
                        <li>
                          Hizmetlerimizin sürekliliğini garanti altına almak ve
                          kullanıcılarımız ile varlıklarımızı korumak üzere
                          tasarlanmış bir İş Sürekliliği ve Felaket Yönetimi
                          stratejimiz vardır
                        </li>
                        <li>
                          Kişisel bilgilere erişim için uygun kısıtlamalar
                          uygularız{" "}
                        </li>
                        <li>
                          Gözlemleme ve fiziksel önlemler de dahil olmak üzere
                          verilerin güvenli bir şekilde saklanması ve
                          aktarılması için uygun önlemleri alır ve kontrolleri
                          gerçekleştiririz
                        </li>
                        <li>
                          Yasal gerekliliklere ve iş prensiplerimize uygun
                          şekilde Gizlilik Etkisi Değerlendirmelerini uygularız{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      Çalışanlar ve Yükleniciler için Eğitim
                      <ul>
                        <li>
                          Kişisel bilgilere ve diğer hassas verilere erişimi
                          olan çalışanlarımızın ve yüklenicilerimizin düzenli
                          olarak gizlilik, bilgi güvenliği ve diğer
                          uygulanabilir eğitimleri almasını zorunlu kılarız
                        </li>
                        <li>
                          Çalışanlarımızın ve yüklenicilerimizin bilgi güvenliği
                          politikalarımıza, prosedürlerimize ve diğer tüm
                          geçerli sözleşme koşullarına uygun olarak
                          çalıştığından emin olmak için gerekli adımları
                          uygularız{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      Satıcı Risk Yönetimi
                      <ul>
                        <li>
                          Sözleşmeler ve güvenlik incelemeleri aracılığıyla
                          üçüncü taraf satıcılarımız ve sağlayıcılarımızın
                          onlara verilen tüm kişisel bilgileri güvenlik
                          politikalarımıza ve prosedürlerimize uygun olarak
                          korumasını zorunlu kılarız{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <h3 id="time-personal-information-kept">
                    Kişisel bilgileri ne kadar süre saklarız?
                  </h3>
                  <p>
                    Thomson Reuters, kişisel bilgilerin tutulmasına ilişkin
                    politikaların ve kuralların uygulanması için gizli bilgileri
                    saklayan resmi kuruluşlarla birlikte çalışan bir Kayıt
                    Yönetimi ekibine sahiptir. Kurumsal Kayıt Tutma Planımız; İş
                    Fonksiyonları, Kayıt Sınıfları ve Kayıt Türlerinden oluşan
                    bir sınıflandırma şeması üzerine kuruludur. Kişisel
                    bilgileri, yasal veya işle ilgili amaçlar için makul
                    çerçevede, ihtiyacımız olduğu sürece tutarız. Thomson
                    Reuters, veri tutma sürelerini belirlerken yerel yasaları,
                    sözleşme yükümlülüklerini ve müşterilerimizin beklentileri
                    ile gereksinimlerini göz önünde bulundurur. Kişisel
                    bilgilere ihtiyacımız kalmadığında bu bilgileri kesin olarak
                    siler veya imha ederiz.{" "}
                  </p>
                  <h3 id="personal-information-access">
                    Kişisel bilgilerinize erişme ve düzeltme hakkınız
                  </h3>
                  <p>
                    Bilgilerinize erişme ve bilgilerinizi kontrol etme hakkınıza
                    saygı duyduğumuz için bilgi isteklerine cevap verir ve
                    gerektiğinde kişisel bilgilerinizi düzeltir, değiştirir veya
                    sileriz.{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>Kişisel Bilgilere Erişim: </strong> Kişisel
                      bilgilerinize erişim hakkı talep ettiğinizde kimlik
                      doğrulama prosedürleri de dahil olmak üzere tüm ilgili
                      yasal gerekliliklere ve muafiyetlere uygun şekilde talebi
                      yerine getiririz. Verileri tarafınıza sağlamadan önce tüm
                      ilgili verilerin yerini bulabilmek için kimlik
                      doğrulamasının yanı sıra bizimle olan etkileşiminizle
                      ilgili bazı bilgiler isteriz. Ayrıca verilerinizin bir
                      kopyasını size sağladığımız için bir ücret alabiliriz
                      (yerel yasaların buna izin vermediği yerler hariç).{" "}
                    </li>
                    <li>
                      <strong>Düzeltme ve Silme:</strong> Bazı yetki alanlarında
                      hatalı veya güncelleme gerektiren kişisel bilgilerinizi
                      düzeltme veya değiştirme hakkınız vardır. Aynı zamanda
                      kişisel bilgilerinizin silinmesini talep edebilirsiniz
                      ancak bu işlem yasal gereklilikler, diğer yükümlülükler ve
                      faktörler nedeniyle her zaman mümkün olmayabilir. İlgili
                      Hizmet içerisindeki “Bize Ulaşın” seçeneğini kullanarak
                      hesap bilgilerinizi güncelleyebileceğinizi unutmayın.
                    </li>
                    <li>
                      <strong>Pazarlama Tercihleri:</strong> E-posta pazarlama
                      listesinden çıkmak için bizden gelen e-postadaki
                      abonelikten çıkma bağlantısını kullanabilir veya geçerli
                      e-posta tercih merkezini ziyaret edebilirsiniz. Diğer
                      pazarlama tercihleri için ilgili Hizmetin içindeki “Bize
                      Ulaşın” seçeneğini kullanın. Çerezleri kontrol etmeye
                      yönelik bilgilere <a href="#cookies">buradan</a>, İlgi
                      Alanına Dayalı ve Mobil Reklamcılığa{" "}
                      <a href="#advertising">buradan</a> ulaşabilirsiniz.
                    </li>
                    <li>
                      <strong>Şikayette Bulunma:</strong> Thomson Reuters’ın
                      kişisel verilerinizi yönetme şeklinden memnun değilseniz
                      bir veri koruma düzenleyici kurumuna şikayet etme hakkınız
                      vardır. Ulusal Veri Koruma Kurumlarının bir listesine
                      buradan ulaşabilirsiniz:{" "}
                      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                      </a>.
                    </li>
                  </ul>
                  <p>
                    Lütfen kişisel bilgilerinizle ilgili tüm istekleriniz için{" "}
                    <a href="#contact-us">Veri Koruma Görevlimiz</a> ile
                    iletişime geçin. PeopleMap ile ve Westlaw ve CLEAR’daki
                    Devlet Arşivleri ile ilgili istekler için lütfen{" "}
                    <a href="https://legalsolutions.thomsonreuters.com/law-products/about/legal-notices/privacy/records">
                      buraya
                    </a>{" "}
                    tıklayın.
                  </p>

                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>
                  <h2 id="cookies">Çerezler ve benzer teknolojiler</h2>
                  <p>
                    Thomson Reuters ve üçüncü taraf sağlayıcıları; kullanıcı
                    tercihlerini kaydetmek ve yönetmek, hedefe yönelik reklam
                    göstermek, içerikleri sağlamak ve analitik veriler ile
                    kullanım verilerini toplamak için çerezleri ve benzer
                    teknolojileri kullanır. Uygulamalardaki, web sitelerindeki
                    veya diğer hizmetlerdeki çevrimiçi etkinliğiniz hakkında
                    bilgi toplayan web siteleri ve uygulamalarda, çerezlerin ve
                    diğer izleme teknolojilerinin kullanılması standart
                    uygulamadır. Aşağıda çerezleri ve benzer teknolojileri nasıl
                    kullandığımıza ve bunları{" "}
                    <a href="#managing-cookies">
                      nasıl kontrol edip yönetebileceğinize
                    </a>{" "}
                    ilişkin daha çok bilgi bulabilirsiniz.
                  </p>
                  <h3 id="what-is-a-cookie">Çerez nedir?</h3>
                  <p>
                    Çerezler, kullanıcıyı veya cihazı tespit etmek ve bilgi
                    toplamak için bir bilgisayara veya başka bir cihaza
                    yerleştirilen küçük metin belgeleridir. Çerezler,
                    işlevlerine ve kullanım amaçlarına göre dört kategoriye
                    ayrılır: Kesinlikle gerekli çerezler, performans çerezleri,
                    işlevsel çerezler ve pazarlama amaçlı çerezler.
                  </p>
                  <h3 id="cookie-types">
                    Çerez türleri ve çerezleri kullanma nedenimiz:
                  </h3>
                  <ul>
                    <li>
                      <strong>Kesinlikle Gerekli Çerezler: </strong> Bu
                      çerezler, bir web sitesinde gezinebilmenizi ve
                      özelliklerini kullanabilmenizi sağlamak için gereken
                      çerezlerdir. Bu çerezler olmadan, istediğiniz hizmetler
                      (çevrimiçi bir alışveriş sepetine ürün eklemek gibi)
                      sağlanamaz.
                    </li>
                    <li>
                      <strong>Performans Çerezleri:</strong> Bu çerezler, web
                      sitelerimizi nasıl kullandığınızla ilgili bilgiler toplar.
                      Toplanan bilgiler arasında (örneğin kullanılan internet
                      tarayıcıları ve işletim sistemleri) daha önce ziyaret
                      edilen web sitenin etki alanı adı, ziyaret etme sayısı,
                      ortalama ziyaret süresi ve görüntülenen sayfalar yer alır.
                      Bu çerezler sizi kişisel olarak tespit eden bilgileri
                      değil, yalnızca kümelenmiş ve anonim bilgileri toplar.
                      Performans çerezleri bir web sitesini daha kullanıcı dostu
                      hale getirmek ve deneyiminizi iyileştirmek için
                      kullanılır.
                    </li>
                    <li>
                      <strong>İşlevsellik Çerezleri: </strong> Bu çerezler, web
                      sitesinin yaptığınız seçimleri (kullanıcı adınız veya
                      kimliğiniz, dil seçiminiz veya bulunduğunuz bölge gibi)
                      hatırlamasına olanak tanır ve daha gelişmiş, kişisel
                      özellikler sağlar. Bu çerezler aynı zamanda metin boyutu,
                      yazı tipi ve web sayfalarının diğer özelleştirilebilir
                      bölümlerinde yaptığınız değişiklikleri hatırlamak için de
                      kullanılır. Ayrıca bir video izlemek veya bir blog’a yorum
                      yapmak gibi istediğiniz hizmetleri sağlamak için de
                      kullanılır. Bu çerezlerin topladığı bilgiler anonim hale
                      getirilebilir ve çerezler diğer web sitelerindeki gözatma
                      etkinliğinizi takip edemezler.{" "}
                    </li>
                    <li>
                      <strong>
                        Hedefe Yönelik Çerezler ve Reklam Çerezleri:
                      </strong>{" "}
                      Bu çerezler gözatma alışkanlıklarınızı izler ve hedefe
                      yönelik (ilgi alanına dayalı) reklam sunmak için
                      kullanılır. Aynı zamanda bir reklamı görme sayınızı
                      kısıtlamak ve reklam kampanyalarının verimliliğini ölçmek
                      için kullanılır. Genelde web sitesi operatörünün izniyle
                      reklam ağları tarafından yerleştirilir. Bir web sitesini
                      ziyaret ettiğinizi hatırlar ve bu bilgileri reklamcılar
                      gibi diğer kurumlarla paylaşılır.
                    </li>
                  </ul>
                  <h3 id="managing-cookies">Çerezleri yönetme</h3>
                  <p>
                    Web sitesi çerezlerini tarayıcı ayarlarınızdan
                    yönetebilirsiniz ve her zaman çerezleri kabul ederek,
                    reddederek veya silerek bu ayarları değiştirme seçeneğiniz
                    vardır. Ayarlarınızı değiştirmek isterseniz Hizmetlerdeki
                    bazı işlevlerin ve özelliklerin istenilen şekilde
                    çalışmadığını görebilirsiniz. Tüm tarayıcıların ayarları
                    biraz farklıdır; bu yüzden çerezleri yönetmek için
                    tarayıcınızdaki ilgili ayarlara başvurmalısınız.{" "}
                  </p>
                  <p>
                    Çerezler hakkında daha fazlasını öğrenmek isteyebileceğinizi
                    anlıyoruz. Çerez türleri, nasıl kullanıldıkları ve çerez
                    tercihlerinin yönetilmesi hakkında ayrıntılı bilgi
                    edinebileceğiniz bazı faydalı kaynaklar şunlardır:{" "}
                    <a href="https://www.aboutcookies.org">
                      www.aboutcookies.org
                    </a>{" "}
                    veya{" "}
                    <a href="https://www.allaboutcookies.org">
                      www.allaboutcookies.org
                    </a>. Yaygın olarak kullanılan tarayıcılarda çerezlerin
                    nasıl devre dışı bırakıldığı ve silindiğiyle ilgili
                    ayrıntılı bilgi almak için lütfen aşağıdaki bağlantılara
                    tıklayın:
                  </p>
                  <ul>
                    <li>
                      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                        Mozilla Firefox®
                      </a>
                    </li>
                    <li>
                      <a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">
                        Microsoft® Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=en">
                        Google Chrome™
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&locale=de_DE">
                        Safari®
                      </a>
                    </li>
                  </ul>

                  <h3 id="other-tracking-tech">Diğer izleme teknolojileri:</h3>
                  <ul>
                    <li>
                      <strong>
                        Yerel Paylaşılan Nesneler/Flash çerezler:{" "}
                      </strong>{" "}
                      Yerel paylaşılan nesneler olarak da bilinen flash
                      çerezler, Adobe Flash tarafından desteklenen tarayıcı
                      içeriklerini desteklemek üzere tasarlanmıştır. Genelde web
                      sitelerinde reklamları ve video içeriklerini
                      etkinleştirmek için kullanılır. Diğer çerezler gibi
                      cihazınızda bilgi saklar ve bu bilgilerin bazıları Flash
                      destekleyen içeriklere özel olur. Flash çerezler,
                      tarayıcınız üzerinden değil yalnızca Adobe Flash
                      içerisinden silinebilir. Lütfen gizlilik ayarlarınızı
                      yönetme ve Flash çerezlerin silinmesiyle ilgili bilgi
                      almak için aşağıdaki yardım sayfasına başvurun:{" "}
                      <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                        https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
                      </a>
                    </li>
                    <li>
                      <strong>Web işaretçileri:</strong> Web sayfalarımız;
                      sitelerimizde çerezleri sunmak, bu siteleri ziyaret eden
                      kullanıcıları saymak, hizmetleri sunmak ve
                      kampanyalarımızın verimliliğini analiz etmek için
                      kullandığımız, web işaretçileri olarak bilinen elektronik
                      görüntüleri (tek pikselli gif’ler ve saydam grafik
                      görüntüleri olarak da bilinir) barındırabilir. Bir
                      e-postanın açılıp açılmadığını veya bağlantıların tıklanıp
                      tıklanmadığını belirlemek için web işaretçilerini
                      pazarlama e-postalarımızda veya haber bültenlerimizde de
                      kullanabiliriz.
                    </li>
                    <li>
                      <strong>Web sunucusu ve uygulama günlükleri: </strong>{" "}
                      Sunucularımız; Hizmetlerin korunması, kullanımın analiz
                      edilmesi ve kullanıcı deneyiminin iyileştirilmesi için
                      bazı bilgileri otomatik olarak toplar. Toplanan bu
                      bilgiler aşağıdakileri de içerir:
                      <ul>
                        <li>IP adresi ve tarayıcı tipi</li>
                        <li>
                          Eşsiz Cihaz Tanımlayıcı (UDID), MAC adresi, Reklam
                          Tanımlayıcısı (IFA) gibi cihaz bilgileri ve bizim veya
                          başkalarının atayabileceği benzer tanımlayıcılar
                        </li>
                        <li>
                          Cihaz işletim sistemi ve diğer teknik özellikler{" "}
                        </li>
                        <li>
                          Web sitemize giriş yaptığınız şehir, eyalet ve ülke
                        </li>
                        <li>
                          Ziyaret edilen sayfalar ve görüntülenen, kaydedilen ve
                          satın alınan içerikler{" "}
                        </li>
                        <li>Girilen bilgiler veya metin</li>
                        <li>Tıklanan bağlantılar ve düğmeler </li>
                        <li>
                          Hizmetlerimizi kullanmadan önce ve sonra ziyaret
                          ettiğiniz URL’ler
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>

                  <h2 id="advertising">
                    İlgi alanına dayalı reklamcılık (Interest based advertising)
                    (IBA){" "}
                  </h2>
                  <p>
                    İlgi alanına dayalı reklamcılık (IBA), Hizmetlerimizin
                    kullanıcılarına hedefe yönelik reklamlar sunmamıza imkan
                    tanır. IBA, size eriştiğiniz veya okuduğunuz içeriklerin
                    türüne bağlı olarak reklam gösterir. Örneğin Hizmetlerimize
                    göz atarken cihazınıza bir reklam çerezi yerleştirilir ve
                    bu, ilgilendiğiniz sayfaları veya içerikleri daha iyi
                    anlamamızı sağlar. Cihazınız hakkında topladığımız bilgiler,
                    sizi benzer ilgi alanlarına sahip diğer cihazlarla
                    gruplamamızı sağlar. Bu sayede reklamları, ortak ilgi
                    alanlarına göre gruplara ayrılan kullanıcı kategorilerine
                    gösterebiliriz. IBA hakkında daha fazla bilgi edinmek için
                    lütfen şu adresi ziyaret edin:{" "}
                    <a href="https://www.iab.net/public_policy/behavioral-advertisingprinciples">
                      https://www.iab.net/public_policy/behavioral-advertisingprinciples
                    </a>.
                  </p>

                  <h3 id="opting-out-iba">IBA’dan çıkış:</h3>
                  <p>
                    İlgi alanına dayalı reklamcılıktan çıkmak isterseniz bu,
                    Hizmetlerimizi kullanırken artık reklam görmeyeceğiniz
                    anlamına gelmez. Bu yalnızca hakkınızda toplanan bilgileri
                    IBA için kullanmayacağımız ve göreceğiniz hiçbir reklamın
                    özelleştirilmiş veya sizinle ilişkili olmayacağı anlamına
                    gelir. Çevrimiçi reklamcılık seçimlerinizi{" "}
                    <a href="https://www.aboutads.info/choices/">buraya</a> veya
                    bir reklamdaki AdChoices simgesine tıklayıp talimatları
                    takip ederek yönetebilirsiniz. Ayrıca (<a href="https://www.networkadvertising.org/choices">
                      https://www.networkadvertising.org/choices
                    </a>) ve AB’de{" "}
                    <a href="https://www.youronlinechoices.com/">
                      https://www.youronlinechoices.com/
                    </a>{" "}
                    adresini ziyaret ederek Ağ Reklamcılığı Girişimi’nin (NAI)
                    Opt Out Aracı ile birçok site için ilgi alanına dayalı
                    reklam almayı durdurabilirsiniz. Çerezleri siler, farklı bir
                    cihaz kullanır veya web tarayıcısını değiştirirseniz tekrar
                    çıkmanız gerekebileceğini unutmayın.
                  </p>

                  <h3 id="mobile-advertising">Mobil cihazlarda reklamcılık</h3>
                  <p>
                    Mobil cihazlarda, şirketlerin belirli bir mobil cihaza
                    hedefe yönelik reklamlar sunmasına olanak tanıyan bir
                    tanımlayıcı bulunmaktadır. Çoğu durumda, istediğiniz zaman
                    mobil cihazınızın gizlilik ayarlarından mobil cihaz reklam
                    takibi özelliğini kapatabilir veya reklam tanıtıcısını
                    sıfırlayabilirsiniz. Mobil cihazınızda reklamları kontrol
                    etmek için kullanabileceğiniz diğer bir araç AppChoices
                    Uygulamasıdır:{" "}
                    <a href="https://youradchoices.com/appchoices">
                      https://youradchoices.com/appchoices
                    </a>. Mobil cihazınızda konum takibi özelliğini de
                    kapatabilirsiniz. Reklam takibi veya konum takibi özelliğini
                    kapatırsanız cihazınızın reklam tanımlayıcısından toplanan
                    bilgileri reklam amaçları doğrultusunda kullanmayız. Yine de
                    aynı sayıda reklam görebilirsiniz ancak bu reklamlar ilgi
                    alanınıza dayalı olmayacağı için daha alakasız olabilir.{" "}
                  </p>

                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>

                  <h2 id="dnt">Beni izleme (Do not track) </h2>
                  <p>
                    Bazı tarayıcılar web sitelerine Beni izleme (DNT) sinyalleri
                    iletir. Sektörde DNT sinyallerinin ortak bir yorumlama şekli
                    olmamasından dolayı Thomson Reuters, halihazırda bu
                    tarayıcılardan gelen DNT isteklerini değiştirmez,
                    dönüştürmez veya bu isteklere yanıt vermez. Bu alandaki
                    sektör hareketliliğini izlemeye devam edeceğiz ve
                    gerektiğinde DNT uygulamalarımızı tekrar değerlendireceğiz.
                  </p>
                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>

                  <h2 id="social-networks">
                    Sosyal ağlar aracılığıyla bağlanma
                  </h2>
                  <p>
                    Hizmetlerimizin bazıları Facebook “Beğen” düğmesi, “Paylaş”
                    düğmesi, pencere öğeleri ve interaktif küçük programlar gibi
                    sosyal ağ özellikleri içerebilir. Ek olarak bazı
                    Hizmetlerimize giriş yapmak için Facebook veya LinkedIn gibi
                    kendi sosyal ağ hesaplarınızı kullanmayı seçebilirsiniz. Bir
                    sosyal ağ sitesi veya benzer bir hizmet kullanarak
                    bağlanmayı seçerseniz giriş yapmanızı sağlamak için o
                    hizmetten doğrulama bilgilerini ve bu hizmetlerle
                    bağlandığınızda paylaşmayı seçtiğiniz diğer bilgileri
                    alabilir ve kaydedebiliriz. Bu hizmetler, ziyaret ettiğiniz
                    web sayfaları ve IP adresiniz gibi bilgileri toplayabilir ve
                    işlevlerin düzgün çalışmasını sağlamak için çerezler
                    kurabilir. Bu üçüncü tarafların topladığı herhangi bir
                    bilginin güvenliğinden veya gizliliğinden sorumlu değiliz.
                    Bağlandığınız, kullandığınız veya eriştiğiniz üçüncü taraf
                    hizmetleri için geçerli olan gizlilik bildirimlerini veya
                    politikalarını sizin incelemeniz gerekir. Kişisel
                    bilgilerinizin, sosyal medya hesap sağlayıcınız veya sosyal
                    medya hizmetinin diğer kullanıcıları ile paylaşılmasını
                    istemiyorsanız lütfen Hizmetler hesabınızı sosyal medya
                    hesabınızla bağlamayın ve Hizmetlerde sosyal paylaşıma
                    katılmayın.{" "}
                  </p>

                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>

                  <h2 id="third-party-services">
                    Üçüncü taraf hizmetlerine giden bağlantılar
                  </h2>
                  <p>
                    Hizmetlerimiz, bizimle bağlı olmayan, tarafımızdan kontrol
                    edilmeyen veya yönetilmeyen üçüncü taraf uygulamaları,
                    hizmetleri, araçları ve web sitelerine giden bağlantılar
                    içerebilir ve bunlarla birlikte kullanılabilir. Facebook,
                    LinkedIn, Twitter ve ses yazılımı ile okuyucular gibi üçüncü
                    taraf uygulamaları bunun örnekleri arasındadır. Üçüncü
                    tarafların gizlilik uygulamaları, tarafların kendi Gizlilik
                    Bildirimleri tarafından yönetilecektir. Bu üçüncü tarafların
                    topladığı herhangi bir bilginin güvenliğinden veya
                    gizliliğinden sorumlu değiliz. Bu üçüncü taraf hizmetleri
                    için geçerli olan gizlilik bildirimlerini veya
                    politikalarını sizin incelemeniz gerekir.
                  </p>
                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>

                  <h2 id="children-privacy">Çocukların gizliliği</h2>
                  <p>
                    Thomson Reuters, profesyonellere bilgi çözümleri sunar ve
                    Hizmetlerimiz genelde çocuklara yönelik değildir. Ancak
                    örneğin bir eğitim kaynağı geliştirmek için çocuklar
                    hakkında bilgi toplar ve kullanırsak sektör ilkelerine ve
                    geçerli yasalara uyarız.{" "}
                  </p>
                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>

                  <h2 id="contact-us">Bize ulaşın</h2>
                  <p>
                    Bu Bildirim veya gizlilik uygulamalarımız hakkında
                    sorularınız veya endişeleriniz olabileceğini veya şikayette
                    bulunmak isteyebileceğinizi anlıyoruz. Lütfen aşağıdaki
                    yöntemlerden birini kullanarak bizimle iletişime geçin:
                  </p>
                  <h3>Thomson Reuters Veri Koruma Görevlisi:</h3>
                </RichText>
                <MarkupContainer>
                  <HorizontalOverflow>
                    <Table>
                      <table>
                        <tbody>
                          <tr>
                            <td>E-posta:</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="mailto:Privacy.enquiries@thomsonreuters.com">
                                Privacy.enquiries@thomsonreuters.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>Adres:</td>
                          </tr>
                          <tr>
                            <td>
                              Attn: Data Protection Officer <br /> Thomson
                              Reuters <br /> D5 S1866 <br /> 610 Opperman Dr.{" "}
                              <br /> Eagan, MN 55123 <br /> USA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Table>
                  </HorizontalOverflow>
                </MarkupContainer>
                <RichText>
                  <h3>Pazarlama tercihleri ve hizmet desteği: </h3>
                  <p>
                    Hesap bilgilerinizi ve e-posta pazarlama tercihlerinizi
                    güncellemek veya teknik destek ya da Hizmetlerinizle ilgili
                    herhangi bir yardım almak için lütfen geçerli e-posta tercih
                    merkezini ziyaret edin veya ilgili Hizmetteki “Bize Ulaşın”
                    seçeneğini kullanın.
                  </p>
                  <p>
                    <a href="https://thomsonreuters.com/en/locations.html">
                      Thomson Reuters konumlarının listesi için lütfen aşağıdaki
                      bağlantıya tıklayın:{" "}
                    </a>
                  </p>
                  <a href="#top">
                    <strong>Başa dön</strong>
                  </a>
                </RichText>
              </Grid.Item>
            </Grid>
          </Section>
        </Language>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default PrivacyTr;
