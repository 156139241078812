import React from "react";
import Filter from "./Filter";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  // NB you can't and shouldn't ever need to rehydrate functions
  const props = {
    isRehydrating: true,
    applyFilterButtonLabel: readData("button-label"),
    disableFilter: readData("disable-filter"),
    dropdownKeys: readData("dropdown-keys"),
    filterCloseLabel: readData("filter-close-label"),
    filterLabel: readData("filter-label"),
    filterOpenLabel: readData("filter-open-label"),
    gridProps: readData("grid-props"),
    header: readData("header"),
    noSavedFiltersLabel: readData("no-saved-filters-label"),
    savedFilters: readData("saved-filters"),
    saveFilterChecked: readData("save-filter-checked"),
    showSaveFilter: readData("show-save-filter"),
    filtersHeader: readData("filters-header")
  };

  return <Filter {...props}>{reactifiedChildren}</Filter>;
};
