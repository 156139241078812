import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import NavList from "./NavList";

export default async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("SiteFooterNavList");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "inner" })}`
  );

  const reactifiedChildren = childrenNode
    ? await rehydrateChildren(childrenNode)
    : null;

  const rehydratedProps = {
    isRehydrating: true,
    title: domNode.getAttribute("data-title")
  };

  return <NavList {...rehydratedProps}>{reactifiedChildren}</NavList>;
};
