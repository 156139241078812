import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import FilterSectionOverflow from "./FilterSectionOverflow";

const rehydrator = async (domNode, rehydrateChildren, extra) => {
  const getClassName = getClassNameFactory(
    "SearchFiltersFilterSectionOverflow"
  );

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "items" })}`
  );

  const reactifiedChildren = await rehydrateChildren(childrenNode, extra);

  const rehydratedProps = {
    collapseText: domNode.getAttribute("data-collapse-text"),
    expandText: domNode.getAttribute("data-expand-text")
  };

  return (
    <FilterSectionOverflow {...rehydratedProps}>
      {reactifiedChildren}
    </FilterSectionOverflow>
  );
};

export default rehydrator;
