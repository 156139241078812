import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const RadioControl = ({ defaultValue, disabled, name, options, value }) => {
  const getClassName = getClassNameFactory(RadioControl.displayName);

  return (
    <div className={getClassName()}>
      {options.map(option => {
        const props = {};

        if (value) {
          // Controlled mode
          props.checked = option.value === value;
        }

        if (defaultValue) {
          // Uncontrolled mode
          props.defaultChecked = option.value === defaultValue;
        }

        return (
          <label
            className={getClassName({ descendantName: "label" })}
            htmlFor={option.value}
            key={option.value}
          >
            <input
              className={getClassName({ descendantName: "input" })}
              disabled={disabled}
              id={option.value}
              name={name}
              onChange={option.onChange}
              type="radio"
              value={option.value}
              {...props}
            />
            <span className={getClassName({ descendantName: "labelText" })}>
              {option.label}
            </span>
          </label>
        );
      })}
    </div>
  );
};

RadioControl.displayName = "Form.RadioControl";

RadioControl.propTypes = {
  /**
   * Initialize with selected <input>
   */
  defaultValue: PropTypes.string,
  /**
   * Disable all <input> elements
   */
  disabled: PropTypes.bool,
  /**
   * Name attribute for <input> elements
   */
  name: PropTypes.string.isRequired,
  /**
   * Array of objects with `label` and `value` properties
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  /**
   * Explicitly set an input as selected. Will switch into controlled mode.
   */
  value: PropTypes.string
};

export default RadioControl;
