import callEach from "./utilities/callEach";

export default class LoginManager {
  constructor(service) {
    this.onChangeCallbacks = [];

    this.service = service;

    this.service.onChange(() => {
      callEach(this.onChangeCallbacks, this);
    });
  }

  // Event handlers

  onChange(cb) {
    this.onChangeCallbacks.push(cb);
  }

  offChange(cb) {
    if (this.onChangeCallbacks.includes(cb)) {
      this.onChangeCallbacks.splice(this.onChangeCallbacks.indexOf(cb), 1);

      return true;
    }

    return false;
  }

  // Class methods

  login() {
    this.service.login();
  }

  async logout() {
    await this.service.logout();
  }

  // Class properties

  get accountOverviewUrl() {
    return this.service.accountOverviewUrl;
  }

  get authState() {
    return this.service.authState;
  }

  get cartUrl() {
    return this.service.cartUrl;
  }

  get itemsInCart() {
    return this.service.itemsInCart;
  }

  get logoutUrl() {
    return this.service.logoutUrl;
  }

  get loginUrl() {
    return this.service.loginUrl;
  }

  get userName() {
    return this.service.userName;
  }

  get userFirstName() {
    return this.service.userFirstName;
  }
}
