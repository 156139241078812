import PropTypes from "prop-types";
import React from "react";

// emcm-ui
import JsonLd from "@emcm-ui/component-json-ld";

// local
import SchemaParentOrganization from "./SchemaParentOrganization";
import SchemaSameAsOrganization from "./SchemaSameAsOrganization";

const SchemaOrganization = ({ name, url, logo, children }) => {
  const childJson = {};

  React.Children.forEach(
    children,
    child => {
      if (child.type === SchemaSameAsOrganization) {
        childJson.sameAs = child.props.url;
      } else if (child.type === SchemaParentOrganization) {
        childJson.parentOrganization = {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: child.props.name,
          url: child.props.url
        };
      }
    },
    {}
  );

  return (
    <JsonLd
      data={{
        "@context": "https://schema.org",
        "@type": "Organization",
        name,
        url,
        logo,
        ...childJson
      }}
    />
  );
};

SchemaOrganization.displayName = "SchemaOrganization";

SchemaOrganization.propTypes = {
  /**
   * The name of the organization.
   */
  name: PropTypes.string.isRequired,

  /**
   * The main website for the organization.
   */
  url: PropTypes.string.isRequired,

  /**
   * The organization's logo
   */
  logo: PropTypes.string.isRequired,

  /**
   * Any other SchemaOrganization subcomponents.
   */
  children: PropTypes.node
};

export default SchemaOrganization;
