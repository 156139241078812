class StorybookAnalytics {
  constructor(actionFunction) {
    this.actionFunction = actionFunction("Analytics event");
  }

  sendEvent(category, action, label) {
    this.actionFunction(
      `Category: ${category}`,
      `Action: ${action}`,
      `Label: ${label}`
    );
  }
}

export default StorybookAnalytics;
