// Default configuration structure
let currentConfiguration = {
  styles: {
    namespace: null
  }
};

const updateConfiguration = newValues => {
  currentConfiguration = {
    ...currentConfiguration,
    ...newValues
  };
};

// Add a way to set the configuration without a knowledge of this package.
// We need this, because sometimes we don't have access to the actual wel
// context.
if (global.emcmUiConfig) {
  updateConfiguration(global.emcmUiConfig);
}

export default {
  get current() {
    return currentConfiguration;
  }
};

export { updateConfiguration };
