import React from "react";

import { ArticleCardFooter, TextCard } from "@emcm-ui/component-card";
import Grid from "@emcm-ui/component-grid";
import Section from "@emcm-ui/component-section";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

const Rss = () => {
  return (
    <div>
      <Section>
        <Grid variant="even-3">
          <Grid.Item>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
          </Grid.Item>
          <Grid.Item />
          <Grid.Item />
        </Grid>
      </Section>
      <Section>
        <Grid variant="even-3">
          <Grid.Item>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
          </Grid.Item>
          <Grid.Item>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
            <VerticalSpacing size="l">
              <TextCard
                description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
                footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
                heading="Aecon’s legal department scales new heights in risk"
                href="https://www.example.com"
                size="small"
              />
            </VerticalSpacing>
          </Grid.Item>
          <Grid.Item />
        </Grid>
      </Section>
      <Section>
        <Grid variant="even-3">
          <Grid.Item>
            <TextCard
              description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
              footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
              heading="Aecon’s legal department scales new heights in risk"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
          <Grid.Item>
            <TextCard
              description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
              footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
              heading="Aecon’s legal department scales new heights in risk"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
          <Grid.Item>
            <TextCard
              description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
              footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
              heading="Aecon’s legal department scales new heights in risk"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
        </Grid>
      </Section>
      <Section>
        <Grid variant="even-4">
          <Grid.Item>
            <TextCard
              description="Artificial Intelligence – AI for short – is a topic of hot debate. Some technologists foresee AI threatening to bring about…"
              footer={<ArticleCardFooter dateTime="2018-02-24 13:57" />}
              heading="How will artificial intelligence affect legal practice – and when?"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
          <Grid.Item>
            <TextCard
              description="Artificial Intelligence – AI for short – is a topic of hot debate. Some technologists foresee AI threatening to bring about…"
              footer={<ArticleCardFooter dateTime="2018-02-24 13:57" />}
              heading="How will artificial intelligence affect legal practice – and when?"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
          <Grid.Item>
            <TextCard
              description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
              footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
              heading="Aecon’s legal department scales new heights in risk"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
          <Grid.Item>
            <TextCard
              description="It wasn’t so long ago that 10 percent of projects undertaken by Aecon Group Inc. were valued at less than $150 million…"
              footer={<ArticleCardFooter dateTime="2018-01-12 13:57" />}
              heading="Aecon’s legal department scales new heights in risk"
              href="https://www.example.com"
              size="small"
            />
          </Grid.Item>
        </Grid>
      </Section>
    </div>
  );
};

export default Rss;
