import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Child = ({ children, width }) => {
  const getClassName = getClassNameFactory("Longform");

  return (
    <div
      className={getClassName({
        descendantName: "child",
        modifiers: classNames({
          narrow: width === "narrow",
          wide: width === "wide",
          full: width === "full"
        })
      })}
    >
      {children}
    </div>
  );
};

Child.propTypes = {
  /**
   * Child nodes to wrap in Child.
   */
  children: PropTypes.node,
  /**
   * Width of the component.
   */
  width: PropTypes.oneOf(["full", "narrow", "wide"])
};

Child.defaultProps = {
  width: "narrow"
};

Child.displayName = "Longform.Child";

export default Child;
