import LegalECommLoginServiceConfiguration from "./LegalECommLoginServiceConfiguration";

export default class extends LegalECommLoginServiceConfiguration {
  constructor() {
    super();

    this.estoreLocation =
      "https://stg.store.legal.thomsonreuters.com/law-products";
  }
}
