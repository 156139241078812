export const callIfDefined = (func, ...args) => {
  if (func) {
    func(...args);
  }
};

export const canPublish = () => typeof window !== "undefined" && window.PubSub;

export const safePublish = (...args) => {
  if (canPublish()) {
    window.PubSub.publish(...args);
  }
};

export const formatDuration = duration => {
  const reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  const timeBase = 10;

  if (reptms.test(duration)) {
    const matches = reptms.exec(duration);

    if (matches[1]) hours = Number(matches[1]);
    if (matches[2]) minutes = Number(matches[2]);
    if (matches[3]) seconds = Number(matches[3]);
  }

  if (hours < timeBase) hours = `0${hours}`;
  if (minutes < timeBase) minutes = `0${minutes}`;
  if (seconds < timeBase) seconds = `0${seconds}`;
  if (hours === "00") return `${minutes}:${seconds}`;

  return `${hours}:${minutes}:${seconds}`;
};
