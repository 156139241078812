import { withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import CompositionsREADME from "../README.md";

import staticRendererDecorator from "./staticRendererDecorator";

export default stories => {
  stories.addDecorator(withKnobs);
  stories.addDecorator(withReadme(CompositionsREADME));
  stories.addDecorator(staticRendererDecorator);
};
