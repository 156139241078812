import React from "react";
import Login from "./Login";

// eslint-disable-next-line require-await
export default async (domNode, rehydrateChildren, { loginManager }) => {
  const props = {
    accountOverviewLabel: domNode.getAttribute("data-account-overview-label"),
    accountOverviewIcon: domNode.getAttribute("data-account-overview-icon"),
    accountLabel: domNode.getAttribute("data-account-label"),
    helloLabel: domNode.getAttribute("data-hello-label"),
    loginLabel: domNode.getAttribute("data-login-label"),
    logoutLabel: domNode.getAttribute("data-logout-label")
  };

  return <Login {...props} loginManager={loginManager} />;
};
