import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import { Section } from "@emcm-ui/component-section";
import Alignment from "@emcm-ui/component-alignment";
import Icon from "@emcm-ui/component-icon";
import Tooltip from "./index";
import "./Tooltip.css";
import TooltipREADME from "../README.md";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const stories = storiesOf("Components/Tooltip", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TooltipREADME));

stories
  .add("Default", () => (
    <Section>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        pellentesque efficitur libero, a suscipit eros. Quisque a sollicitudin
        dui, a congue nunc. Vestibulum at lectus feugiat, pulvinar purus sit
        amet, ullamcorper sem. Ut vel finibus ex. Donec eget dui commodo,
        iaculis nibh nec, molestie ex. Pellentesque et ipsum in odio gravida
        accumsan. Pellentesque egestas ultricies sapien in hendrerit. In sit
        amet egestas nisi, in condimentum augue.
      </p>
      <Alignment horizontal="center">
        <Tooltip
          tooltipText={text("TooltipText", "This is sample text")}
          position={select("Type", Tooltip.Positions, "top")}
        >
          {text("Text", "Hello, world!")}
        </Tooltip>
      </Alignment>
    </Section>
  ))
  .add("Tooltip on icon", () => (
    <div>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
        pellentesque efficitur libero, a suscipit eros. Quisque a sollicitudin
        dui, a congue nunc. Vestibulum at lectus feugiat, pulvinar purus sit
        amet, ullamcorper sem. Ut vel finibus ex. Donec eget dui commodo,
        iaculis nibh nec, molestie ex. Pellentesque et ipsum in odio gravida
        accumsan. Pellentesque egestas ultricies sapien in hendrerit. In sit
        amet egestas nisi, in condimentum augue.
      </p>
      <Alignment horizontal="center">
        <Tooltip
          tooltipText="This is sample tooltip"
          position={select("Type", Tooltip.Positions, "top")}
        >
          <Icon path="./icons/ui/grey/question-07.svg" size="xs" />
        </Tooltip>
      </Alignment>
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          pellentesque efficitur libero, a suscipit eros. Quisque a sollicitudin
          dui, a congue nunc. Vestibulum at lectus feugiat, pulvinar purus sit
          amet, ullamcorper sem. Ut vel finibus ex. Donec eget dui commodo,
          iaculis nibh nec, molestie ex. Pellentesque et ipsum in odio gravida
          accumsan. Pellentesque egestas ultricies sapien in hendrerit. In sit
          amet egestas nisi, in condimentum augue.
        </p>
        <Alignment horizontal="center">
          <Tooltip
            tooltipText="هذا هو تلميح الأدوات"
            position={select("Type", Tooltip.Positions, "top")}
          >
            {text("Text", "مرحبا بالعالم")}
          </Tooltip>
        </Alignment>
      </Section>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Section>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
          pellentesque efficitur libero, a suscipit eros. Quisque a sollicitudin
          dui, a congue nunc. Vestibulum at lectus feugiat, pulvinar purus sit
          amet, ullamcorper sem. Ut vel finibus ex. Donec eget dui commodo,
          iaculis nibh nec, molestie ex. Pellentesque et ipsum in odio gravida
          accumsan. Pellentesque egestas ultricies sapien in hendrerit. In sit
          amet egestas nisi, in condimentum augue.
        </p>
        <Alignment horizontal="center">
          <Tooltip
            tooltipText="This is sample tooltip"
            position={select("Type", Tooltip.Positions, "top")}
          >
            {text("Text", "Hello, world!")}
          </Tooltip>
        </Alignment>
      </Section>
    </StaticRenderer>
  ));
