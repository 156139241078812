import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const MarkupContainer = ({ children, sticky }) => {
  const getClassName = getClassNameFactory(MarkupContainer.displayName);

  return (
    <div
      data-react-from-markup-container
      className={getClassName({
        modifiers: classNames({ sticky })
      })}
    >
      {children}
    </div>
  );
};

MarkupContainer.displayName = "MarkupContainer";

MarkupContainer.propTypes = {
  /**
   * Children
   */
  children: PropTypes.node.isRequired,
  /**
   * Enable wrapping of `position: sticky` rehydratable components
   */
  sticky: PropTypes.bool
};

export default MarkupContainer;
