import getClassNameFactory from "@emcm-ui/utility-class-names";
import PropTypes from "prop-types";
import React from "react";
import SandwichContentItem from "./components/SandwichContentItem";
import classNames from "classnames";

const Sandwich = ({
  alignMiddle,
  children,
  layoutVariant,
  leadItems,
  media
}) => {
  const getClassName = getClassNameFactory(Sandwich.displayName);

  const cleanLayoutVariant = layoutVariant.replace(/,/g, "");

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          alignMiddle,
          [cleanLayoutVariant]: true
        })
      })}
    >
      {leadItems ? (
        <div className={getClassName({ descendantName: "lead" })}>
          {leadItems.map((item, i) => (
            <div
              key={i}
              className={getClassName({ descendantName: "leadItem" })}
            >
              {item}
            </div>
          ))}
        </div>
      ) : null}
      <div className={getClassName({ descendantName: "media" })}>{media}</div>
      {children ? (
        <div className={getClassName({ descendantName: "content" })}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

Sandwich.layoutVariants = [
  "content-3/5,media-2/5",
  "media-2/5,content-3/5",
  "content-1/3,media-2/3",
  "media-2/3,content-1/3"
];

Sandwich.displayName = "Sandwich";

/* eslint-disable max-len */
Sandwich.propTypes = {
  /**
   * Align content to the middle, vertically
   */
  alignMiddle: PropTypes.bool,

  /**
   * Content, typically a RichText component along with a link or a group
   */
  children: PropTypes.node.isRequired,

  /**
   * Layout variant modifier (Default: "content-3/5,media-2/5")
   */
  layoutVariant: PropTypes.oneOf(Sandwich.layoutVariants),

  /**
   * Leading components
   */
  leadItems: PropTypes.array.isRequired,

  /**
   * Media Component
   */
  media: PropTypes.node.isRequired
};

Sandwich.defaultProps = {
  layoutVariant: "content-3/5,media-2/5"
};

/* eslint-enable max-len */

Sandwich.ContentItem = SandwichContentItem;

export default Sandwich;
