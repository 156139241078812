import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import HorizontalOverflow from "./index";
import "./HorizontalOverflow.css";
import HorizontalOverflowREADME from "../README.md";

import Section from "@emcm-ui/component-section";
import Table from "@emcm-ui/component-table";

const stories = storiesOf("Components/HorizontalOverflow", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(HorizontalOverflowREADME));

const table = (
  <Table>
    <table>
      <thead>
        <tr>
          <th scope="col">Name of Entity</th>
          <th scope="col">Registered Address</th>
          <th scope="col">Registered Number</th>
          <th scope="col">Name of Entity</th>
          <th scope="col">Registered Address</th>
          <th scope="col">Registered Number</th>
          <th scope="col">Registered Address</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
          <td>Marnixlaan 17 1000, Brussels Belgium</td>
          <td>0473.542.617</td>
          <td>Thomson Reuters (Markets) SA., Belgium Branch</td>
          <td>Marnixlaan 17 1000, Brussels Belgium</td>
          <td>0473.542.617</td>
          <td>Marnixlaan 17 1000, Brussels Belgium</td>
        </tr>
        <tr>
          <td>eXimius NV</td>
          <td>Zandvoortstraat 45C 2800, Mechelen Belgium</td>
          <td>BTW BE 0881.564.110 RPR Mechelen</td>
          <td>eXimius NV</td>
          <td>Zandvoortstraat 45C 2800, Mechelen Belgium</td>
          <td>BTW BE 0881.564.110 RPR Mechelen</td>
          <td>Zandvoortstraat 45C 2800, Mechelen Belgium</td>
        </tr>
        <tr>
          <td>Aktor Knowledge Technology N.V.</td>
          <td>Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
          <td>HRA 332462</td>
          <td>Aktor Knowledge Technology N.V.</td>
          <td>Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
          <td>HRA 332462</td>
          <td>Sint Pietersvliet 7 Antwerp, 2000 Belgium</td>
        </tr>
        <tr>
          <td>Compu-Mark N.V.</td>
          <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
          <td>271 928</td>
          <td>Compu-Mark N.V.</td>
          <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
          <td>271 928</td>
          <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
        </tr>
      </tbody>
    </table>
  </Table>
);

stories
  .add("Default", () => (
    <Section
      background={select("Background", [""].concat(Section.backgrounds))}
    >
      <HorizontalOverflow
        background={select("Background", [""].concat(Section.backgrounds))}
      >
        {table}
      </HorizontalOverflow>
    </Section>
  ))
  .add("Inherit background from Section", () => (
    <Section
      background={select("Background", [""].concat(Section.backgrounds))}
    >
      <HorizontalOverflow>{table}</HorizontalOverflow>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section
        background={select("Background", [""].concat(Section.backgrounds))}
      >
        <HorizontalOverflow
          background={select("Background", [""].concat(Section.backgrounds))}
        >
          {" "}
          <Table>
            <table>
              <thead>
                <tr>
                  <th scope="col">اسم الكيان</th>
                  <th scope="col">عنوان مسجل</th>
                  <th scope="col">رقم مسجل</th>
                  <th scope="col">اسم الكيان</th>
                  <th scope="col">عنوان مسجل</th>
                  <th scope="col">رقم مسجل</th>
                  <th scope="col">اسم الكيان</th>
                  <th scope="col">عنوان مسجل</th>
                  <th scope="col">رقم مسجل</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Thomson Reuters (Markets) SA.، Belgium Branch</td>
                  <td>Marnixlaan، 17، 1000، بروكسل، Belgium</td>
                  <td>0473.542.617</td>
                  <td>Thomson Reuters (Markets) SA.، Belgium Branch</td>
                  <td>Marnixlaan، 17، 1000، بروكسل، Belgium</td>
                  <td>0473.542.617</td>
                  <td>Thomson Reuters (Markets) SA.، Belgium Branch</td>
                  <td>Marnixlaan، 17، 1000، بروكسل، Belgium</td>
                  <td>0473.542.617</td>
                </tr>
                <tr>
                  <td>استثنائي NY</td>
                  <td>Zandvoortstraat 45C 2800، Mechelen Belgium</td>
                  <td>BTW BE 0881.564.110 RPR Mechelen</td>
                  <td>استثنائي NY</td>
                  <td>Zandvoortstraat 45C 2800، Mechelen Belgium</td>
                  <td>BTW BE 0881.564.110 RPR Mechelen</td>
                  <td>استثنائي NY</td>
                  <td>Zandvoortstraat 45C 2800، Mechelen Belgium</td>
                  <td>BTW BE 0881.564.110 RPR Mechelen</td>
                </tr>
                <tr>
                  <td>Aktor Knowledge Technology N.V.</td>
                  <td>Sint Pietersvliet 7 Antwerp، 2000 Belgium</td>
                  <td>HRA 332462</td>
                  <td>Aktor Knowledge Technology N.V.</td>
                  <td>Sint Pietersvliet 7 Antwerp، 2000 Belgium</td>
                  <td>HRA 332462</td>
                  <td>Aktor Knowledge Technology N.V.</td>
                  <td>Sint Pietersvliet 7 Antwerp، 2000 Belgium</td>
                  <td>HRA 332462</td>
                </tr>
                <tr>
                  <td>Compu-Mark N.V.</td>
                  <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
                  <td>271 928</td>
                  <td>Compu-Mark N.V.</td>
                  <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
                  <td>271 928</td>
                  <td>Compu-Mark N.V.</td>
                  <td>St Pietersvliet 7 B-2000 Antwerpen Belgium</td>
                  <td>271 928</td>
                </tr>
              </tbody>
            </table>
          </Table>
        </HorizontalOverflow>
      </Section>
    </Language>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Section>
        <HorizontalOverflow>{table}</HorizontalOverflow>
      </Section>
    </StaticRenderer>
  ));
