import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Column = ({ children }) => {
  const getClassName = getClassNameFactory("GlobalHeaderColumn");

  return <div className={getClassName()}>{children}</div>;
};

Column.propTypes = {
  /**
   * The contents of the column. If this is a typical navigation menu, you should use `GlobalHeader.NavList` here.
   */
  children: PropTypes.node.isRequired
};

Column.displayName = "GlobalHeader.Column";

export default Column;
