import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Link from "@emcm-ui/component-link";

const ListOfLinksLink = ({ children, ...props }) => {
  const getClassName = getClassNameFactory(ListOfLinksLink.displayName);

  return (
    <li className={getClassName()}>
      <Link {...props}>{children}</Link>
    </li>
  );
};

ListOfLinksLink.displayName = "ListOfLinks.Link";

ListOfLinksLink.propTypes = {
  /**
   * Title text.
   */
  children: PropTypes.node.isRequired,

  /**
   * URL for the link.
   */
  href: PropTypes.string,

  /**
   * Type of link.
   */
  type: PropTypes.oneOf(Link.types),

  /**
   * Enable block behaviour.
   */
  block: PropTypes.bool,

  /**
   * Open link in new tab/window.
   */
  newWindow: PropTypes.bool
};

export default ListOfLinksLink;
