import SampleDismissibleBanner from "./SampleDismissibleBanner";
import SampleGlobalHeader from "./SampleGlobalHeader";
import SampleGlobalHeaderTax from "./SampleGlobalHeaderTax";
import SampleHeader from "./SampleHeader";
import SampleSiteHeader from "./SampleSiteHeader";

export default SampleHeader;
export {
  SampleDismissibleBanner,
  SampleGlobalHeader,
  SampleGlobalHeaderTax,
  SampleSiteHeader
};
