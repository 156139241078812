import React from "react";

import Button from "@emcm-ui/component-button";
import Heading from "@emcm-ui/component-heading";
import Link from "@emcm-ui/component-link";
import PageHeadingWrapper from "@emcm-ui/component-page-heading-wrapper";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import Icon from "@emcm-ui/component-icon";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const Icons = {
  plusBlue: "./icons/ui/blue/plus.svg",
  playtriangleWhite: "./icons/ui/white/playtriangle.svg"
};

const Modal = () => (
  <div>
    <SampleHeader />

    <SemanticMain>
      <Section background="grayLightest">
        <PageHeadingWrapper
          description={
            <p>
              This page demonstrates the various ways of opening a modal dialog.
              Notice the use of the <code>tr-Anchor</code> class, and the{" "}
              <code>data-content-location</code>,{" "}
              <code>data-content-modal</code>, and <code>data-modal-label</code>{" "}
              attributes - these act as the triggers.
            </p>
          }
          heading={<strong>Modals</strong>}
          large
        />
      </Section>

      <Section>
        <VerticalSpacing size="m">
          <Heading rank="3" type="m">
            Link
          </Heading>
        </VerticalSpacing>

        <VerticalSpacing size="l">
          <Link
            contentLocation="https://www.mocky.io/v2/5b891b8a3000006e0c338312"
            href="https://www.example.com/"
            modalLabel="A bunch of text"
            type="icon"
            icon={<Icon path={Icons.plusBlue} size="s" />}
            iconPosition={"right"}
          >
            See details
          </Link>
        </VerticalSpacing>

        <VerticalSpacing size="m">
          <Heading rank="3" type="m">
            Button
          </Heading>
        </VerticalSpacing>

        <VerticalSpacing size="l">
          <Button
            color="primary"
            contentLocation="https://www.mocky.io/v2/5b8917243000006e0c3382e2"
            href="https://www.example.com/"
            icon={<Icon path={Icons.playtriangleWhite} size="s" />}
            kind="link"
            modalLabel="Video title"
          >
            Watch video
          </Button>
        </VerticalSpacing>
      </Section>
    </SemanticMain>

    <SampleFooter />
  </div>
);

export default Modal;
