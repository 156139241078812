import React from "react";
import Ticker from "./Ticker";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const rehydrator = async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("Ticker");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "itemsInner" })}`
  );
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    initialTime:
      domNode.getAttribute("data-initial-time") || new Date().toISOString()
  };

  return <Ticker {...props}>{reactifiedChildren}</Ticker>;
};

export default rehydrator;
