import PropTypes from "prop-types";
import React from "react";
import CampaignTrackingParameterService from "@emcm-ui/utility-campaign";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

/**
 * This control creates a hidden field, and gets its value from the provided
 * service (which should be a CampaignTrackingParameterService) by passing the
 * `parameterToTrack` to the service's `get` method.
 *
 * In the case of rehydration using react-from-markup, the service will come
 * from the `campaignControl` property of `extra`
 */
const CampaignControl = ({ defaultValue, name, parameterToTrack, service }) => {
  const getClassName = getClassNameFactory(CampaignControl.displayName);

  return (
    <input
      className={getClassName()}
      data-parameter-to-track={parameterToTrack}
      data-default-value={defaultValue}
      data-rehydratable={getRehydratableName(CampaignControl.displayName)}
      type="hidden"
      value={(service && service.get(parameterToTrack)) || defaultValue}
      name={name}
    />
  );
};

CampaignControl.displayName = "Form.CampaignControl";

CampaignControl.defaultProps = {
  defaultValue: ""
};

CampaignControl.propTypes = {
  /**
   * The default value for this field, if the tracked parameter doesn't exist.
   */
  defaultValue: PropTypes.string,

  /**
   * Name attribute for <input>, required for serialisation.
   */
  name: PropTypes.string.isRequired,

  /**
   * The name of the tracked parameter.
   */
  parameterToTrack: PropTypes.string.isRequired,

  /**
   * The service providing values for tracked parameters.
   *
   * Optional, in case the service isn't ready yet (ie. when rendering on the server)
   */
  service: PropTypes.instanceOf(CampaignTrackingParameterService)
};

export default CampaignControl;
