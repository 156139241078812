import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import debounce from "lodash.debounce";

import { anchorPoints } from "@emcm-ui/component-image";

/**
 * VideoLoop
 *
 * Displays a looping video with auto-play.
 * Mimicks component `Image`, so could be used in place of images.
 *
 * Since IE doesn't support CSS object-fit we'll do resize and
 * alignment logic in code.
 *
 * This component is partially speculative: It's presently not clear
 * how the new video service (likely Kaltura) will work for this purpose,
 * for now we assume it'll be a player inside an iframe.
 *
 * There's a chance the iframe flickers as it's loading the external page
 * with the video.
 * To prevent this it's initially hidden, with a delayed fade-in animation,
 * so it'll appear without JS as well. Then on mount we immediately remove
 * the animation so it stays hidden, then wait for the onload event to
 * actually show it.
 *
 */
class VideoLoop extends React.Component {
  constructor(props) {
    super(props);

    this.statuses = {
      INITIAL: "initial",
      LOADING: "loading",
      LOADED: "loaded"
    };
    this.containerEl = null;
    this.debounceTime = 100;
    this.onResizeDebounced = debounce(this.onResize, this.debounceTime);

    this.state = {
      status: this.statuses.INITIAL
    };
  }

  onRefContainer = el => {
    this.containerEl = el;
    if (this.containerEl) {
      this.forceUpdate();
    }
  };

  onLoadIFrame = () => {
    this.setState({ status: this.statuses.LOADED });
  };

  onResize = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    window.addEventListener("resize", this.onResizeDebounced);
    this.setState({ status: this.statuses.LOADING });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeDebounced);
  }

  render() {
    const {
      anchor,
      fillColor,
      fit,
      height,
      overlay,
      width,
      src,
      videoWidth,
      videoHeight
    } = this.props;

    const { status } = this.state;

    const getClassName = getClassNameFactory(VideoLoop.displayName);

    return (
      <div
        ref={this.onRefContainer}
        className={getClassName({
          modifiers: classNames({
            overlay,
            [`${overlay}`]: overlay,
            [`${status}`]: status
          })
        })}
        style={{
          ...(fillColor && { backgroundColor: fillColor }),
          ...(height && { height: `${height}px` }),
          ...(width && { width: `${width}px` })
        }}
      >
        <iframe
          className={getClassName({
            descendantName: "iframe"
          })}
          onLoad={this.onLoadIFrame}
          scrolling="no"
          src={src}
          style={{
            ...(this.containerEl &&
              this.getIFrameStyle({
                videoWidth,
                videoHeight,
                maxWidth: width || this.containerEl.offsetWidth,
                maxHeight: height || this.containerEl.offsetHeight,
                anchor,
                fit
              }))
          }}
        />
      </div>
    );
  }

  getIFrameStyle({
    videoWidth,
    videoHeight,
    maxWidth,
    maxHeight,
    anchor,
    fit
  }) {
    const scale =
      fit === "cover"
        ? Math.max(maxWidth / videoWidth, maxHeight / videoHeight)
        : Math.min(maxWidth / videoWidth, maxHeight / videoHeight);

    const width = videoWidth * scale;
    const height = videoHeight * scale;

    return {
      width: `${width}px`,
      height: `${height}px`,
      ...this.getAlignedPosition({ width, height, maxWidth, maxHeight, anchor })
    };
  }

  getAlignedPosition({ width, height, maxWidth, maxHeight, anchor }) {
    const ZERO = 0;
    const HALF = 0.5;
    const ONE = 1;

    const alignmentRatios = {
      C: [HALF, HALF],
      N: [HALF, ZERO],
      NE: [ZERO, ZERO],
      E: [ZERO, HALF],
      SE: [ZERO, ONE],
      S: [HALF, ONE],
      SW: [ONE, ONE],
      W: [ONE, HALF],
      NW: [HALF, ZERO]
    };

    const ratios = alignmentRatios[anchor] || alignmentRatios.C;

    return {
      left: `${(maxWidth - width) * ratios[0]}px`,
      top: `${(maxHeight - height) * ratios[1]}px`
    };
  }
}

VideoLoop.displayName = "VideoLoop";

VideoLoop.overlays = ["scrimBottomDark"];

/* eslint-disable max-len */
VideoLoop.propTypes = {
  /**
   * Anchor point for aligning the video inside the container
   */
  anchor: PropTypes.oneOf(anchorPoints),

  /**
   * Fallback hexadecimal fill color. The intent is to increase perceived loading speed. Do not use on images with transparency. Requires that an explicit width and height be set for the image.
   */
  fillColor: PropTypes.string,

  /**
   * Fit of the image.
   */
  fit: PropTypes.oneOf(["cover", "contain"]),

  /**
   * Height in pixels. If empty defaults to 100%.
   */
  height: PropTypes.number,

  /**
   * Apply an overlay to the image, e.g. a scrim gradient for text legibility.
   * Should not be used if the image has a rasterized overlay.
   */
  overlay: PropTypes.oneOf(VideoLoop.overlays),

  /**
   *
   */
  src: PropTypes.string.isRequired,

  /**
   * Width in pixels. If empty defaults to 100%.
   */
  width: PropTypes.number,

  /**
   * Width of the video in pixels
   *
   * Note:
   * Video size needed for now to calculate scale/alignment,
   * but maybe the future video service has an API for that.
   */
  videoWidth: PropTypes.number.isRequired,

  /**
   * Height of the video in pixels
   */
  videoHeight: PropTypes.number.isRequired
};
/* eslint-enable max-len */

VideoLoop.defaultProps = {
  anchor: "C",
  fit: "cover"
};

export default VideoLoop;
