import React from "react";
import { storiesOf } from "@storybook/react";
import { text, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import GlobalFooter, {
  GlobalFooterItem
} from "@emcm-ui/component-global-footer";
import SiteFooter from "@emcm-ui/component-site-footer";
import SemanticFooter from "./index";
import "./SemanticFooter.css";
import SemanticFooterREADME from "../README.md";

const stories = storiesOf("Components/SemanticFooter", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SemanticFooterREADME));

const lists = [
  {
    label: "About Thomson Reuters",
    items: [
      {
        href: "#",
        label: "About us"
      },
      {
        href: "#",
        label: "Investor relations"
      },
      {
        href: "#",
        label: "Newsroom"
      },
      {
        href: "#",
        label: "Press release"
      },
      {
        href: "#",
        label: "Innovation"
      },
      {
        href: "#",
        label: "Global site directory"
      }
    ]
  },
  {
    label: "Products & services",
    items: [
      {
        href: "#",
        label: "All products"
      },
      {
        href: "#",
        label: "Financial"
      },
      {
        href: "#",
        label: "Government"
      },
      {
        href: "#",
        label: "Legal"
      },
      {
        href: "#",
        label: "Reuters news agency"
      },
      {
        href: "#",
        label: "Risk management"
      },
      {
        href: "#",
        label: "Tax & accounting"
      }
    ]
  },
  {
    label: "Resources",
    items: [
      {
        href: "#",
        label: "Careers"
      },
      {
        href: "#",
        label: "Answers On"
      },
      {
        href: "#",
        label: "Product logins"
      },
      {
        href: "#",
        label: "Mobile apps"
      },
      {
        href: "#",
        label: "Know 360 publications"
      }
    ]
  },
  {
    label: "Contact Us",
    items: [
      {
        href: "#",
        label: "Support & Training"
      },
      {
        href: "#",
        label: "Office locations"
      },
      {
        href: "#",
        label: "Media relations"
      },
      {
        href: "#",
        label: "Sales"
      }
    ]
  },
  {
    label: "Connect",
    items: [
      {
        href: "#",
        label: "Facebook"
      },
      {
        href: "#",
        label: "Twitter"
      },
      {
        href: "#",
        label: "LinkedIn"
      },
      {
        href: "#",
        label: "Google+"
      },
      {
        href: "#",
        label: "YouTube"
      },
      {
        href: "#",
        label: "Instagram"
      }
    ]
  }
];

stories
  .add("Default", () => (
    <SemanticFooter>Footer content goes here</SemanticFooter>
  ))
  .add("With Global and SiteFooter", () => (
    <SemanticFooter>
      <SiteFooter
        lists={lists}
        searchFormAction={text("searchFormAction", "/")}
        searchFormMethod={text("searchFormMethod", "get")}
        typeaheadUrl={text(
          "typeaheadUrl",
          "//content.atomz.com/autocomplete/sp10/05/0d/51?_=1506335725065"
        )}
      />
      <GlobalFooter logoHref="#">
        <GlobalFooterItem href="#" title="Cookie policy" />
        <GlobalFooterItem href="#" title="Privacy statement" />
        <GlobalFooterItem href="#" title="Terms of use" />
        <GlobalFooterItem href="#" title="Copyright" />
        <GlobalFooterItem href="#" title="Sitemap" />
        <GlobalFooterItem href="#" title="Disclosures" />
        <GlobalFooterItem href="#" title="Third-party restrictions" />
      </GlobalFooter>
    </SemanticFooter>
  ));
