import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import DismissibleBanner from "./DismissibleBanner";

const getClassName = getClassNameFactory("DismissibleBanner");

export default async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };
  const childrenClass = getClassName({
    descendantName: "links"
  });
  const headingClass = getClassName({
    descendantName: "heading"
  });
  const textClass = getClassName({
    descendantName: "text"
  });

  const rehydrationProps = {
    alreadySeenCookieName: domNode.getAttribute(
      "data-already-seen-cookie-name"
    ),
    alreadySeenCookieExpectedValue: domNode.getAttribute(
      "data-already-seen-cookie-expected-value"
    ),
    dismissButtonText: domNode.getAttribute("data-dismiss-button-text"),
    fixed: domNode.getAttribute("data-fixed") === "true",
    heading: domNode.querySelector(`.${headingClass}`).textContent,
    hideIfAlreadySeen:
      domNode.getAttribute("data-hide-if-already-seen") === "true",
    text: domNode.querySelector(`.${textClass}`).textContent,
    validParentDomains: readData("valid-parent-domains")
  };

  const childrenNode = domNode.querySelector(`.${childrenClass}`);
  const children = childrenNode ? await rehydrateChildren(childrenNode) : null;

  return (
    <DismissibleBanner {...rehydrationProps}>{children}</DismissibleBanner>
  );
};
