import PropTypes from "prop-types";
import React from "react";

import Button from "@emcm-ui/component-button";
import Form from "@emcm-ui/component-form";
import Anchor from "@emcm-ui/component-anchor";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const FilterFooter = ({
  applyFilterButtonLabel,
  disableFilter,
  filterCloseLabel,
  filterData,
  id,
  onSaveFilterCheckboxClick,
  showFilterBody,
  saveFilterChecked,
  showSaveFilter
}) => {
  const getClassName = getClassNameFactory(FilterFooter.displayName);

  return (
    <div
      className={getClassName({
        descendantName: "applyFilterWrapper"
      })}
    >
      <div
        className={getClassName({
          descendantName: "clearFilter"
        })}
      >
        <Anchor className="tr-Temp" href="/" onClick={showFilterBody}>
          {filterCloseLabel}
        </Anchor>
      </div>
      <div
        className={getClassName({
          descendantName: "applyFilterButton"
        })}
      >
        {showSaveFilter && (
          <span
            className={getClassName({
              descendantName: "checkboxWrapper"
            })}
          >
            <Form.CheckboxControl
              disabled={false}
              checked={saveFilterChecked}
              id={id}
              labelText={"Save filter"}
              name={"saveChartFilter"}
              value={"saveChartFilter"}
              onChange={onSaveFilterCheckboxClick}
            />
          </span>
        )}
        <Button
          block
          color="primary"
          kind="button"
          onClick={filterData}
          disabled={disableFilter}
        >
          {applyFilterButtonLabel}
        </Button>
      </div>
    </div>
  );
};

FilterFooter.displayName = "FilterFooter";

FilterFooter.propTypes = {
  /**
   * applyFilterButtonLabel.
   */
  applyFilterButtonLabel: PropTypes.string.isRequired,
  /**
   * disableFilter.
   */
  disableFilter: PropTypes.bool.isRequired,
  /**
   * id.
   */
  id: PropTypes.string.isRequired,
  /**
   * filterCloseLabel.
   */
  filterCloseLabel: PropTypes.string.isRequired,
  /**
   * filterData.
   */
  filterData: PropTypes.func.isRequired,
  /**
   * onSaveFilterCheckboxClick.
   */
  onSaveFilterCheckboxClick: PropTypes.func.isRequired,
  /**
   * showFilterBody.
   */
  showFilterBody: PropTypes.func.isRequired,
  /**
   * saveFilterChecked.
   */
  saveFilterChecked: PropTypes.bool.isRequired,
  /**
   * showSaveFilter.
   */
  showSaveFilter: PropTypes.bool.isRequired
};

export default FilterFooter;
