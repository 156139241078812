import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Alert from "./index";
import "./Alert.css";
import AlertREADME from "../README.md";

const stories = storiesOf("Components/Alert", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AlertREADME));

stories
  .add("Default", () => (
    <Alert state={select("state", ["success", "failure"], "success")}>
      {text("text", "The form submitted successfully.")}
    </Alert>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Alert state={select("state", ["success", "failure"], "success")}>
        {text("text", "مرحبا بالعالم")}
      </Alert>
    </Language>
  ));
