import React from "react";
import { storiesOf } from "@storybook/react";
import { boolean, number, select, withKnobs } from "@storybook/addon-knobs";
import Eyebrow from "@emcm-ui/component-eyebrow";
import Heading from "@emcm-ui/component-heading";
import withReadme from "storybook-readme/with-readme";
import Grid from "./index";
import "./Grid.css";
import GridREADME from "../README.md";
import { Language } from "@emcm-ui/component-theme";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

const stories = storiesOf("Components/Grid", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(GridREADME));

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";

  const base = 16;
  const maxRange = 6;

  for (let i = 0; i < maxRange; i++) {
    color += letters[Math.floor(Math.random() * base)];
  }

  return color;
};

const getBlockStyle = ({ gutterless = true, size = "small" } = {}) => {
  const gutterSize = 36;
  const smallHeight = 200;
  const largeHeight = 2 * smallHeight; // eslint-disable-line no-magic-numbers
  const largeHeightWithGutter = largeHeight + gutterSize;

  return {
    backgroundColor: getRandomColor(),
    minHeight:
      size === "large" // eslint-disable-line no-nested-ternary
        ? gutterless ? largeHeight : largeHeightWithGutter
        : smallHeight,
    height: "100%",
    fontWeight: "800",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 48,
    color: "white"
  };
};

const numberOfItems = 6;

stories
  .add("Default", () => (
    <Section>
      <Grid
        variant={select("variant", Grid.variants, "even-4")}
        flex={boolean("flex version", false)}
        gutterBorder={boolean("gutterBorder", false)}
        gutterless={boolean("gutterless", false)}
        gutterlessAtNarrow={boolean("gutterlessAtNarrow", false)}
        gutterVerticalSmall={boolean("gutterVerticalSmall", false)}
        setRoleAttribute={boolean("setRoleAttribute", false)}
      >
        {[...Array(number("Number of items", numberOfItems)).keys()].map(i => (
          <Grid.Item
            key={i}
            setRoleAttribute={boolean("setRoleAttribute", false)}
          >
            <div style={getBlockStyle()}>{i + 1}</div>
          </Grid.Item>
        ))}
      </Grid>
    </Section>
  ))
  .add("Squeezed content", () => (
    <Section>
      <VerticalSpacing size="l">
        <Grid
          variant="3/4,1/4"
          flex={boolean("flex version", false)}
          gutterless
        >
          <Grid.Item>
            <VerticalSpacing size="xs">
              <Eyebrow text="september 18, 2017 | Lorem Ipsum Dolor" />
            </VerticalSpacing>
            <VerticalSpacing size="xs">
              <Heading rank="2" type="l">
                <span>
                  Providing answers across <b>every industry</b>
                </span>
              </Heading>
            </VerticalSpacing>
            <VerticalSpacing size="s">
              <RichText>
                <p>
                  Before 2016 had even begun, Reuters Breakingviews provided a
                  fictionalized account of a Britain where Prime Minister David
                  Cameron had backed the campaign to leave the European Union.
                  Purportedly written by a private secretary to the Prime
                  Minister, Breakingviews chronicled the events in the month
                  after UK voters decided to leave the EU – an imagined vision
                  of a weakened pound, a stalling economy and mountains of
                  unsold pork pies.
                </p>
              </RichText>
            </VerticalSpacing>
          </Grid.Item>
        </Grid>
      </VerticalSpacing>
      <VerticalSpacing size="l">
        <Grid
          variant="1/4,3/4"
          flex={boolean("flex version", false)}
          gutterless
        >
          <Grid.Item />
          <Grid.Item>
            <VerticalSpacing size="xs">
              <Eyebrow text="september 18, 2017 | Lorem Ipsum Dolor" />
            </VerticalSpacing>
            <VerticalSpacing size="xs">
              <Heading rank="2" type="l">
                <span>
                  Providing answers across <b>every industry</b>
                </span>
              </Heading>
            </VerticalSpacing>
            <VerticalSpacing size="s">
              <RichText>
                <p>
                  Before 2016 had even begun, Reuters Breakingviews provided a
                  fictionalized account of a Britain where Prime Minister David
                  Cameron had backed the campaign to leave the European Union.
                  Purportedly written by a private secretary to the Prime
                  Minister, Breakingviews chronicled the events in the month
                  after UK voters decided to leave the EU – an imagined vision
                  of a weakened pound, a stalling economy and mountains of
                  unsold pork pies.
                </p>
              </RichText>
            </VerticalSpacing>
          </Grid.Item>
        </Grid>
      </VerticalSpacing>
    </Section>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Section>
      <Language code="ar">
        <Grid
          variant={select("variant", Grid.variants, "even-4")}
          flex={boolean("flex version", false)}
          gutterBorder={boolean("gutterBorder", false)}
          gutterless={boolean("gutterless", false)}
          gutterlessAtNarrow={boolean("gutterlessAtNarrow", false)}
        >
          {[...Array(number("Number of items", numberOfItems)).keys()].map(
            i => (
              <Grid.Item key={i}>
                <div style={getBlockStyle()}>{i + 1}</div>
              </Grid.Item>
            )
          )}
        </Grid>
      </Language>
    </Section>
  ));
