import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Avatar from "./index";
import "./Avatar.css";
import AvatarREADME from "../README.md";

const stories = storiesOf("Components/Avatar", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AvatarREADME));

stories
  .add("Default (bounded)", () => (
    <div style={{ maxHeight: "55px", maxWidth: "100%", width: "55px" }}>
      <Avatar alt="" fillColor="#ff8000" src="headshot.jpg" />
    </div>
  ))
  .add("Sized", () => (
    <Avatar
      alt=""
      fillColor="#ff8000"
      src="headshot.jpg"
      width={parseInt(text("Width", "300"))}
    />
  ));
