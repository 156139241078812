import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, text, select } from "@storybook/addon-knobs";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import DownloadButton from "./index";
import "./DownloadButton.css";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const stories = storiesOf("Components/DownloadButton", module);

stories.addDecorator(withKnobs);

stories
  .add("Basic", () => (
    <DownloadButton
      href="#_"
      block={boolean("Block", false)}
      icon={<SVGIcon name="download-07" size="s" />}
    >
      {text("Button text", "Download Fact Sheet")}
    </DownloadButton>
  ))
  .add("With file information", () => (
    <DownloadButton
      fileSize={text("Size", "2Mb")}
      fileType={text("File type", "pdf")}
      href={text("href", "#_")}
      block={boolean("Block", false)}
      newWindow={boolean("newWindow", false)}
      icon={<SVGIcon name="download-07" size="s" />}
    >
      {text("Button text", "Download Fact Sheet")}
    </DownloadButton>
  ))
  .add("Direct download", () => (
    <DownloadButton
      fileName={text("Save name", "saved_file_name.pdf")}
      fileSize={text("Size", "2Mb")}
      fileType={text("File type", "pdf")}
      href={text("href", "/udhr.eng.pdf")}
      block={boolean("Block", false)}
      icon={<SVGIcon name="download-07" size="s" />}
    >
      {text("Button text", "Download Fact Sheet")}
    </DownloadButton>
  ))
  .add("Link to page", () => (
    <DownloadButton
      fileSize={text("Size", "2Mb")}
      fileType={text("File type", "pdf")}
      href={text("href", "#_")}
      block={boolean("Block", false)}
      newWindow={boolean("newWindow", false)}
      icon={<SVGIcon name="download-07" size="s" />}
    >
      {text("Button text", "Download Fact Sheet")}
    </DownloadButton>
  ))
  .add("Language: Arabic", () => (
    <Language code="ar">
      <DownloadButton
        fileName={text("Save name", "saved_file_name.pdf")}
        fileSize={text("Size", "2Mb")}
        fileType={text("File type", "pdf")}
        href={text("href", "/udhr.eng.pdf")}
        block={boolean("Block", false)}
        newWindow={boolean("newWindow", false)}
        icon={<SVGIcon name="download-07" size="s" />}
      >
        {text("Button text", "حمل ورقة الحقائق")}
      </DownloadButton>
    </Language>
  ))
  .add("On Dark Background", () => (
    <Section
      background={select(
        "Section background",
        [""].concat(Section.backgrounds)
      )}
    >
      <DownloadButton
        isDark={boolean("Dark", true)}
        href="#_"
        block={boolean("Block", false)}
        icon={<SVGIcon name="download-07" size="s" />}
        disabled={boolean("Disabled", false)}
        fileSize={text("Size", "2Mb")}
        fileType={boolean("File Info", true) ? text("File type", "pdf") : null}
      >
        {text("Button text", "Download Fact Sheet")}
      </DownloadButton>
    </Section>
  ));
