import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Item = ({ children }) => {
  const getClassName = getClassNameFactory("SocialDropdown");

  return (
    <li className={getClassName({ descendantName: "item" })}>
      <div className={getClassName({ descendantName: "itemInner" })}>
        {children}
      </div>
    </li>
  );
};

Item.displayName = "SocialDropdown.Item";

Item.propTypes = {
  /**
   * Child nodes, made up of <SocialShare />.
   */
  children: PropTypes.node
};

export default Item;
