import React from "react";
import ChapterNavInner from "./ChapterNavInner";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const getClassName = getClassNameFactory("LongformChapterNavInner");

const rehydrator = async (domNode, rehydrateChildren) => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "chapters" })}`
  );

  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = { spy: readData("spy"), children: reactifiedChildren };

  return <ChapterNavInner {...props} />;
};

export default rehydrator;
