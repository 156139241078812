import ECommLoginService from "./ECommLoginService";
import ECommLoginServiceConfiguration from "./configurations/ECommLoginServiceConfiguration";
import ECommLoginServiceConfigurationUAT from "./configurations/ECommLoginServiceConfigurationUAT";
import ECommLoginServiceConfigurationPPE from "./configurations/ECommLoginServiceConfigurationPPE";
import ECommLoginServiceConfigurationProd from "./configurations/ECommLoginServiceConfigurationProd";
import LegalECommLoginServiceConfiguration from "./configurations/LegalECommLoginServiceConfiguration";
import LegalECommLoginServiceConfigurationUAT from "./configurations/LegalECommLoginServiceConfigurationUAT";
import LegalECommLoginServiceConfigurationPPE from "./configurations/LegalECommLoginServiceConfigurationPPE";
import LegalECommLoginServiceConfigurationProd from "./configurations/LegalECommLoginServiceConfigurationProd";

export default ECommLoginService;
export {
  ECommLoginServiceConfiguration,
  ECommLoginServiceConfigurationUAT,
  ECommLoginServiceConfigurationPPE,
  ECommLoginServiceConfigurationProd,
  LegalECommLoginServiceConfiguration,
  LegalECommLoginServiceConfigurationUAT,
  LegalECommLoginServiceConfigurationPPE,
  LegalECommLoginServiceConfigurationProd
};
