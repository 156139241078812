import AuthState from "../../AuthState";
import callEach from "../../utilities/callEach";
import { cart } from "../api/estore";
import Cookie from "js-cookie";

const defaultState = {
  authState: AuthState.UNKNOWN,
  itemsInCart: 0,
  userName: null,
  userFirstName: null
};

export default class ECommLoginService {
  constructor(config) {
    this.onChangeCallbacks = [];
    this.state = {
      ...defaultState
    };

    this.config = config;
  }

  /**
   * Initialize the object. Make calls to ecomm, and update the state based on responses.
   */
  async activate() {
    await this.fetchDetails();
  }

  /**
   * Fetch details. Send a request to eStore, to get cart state.
   */
  async fetchDetails() {
    try {
      const userNames = this.config.nameCookies
        .map(Cookie.get)
        .filter(v => typeof v !== "undefined")
        .map(v => v.trim())
        .filter(v => v.length > 0);

      const cartDetails = await cart(this.config.estoreLocation);

      userNames.push(cartDetails.userName);

      this.state.userName = userNames[0];
      this.state.userFirstName = cartDetails.firstName;

      this.state.authState =
        this.state.userName === "Anonymous"
          ? AuthState.LOGGED_OUT
          : AuthState.LOGGED_IN;
      this.state.itemsInCart = cartDetails.itemsInCart;
    } catch (err) {
      this.state = {
        ...defaultState,
        authState: AuthState.LOGGED_OUT
      };

      throw err;
    } finally {
      this.privateTriggerChange();
    }
  }

  /**
   * Redirect to login of tax site.
   */
  login() {
    if (window) {
      window.location.assign(this.loginUrl);
    }
  }

  /**
   * Logout. Redirect to logout of Hybris.
   */
  logout() {
    if (window) {
      window.location.assign(this.logoutUrl);
    }
  }

  onChange(cb) {
    this.onChangeCallbacks.push(cb);
  }

  offChange(cb) {
    if (this.onChangeCallbacks.includes(cb)) {
      this.onChangeCallbacks.splice(this.onChangeCallbacks.indexOf(cb), 1);

      return true;
    }

    return false;
  }

  privateTriggerChange() {
    callEach(this.onChangeCallbacks, this);
  }

  get accountOverviewUrl() {
    return this.config.accountOverviewUrl;
  }

  get authState() {
    return this.state.authState;
  }

  get cartUrl() {
    return this.config.cartUrl;
  }

  get itemsInCart() {
    return this.state.itemsInCart;
  }

  get logoutUrl() {
    return this.config.logoutUrl;
  }

  get loginUrl() {
    return this.config.loginUrl;
  }

  get userName() {
    return this.state.userName;
  }

  get userFirstName() {
    return this.state.userFirstName;
  }
}
