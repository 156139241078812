import PropTypes from "prop-types";
import React, { Component } from "react";
import loadVimeo from "../apiLoaders/loadVimeo";
import BasePlayerEmbed from "./BasePlayerEmbed";

import { canPublish } from "../utilities";
import { fireEnd, firePause, firePlay } from "../analytics";

class VimeoPlayerEmbed extends Component {
  static propTypes = {
    videoId: PropTypes.string.isRequired,
    analytics: PropTypes.func
  };

  static eventToMetadata(e) {
    return {
      currentTime: e.seconds || 0,
      duration: e.duration || 0
    };
  }

  static addAnalyticsToPlayer(player, videoMetadata, analytics) {
    player.on("play", e =>
      firePlay(
        { ...videoMetadata, ...VimeoPlayerEmbed.eventToMetadata(e) },
        analytics
      )
    );

    player.on("pause", e =>
      firePause(
        { ...videoMetadata, ...VimeoPlayerEmbed.eventToMetadata(e) },
        analytics
      )
    );

    player.on("finish", e =>
      fireEnd(
        { ...videoMetadata, ...VimeoPlayerEmbed.eventToMetadata(e) },
        analytics
      )
    );
  }

  handleFrameReady = async iframe => {
    if (this.props.analytics || canPublish()) {
      const Vimeo = await loadVimeo();
      const player = new Vimeo.Player(iframe);
      const videoMetadata = {
        id: this.props.videoId,
        type: "Vimeo"
      };

      // We should try to find the title before we fire any analytics events
      try {
        videoMetadata.title = await player.getVideoTitle();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(
          `Could not get a video title from Vimeo for video ID ${
            this.props.videoId
          }`
        );
      }

      VimeoPlayerEmbed.addAnalyticsToPlayer(
        player,
        videoMetadata,
        this.props.analytics
      );

      // Manually fire the initial play event - Vimeo won't do this for us.
      firePlay(
        {
          ...videoMetadata,
          currentTime: 0,
          duration: await player.getDuration()
        },
        this.props.analytics
      );
    }
  };

  render() {
    const { analytics, videoId, ...extra } = this.props; // eslint-disable-line no-unused-vars
    const frameUrl = `https://player.vimeo.com/video/${videoId}?api=1&autoplay=1&html5=1`;

    return (
      <BasePlayerEmbed
        src={frameUrl}
        onFrameReady={this.handleFrameReady}
        {...extra}
      />
    );
  }
}

export default VimeoPlayerEmbed;
