import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import { Section, SectionBridge } from "./index";
import "./Section.css";
import "./SectionBridge.css";
import SectionREADME from "../README.md";
import Block from "@emcm-ui/component-block";
import Link from "@emcm-ui/components/Link";
import RichText from "@emcm-ui/component-rich-text";
import Icon from "@emcm-ui/component-icon";

const stories = storiesOf("Components/Section", module);

const displayIcon = bg => ({
  ...(!bg && {
    null: "./icons/ui/blue/arrow-right.svg"
  }),
  ...(bg === "white" && {
    white: "./icons/ui/blue/arrow-right.svg"
  }),
  ...(bg === "black" && { black: "./icons/ui/white/arrow-right.svg" }),
  ...(bg === "blue" && { blue: "./icons/ui/white/arrow-right.svg" }),
  ...(bg === "grayDark" && {
    grayDark: "./icons/ui/white/arrow-right.svg"
  }),
  ...(bg === "grayLighter" && {
    grayLighter: "./icons/ui/blue/arrow-right.svg"
  }),
  ...(bg === "grayLightest" && {
    grayLightest: "./icons/ui/blue/arrow-right.svg"
  }),
  ...(bg === false && {
    false: "./icons/ui/blue/arrow-right.svg"
  })
});

const storybookSelectIcon = (displayName, options, defaultValue) => {
  const selectFn = select(displayName, options);

  return typeof displayIcon(selectFn)[selectFn] === "undefined"
    ? defaultValue
    : displayIcon(selectFn)[selectFn];
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SectionREADME));

stories
  .add("Default", () => {
    return (
      <Section
        background={select("Background", ["white"].concat(Section.backgrounds))}
        fullWidth={boolean("Full width", false)}
      >
        <Block height="6rem" text="children" />
      </Section>
    );
  })
  .add("Bridge", () => {
    return (
      <SectionBridge
        backgroundTop={select(
          "Top background",
          SectionBridge.backgrounds,
          "white"
        )}
        backgroundBottom={select(
          "Bottom background",
          SectionBridge.backgrounds,
          "grayLightest"
        )}
      >
        <Block height="6rem" text="children" />
      </SectionBridge>
    );
  })
  .add("Text", () => {
    return (
      <Section
        background={select("Background", [""].concat(Section.backgrounds))}
        fullWidth={boolean("Full width", false)}
      >
        <RichText>
          <p>
            The <a href="/">slow black dog</a> bows before the{" "}
            <a href="https://not-visited.com">regal fox</a>
          </p>
        </RichText>

        <Link
          href={"https://example.com"}
          icon={
            <Icon
              path={storybookSelectIcon(
                "Background",
                Section.backgrounds,
                "./icons/ui/blue/arrow-right.svg"
              )}
              size="s"
            />
          }
          iconPosition={"right"}
          type={"icon"}
        >
          With icon right
        </Link>
      </Section>
    );
  })
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section
        background={select("Background", ["white"].concat(Section.backgrounds))}
        fullWidth={boolean("Full width", false)}
      >
        <Block height="6rem" text="الأطفال" />
      </Section>
    </Language>
  ));
