import PropTypes from "prop-types";
import React, { Component } from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

const allBreakpoint = {
  xsmall: window.matchMedia("(max-width: 319px)"),
  small: window.matchMedia("(min-width: 320px) and (max-width: 767px)"),
  medium: window.matchMedia("(min-width: 768px) and (max-width: 1023px)"),
  desktop: window.matchMedia("(min-width: 1024px) and (max-width: 1280px)"),
  xlarge: window.matchMedia("(min-width: 1281px)")
};

const filterBreakpoint = list => {
  return list.reduce((obj, key) => ({ ...obj, [key]: allBreakpoint[key] }), {});
};

class MediaQuery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breakpoint: filterBreakpoint(this.props.breakpoint)
    };
  }

  static displayName = "MediaQuery";

  static propTypes = {
    /**
     * Child nodes passed to the component
     */
    children: PropTypes.node,
    /**
     * Responsive device breakpoints
     */
    breakpoint: PropTypes.array.isRequired
  };

  getClassName = getClassNameFactory(MediaQuery.displayName);

  componentDidMount() {
    const handler = e => this.setState({ matches: e.matches });
    const { breakpoint } = this.state;

    for (const size in breakpoint) {
      window
        .matchMedia(breakpoint[size].media)
        .addEventListener("resize", handler);
    }
  }

  render() {
    const { children } = this.props;
    const { xsmall, small, medium, desktop, xlarge } = this.state.breakpoint;
    const isXSmall = xsmall && xsmall.matches;
    const isSmall = small && small.matches;
    const isMedium = medium && medium.matches;
    const isDesktop = desktop && desktop.matches;
    const isXlarge = xlarge && xlarge.matches;

    return (
      <div
        data-rehydratable={getRehydratableName(MediaQuery.displayName)}
        data-breakpoint={JSON.stringify(this.props.breakpoint)}
      >
        <div data-rehydratable-children>
          {isXSmall && children}
          {isSmall && children}
          {isMedium && children}
          {isDesktop && children}
          {isXlarge && children}
        </div>
      </div>
    );
  }
}

export default MediaQuery;
