import React from "react";
import TickerItem from "./TickerItem";

// eslint-disable-next-line require-await
const rehydrator = async domNode => {
  const props = {
    dateTime: domNode.getAttribute("data-date-time"),
    href: domNode.getAttribute("data-href"),
    lang: domNode.getAttribute("data-lang"),
    lead: domNode.getAttribute("data-lead") === "true",
    title: domNode.getAttribute("data-title")
  };

  return <TickerItem {...props} />;
};

export default rehydrator;
