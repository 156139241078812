import React from "react";
import Chart from "./Chart";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  // NB you can't and shouldn't ever need to rehydrate functions
  const props = {
    chartLabel: readData("chart-label"),
    chartTitle: readData("chart-title"),
    color: readData("color"),
    data: readData("data"),
    height: readData("height"),
    isRehydrating: true,
    labels: readData("labels"),
    numberOfGrids: readData("number-of-grids"),
    type: readData("type"),
    width: readData("width")
  };

  return <Chart {...props} />;
};
