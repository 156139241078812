import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { Language } from "@emcm-ui/component-theme";
import Block from "@emcm-ui/component-block";
import Alignment from "./index";
import "./Alignment.css";
import AlignmentREADME from "../README.md";

const stories = storiesOf("Components/Alignment", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AlignmentREADME));

stories
  .add("Default", () => (
    <Alignment
      horizontal={select("Horizontal", Alignment.horizontalAlignments, "left")}
      vertical={select("Vertical", Alignment.verticalAlignments, "top")}
    >
      <div style={{ height: "200px", width: "200px" }}>
        <Block height="200px">Block</Block>
      </div>
    </Alignment>
  ))
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Alignment
        horizontal={select(
          "Horizontal",
          Alignment.horizontalAlignments,
          "left"
        )}
        vertical={select("Vertical", Alignment.verticalAlignments, "top")}
      >
        <div style={{ height: "200px", width: "200px" }}>
          <Block height="200px">Block</Block>
        </div>
      </Alignment>
    </Language>
  ));
