import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Column = ({ children, hiddenOnMobile }) => {
  const getClassName = getClassNameFactory("SiteHeaderColumn");

  return (
    <div
      className={getClassName({
        modifiers: classNames({
          hiddenOnMobile
        })
      })}
    >
      {children}
    </div>
  );
};

Column.propTypes = {
  /**
   * The contents of the column. If this is a typical navigation menu, you should use `SiteHeader.NavList` here.
   */
  children: PropTypes.node,

  /**
   * Whether or not this column is hidden at smaller breakpoints.
   */
  hiddenOnMobile: PropTypes.bool
};

Column.displayName = "SiteHeader.Column";

export default Column;
