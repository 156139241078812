import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import VerticalNav from "./VerticalNav";
import Menu from "./components/Menu";
import Item from "./components/Item";
import Icon from "@emcm-ui/component-icon";

const menuChildrens = [];
const menuClassName = getClassNameFactory("VerticalNavMenu");
const itemClassName = getClassNameFactory("VerticalNavItem");

const clean = node => {
  for (let n = 0; n < node.childNodes.length; n++) {
    const child = node.childNodes[n];

    if (
      child.nodeType === Node.COMMENT_NODE ||
      (child.nodeType === Node.TEXT_NODE && !/\S/.test(child.nodeValue))
    ) {
      node.removeChild(child);
      n--;
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      clean(child);
    }
  }
};

const rehydrateChildrens = async (domNode, rehydrateChildren, children) => {
  if (
    children &&
    children.props &&
    children.props.className.includes(menuClassName())
  ) {
    const menuLabel = children.props["data-label"];
    const menuNode = domNode.querySelector(`[data-label="${menuLabel}"]`);

    menuChildrens[menuLabel] = await rehydrateChildren(menuNode);
    if (
      menuChildrens[menuLabel] &&
      menuChildrens[menuLabel].props.children.props.children.props.children
    ) {
      await rehydrateChildrens(
        domNode,
        rehydrateChildren,
        menuChildrens[menuLabel].props.children.props.children.props.children,
        menuClassName
      );
    }
  }
  for (let i = 0; i <= children.length; i++) {
    if (children[i] && children[i].props) {
      if (children[i].props.className.includes(menuClassName())) {
        const menuLabel = children[i].props["data-label"];
        const menuNode = domNode.querySelector(`[data-label="${menuLabel}"]`);

        menuChildrens[menuLabel] = await rehydrateChildren(menuNode);
        if (
          menuChildrens[menuLabel] &&
          menuChildrens[menuLabel].props.children.props.children.props.children
        ) {
          await rehydrateChildrens(
            domNode,
            rehydrateChildren,
            menuChildrens[menuLabel].props.children.props.children.props
              .children,
            menuClassName
          );
        }
      }
    }
  }
};

const rehydrator = async (domNode, rehydrateChildren) => {
  const getClassName = getClassNameFactory("VerticalNav");
  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "children" })}`
  );

  clean(childrenNode);
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  await rehydrateChildrens(
    domNode,
    rehydrateChildren,
    reactifiedChildren.props.children &&
    reactifiedChildren.props.children.length
      ? reactifiedChildren.props.children
      : reactifiedChildren
  );

  const props = {
    isRehydrating: true
  };

  const renderItem = element => {
    const rehydrationProps = {
      isRehydrating: true,
      iconType: element.props["data-icon-path"] ? (
        <Icon path={element.props["data-icon-path"]} size="s" />
      ) : null,
      label: element.props["data-label"],
      href: element.props["data-href"],
      isAjax: element.props["data-is-ajax"] === "true",
      isSelected: Boolean(element.props["data-is-selected"]),
      isUrlHashActive: element.props["data-is-urlactive"]
    };

    return <Item {...rehydrationProps} key={rehydrationProps.label} />;
  };

  const renderMenu = element => {
    const menuLabel = element.props["data-label"];
    const childrens = [];

    if (
      menuChildrens[menuLabel].props.children.props.children.props.children
        .props
    ) {
      const child =
        menuChildrens[menuLabel].props.children.props.children.props.children;

      if (child.props && child.props.className.includes(menuClassName())) {
        childrens.push(renderMenu(child));
      } else if (child.props && child.props.className.includes(itemClassName()))
        childrens.push(renderItem(child));
    } else if (
      menuChildrens[menuLabel].props.children.props.children.props.children
    )
      menuChildrens[menuLabel].props.children.props.children.props.children.map(
        child => {
          if (child.props && child.props.className.includes(menuClassName())) {
            childrens.push(renderMenu(child));
          } else if (
            child.props &&
            child.props.className.includes(itemClassName())
          )
            childrens.push(renderItem(child));

          return false;
        }
      );
    const rehydrationProps = {
      isRehydrating: true,
      label: menuLabel,
      children: childrens
    };

    return <Menu {...rehydrationProps} key={rehydrationProps.label} />;
  };

  const returnSingleMenu = children => {
    if (children.props.className === menuClassName()) {
      return renderMenu(children);
    } else if (children.props.className.includes(itemClassName())) {
      return renderItem(children);
    }
  };

  return (
    <VerticalNav {...props}>
      {reactifiedChildren.props.children &&
      reactifiedChildren.props.children.length
        ? reactifiedChildren.props.children.map(element => {
            if (element.props && element.props.className === menuClassName()) {
              return renderMenu(element);
            } else if (
              element.props &&
              element.props.className === itemClassName()
            )
              return renderItem(element);

            return false;
          })
        : returnSingleMenu(reactifiedChildren)}
    </VerticalNav>
  );
};

export default rehydrator;
