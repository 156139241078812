import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Item = ({ children, setRoleAttribute }) => {
  const getClassName = getClassNameFactory("Grid");

  return (
    <div
      className={getClassName({ descendantName: "item" })}
      {...(setRoleAttribute ? { role: "listitem" } : {})}
    >
      {children}
    </div>
  );
};

/* eslint-disable max-len */
Item.propTypes = {
  /**
   * The contents of the item.
   */
  children: PropTypes.node,
  /**
   * The role attribute append in div.
   */
  setRoleAttribute: PropTypes.bool
};
/* eslint-enable max-len */

Item.displayName = "Grid.Item";

export default Item;
