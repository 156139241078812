import mountScript from "@emcm-ui/utility-mount-script";
import { VIMEO_SCRIPT_URL } from "./config";

export default async () => {
  if (window.Vimeo) {
    return window.Vimeo;
  }

  try {
    await mountScript(VIMEO_SCRIPT_URL);
  } catch (e) {
    const vimeoException = new Error("Unable to load the Vimeo API.", e);

    vimeoException.name = "VimeoLoadError";

    throw vimeoException;
  }

  return window.Vimeo;
};
