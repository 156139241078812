import BaseItem from "../BaseItem";
import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Item = props => {
  const getClassName = getClassNameFactory("GlobalHeaderItem");

  return (
    <li className={getClassName()}>
      <BaseItem {...props} />
    </li>
  );
};

Item.propTypes = {
  /**
   * The children to show in this `Item`'s menu
   */
  children: PropTypes.node,

  /**
   * A destination that this `Item` links to. Acts as a fallback href if children are specified.
   */
  href: PropTypes.string,

  /**
   * Icon node. Should be an icon from `@emcm-ui/component-icon`.
   */
  icon: PropTypes.node,

  /**
   * The label to show on this Item.
   */
  label: PropTypes.string,

  /**
   * A long variant of the label to show on this Item. This will only be shown above narrow breakpoints, and will override `label`.
   */
  longLabel: PropTypes.string,

  /**
   * Narrow variant of the menu on desktop. Used for single column menus. If this is omitted, `Item` will do it's best to set this automatically.
   */
  narrow: PropTypes.bool,

  /**
   * Should we use the graident background style
   */
  gradientBackground: PropTypes.bool
};
Item.displayName = "GlobalHeader.Item";

export default Item;
