import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Image from "@emcm-ui/component-image";

const Avatar = ({ alt, fillColor, sizes, src, srcSet, width }) => {
  const getClassName = getClassNameFactory(Avatar.displayName);
  const divProps = { className: getClassName() };

  if (width) {
    divProps.style = { maxHeight: `${width}px`, width: `${width}px` };
  }

  return (
    <div {...divProps}>
      <Image
        alt={alt}
        fillColor={fillColor}
        fit="cover"
        sizes={sizes}
        src={src}
        srcSet={srcSet}
      />
    </div>
  );
};

Avatar.displayName = "Avatar";

/* eslint-disable max-len */
Avatar.propTypes = {
  /**
   * Alternative text for the image, shown if the image cannot be displayed.
   */
  alt: PropTypes.string.isRequired,

  /**
   * Fallback hexadecimal fill color. The intent is to increase perceived loading speed. Do not use on images with transparency. Requires that an explicit width and height be set for the image.
   */
  fillColor: PropTypes.string,

  /**
   * The HTML [sizes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes attribute. Required if srcSet is defined.
   */
  sizes: PropTypes.string,

  /**
   * Image `src`. Used if srcSet is not defined, or as a fallback.
   */
  src: PropTypes.string.isRequired,

  /**
   * A comma-separated list indicating a set of possible image sources for the user agent to use for different screen sizes. Same format as [srcSet](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#Example_4_Using_the_srcset_and_sizes_attributes), i.e. `my-image-200.png 200w, my-image-200.png 200w`.
   */
  srcSet: PropTypes.string,

  /**
   * Width of the image, in pixels
   */
  width: PropTypes.number
};
/* eslint-enable max-len */

export default Avatar;
