import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import Selected from "./Selected";

const rehydrator = async (domNode, rehydrateChildren, extra) => {
  const getClassName = getClassNameFactory("SearchFiltersSelected");

  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "items" })}`
  );

  const reactifiedChildren = await rehydrateChildren(childrenNode, extra);

  const rehydratedProps = {
    clearAllHref: domNode.getAttribute("data-clear-all-href"),
    clearAllText: domNode.getAttribute("data-clear-all-text"),
    title: domNode.getAttribute("data-title")
  };

  return <Selected {...rehydratedProps}>{reactifiedChildren}</Selected>;
};

export default rehydrator;
