import React from "react";
import Recaptcha from "./Recaptcha";

export default async (domNode, rehydrateChildren) => {
  const props = {
    label: domNode.getAttribute("data-label"),
    apiKey: domNode.getAttribute("data-sitekey"),
    captchaId: domNode.getAttribute("data-captcha-id"),
    validationMessage: domNode.getAttribute("data-validation-message")
  };

  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const children = await rehydrateChildren(childrenNode);

  return <Recaptcha {...props}>{children}</Recaptcha>;
};
