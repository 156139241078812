import mountScript from "@emcm-ui/utility-mount-script";

export default async loadSrcScript => {
  if (window.kWidget) {
    return window.kWidget;
  }

  try {
    await mountScript(loadSrcScript);
  } catch (e) {
    const kalturaException = new Error("Unable to load the Kaltura API.", e);

    kalturaException.name = "KalturaLoadError";

    throw kalturaException;
  }

  return window.kWidget;
};
