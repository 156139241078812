import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, boolean, select, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import { anchorPoints } from "@emcm-ui/component-image";
import { Language } from "@emcm-ui/component-theme";
import ImageCard from "./index";
import "./ImageCard.css";
import Icon from "@emcm-ui/component-icon";

import ArticleCardFooter from "../../footers/ArticleCardFooter";
import LinkCardFooter from "../../footers/LinkCardFooter";
import VideoCardFooter from "../../footers/VideoCardFooter";
import DownloadCardFooter from "../../footers/DownloadCardFooter";

import CardREADME from "../../../../README.md";

const stories = storiesOf("Components/Card/Cards/ImageCard", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(CardREADME));

const headingRanks = [""].concat(ImageCard.headingRanks);

const footers = {
  article: (
    <ArticleCardFooter
      attributionName="Sophie P."
      dateTime="2016-03-02 13:57"
      readingTime="5 min"
      icon={<Icon path="./icons/ui/white/time.svg" size="xs" />}
    />
  ),
  link: (
    <LinkCardFooter
      title="Link"
      type="icon"
      icon={<Icon path="./icons/ui/white/arrow-right.svg" size="xs" />}
    />
  ),
  extrenalLink: (
    <LinkCardFooter
      title="Link"
      type="icon"
      icon={<Icon path="./icons/ui/white/external.svg" size="s" />}
    />
  ),
  video: (
    <VideoCardFooter
      runningTime="25 min"
      icon={<Icon path="./icons/ui/white/playoutline.svg" size="xs" />}
    />
  ),
  download: (
    <DownloadCardFooter
      dateTime="2016-03-02 13:57"
      fileLabel="Download"
      disabled={false}
      size="20.54KB"
      href="https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      icon={<Icon path="./icons/ui/white/download.svg" size="s" />}
    />
  )
};

const footersAr = {
  article: (
    <ArticleCardFooter
      attributionName="Sophie P."
      dateTime="2016-03-02 13:57"
      readingTime="5 دقائق"
      icon={<Icon path="./icons/ui/white/time.svg" size="xs" />}
    />
  ),
  link: (
    <LinkCardFooter
      title="حلقة الوصل"
      type="icon"
      icon={<Icon path="./icons/ui/white/arrow-right.svg" size="s" />}
    />
  ),
  externalLink: (
    <LinkCardFooter
      title="حلقة الوصل"
      type="icon"
      icon={<Icon path="./icons/ui/white/external.svg" size="s" />}
    />
  ),
  video: (
    <VideoCardFooter
      runningTime="25 دقيقة"
      icon={<Icon path="./icons/ui/white/playoutline.svg" size="xs" />}
    />
  ),
  download: (
    <DownloadCardFooter
      dateTime="2016-03-02 13:57"
      fileLabel={text("fileName", "دقائق.zip")}
      disabled={false}
      size="20.54KB"
      href="https://www.refinitiv.com/content/dam/marketing/bundle-emcm/static/fonts/all-characters/proximanova-regular-webfont.woff2"
      icon={<Icon path="./icons/ui/white/download.svg" size="s" />}
    />
  )
};

stories
  .add("Default", () => (
    <div style={{ maxWidth: 360 }}>
      <ImageCard
        href={text("href", "https://www.example.com")}
        size={select("size", ImageCard.sizes, "small")}
        eyebrow={text("eyebrow", "Eyebrow")}
        heading={text("heading", "Heading")}
        headingRank={select("headingRank", headingRanks)}
        footer={footers[select("footer", Object.keys(footers), "article")]}
        imgSrc={text("imgSrc", "test-images/16-9/large_2.jpg")}
        imgSrcSet={text(
          "imgSrc",
          "test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
        )}
        imgAnchor={select("imgAnchor", anchorPoints, "C")}
        imgGradient={boolean("imgGradient", false)}
        largeHeading={boolean("largeHeading", false)}
      />
    </div>
  ))
  .add("Language: Arabic (RTL)", () => (
    <div style={{ maxWidth: 360 }}>
      <Language code="ar">
        <ImageCard
          href={text("href", "https://www.example.com")}
          size={select("size", ImageCard.sizes, "small")}
          eyebrow={text("eyebrow", "حاجب العين")}
          heading={text("heading", "عنوان")}
          headingRank={select("headingRank", headingRanks)}
          footer={
            footersAr[select("footer", Object.keys(footersAr), "article")]
          }
          imgSrc={text("imgSrc", "test-images/16-9/large_2.jpg")}
          imgSrcSet={text(
            "imgSrc",
            "test-images/16-9/large_2.jpg 2000w, test-images/16-9/medium_2.jpg 800w, test-images/16-9/small_2.jpg 400w"
          )}
          imgAnchor={select("imgAnchor", anchorPoints, "C")}
          imgGradient={boolean("imgGradient", false)}
          largeHeading={boolean("largeHeading", false)}
        />
      </Language>
    </div>
  ));
