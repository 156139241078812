import fetchMock from "fetch-mock";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { storiesOf } from "@storybook/react";
import { action } from "@storybook/addon-actions";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Block from "@emcm-ui/component-block";
import { Language } from "@emcm-ui/component-theme";
import Section from "@emcm-ui/component-section";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import getAnalytics from "@emcm-ui/utility-analytics";
import Tabs from "./index";
import "./Tabs.css";
import TabsREADME from "../README.md";

const stories = storiesOf("Components/Tabs", module);

const analytics = getAnalytics("storybook", action);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(TabsREADME));

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));
const halfSecond = 500;

stories
  .add("Default", () => {
    const tab2Location = "tab2.html";
    const tab4Location = "tab4.html";
    const tab2Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="green" height="32em" />
    );
    const tab4Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #4 AJAXed content" color="green" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(tab2Location, async () => {
      await delay(halfSecond);

      return tab2Response;
    });
    fetchMock.get(tab4Location, async () => {
      await delay(halfSecond);

      return tab4Response;
    });

    return (
      <Section>
        <Tabs
          analytics={analytics}
          layoutVariant={
            select("layoutVariant", [
              null,
              "verticalAtDesktop",
              "verticalAtDesktopWrapped"
            ]) || null
          }
        >
          <Tabs.Panel id="tab1" tabTitle="Tab 1">
            <Block text="Panel 1" color="blue" height="32em" />
          </Tabs.Panel>

          <Tabs.Panel
            id="tab2"
            tabTitle="AJAX Tab 2"
            contentLocation={tab2Location}
          />

          <Tabs.Panel id="tab3" tabTitle="Tab 3">
            <Block text="Panel 3" color="purple" height="32em" />
          </Tabs.Panel>

          <Tabs.Panel
            id="tab4"
            tabTitle="AJAX Tab 4"
            contentLocation={tab4Location}
          />
        </Tabs>
      </Section>
    );
  })
  .add("Wrapped", () => {
    const tab2Location = "tab2.html";
    const tab4Location = "tab4.html";
    const tab2Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="green" height="32em" />
    );
    const tab4Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #4 AJAXed content" color="green" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(tab2Location, async () => {
      await delay(halfSecond);

      return tab2Response;
    });
    fetchMock.get(tab4Location, async () => {
      await delay(halfSecond);

      return tab4Response;
    });

    return (
      <Section>
        <Tabs analytics={analytics} layoutVariant="wrapped">
          <Tabs.Panel id="tab1" tabTitle="Tab 1">
            <Block text="Panel 1" color="blue" height="32em" />
          </Tabs.Panel>

          <Tabs.Panel
            id="tab2"
            tabTitle="AJAX Tab 2"
            contentLocation={tab2Location}
          />

          <Tabs.Panel id="tab3" tabTitle="Tabbed Content Title  3">
            <Block text="Panel 3" color="purple" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3a" tabTitle="Tabbed Content Title 3a">
            <Block text="Panel 3a" color="purple" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3b" tabTitle="Tabbed Content Title  3b">
            <Block text="Panel 3b" color="purple" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3c" tabTitle="Tabbed Content Title  3c">
            <Block text="Panel 3c" color="purple" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3d" tabTitle="Tabbed Content Title  3d">
            <Block text="Panel 3d" color="purple" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3e" tabTitle="Tabbed Content Title  3e">
            <Block text="Panel 3e" color="purple" height="32em" />
          </Tabs.Panel>
          <Tabs.Panel id="tab3f" tabTitle="Tabbed Content Title  3f">
            <Block text="Panel 3f" color="purple" height="32em" />
          </Tabs.Panel>

          <Tabs.Panel
            id="tab4"
            tabTitle="AJAX Tab 4"
            contentLocation={tab4Location}
          />
        </Tabs>
      </Section>
    );
  })
  .add("Language: Arabic (RTL)", () => (
    <Language code="ar">
      <Section>
        <Tabs
          analytics={analytics}
          layoutVariant={
            select("layoutVariant", [null, "verticalAtDesktop"]) || null
          }
        >
          <Tabs.Panel id="tab1" tabTitle="الإنسان">
            اعتُمد بموجب قرار الجمعية العامة 217 ألف (د-3) المؤرخ في 10 كانون
            الأول / ديسمبر 1948.
          </Tabs.Panel>

          <Tabs.Panel id="tab2" tabTitle="لحقوق">
            لمّا كان الاعتراف بالكرامة المتأصلة في جميع أعضاء الأسرة البشرية
            وبحقوقهم المتساوية الثابتة هو أساس الحرية والعدل والسلام في العالم.
          </Tabs.Panel>

          <Tabs.Panel id="tab3" tabTitle="العالمي">
            ولما كان تناسي حقوق الإنسان وازدراؤها قد أفضيا إلى أعمال همجية آذت
            الضمير الإنساني. وكان غاية ما يرنو إليه عامة البشر انبثاق عالم يتمتع
            فيه الفرد بحرية القول والعقيدة ويتحرر من الفزع والفاقة.
          </Tabs.Panel>
        </Tabs>
      </Section>
    </Language>
  ))
  .add("Rehydrated", () => {
    const tab2Location = "tab2.html";
    const tab2Response = ReactDOMServer.renderToStaticMarkup(
      <Block text="Panel #2 AJAXed content" color="green" height="32em" />
    );

    fetchMock.restore();
    fetchMock.get(tab2Location, async () => {
      await delay(halfSecond);

      return tab2Response;
    });

    return (
      <StaticRenderer>
        <Tabs>
          <Tabs.Panel id="tab1" tabTitle="Tab 1">
            <Block text="Panel 1" color="blue" height="32em" />
          </Tabs.Panel>

          <Tabs.Panel
            id="tab2"
            tabTitle="AJAX Tab 2"
            contentLocation={tab2Location}
          />

          <Tabs.Panel id="tab3" tabTitle="Tab 3">
            <Block text="Panel 3" color="purple" height="32em" />
          </Tabs.Panel>
        </Tabs>
      </StaticRenderer>
    );
  });
