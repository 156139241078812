import mountScript from "@emcm-ui/utility-mount-script";
import { RECAPTCHA_SCRIPT_URL } from "./config";

export default async () => {
  if (window.Recaptcha) {
    return window.Recaptcha;
  }

  try {
    await mountScript(RECAPTCHA_SCRIPT_URL);
  } catch (e) {
    const recaptchaException = new Error(
      "Unable to load the Recaptcha API.",
      e
    );

    recaptchaException.name = "RecaptchaLoadError";

    throw recaptchaException;
  }

  return window.Recaptcha;
};
