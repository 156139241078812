import React from "react";
import Chapter from "./Chapter";

// eslint-disable-next-line require-await
const rehydrator = async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };
  const props = {
    active: readData("active"),
    href: readData("href"),
    prefix: readData("prefix"),
    title: readData("title")
  };

  return <Chapter {...props} />;
};

export default rehydrator;
