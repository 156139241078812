import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const FilterSection = ({ children, title }) => {
  const getClassName = getClassNameFactory("SearchFiltersFilterSection");

  return (
    <div className={getClassName()}>
      <div
        className={getClassName({
          descendantName: "title",
          utilities: "typographySmallCaps"
        })}
      >
        {title}
      </div>

      <ul className={getClassName({ descendantName: "items" })}>{children}</ul>
    </div>
  );
};

FilterSection.displayName = "SearchFilters.FilterSection";

FilterSection.propTypes = {
  /**
   * The children of this section. Should be a `SearchFilters.FilterItem` or `SearchFilters.FilterGroup`.
   */
  children: PropTypes.node,

  /**
   * The title of this group.
   */
  title: PropTypes.string
};

export default FilterSection;
