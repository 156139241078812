import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

import Image, { anchorPoints } from "@emcm-ui/component-image";
import VideoLoop from "@emcm-ui/component-video-loop";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const Poster = ({
  alignBottom,
  children,
  height,
  imageAnchor,
  mobileImageAnchor,
  imageInlineAtNarrow,
  imageOverlay,
  imageSrc,
  imageSrcSet,
  enableMobileBackground,
  mobileImageSrc,
  share,
  videoSrc,
  videoWidth,
  videoHeight
}) => {
  const getClassName = getClassNameFactory(Poster.displayName);
  const mediaProps = {
    anchor: imageAnchor,
    fit: "cover",
    overlay: imageOverlay
  };
  const mobileMediaProps = {
    anchor: mobileImageAnchor,
    fit: "cover",
    overlay: imageOverlay
  };
  const media = videoSrc ? (
    <VideoLoop
      src={videoSrc}
      videoWidth={videoWidth}
      videoHeight={videoHeight}
      {...mediaProps}
    />
  ) : (
    <Image
      alt=""
      src={imageSrc}
      srcSet={imageSrcSet}
      sizes={Image.sizes.edgeToEdge}
      {...mediaProps}
    />
  );

  const getMedia = checkMobileBackground => {
    if (checkMobileBackground) {
      return (
        <React.Fragment>
          <div className={getClassName({ descendantName: "image-mobile" })}>
            <Image
              alt=""
              src={mobileImageSrc}
              sizes={Image.sizes.edgeToEdge}
              {...mobileMediaProps}
            />
          </div>
          <div className={getClassName({ descendantName: "image-desktop" })}>
            {media}
          </div>
        </React.Fragment>
      );
    }

    return (
      <div className={getClassName({ descendantName: "image" })}>{media}</div>
    );
  };

  const isImageInlineAtNarrow = imageInlineAtNarrow
    ? getClassName({ descendantName: "imageWrapper" })
    : null;

  return (
    <div
      className={getClassName({
        modifiers: classNames(alignBottom && "alignBottom", height)
      })}
    >
      <div className={isImageInlineAtNarrow}>
        {getMedia(enableMobileBackground)}
      </div>

      <div className={getClassName({ descendantName: "content" })}>
        <div className={getClassName({ descendantName: "contentInner" })}>
          {children}
        </div>
        {share && (
          <div className={getClassName({ descendantName: "share" })}>
            {share}
          </div>
        )}
      </div>
    </div>
  );
};

Poster.displayName = "Poster";

Poster.heights = ["xs", "s", "m", "l"];

Poster.propTypes = {
  /**
   * Align content to bottom of Poster
   */
  alignBottom: PropTypes.bool,
  /**
   * Markup elements or components to render on Poster (usually a single Grid)
   */
  children: PropTypes.node,
  /**
   * T-shirt-sized (minimum) height of Poster
   */
  height: PropTypes.oneOf(Poster.heights),
  /**
   * Anchor point for Poster image
   */
  imageAnchor: PropTypes.oneOf(anchorPoints),
  mobileImageAnchor: PropTypes.oneOf(anchorPoints),
  /**
   * Optionally split Poster image above the content at narrow viewport
   */
  imageInlineAtNarrow: PropTypes.bool,
  /**
   * Apply an overlay to the Poster image, e.g. a scrim gradient for text
   * legibility. Should not be used if the image has a rasterized overlay.
   */
  imageOverlay: PropTypes.oneOf(Image.overlays),
  /**
   * Poster image `src`. Used if srcSet is not defined, or as a fallback.
   */
  mobileImageSrc: PropTypes.string,
  /**
   * Comma-separated list indicating a set of possible Poster image sources
   * for the user agent to use at mobile view
   */
  imageSrc: PropTypes.string,
  /**
   * Comma-separated list indicating a set of possible Poster image sources
   * for the user agent to use at different screen sizes
   */
  imageSrcSet: PropTypes.string,
  /**
   * Poster video `src`.
   */
  videoSrc: PropTypes.string,
  /**
   * Natural width of the source video file, required when using VideoLoop
   */
  videoWidth: PropTypes.number,
  /**
   * Natural height of the source video file, required when using VideoLoop
   */
  videoHeight: PropTypes.number,
  /**
   * Component (i.e. SocialDropdown) for sharing the page url
   */
  share: PropTypes.node,
  enableMobileBackground: PropTypes.bool
  /**
   * This is used to enable Poster images at background for mobile view, else uses desktop view
   */
};

export default Poster;
