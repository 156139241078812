import React from "react";
import { storiesOf } from "@storybook/react";
import { number, withKnobs, text } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import ContentLike from "./index";
import "./ContentLike.css";
import ContentLikeREADME from "../README.md";

const stories = storiesOf("Components/ContentLike", module);

const handleClick = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve("clap");
    }, 10); // eslint-disable-line no-magic-numbers
  });
};

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(ContentLikeREADME));

stories
  .add("Default", () => (
    <ContentLike
      text={text("Text", "Found this article helpful?")}
      count={number("Count", 0)}
      handleClick={handleClick}
    />
  ))

  .add("Rehydrated", () => (
    <StaticRenderer>
      <ContentLike
        text={text("Text", "Found this article helpful?")}
        count={number("Count", 1000)} // eslint-disable-line no-magic-numbers
        handleClick={handleClick}
      />
    </StaticRenderer>
  ));
