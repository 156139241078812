import PropTypes from "prop-types";

const SchemaParentOrganization = () => null;

SchemaParentOrganization.displayName = "SchemaParentOrganization";

SchemaParentOrganization.propTypes = {
  /**
   * The name of the parent organization
   */
  name: PropTypes.string,

  /**
   * The main website for the parent organization
   */
  url: PropTypes.string
};

SchemaParentOrganization.defaultProps = {
  name: "LSEG",
  url: "https://www.lseg.com/"
};

export default SchemaParentOrganization;
