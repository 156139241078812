import PropTypes from "prop-types";
import React, { Component } from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

import { Provider as CurrentOpenTabPanelProvider } from "./currentOpenTabPanelContext";
import { Provider as DefaultOpenTabPanelProvider } from "./defaultOpenTabPanelContext";
import { Provider as SetOpenTabPanelProvider } from "./setOpenTabPanelContext";

import Tab from "./components/Tab";
import TabList from "./components/TabList";
import TabPanel from "./components/TabPanel";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

class Flyouts extends Component {
  static displayName = "SiteHeaderFlyouts";

  static propTypes = {
    /**
     * The content of the flyout.
     * These should be of type `SiteHeader.Flyouts.TabPanel`.
     */
    children: PropTypes.node,
    /**
     * Href for summary link below list, required if `summaryLinkText`.
     */
    summaryLinkHref: PropTypes.string,
    /**
     * Text for summary link below list, required if `summaryLinkHref`.
     */
    summaryLinkText: PropTypes.string
  };

  static getFlyouts(children) {
    const tabs = [];

    React.Children.forEach(children, child => {
      if (child.type === TabPanel) {
        tabs.push({
          title: child.props.tabTitle,
          relatedId: child.props.id
        });
      } else if (child.type === React.Fragment) {
        tabs.push(...Flyouts.getFlyouts(child.props.children));
      }
    });

    return tabs;
  }

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(Flyouts.displayName);

    const tabs = Flyouts.getFlyouts(props.children);

    this.state = {
      currentOpenPanel: null,
      defaultOpenPanel: (tabs[0] && tabs[0].relatedId) || null,
      tabs
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleTabListKeyDown = this.handleTabListKeyDown.bind(this);
    this.setOpenPanel = this.setOpenPanel.bind(this);
  }

  setOpenPanel(panelId) {
    this.setState({ currentOpenPanel: panelId });
  }

  handleTabListKeyDown(e) {
    const siteHeaderViewportWidth = 1280;

    /*
     * Only apply this behaviour above the SiteHeader viewport width, where the
     * "tabs" UI pattern is used.
     */
    if (window.innerWidth < siteHeaderViewportWidth) {
      return;
    }

    const currentOpenPanelIndex = this.state.tabs.findIndex(
      tab => tab.relatedId === this.state.currentOpenPanel
    );

    const direction = getComputedStyle(this.ref).direction;
    const nextKey = "ArrowDown";
    const previousKey = "ArrowUp";
    const focusPanelKey = direction === "ltr" ? "ArrowRight" : "ArrowLeft";

    if ([nextKey, previousKey].includes(e.key)) {
      let newOpenPanel;

      if (e.key === previousKey) {
        newOpenPanel = this.state.tabs[Math.max(0, currentOpenPanelIndex - 1)]
          .relatedId;
      } else {
        newOpenPanel = this.state.tabs[
          Math.min(this.state.tabs.length - 1, currentOpenPanelIndex + 1)
        ].relatedId;
      }

      this.setOpenPanel(newOpenPanel);
    } else if (e.key === focusPanelKey || e.key === "Tab") {
      // See https://inclusive-components.design/tabbed-interfaces/#aproblemreadingpanels
      const openPanel = this.ref.querySelector(
        `#${this.state.currentOpenPanel}`
      );

      if (openPanel) {
        openPanel.focus();
      }
    }
  }

  handleTabClick(e, relatedId) {
    e.preventDefault();
    const siteHeaderViewportWidth = 1280;

    this.setOpenPanel(relatedId);

    /*
     * Only apply this behaviour below the SiteHeader viewport width, where the
     * "sliding panels" UI pattern is used.
     */
    if (window.innerWidth < siteHeaderViewportWidth) {
      this.ref.querySelector(`#${relatedId}`).focus();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {
      tabs: Flyouts.getFlyouts(nextProps.children)
    };

    if (newState.tabs.length === 0) {
      newState.currentOpenPanel = null;
    } else if (
      !newState.tabs
        .map(tab => tab.relatedId)
        .includes(this.state.currentOpenPanel)
    ) {
      // The previously open flyout no longer exists. Open the first flyout.
      newState.currentOpenPanel = newState.tabs[0].relatedId;
    }

    this.setState(newState);
  }

  componentDidUpdate(oldProps, oldState) {
    /*
     * Below the SiteHeader viewport width, ensure that focus is on the correct
     * Tab when closing a TabPanel.
     *
     * At or above the SiteHeader viewport width `this.state.currentOpenPanel`
     * is never `null`, so this behaviour will be skipped.
     */
    if (
      this.state.currentOpenPanel === null &&
      oldState.currentOpenPanel !== null
    ) {
      this.ref.querySelector(`a[href="#${oldState.currentOpenPanel}"]`).focus();
    }
  }

  render() {
    const { children, summaryLinkHref, summaryLinkText } = this.props;

    return (
      <div
        className={this.getClassName({
          modifiers: summaryLinkHref && summaryLinkText && `withSummaryLink`
        })}
        ref={ref => (this.ref = ref)}
        data-rehydratable={getRehydratableName(Flyouts.displayName)}
        data-summary-link-href={summaryLinkHref}
        data-summary-link-text={summaryLinkText}
      >
        <div className={this.getClassName({ descendantName: "list" })}>
          <TabList>
            {this.state.tabs.map(tab => (
              <Tab
                {...tab}
                key={tab.relatedId}
                onClick={this.handleTabClick}
                onKeyDown={this.handleTabListKeyDown}
                selected={
                  tab.relatedId === this.state.currentOpenPanel ||
                  (this.state.currentOpenPanel === null &&
                    tab.relatedId === this.state.defaultOpenPanel)
                }
              />
            ))}
          </TabList>
          {summaryLinkHref &&
            summaryLinkText && (
              <a
                className={this.getClassName({ descendantName: "summaryLink" })}
                href={summaryLinkHref}
                tabIndex="-1"
              >
                <span
                  className={this.getClassName({
                    descendantName: "summaryLinkText"
                  })}
                >
                  {summaryLinkText}
                </span>
                <span
                  className={this.getClassName({
                    descendantName: "summaryLinkIcon"
                  })}
                >
                  <SVGIcon name="arrow" size="s" />
                </span>
              </a>
            )}
        </div>

        <div className={this.getClassName({ descendantName: "panel" })}>
          <SetOpenTabPanelProvider value={this.setOpenPanel}>
            <CurrentOpenTabPanelProvider value={this.state.currentOpenPanel}>
              <DefaultOpenTabPanelProvider value={this.state.defaultOpenPanel}>
                {children}
              </DefaultOpenTabPanelProvider>
            </CurrentOpenTabPanelProvider>
          </SetOpenTabPanelProvider>
        </div>
      </div>
    );
  }
}

Flyouts.TabPanel = TabPanel;

export default Flyouts;
