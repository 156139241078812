import React from "react";
import GoogleMap from "./GoogleMap";

// eslint-disable-next-line require-await
export default async domNode => {
  const props = {
    id: domNode.getAttribute("data-id"),
    apiKey: domNode.getAttribute("data-api-key"),
    latitude: Number(domNode.getAttribute("data-latitude")),
    longitude: Number(domNode.getAttribute("data-longitude")),
    zoom: Number(domNode.getAttribute("data-zoom")),
    height: Number(domNode.getAttribute("data-height")),
    width: Number(domNode.getAttribute("data-width"))
  };

  return <GoogleMap {...props} />;
};
