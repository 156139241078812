class ConsoleAnalytics {
  // eslint-disable-next-line no-console
  constructor(logger = console.log) {
    this.logger = logger;
  }

  sendEvent(category, action, label) {
    this.logger({
      category,
      action,
      label
    });
  }
}

export default ConsoleAnalytics;
