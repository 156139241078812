import React from "react";
import ContentLike from "./ContentLike";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    isRehydrating: true,
    text: domNode.getAttribute("data-text"),
    count: Number(domNode.getAttribute("data-count"))
  };

  return <ContentLike {...props}>{reactifiedChildren}</ContentLike>;
};
