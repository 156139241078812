import PropTypes from "prop-types";
import React, { Component } from "react";

import BasePlayerEmbed from "./BasePlayerEmbed";
import loadYouTube from "../apiLoaders/loadYouTube";
import { canPublish } from "../utilities";
import { fireBuffer, fireEnd, firePause, firePlay } from "../analytics";

class YouTubePlayerEmbed extends Component {
  static propTypes = {
    videoId: PropTypes.string.isRequired,
    analytics: PropTypes.func
  };

  handleFrameReady = async iframe => {
    if (this.props.analytics || canPublish()) {
      const YT = await loadYouTube();
      const player = new YT.Player(iframe);

      player.addEventListener("onStateChange", e => {
        const videoMetadata = {
          id: this.props.videoId,
          type: "YouTube",
          title: player.getVideoData().title,
          duration: player.getDuration(),
          currentTime: player.getCurrentTime()
        };

        switch (e.data) {
          case YT.PlayerState.PLAYING:
            firePlay(videoMetadata, this.props.analytics);
            break;
          case YT.PlayerState.BUFFERING:
            fireBuffer(videoMetadata);
            break;
          case YT.PlayerState.PAUSED:
            firePause(videoMetadata, this.props.analytics);
            break;
          case YT.PlayerState.ENDED:
            fireEnd(videoMetadata, this.props.analytics);
            break;
        }
      });
    }
  };

  render() {
    const { analytics, videoId, ...extra } = this.props; // eslint-disable-line no-unused-vars
    const frameUrl = `https://www.youtube.com/embed/${videoId}?enablejsapi=1&autoplay=1&color=white&modestbranding=1&rel=0&showinfo=0&theme=light`;

    return (
      <BasePlayerEmbed
        src={frameUrl}
        onFrameReady={this.handleFrameReady}
        {...extra}
      />
    );
  }
}

export default YouTubePlayerEmbed;
