import React from "react";

import Heading from "@emcm-ui/component-heading";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import Grid from "@emcm-ui/component-grid";
import LanguageSwitcher from "@emcm-ui/component-language-switcher";
import MarkupContainer from "@emcm-ui/component-markup-container";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Table from "@emcm-ui/component-table";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";
import { Language } from "@emcm-ui/component-theme";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const PrivacyKo = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Language code="ko">
          <Section>
            <Grid variant="2/3,1/3">
              <Grid.Item>
                <VerticalSpacing size="xl">
                  <Heading rank="1" type="l">
                    プライバシーに関する声明
                  </Heading>
                </VerticalSpacing>
                <VerticalSpacing size="xl">
                  <LanguageSwitcher heading="Available in other languages">
                    <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="nl"
                      title="Nederlands"
                    />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="fr"
                      title="Français"
                    />
                    <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="it"
                      title="Italiano"
                    />
                    <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
                    <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
                    <LanguageSwitcher.Item
                      href="#"
                      lang="pt"
                      title="Português"
                    />
                    <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
                    <LanguageSwitcher.Item href="#" lang="es" title="Español" />
                    <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
                  </LanguageSwitcher>
                </VerticalSpacing>
                <RichText large>
                  <h2>소개</h2>

                  <p>
                    귀하의 개인정보와 신뢰는 당사에게 매우 중요하며, 본 개인정보
                    보호정책(“정책”)은 Thomson Reuters 및 Thomson Reuters의 전
                    세계 계열사와 자회사(“Thomson Reuters”, “당사” 또는
                    “우리”)에서 개인정보를 취급하는 방식에 대한 정보를
                    제공합니다. 본 정책은 일체의 Thomson Reuters 웹사이트,
                    애플리케이션, 제품, 소프트웨어 또는 그와 연결된 서비스(통칭
                    “서비스”)에 대해 적용됩니다. 경우에 따라 서비스는 해당
                    서비스의 특정 개인정보 취급방침이 명시된 다른 개인정보
                    보호정책에 연결될 수 있습니다.{" "}
                  </p>

                  <p>
                    본 정책을 주의하여 읽고, 당사의 개인정보 취급방침 또는
                    귀하의 개인정보 선택권과 관련하여 문의 사항이 있을 경우
                    당사의 <a href="#contact-us">데이터 보호 담당자</a>에게
                    문의하십시오. 본 정책의 업데이트 사항을 수시로 확인하는 것이
                    중요합니다. 만일 당사에서 중요한 것으로 판단되는 변경을
                    실시할 경우 관련 서비스에 관한 공지를 게시하여 귀하에게
                    알리거나 이메일 등의 기타 방법을 이용해 연락을 취할
                    것입니다.{" "}
                  </p>

                  <p>
                    <strong>
                      본 정책은 2017년 6월 1일에 마지막으로 업데이트되었습니다.{" "}
                    </strong>
                  </p>

                  <p>
                    본 정책에 관한 유용한 정보는 아래에서 찾아볼 수 있습니다.
                  </p>
                  <ul>
                    <li>
                      <a href="#personal-information">개인정보 </a>
                      <ul>
                        <li>
                          <a href="#personal-information-collected">
                            당사가 수집하는 개인정보의 유형
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-used">
                            당사가 개인정보를 사용하는 방식
                          </a>
                        </li>
                        <li>
                          <a href="#when-personal-information-shared">
                            당사가 개인정보를 공유하는 경우
                          </a>
                        </li>
                        <li>
                          <a href="#where-personal-information-stored">
                            당사가 개인정보를 보관 및 처리하는 위치
                          </a>
                        </li>
                        <li>
                          <a href="#how-personal-information-secured">
                            당사가 개인정보의 보안을 보장하는 방법
                          </a>
                        </li>
                        <li>
                          <a href="#time-personal-information-kept">
                            당사가 개인정보를 보관하는 기간
                          </a>
                        </li>
                        <li>
                          <a href="#personal-information-access">
                            귀하의 개인정보 액세스 및 수정 권한
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#cookies">쿠키 및 유사 기술</a>
                      <ul>
                        <li>
                          <a href="#what-is-a-cookie">쿠키란 무엇인가? </a>
                        </li>
                        <li>
                          <a href="#cookie-types">
                            쿠키의 유형 및 쿠키를 사용하는 이유
                          </a>
                        </li>
                        <li>
                          <a href="#managing-cookies">쿠키 관리</a>
                        </li>
                        <li>
                          <a href="#other-tracking-tech">기타 추적 기술</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#advertising">관심 기반 광고(IBA)</a>
                      <ul>
                        <li>
                          <a href="#opting-out-iba">IBA 수신 거부</a>
                        </li>
                        <li>
                          <a href="#mobile-advertising">모바일 장치의 광고</a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#dnt">추적 방지(DNT)</a>
                    </li>
                    <li>
                      <a href="#social-networks">소셜 네트워크를 통한 연결</a>
                    </li>
                    <li>
                      <a href="#third-party-services">
                        제3자 서비스에 대한 링크 및 연결
                      </a>
                    </li>
                    <li>
                      <a href="#children-privacy">아동의 개인정보 보호</a>
                    </li>
                    <li>
                      <a href="#contact-us">당사 연락 방법</a>
                    </li>
                  </ul>
                  <h2 id="personal-information">개인정보 </h2>
                  <p>
                    Thomson Reuters는 개인정보 를 책임 있는 방식으로 취급하고
                    보호할 것을 약속합니다.{" "}
                  </p>
                  <p>
                    개인정보란 식별되거나 식별 가능한 자연인과 관련된 일체의
                    정보를 뜻합니다. 식별 가능한 사람이란 특히 이름, ID 번호,
                    위치 데이터, 온라인 식별자와 같은 식별자 또는 해당 사람의
                    물리적, 생리적, 유전적, 정신적, 경제적, 문화적 또는 사회적
                    정체성으로 특정되는 요소 1개 이상을 통해 직접적이든
                    간접적이든 식별 가능한 사람을 뜻합니다.
                  </p>
                  <p>
                    당사는 당사의 서비스를 제공하기 위해 필요하거나 본 정책에
                    기술된 당사의 운영 및 비즈니스 목적을 위해 필요한 경우에
                    개인정보를 수집, 사용, 공개, 이전 및 보관합니다. 당사는
                    귀하가 자신의 정보 사용과 관하여 정보에 기초한 선택을 할 수
                    있도록 당사의 개인정보 취급방침에 대해 명확성을 보이고자
                    하며, 의문 또는 우려 사항이 있을 경우 언제든{" "}
                    <a href="#contact-us">당사 연락처</a>를 통해 문의하시기
                    바랍니다.
                  </p>

                  <h3 id="personal-information-collected">
                    당사가 수집하는 개인정보의 유형
                  </h3>
                  <p>
                    당사는 일례로 귀하가 이벤트에 등록하거나, 정보를 요청하거나,
                    당사의 서비스를 구매 또는 사용하거나, 고객 지원을 요청하는
                    등의 경우에 귀하로부터 개인정보를 수집합니다. 당사는
                    귀하에게 귀하의 이름, 주소, 전화번호, 이메일 주소, 사용자
                    이름, 비밀번호 및 귀하의 장치에 관한 정보 등과 같은 정보를
                    제공할 것을 요구할 수 있습니다. 단, Thomson Reuters에서
                    보관하는 귀하의 정보는 반드시 귀하로부터 직접 수집되는 것은
                    아닙니다. 예를 들어, 귀하의 고용주, 귀하가 속한 기타 조직
                    또는 세무/회계 전문가 또는 변호사 등의 전문 서비스
                    공급자로부터도 정보가 수집될 수 있습니다(그들이 당사의
                    서비스를 이용하는 경우). 또한 당사는 당사가 판단하기에
                    귀하가 관심을 보일 만한 서비스를 제공하고 데이터의 정확성을
                    유지하고 서비스를 제공 및 개선하기 위한 목적으로 당사의
                    파트너, 서비스 공급자 및 일반에 공개된 웹사이트 등의
                    제3자로부터 개인정보를 수집할 수 있습니다.{" "}
                  </p>

                  <p>
                    이 외에도 당사는 서비스를 관리, 보호 및 개선하고 사용 실태를
                    분석하고 사용자 환경을 개선하기 위한 목적으로 당사의 서버,
                    로그 및 기타 기술을 통해{" "}
                    <a href="#other-tracking-tech">특정 정보를 자동 수집</a>합니다.
                    당사는 본 정책에 기술된 바에 부합하거나 법으로 허용되거나
                    요구된다고 판단될 경우에 한해 개인정보를 타인과 공유합니다.
                  </p>

                  <p>
                    경우에 따라 당사는 민감한 개인정보 로 간주되는 정보를
                    수집하고 처리합니다.{" "}
                  </p>
                  <p>
                    민감한 개인정보란 개인정보의 일부로서 일반적으로
                    인종적/민족적 출신, 정치 견해, 종교적 믿음, 노조 가입,
                    신체적/정신적 건강, 기타 생체적/유전적 데이터, 성적 취향을
                    비롯한 의료 정보 또는 선호도와 관련된 모든 정보로
                    정의됩니다. 일부의 경우 민감한 개인정보에 범죄 혐의 또는
                    범죄 경력, 정밀한 지리적 위치 정보, 금융 계정 및 은행
                    계좌번호를 비롯해 주민등록번호, 운전면허번호, 여권번호와
                    같은 고유 식별자가 포함될 수 있습니다
                  </p>
                  <p>
                    예를 들어, 귀하가 당사로부터 무언가를 구매하거나 당사의
                    서비스에 가입하는 경우 당사는 금융 또는 은행 카드 정보와
                    같은 결제 정보 및 당사가 거래를 처리하기 위해 필요한 기타
                    정보를 수집합니다. 귀하 또는 귀하의 서비스 전문가가 당사의
                    세금 및 회계 서비스를 이용하는 경우 당사는 금융 및 세금
                    정보를 수집하고 처리합니다. 당사의 서비스 중 일부는 귀하의
                    환경을 사용자 지정하고 서비스의 정확성을 높이기 위해
                    귀하에게 정확한 지리적 위치를 공유할 것을 요구합니다. 또한,
                    귀하가 당사의 eRecruitment 서비스를 이용해 입사 지원을 하는
                    경우 당사는 정부에서 발행한 식별 번호 등의 정보를 수집할 수
                    있습니다. 관련 법에서 민감한 정보로 간주하는 정보는 해당
                    법에 따라 취급됩니다.{" "}
                  </p>

                  <h3 id="how-personal-information-used">
                    당사가 개인정보를 사용하는 방식
                  </h3>
                  <p>
                    당사는 다음과 같은 서비스 및 비즈니스 관련 목적을 위해
                    개인정보를 처리합니다.
                  </p>
                  <ul>
                    <li>
                      <strong>계정 설정 및 관리: </strong> 당사는 귀하의 계정을
                      설정 및 관리하고, 기술 지원, 고객 지원 및 교육 지원을
                      제공하고, 귀하의 신원을 확인하고, 중요 계정, 가입 및
                      서비스 정보를 발송하기 위해 귀하의 이름, 이메일 주소,
                      전화번호 등의 개인정보 및 귀하의 장치에 관한 정보를
                      사용합니다.
                    </li>
                    <li>
                      <strong>맞춤화:</strong> 당사는 뉴스, 조사, 보고서 및
                      비즈니스 정보 등의 맞춤형 콘텐츠를 제공 및 제안하는 동시에
                      당사 서비스에 대한 귀하의 체험을 맞춤화하기 위해
                      개인정보를 사용합니다. 당사의 서비스 중 일부는 귀하의
                      환경을 사용자 지정하고 서비스의 정확성을 높이기 위해
                      귀하에게 정확한 지리적 위치를 공유할 것을 요구합니다.
                      자신의 정확한 지리적 위치를 당사와 공유하기로 동의한
                      경우에도 언제든 모바일 장치 또는 온라인에서 개인정보 설정
                      화면으로 이동하여 위치 공유를 취소할 수 있습니다.
                    </li>
                    <li>
                      <strong>마케팅 및 이벤트:</strong> 당사는 이메일, 전화,
                      문자, 다이렉트 메일 및 온라인 등의 다양한 플랫폼을 통해
                      귀하에게 마케팅 및 이벤트 커뮤니케이션 메시지를 전달하기
                      위해 개인정보를 사용합니다. 당사가 귀하에게 마케팅
                      이메일을 보내는 경우 향후 이러한 이메일의 수신을 거부하는
                      방법에 관한 지침을 메일에 포함합니다. 또한, 당사는 귀하의
                      정보 및 마케팅 기본 설정을 관리하기 위해 이메일 기본 설정
                      센터를 유지 관리합니다. 하지만 귀하가 마케팅 이메일을 수신
                      거부하더라도 당사는 귀하의 계정 및 가입에 관한 중요 서비스
                      정보를 여전히 귀하에게 전송할 수 있다는 점을 유념하십시오.{" "}
                    </li>
                    <li>
                      <strong>설문 조사 및 여론 조사:</strong> 귀하가 설문 또는
                      여론 조사에 참여하기로 결정할 경우 귀하가 제공하는 일체의
                      개인정보가 마케팅 또는 시장 조사 목적으로 사용될 수
                      있습니다.{" "}
                    </li>
                    <li>
                      <strong>연구 및 개발: </strong> 당사는 내부 조사 및 개발을
                      위한 목적 내지 당사 서비스의 특성과 기능을 개선하고
                      테스트하기 위한 목적으로 개인정보를 사용합니다.{" "}
                    </li>
                    <li>
                      <strong>채팅방, 메시지, 커뮤니티 및 이벤트 포럼:</strong>{" "}
                      당사의 서비스 중 다수는 협업, 피어 연결, 게임 및 정보 교환
                      목적을 위해 채팅방, 메시지 서비스, 커뮤니티 및 이벤트 포럼
                      기능을 제공합니다. 서비스에 따라 귀하가 자의로 게시, 공유,
                      업로드 또는 제공하는 개인정보가 일반에 공개되고 이러한
                      서비스를 이용하는 타인에게 표시될 수 있습니다. 귀하는 게시
                      또는 공유에 대한 허가를 득한 경우를 제외하고 기밀 또는
                      타인에 관한 일체의 정보를 게시하거나 공유해서는 안 됩니다.
                      당사는 귀하의 환경을 맞춤화하고 콘텐츠 및 피어 연결 추천을
                      하기 위해 귀하가 커뮤니티 및 이벤트 프로필과 포럼을 통해
                      제공하는 정보를 사용할 수 있습니다. 이러한 서비스에는 해당
                      서비스 자체의 이용 약관 및 개인정보 보호정책(해당되는
                      경우)이 있을 수 있습니다. 당사는 당사의 이용 약관에
                      준수하도록 이러한 서비스를 관리합니다.{" "}
                    </li>
                    <li>
                      <strong>호스트 서비스:</strong> 당사의 서비스 중 일부는
                      제품 또는 솔루션 제공을 위한 필수적 요소로서 데이터 및
                      문서 저장소를 제공합니다. 당사의 고객이 저장하는 문서와
                      데이터에는 일례로 비즈니스 및 개인 세무 양식, 급여 및 금융
                      데이터, 법률 및 소송 관련 문서 등의 개인정보가 포함되어
                      있을 수 있습니다. 당사의 고객 또는 당사 고객의 대리인이
                      저장하는 일체의 정보는 오직 해당 고객 또는 해당 고객이
                      종종 권한을 부여하는 자만이 통제 및 관리하고 액세스할 수
                      있습니다. 이러한 정보에 대한 당사의 액세스는 기술 지원 등
                      중요한 비즈니스적 사유가 있는 Thomson Reuters 직원에게로
                      한정됩니다. 당사가 정보를 처리하고 저장하는 위치에 대한
                      자세한 내용을 보려면{" "}
                      <a href="#where-personal-information-stored">여기</a>를
                      클릭하십시오.
                    </li>
                    <li>
                      <strong>법적 의무:</strong> 당사는 범죄, 손실 또는 사기의
                      예방, 탐지 또는 조사 등의 법률 및 준법감시적 이유로 인해
                      개인정보를 사용하고 보존해야 할 의무를 집니다. 또한,
                      당사는 당사의 내부 및 외부 감사 요구 사항, 정보 보안 목적
                      및 당사의 견해상 필요하거나 적절하다고 판단되는 기타
                      사항을 충족하기 위한 목적상 (a) 관련 법률(귀하의 거주 국가
                      외부 지역의 법률이 포함될 수 있음)에 따라 (b) 법원, 사법
                      집행 당국, 규제 기관 및 기타 공공/정부 기관(귀하의 거주
                      국가 외부 지역의 기관이 포함될 수 있음)의 요청에 대응하기
                      위해 (c) 당사의 이용약관을 시행하기 위해 그리고 (d) 당사나
                      기타 타인의 권리, 개인정보, 안전 또는 재산을 보호하기 위해
                      개인정보를 사용할 수 있습니다.{" "}
                    </li>
                  </ul>

                  <h3 id="when-personal-information-shared">
                    당사가 개인정보를 공유하는 경우
                  </h3>
                  <p>
                    Thomson Reuters는 아래에 기술된 바와 같이 서비스를
                    제공하거나 비즈니스를 운영하기 위해 필요한 경우 개인정보를
                    공유하거나 공개합니다. 당사는 개인정보를 공유함에 있어
                    데이터 개인정보 보호 및 보안 요구 사항을 준수합니다. 당사는
                    경우에 따라 비개인, 익명 및 통계 데이터를 제3자와 공유할 수
                    있습니다. 다음은 당사가 개인정보를 공유할 수 있는 당사자 및
                    공유 이유입니다.{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>Thomson Reuters 내부:</strong> 전 세계를 무대로
                      하는 당사의 비즈니스는 다양한 Thomson Reuters 팀 및 기능
                      단위에 의해 지원되며, 만일 서비스 제공, 계정 관리, 판매 및
                      마케팅, 고객 및 기술 지원, 비즈니스 및 제품 개발 등을 위해
                      필요하다면 그러한 당사자들에게 개인정보가 제공됩니다.
                      당사의 모든 직원 및 하청업체는 개인정보를 취급함에 있어
                      당사의 데이터 개인정보 보호 및 보안 정책을 준수해야
                      합니다. 당사가 개인 데이터를 처리하는 Thomson Reuters의
                      사무소 위치 목록을 보려면{" "}
                      <a href="#where-personal-information-stored">여기</a>를
                      클릭하십시오.{" "}
                    </li>
                    <li>
                      <strong>당사의 비즈니스 파트너:</strong> 당사는 경우에
                      따라 공동 브랜드 서비스 제공, 콘텐츠 제공, 이벤트,
                      컨퍼런스 및 세미나 주최를 위해 타 조직과 파트너 관계를
                      맺습니다. 이러한 계약의 일부로서 귀하는 Thomson Reuters 및
                      당사 파트너의 고객이 될 수 있으며, 당사와 당사의 파트너는
                      귀하에 관한 정보를 수집하고 공유할 수 있습니다. Thomson
                      Reuters는 본 정책에 따라 개인정보를 취급하며, 귀하가 당사
                      파트너의 개인정보 보호정책을 검토하여 개인정보를 어떻게
                      수집, 사용 및 공유하는지 확인할 것을 권장합니다.
                    </li>
                    <li>
                      <strong>당사의 제3자 서비스 공급자:</strong> 당사는 전
                      세계의 서비스 공급자와 파트너 관계를 맺고 지원을 받습니다.
                      개인정보는 서비스 공급자가 당사에 제공해야 할
                      서비스(소프트웨어, 시스템 및 플랫폼 지원, 다이렉트 마케팅
                      서비스, 클라우드 호스팅 서비스, 광고, 데이터 분석 및 주문
                      처리 및 배송 등)를 이행함에 있어 필요한 경우에만
                      제공됩니다. 당사의 제3자 서비스 공급자는 당사에 대한
                      서비스 제공을 제외한 기타 일체의 목적을 위해 당사가
                      제공하는 개인정보를 공유하거나 사용하도록 허가되지
                      않습니다.{" "}
                    </li>
                    <li>
                      <strong>법적 이유로 인한 제3자: </strong> 당사는 다음과
                      같은 목적상 필요하다고 판단될 경우 개인정보를 공유합니다.
                      <ul>
                        <li>
                          법적 의무를 준수하고 정부 기관(사법 당국 및 기타 공공
                          기관 포함)의 요청에 대응하기 위한 목적(귀하의 거주
                          국가 외부 지역의 기관이 포함될 수 있음).
                        </li>
                        <li>
                          당사의 비즈니스, 자산 또는 주식 자본이 합병, 매각,
                          구조조정, 인수, 합작 투자, 양도, 이전 또는 기타
                          처분되는 경우(일체의 파산 또는 그와 유사한 법적 절차와
                          연관된 경우 포함).
                        </li>
                        <li>
                          당사의 권리, 사용자, 시스템 및 서비스를 보호하기 위한
                          목적.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>eRecruitment 파트너:</strong> 귀하가 입사 지원을
                      위해 당사의 e-recruitment 서비스 사용자로 등록한 경우
                      귀하의 개인정보(귀하가 제공하는 일체의 민감한 개인정보
                      포함)가 귀하가 지원하는 조직에 제공됩니다. 또한, 지원
                      사항을 완전하게 검토하기 위한 목적으로 귀하의 개인정보가
                      해당 조직의 전 세계 사무소에 전달될 수 있습니다.{" "}
                    </li>
                  </ul>

                  <h3 id="where-personal-information-stored">
                    당사가 개인정보를 보관 및 처리하는 위치
                  </h3>
                  <p>
                    Thomson Reuters는 글로벌 조직이므로 귀하의 개인정보가 자국
                    외부에서 보관되고 처리될 수 있습니다. 당사는 데이터가 소재한
                    위치가 어디이든 관계없이 당사가 수집하는 정보가 본 개인정보
                    보호정책 및 관련 법률의 요구 사항에 따라 처리되도록 하기
                    위한 조치를 취합니다.{" "}
                  </p>
                  <p>
                    Thomson Reuters는 당사의 전 세계 사무소를 거점 삼아
                    네트워크, 데이터베이스, 서버, 시스템, 지원 및 지원 센터를
                    두고 있습니다. 당사는 당사의 비즈니스, 인력 및 고객의 요구
                    사항을 지원하기 위해 전 세계에 위치한 클라우드 호스팅
                    서비스, 공급업체 및 기술 지원 업체 등의 제3자와 협력합니다.
                    당사는 관련 법률에 따라 개인정보를 처리, 보호 및 이전하기
                    위해 적절한 조치를 취합니다. 경우에 따라 당사는 Thomson
                    Reuters 내부 또는 귀하의 거주 국가 외부 지역의 제3자를
                    대상으로 귀하의 개인정보를 공개하거나 이전해야 할 수
                    있습니다. 이러한 정보 수신자가 위치한 지역은 종종
                    달라지지만, 미국, 유럽, 캐나다, 아시아, 호주, 인도 및 기타
                    Thomson Reuters가 소재하거나 하청업체를 이용하는 국가가
                    포함될 수 있습니다. Thomson Reuters의 사무소 목록은 “<a href="#contact-us">
                      당사 연락 방법
                    </a>” 섹션을 참조하십시오.
                  </p>
                  <p>
                    당사가 개인정보를 유럽경제지역으로부터 기타 국가로 이전해야
                    하고 해당 국가의 관련 법률에서 귀하의 거주국과 동일한 수준의
                    데이터 개인정보 보호를 보장하지 않는 경우, 당사는 적절한
                    수준의 데이터 개인정보 보호를 위한 조치를 취합니다. 달리
                    말해, 귀하의 데이터에 적용되는 권리와 보호 수준은 동일하게
                    유지됩니다. 예를 들어, 당사는 승인된 계약 조항, 다자간
                    데이터 이전 계약, 그룹 내부 계약 및 귀하의 개인정보를
                    수신하는 자가 해당 정보를 보호하도록 하기 위해 설계된 기타
                    조치를 사용합니다. 당사의 데이터 이전 방식에 대해 자세히
                    알고 싶은 경우 당사의{" "}
                    <a href="#contact-us">데이터 보호 담당자</a>에게
                    문의하십시오.
                  </p>

                  <h3 id="how-personal-information-secured">
                    당사의 개인정보 보안 방법{" "}
                  </h3>
                  <p>
                    Thomson Reuters는 데이터 보안을 매우 중요하게 생각하며
                    개인정보 보호를 위한 적절한 기술과 절차를 사용합니다. 당사의
                    정보 보안 정책 및 절차는 널리 인정되는 국제 표준과 밀접하게
                    조율되어 있으며, 당사의 비즈니스 요구 사항, 기술의 변화 및
                    규제 요구 사항을 충족할 수 있도록 주기적으로 검토되고 필요에
                    따라 업데이트됩니다.{" "}
                  </p>
                  <p>예:</p>
                  <ul>
                    <li>
                      정책 및 절차
                      <ul>
                        <li>
                          당사는 과실에 의한 데이터 분실 및 허가되지 않은 데이터
                          액세스, 사용, 파괴 또는 공개를 방지하기 위한 조치를
                          실행하고 있습니다.
                        </li>
                        <li>
                          당사는 고객에 대한 당사 서비스의 지속성을 보호하고
                          당사의 인력과 자산을 보호하기 위해 설계된 비즈니스
                          지속성 및 재해 복구 전략을 두고 있습니다.
                        </li>
                        <li>
                          당사는 개인정보 액세스에 적절한 제한을 가합니다.{" "}
                        </li>
                        <li>
                          당사는 데이터의 안전한 보관 및 이전을 위해 적절한 조치
                          및 통제를 실시합니다(모니터링 및 물리적 조치 포함).
                        </li>
                        <li>
                          당사는 법적 요구 사항 및 당사의 비즈니스 정책에 따라
                          개인정보 영향평가를 수행합니다.{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      직원 및 하청업체에 대한 교육
                      <ul>
                        <li>
                          당사는 개인정보 및 기타 민감한 데이터에 액세스하는
                          당사의 직원과 하청업체에 대해 주기적으로 개인정보
                          보호, 정보 보안 및 기타 관련 교육을 요구합니다.
                        </li>
                        <li>
                          당사는 당사의 직원과 하청업체가 당사의 정보 보안 정책
                          및 절차와 일체의 관련 계약 조건에 따라 행동하도록 하기
                          위한 조치를 취합니다.{" "}
                        </li>
                      </ul>
                    </li>
                    <li>
                      공급업체 위험 관리
                      <ul>
                        <li>
                          당사는 당사의 제3자 공급업체와 공급자에 대해 계약 및
                          보안 검토 방식을 활용하여 당사의 보안 정책 및 절차에
                          따라 자신에게 위탁된 일체의 개인정보를 보호할 것을
                          요구합니다.{" "}
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <h3 id="time-personal-information-kept">
                    당사가 개인정보를 보관하는 기간
                  </h3>

                  <p>
                    Thomson Reuters는 개인정보의 보존과 관련된 정책과 규칙을
                    실행하기 위해 개인정보 보호 부서와 연계하여 운영하는 기록
                    관리 팀을 운영하고 있습니다. 또한, 당사는 비즈니스 기능,
                    기록 등급 및 기록 유형으로 구성된 분류 체계에 기초한 회사
                    기록 보존 일정을 보유하고 있습니다. 당사는 법률 또는
                    비즈니스 목적상 합당히 필요한 기간 동안 개인정보를
                    보존합니다. Thomson Reuters는 데이터 보존 기간을 결정함에
                    있어 현지 법률, 계약 의무, 그리고 고객의 기대 및 요구 사항을
                    고려합니다. 당사는 개인정보가 더 이상 필요 없어질 경우 해당
                    정보를 안전하게 삭제하거나 파기합니다.{" "}
                  </p>

                  <h3 id="personal-information-access">
                    귀하의 개인정보 액세스 및 수정 권한
                  </h3>
                  <p>
                    당사는 자신의 정보에 대한 귀하의 액세스 및 통제 권한을
                    존중하고 정보 요청에 대응하며, 해당되는 경우 귀하의
                    개인정보를 수정, 정정 또는 삭제합니다.{" "}
                  </p>
                  <ul>
                    <li>
                      <strong>개인정보 액세스:</strong> 귀하가 자신의 개인정보에
                      대한 액세스를 요청하는 경우 당사는 모든 관련 법률의 요구
                      사항 및 예외 사항을 준수합니다(신원 확인 절차 포함).
                      당사는 귀하에게 데이터를 제공하기에 앞서 관련 데이터를
                      찾기 위해 신원 증명 및 귀하와 당사 간의 상호작용에 관한
                      충분한 정보를 요구합니다. 또한, 당사는 데이터의 사본을
                      제공하는 데 필요한 비용을 귀하에게 부과할 수 있습니다(현지
                      법률에 의해 허가되지 않는 경우는 제외).
                    </li>
                    <li>
                      <strong>수정 및 삭제:</strong> 일부 관할의 경우 귀하는
                      자신의 개인정보가 부정확하거나 업데이트가 필요할 시 이를
                      수정하거나 정정할 권한을 갖습니다. 또한, 귀하는 자신의
                      개인정보 삭제를 요청할 권리를 갖습니다. 단, 법적 요구 사항
                      내지 기타 의무 사항 및 요인으로 인해 이것이 항상 가능한
                      것은 아닙니다. 참고로 귀하는 당사의 서비스에 포함된 “당사
                      연락처” 옵션을 이용해 자신의 계정 정보를 업데이트할 수
                      있습니다.
                    </li>
                    <li>
                      <strong>마케팅 기본 설정:</strong> 이메일 마케팅을 수신
                      거부하려면 당사로부터 수신한 이메일 커뮤니케이션에 들어
                      있는 구독 취소 링크를 이용하거나 해당 이메일 기본 설정
                      센터를 방문하면 됩니다. 기타 마케팅 기본 설정은 관련
                      서비스의 “당사 연락처” 옵션을 이용할 수 있습니다. 쿠키
                      관리와 관련된 정보는 <a href="#cookies">여기</a>에서
                      찾아볼 수 있으며, 관심 기반 광고와 모바일 광고 관련 정보는{" "}
                      <a href="#advertising">여기</a>에서 찾아볼 수 있습니다.
                    </li>
                    <li>
                      <strong>불만 제기:</strong> 귀하는 Thomson Reuters에서
                      귀하의 개인 데이터를 관리하는 방식에 만족하지 않는 경우
                      데이터 개인정보 보호 규제당국에 불만을 제기할 권리가
                      있습니다. 전국의 데이터 보호 당국 목록은{" "}
                      <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                        https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                      </a>에서 찾아볼 수 있습니다.
                    </li>
                  </ul>
                  <p>
                    개인정보와 관련된 일체의 요청 사항은{" "}
                    <a href="#contact-us">데이터 보호 담당자</a>에게
                    문의하십시오. PeopleMap, Public Records on Westlaw 및
                    CLEAR와 관련된 일체의 요청 사항은{" "}
                    <a href="https://legalsolutions.thomsonreuters.com/law-products/about/legal-notices/privacy/records">
                      여기
                    </a>를 클릭하십시오.
                  </p>

                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>
                  <h2 id="cookies">쿠키 및 유사 기술</h2>
                  <p>
                    Thomson Reuters와 당사의 제3자 공급자는 일례로 사용자 기본
                    설정을 저장 및 관리하고, 대상 지정 광고를 전달하고, 콘텐츠를
                    활성화하고, 분석 및 사용 데이터를 수집하기 위해 쿠키 및 유사
                    기술을 설정하고 사용합니다. 쿠키 및 기타 추적 기술을
                    사용하는 것은 표준화된 웹사이트 및 앱 활용 방식이며, 이를
                    통해 애플리케이션, 웹사이트 또는 기타 서비스 환경에서
                    이루어지는 귀하의 온라인 행동에 대한 정보가 수집됩니다.
                    당사가 쿠키 및 유사 기술을 사용하는 방식에 대한 자세한
                    내용은 아래의{" "}
                    <a href="#managing-cookies">
                      쿠키를 제어하고 관리하는 방법
                    </a>을 참조하십시오.{" "}
                  </p>

                  <h3 id="what-is-a-cookie">쿠키란 무엇인가? </h3>
                  <p>
                    쿠키란 컴퓨터 또는 기타 장치에 존재하며 사용자 또는 장치를
                    식별하고 정보를 수집하기 위해 사용되는 작은 텍스트
                    파일입니다. 쿠키는 일반적으로 그 기능과 의도된 목적에 따라
                    절대적으로 필요한 쿠키, 성능 쿠키, 기능 쿠키 및 마케팅
                    목적의 쿠키라는 네 개의 범주 중 하나에 할당됩니다.
                  </p>

                  <h3 id="cookie-types">
                    쿠키의 유형 및 쿠키를 사용하는 이유:
                  </h3>
                  <ul>
                    <li>
                      <strong>절대적으로 필요한 쿠키:</strong> 이 쿠키는 귀하가
                      웹사이트의 이곳저곳을 이동하고 그 기능을 사용할 수 있도록
                      해주는 필수적 기능을 합니다. 이 쿠키가 없으면 귀하가
                      요청한 서비스(온라인 장바구니에 물품을 추가하는 등)를
                      제공할 수 없습니다.{" "}
                    </li>
                    <li>
                      <strong>성능 쿠키:</strong> 이 쿠키는 귀하가 당사의
                      웹사이트를 사용하는 방식에 관한 정보를 수집합니다.
                      수집되는 정보의 예로는 사용 중인 인터넷 브라우저와 운영
                      체제, 이전에 방문한 웹사이트의 도메인 이름, 방문 횟수,
                      평균 방문 시간, 살펴본 페이지 등을 꼽을 수 있습니다. 이
                      쿠키는 귀하의 신원을 식별할 만한 정보를 수집하지 않으며
                      단지 집계 정보와 익명 정보만을 수집합니다. 성능 쿠키는
                      웹사이트의 사용자 친화성을 개선하고 사용 환경을 개선하기
                      위한 용도로 사용됩니다.
                    </li>
                    <li>
                      <strong>기능 쿠키: </strong> 이 쿠키는 웹사이트가 귀하의
                      선택(귀하의 사용자 이름이나 ID, 언어 기본 설정 또는 귀하가
                      소재한 구역이나 지역 등)을 기억할 수 있게 해주며, 보다
                      개선되고 개인화된 기능을 제공합니다. 이 쿠키는 텍스트
                      크기, 글꼴 및 웹 페이지의 기타 사용자 설정 요소에 대해
                      귀하가 적용한 변경 사항을 기억하기 위한 용도로도
                      사용됩니다. 또한, 이 쿠키는 비디오 시청 또는 블로그 의견
                      게시 등 귀하가 요청한 서비스를 제공하기 위한 용도로도
                      사용될 수 있습니다. 이 쿠키가 수집하는 정보는 익명화되어
                      있는 것이 보통이며 귀하의 타 웹사이트 탐색 실태를 추적할
                      수 없습니다.{" "}
                    </li>
                    <li>
                      <strong>마케팅 목적의 쿠키:</strong> 이 쿠키는 탐색 행동을
                      추적하며, 대상 지정(인터넷 기반) 광고를 전달하기 위해
                      사용됩니다. 또한, 이 쿠키는 귀하가 광고를 보는 횟수를
                      제한하고 광고 캠페인의 효과를 측정하기 위한 용도로도
                      사용됩니다. 이 쿠키는 보통 웹사이트 운영자의 허가하에 광고
                      네트워크에 의해 설치됩니다. 이 쿠키는 귀하가 특정
                      웹사이트를 방문한 사실을 기억하며, 이 정보는 광고주 등의
                      타 조직과 공유됩니다.
                    </li>
                  </ul>

                  <h3 id="managing-cookies">쿠키 관리</h3>
                  <p>
                    귀하는 브라우저의 설정에서 웹사이트 쿠키를 관리할 수 있으며,
                    언제든 쿠키를 수락, 거부 또는 삭제함으로써 이 설정을 변경할
                    수 있는 선택권을 갖습니다. 단, 설정을 변경할 경우 서비스를
                    통해 제공되는 특정 기능과 특성이 작동하지 않을 수 있습니다.
                    모든 브라우저 설정은 약간씩 다르기 때문에 쿠키를 관리하기
                    위해서는 브라우저 내의 관련 설정을 참조해야 합니다.{" "}
                  </p>
                  <p>
                    당사는 귀하가 쿠키에 대한 추가 정보를 원할 수 있음을
                    이해합니다.{" "}
                    <a href="https://www.aboutcookies.org">
                      www.aboutcookies.org
                    </a>{" "}
                    또는{" "}
                    <a href="https://www.allaboutcookies.org">
                      www.allaboutcookies.org
                    </a>에서 쿠키의 유형, 쿠키가 사용되는 방식 및 쿠키 기본
                    설정을 관리하는 방법에 대한 자세한 정보를 확인하십시오.
                    보편적으로 사용되는 브라우저에서 쿠키를 비활성화하고
                    삭제하는 방법에 관한 상세 정보를 보려면 아래의 링크를
                    클릭하십시오.
                  </p>
                  <ul>
                    <li>
                      <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                        Mozilla Firefox®
                      </a>
                    </li>
                    <li>
                      <a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">
                        Microsoft® Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/accounts/answer/61416?hl=en">
                        Google Chrome™
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&locale=de_DE">
                        Safari®
                      </a>
                    </li>
                  </ul>

                  <h3 id="other-tracking-tech">기타 추적 기술:</h3>
                  <ul>
                    <li>
                      <strong>로컬 공유 객체/플래시 쿠키: </strong> 일명 로컬
                      공유 객체라고도 불리는 플래시 쿠키는 Adobe Flash를 통해
                      지원되는 브라우저 콘텐츠를 지원하기 위해 설계되었습니다.
                      일반적으로 웹사이트에서 광고 및 비디오 콘텐츠를 활성화하기
                      위해 사용됩니다. 플래시 쿠키는 다른 쿠키와 마찬가지로
                      귀하의 장치에 대한 정보를 저장하며, 그 중 일부는 플래시
                      지원 콘텐츠와 관련되어 있습니다. 플래시 쿠키는 Adobe Flash
                      내에서만 삭제할 수 있으며, 브라우저를 통해서는 삭제가
                      불가합니다. 개인정보 보호 설정을 관리하는 방법과 플래시
                      쿠키의 삭제에 관한 정보는 아래의 도움말 페이지를
                      참조하십시오.{" "}
                      <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                        https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
                      </a>
                    </li>
                    <li>
                      <strong>웹 비콘:</strong> 당사의 웹 페이지에 일명 웹
                      비콘(단일 픽셀 gif 및 투명 그래픽 이미지라고도 함)이라는
                      전자 이미지가 포함될 수 있으며, 일례로 당사 사이트에
                      쿠키를 전달하고, 그러한 사이트를 방문한 사용자의 수를
                      계산하고, 서비스를 제공하고, 당사 홍보 캠페인의 효과를
                      분석하기 위한 용도로 사용됩니다. 또한, 당사는 이메일이
                      열리는지 또는 링크가 클릭되는지 여부를 판단하기 위해 웹
                      비콘을 마케팅 이메일 메시지 또는 뉴스레터에 포함할 수
                      있습니다.
                    </li>
                    <li>
                      <strong>웹 서버 및 애플리케이션 로그:</strong> 당사의
                      서버는 서비스의 관리와 보호, 사용 실태 분석 및 사용자
                      환경의 개선을 위해 특정 정보를 자동으로 수집합니다.
                      수집되는 정보의 예는 다음과 같습니다.
                      <ul>
                        <li>IP 주소와 브라우저 유형</li>
                        <li>
                          당사 또는 제3자가 할당하는 UDI(Unique Device
                          Identification), MAC 주소, IFA(Identifier for
                          Advertisers) 및 유사 식별자를 포함한 장치 정보
                        </li>
                        <li>장치 운영 체제 및 기타 기술 정보 </li>
                        <li>
                          귀하가 당사 웹 사이트에 접속한 시/군/구, 주/도 및 국가
                        </li>
                        <li>방문한 페이지 및 확인, 저장 및 구매한 콘텐츠 </li>
                        <li>입력한 정보 또는 텍스트</li>
                        <li>클릭한 링크 및 버튼 </li>
                        <li>
                          귀하가 당사의 서비스를 사용하기 전후로 방문한 URL
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>

                  <h2 id="advertising">관심 기반 광고(IBA) </h2>
                  <p>
                    관심 기반 광고(IBA)는 대상이 지정된 광고를 당사의 서비스
                    사용자에게 전달할 수 있도록 해 줍니다. IBA는 귀하가
                    액세스하거나 읽은 콘텐츠의 유형에 기초하여 귀하에게 광고를
                    보여주는 방식으로 작동합니다. 예를 들어, 귀하가 당사의
                    서비스를 탐색하는 동안 귀하의 장치에 설치된 쿠키 중 하나가
                    광고 쿠키로 변하고, 결과적으로 당사는 귀하가 어떤 종류의
                    페이지나 콘텐츠에 관심이 있는지 더 잘 알 수 있게 됩니다.
                    귀하의 장치에 관하여 수집되는 정보는 유사한 관심사를 보인
                    여타 사용자의 장치와 귀하를 그룹화할 수 있도록 해줍니다.
                    따라서 당사는 공통의 관심사에 기초하여 사용자 범주별로
                    광고를 표시할 수 있습니다. IBA에 대한 자세한 정보는{" "}
                    <a href="https://www.iab.net/public_policy/behavioral-advertisingprinciples">
                      https://www.iab.net/public_policy/behavioral-advertisingprinciples
                    </a>를 참조하십시오.
                  </p>

                  <h3 id="opting-out-iba">IBA 수신 거부:</h3>
                  <p>
                    관심 기반 광고의 수신을 거부한다는 것은 귀하가 당사의
                    서비스를 이용하는 중에 광고를 더 이상 수신하지 않는 것이
                    아닙니다. 단지 당사가 귀하에 관하여 수집하는 정보를 IBA를
                    위해 사용하지 않고, 귀하가 보는 광고가 귀하에게 맞춤화되지
                    않거나 귀하와 관련이 없을 것임을 의미합니다. 온라인 광고
                    선택을 경험해 보려면{" "}
                    <a href="https://optout.aboutads.info">여기</a>를 클릭하거나
                    광고의 AdChoices 아이콘을 클릭하고 지침을 따르면 됩니다.
                    또한, 귀하는 NAI(Network Advertising Initiative)의 수신 거부
                    도구(<a href="https://www.networkadvertising.org/choices">
                      https://www.networkadvertising.org/choices
                    </a>) 및 EU 지역인 경우{" "}
                    <a href="https://www.youronlinechoices.com/">
                      https://www.youronlinechoices.com/
                    </a>을 통해 다수의 사이트로부터 발송되는 관심 기반 광고의
                    수신을 거부할 수 있습니다. 쿠키를 삭제하거나, 다른 장치를
                    사용하거나, 웹 브라우저를 변경할 경우 다시 수신 거부를 해야
                    할 수 있다는 점을 기억하십시오.{" "}
                  </p>
                  <h3 id="mobile-advertising">모바일 장치의 광고</h3>
                  <p>
                    모바일 장치는 특정 모바일 장치로 해당 업체가 대상 지정
                    광고를 제공할 수 있는 일종의 식별자를 가지고 있습니다. 많은
                    경우에 귀하는 언제든 모바일 장치 개인정보 설정을 통해 모바일
                    장치 광고 추적 기능을 끄거나 광고 식별자를 재설정할 수
                    있습니다. 귀하의 장치에 표시되는 광고를 제어하기 위해 사용할
                    수 있는 또 다른 도구는 AppChoices 앱(<a href="https://youradchoices.com/appchoices">
                      https://youradchoices.com/appchoices
                    </a>)입니다. 또한, 귀하는 자신의 모바일 장치에서 위치 추적
                    기능을 끌 수 있습니다. 이렇게 광고 추적 또는 위치 추적
                    기능을 끄면 당사는 귀하의 장치의 광고 식별자로부터 수집된
                    정보를 더 이상 마케팅 목적으로 사용하지 않습니다. 즉, 귀하가
                    보게 될 광고의 개수는 동일할 수 있으나, 광고가 귀하의
                    관심사에 기초하지 않으므로 관련성이 떨어질 수 있습니다.{" "}
                  </p>

                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>

                  <h2 id="dnt">추적 방지 </h2>
                  <p>
                    일부 브라우저는 추적 방지(DNT) 신호를 웹사이트로 전송합니다.
                    단, DNT 신호에 대한 해석이 전 산업적으로 통일되어 있지 않기
                    때문에 Thomson Reuters는 현재 이러한 브라우저가 보내는 DNT
                    요청 또는 신호의 변경, 수정 또는 대응을 하지 않고 있습니다.
                    당사는 이 영역에 관한 업계의 동향을 계속해서 모니터링할
                    것이며, 필요에 따라 당사의 DNT 방식을 재평가할 것입니다.
                  </p>

                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>

                  <h2 id="social-networks">소셜 네트워크를 통한 연결</h2>
                  <p>
                    당사 서비스 중 일부에 Facebook “좋아요” 버튼 및 위젯, “공유”
                    버튼 및 대화형 미니 프로그램 등의 소셜 네트워킹 기능이
                    포함되어 있을 수 있습니다. 그 외에도, 귀하는 당사 서비스 중
                    일부에 로그인하기 위해 자신의 소셜 네트워킹 로그인(예를
                    들어, Facebook 또는 LinkedIn)을 사용할 수 있습니다. 귀하가
                    소셜 네트워킹 또는 유사 서비스를 이용해 연결을 하는 경우
                    당사는 귀하의 로그인을 가능하게 하기 위해 해당 서비스로부터
                    전송되는 인증 정보 및 귀하가 이러한 서비스에 연결할 때
                    공유하기로 한 기타 정보를 수신하고 저장할 수 있습니다.
                    이러한 서비스는 귀하가 방문한 웹 페이지 및 IP 주소와 같은
                    정보를 수집하고 기능의 적절한 작동을 가능하게 하기 위해
                    쿠키를 설정할 수 있습니다. 당사는 이러한 제3자가 수집하는
                    정보의 보안성 또는 기밀성에 대해 책임을 지지 않습니다.
                    귀하는 귀하가 연결, 사용 또는 액세스하는 제3자 서비스에
                    적용되는 개인정보 보호정책 또는 정책을 검토해야 합니다.
                    귀하의 개인정보가 귀하의 소셜 미디어 계정 공급자 또는 소셜
                    미디어 서비스의 여타 사용자와 공유되는 것을 원하지 않을
                    경우, 서비스에서 소셜 미디어 계정과 귀하의 계정을 연결하지
                    말고 서비스를 통한 소셜 공유에 참여하지 마십시오.{" "}
                  </p>

                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>

                  <h2 id="third-party-services">
                    제3자 서비스에 대한 링크 및 연결
                  </h2>
                  <p>
                    당사의 서비스에는 당사와 연관되거나 당사에 의해 통제되거나
                    관리되지 않는 제3자 앱, 서비스, 도구 및 웹사이트로 연결되는
                    링크가 포함될 수 있으며 귀하는 이러한 항목과 함께 서비스를
                    사용할 수 있습니다. 그러한 예로는 Facebook, LinkedIn,
                    Twitter 및 제3자 앱(음성 소프트웨어 및 리더 등)을 꼽을 수
                    있습니다. 이러한 제3자의 개인정보 취급방침에 대해서는 해당
                    제3자의 개인정보 보호정책이 적용됩니다. 당사는 이러한
                    제3자가 수집하는 정보의 보안성 또는 기밀성에 대해 책임을
                    지지 않습니다. 귀하는 이러한 제3자 서비스에 적용되는
                    개인정보 보호정책 또는 정책을 검토해야 합니다.
                  </p>
                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>

                  <h2 id="children-privacy">아동의 개인정보 보호 </h2>
                  <p>
                    Thomson Reuters는 전문가를 위한 정보 솔루션을 제공하며,
                    당사의 서비스는 일반적으로 아동을 대상으로 하지 않습니다.
                    그러나 당사가 아동에 관한 정보를 수집하고 사용하는 경우(예:
                    교육 리소스 개발), 당사는 업계의 지침과 관련 법률을 준수할
                    것입니다.{" "}
                  </p>
                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>

                  <h2 id="contact-us">당사 연락 방법 </h2>
                  <p>
                    당사는 귀하가 본 정책 또는 당사의 개인정보 취급방침에 대해
                    질문 또는 우려를 제기하거나 불만을 표하고자 할 수 있다는
                    점을 이해합니다. 다음 방법 중 하나를 사용하여 언제든 당사에
                    문의하시기 바랍니다.
                  </p>
                  <h3>Thomson Reuters 데이터 보호 담당자:</h3>
                </RichText>
                <MarkupContainer>
                  <HorizontalOverflow>
                    <Table>
                      <table>
                        <tbody>
                          <tr>
                            <td>이메일:</td>
                          </tr>
                          <tr>
                            <td>
                              <a href="mailto:Privacy.enquiries@thomsonreuters.com">
                                Privacy.enquiries@thomsonreuters.com
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>주소:</td>
                          </tr>
                          <tr>
                            <td>
                              Attn: Data Protection Officer <br /> Thomson
                              Reuters <br /> D5 S1866 <br /> 610 Opperman Dr.{" "}
                              <br /> Eagan, MN 55123 <br /> USA
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Table>
                  </HorizontalOverflow>
                </MarkupContainer>
                <RichText>
                  <h3>마케팅 기본 설정 및 서비스 지원: </h3>
                  <p>
                    귀하의 계정 정보 또는 이메일 마케팅 기본 설정을
                    업데이트하거나 귀하의 서비스에 대해 기술 지원 또는 기타
                    도움을 받고자 할 경우, 관련 이메일 기본 설정 센터를
                    방문하거나 해당 서비스와 관련된 “당사 연락처” 옵션을
                    사용하십시오.
                  </p>
                  <p>
                    <a href="https://www.thomsonreuters.com/en/locations.html">
                      Thomson Reuters 사무소 위치 목록을 보려면 다음 링크를
                      클릭하십시오.{" "}
                    </a>
                  </p>
                  <a href="#top">
                    <strong>맨 위로</strong>
                  </a>
                </RichText>
              </Grid.Item>
            </Grid>
          </Section>
        </Language>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default PrivacyKo;
