import React from "react";
import SchemaOrganization, {
  SchemaSameAsOrganization
} from "@emcm-ui/component-schema-organization";

const SampleSchemaOrganization = () => (
  <SchemaOrganization
    logo="https://app-data.gcs.trstatic.net/wel-trdotcom/static/tr-logo-schema.png"
    name="Thomson Reuters Corporation"
    url="https://www.thomsonreuters.com/"
  >
    <SchemaSameAsOrganization url="https://www.facebook.com/thomsonreuters" />
    <SchemaSameAsOrganization url="https://twitter.com/thomsonreuters" />
    <SchemaSameAsOrganization url="https://www.linkedin.com/company/thomson-reuters" />
    <SchemaSameAsOrganization url="https://plus.google.com/+ThomsonReuters" />
    <SchemaSameAsOrganization url="https://www.youtube.com/thomsonreuters" />
    <SchemaSameAsOrganization url="https://www.instagram.com/thomsonreuters/" />
  </SchemaOrganization>
);

export default SampleSchemaOrganization;
