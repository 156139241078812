const stringPadding = duration => {
  const targetLength = 2;
  const padString = "0";

  return duration.toString().padStart(targetLength, padString);
};

export const durationFormatter = duration => {
  const oneMinute = 60;
  const oneHour = 3600;
  const hours = Math.floor(duration / oneHour);
  const minutes = Math.floor((duration % oneHour) / oneMinute);
  const seconds = Math.floor((duration % oneMinute) % oneMinute);
  const hoursStr = stringPadding(hours);
  const minutesStr = stringPadding(minutes);
  const secondsStr = stringPadding(seconds);

  return hours
    ? `${hoursStr}:${minutesStr}:${secondsStr}`
    : `${minutesStr}:${secondsStr}`;
};

export const findIndexByList = (lineItems, propertyName, match) => {
  return lineItems.findIndex(lineItem => lineItem[propertyName] === match);
};

export const stringDecoder = (str, source, target) => {
  return str.replace(new RegExp(source, "g"), target);
};

export const generateUrl = (url, key, value) => {
  let newUrl = stringDecoder(url, "&amp;", "&");
  const params = new URL(newUrl);
  const existingValue = params.searchParams.get(key);

  if (existingValue) {
    newUrl = newUrl.replace(`${key}=${existingValue}`, `${key}=${value}`);
  } else {
    newUrl = `${newUrl}&${key}=${value}`;
  }

  return newUrl;
};
