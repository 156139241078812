import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";

import SemanticMain from "./index";
import SemanticMainREADME from "../README.md";
import "./SemanticMain.css";

const stories = storiesOf("Components/SemanticMain", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(SemanticMainREADME));

stories.add("Default", () => (
  <SemanticMain role={select("Role", ["main", "presentation"])}>
    <p>
      This is the main content. Lorem ipsum dolor sit amet, consectetur
      adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
      magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
      laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
      qui officia deserunt mollit anim id est laborum.
    </p>
  </SemanticMain>
));
