import React from "react";
import Datamap from "./Datamap";

// eslint-disable-next-line require-await
export default async domNode => {
  const readData = prop => {
    const data = domNode.getAttribute(`data-${prop}`);

    return data ? JSON.parse(data) : null;
  };

  // NB you can't and shouldn't ever need to rehydrate functions
  const props = {
    data: readData("data"),
    fillKey: readData("fill-key"),
    fills: readData("fills"),
    height: readData("height"),
    legendProps: readData("legend-props"),
    projection: readData("projection"),
    width: readData("width")
  };

  return <Datamap {...props} />;
};
