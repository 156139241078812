import React from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";

import Flyouts from "./Flyouts";

const getClassName = getClassNameFactory(Flyouts.displayName);

const rehydrator = async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector(
    `.${getClassName({ descendantName: "panel" })}`
  );
  const reactifiedChildren = await rehydrateChildren(childrenNode);
  const props = {
    summaryLinkHref: domNode.getAttribute("data-summary-link-href") || null,
    summaryLinkText: domNode.getAttribute("data-summary-link-text") || null
  };

  return <Flyouts {...props}>{reactifiedChildren}</Flyouts>;
};

export default rehydrator;
