import Block from "@emcm-ui/component-block";
import React from "react";
import ReactDOMServer from "react-dom/server";
import fetchMock from "fetch-mock";
import { storiesOf } from "@storybook/react";
import { boolean, select, text, withKnobs } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import Image from "@emcm-ui/component-image";
import VideoPlayer from "@emcm-ui/component-video-player";
import AjaxContent from "./index";
import "./AjaxContent.css";
import AjaxContentREADME from "../README.md";
import { options } from "@emcm-ui/bundle-emcm/src/reactFromMarkup";
import StaticRenderer from "@emcm-ui/component-static-renderer";

const httpDelay = 3000;
const httpErrorCode = 1000;
const stories = storiesOf("Components/AjaxContent", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(AjaxContentREADME));

const delay = duration => new Promise(resolve => setTimeout(resolve, duration));

const endpoints = {
  "Basic content #1": ReactDOMServer.renderToStaticMarkup(
    <Block color="green" text="fetched content" height="13rem" />
  ),
  "Basic content #2": ReactDOMServer.renderToStaticMarkup(
    <Block color="blue" text="fetched content #2" height="26rem" />
  ),
  "Video player": ReactDOMServer.renderToStaticMarkup(
    <VideoPlayer
      duration="PT30S"
      kind="youtube"
      playLabel="play video"
      videoId="JcaRpzbWxi8"
    >
      <Image
        alt="Placeholder image"
        fit="cover"
        src="placeholder-images/1920x1080.png"
      />
    </VideoPlayer>
  ),
  "Server error": httpErrorCode
};

stories
  .add("Default", () => {
    fetchMock.restore();
    Object.keys(endpoints).forEach(label => {
      const location = encodeURIComponent(label);

      fetchMock.get(location, async () => {
        await delay(httpDelay);

        return endpoints[label];
      });
    });

    const location = boolean("Empty location", false)
      ? null
      : encodeURIComponent(
          select(
            "Content endpoints",
            Object.keys(endpoints),
            Object.keys(endpoints)[0]
          )
        );

    return (
      <AjaxContent
        failedContent={text("failedContent", "Failed to retrieve content")}
        loadingLabel={text("loadingLabel", "Loading")}
        location={location}
        minHeight={parseInt(text("minHeight"))}
        reactFromMarkupOptions={options}
        reactFromMarkupRehydrators={options.extra.rehydrators}
      >
        <Block color="red" text="default content" height="100px" />
      </AjaxContent>
    );
  })
  .add("Rehydrated", () => (
    <StaticRenderer>
      <div id="root">
        <div data-react-from-markup-container="">
          <div
            data-auto-load="true"
            data-failed-content-message="&quot;Failed to Load&quot;"
            data-rehydratable="tr-AjaxContent"
            data-loading-label="&quot;Loading...&quot;"
            data-location="&quot;https://www.mocky.io/v2/5b8527613000008100729143&quot;"
            data-min-height="200"
          />
        </div>
      </div>
    </StaticRenderer>
  ));
