import PropTypes from "prop-types";
import React, { Component } from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";

class GroupItem extends Component {
  static displayName = "GroupItem";

  static propTypes = {
    /**
     * Component or element to be grouped
     */
    children: PropTypes.node.isRequired
  };

  constructor(props) {
    super(props);

    this.getClassName = getClassNameFactory(GroupItem.displayName);
  }

  render() {
    return <div className={this.getClassName()}>{this.props.children}</div>;
  }
}

export default GroupItem;
