import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import PageNavInner from "./PageNavInner";
import Anchor from "./components/Anchor";
import Item from "./components/Item";

const PageNav = ({ sticky, ...props }) => {
  const getClassName = getClassNameFactory("PageNav");

  return (
    <div className={getClassName({ modifiers: classNames({ sticky }) })}>
      {/* Use a separate Inner component so we can use position:sticky on the
        root. This is a workaround for rehydration, which wraps the component
        in a div, interfering with position:sticky.*/}
      <PageNavInner {...props} sticky={sticky} />
    </div>
  );
};

PageNav.displayName = "PageNav";

PageNav.propTypes = {
  /**
   * Action nodes. Use to add a call-to-action to the PageNav, like a Button.
   */
  actions: PropTypes.node,
  /**
   * Child nodes. Should be `PageNav.Item` components.
   */
  children: PropTypes.node.isRequired,
  /**
   * Share node. Should be a SocialDropdown component.
   */
  share: PropTypes.node,
  /**
   * Label for Share node.
   */
  shareLabel: PropTypes.string,
  /**
   * Enable sticky behaviour to make the PageNav stick to the top of the screen.
   */
  sticky: PropTypes.bool,
  /**
   * Enable scroll spy to update the current item as you scroll the page.
   */
  spy: PropTypes.bool,
  /**
   * Show primary actions when the pagenav is sticked to the top and the page is scrolled
   */
  showPrimaryActionOnlyOnSticky: PropTypes.bool
};

PageNav.defaultProps = {
  shareLabel: "Share",
  sticky: true,
  spy: true,
  showPrimaryActionOnlyOnSticky: false
};

PageNav.Anchor = Anchor;
PageNav.Item = Item;

export default PageNav;
