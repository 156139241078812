import PropTypes from "prop-types";
import React from "react";

import getClassNameFactory from "@emcm-ui/utility-class-names";

const TabList = ({ children }) => {
  const getClassName = getClassNameFactory(TabList.displayName);

  return (
    <div role="presentation">
      <ul role="tablist" className={getClassName({ descendantName: "list" })}>
        {children}
      </ul>
    </div>
  );
};

TabList.propTypes = {
  /**
   * The children of this `TabList`. Should be of type `Tab`
   */
  children: PropTypes.node
};

TabList.displayName = "SiteHeader.Flyouts.TabList";

export default TabList;
