import React from "react";

import Heading from "@emcm-ui/component-heading";
import HorizontalOverflow from "@emcm-ui/component-horizontal-overflow";
import Grid from "@emcm-ui/component-grid";
import LanguageSwitcher from "@emcm-ui/component-language-switcher";
import MarkupContainer from "@emcm-ui/component-markup-container";
import RichText from "@emcm-ui/component-rich-text";
import Section from "@emcm-ui/component-section";
import SemanticMain from "@emcm-ui/component-semantic-main";
import Table from "@emcm-ui/component-table";
import VerticalSpacing from "@emcm-ui/component-vertical-spacing";

import SampleSchemaOrganization from "../../fragments/schema-organization";
import SampleFooter from "../../fragments/footer";
import SampleHeader from "../../fragments/header";

const PrivacyEn = () => {
  return (
    <div>
      <SampleSchemaOrganization />
      <SampleHeader />
      <SemanticMain>
        <Section>
          <Grid variant="2/3,1/3">
            <Grid.Item>
              <VerticalSpacing size="xl">
                <Heading rank="1" type="l">
                  Thomson Reuters privacy statement
                </Heading>
              </VerticalSpacing>
              <VerticalSpacing size="xl">
                <LanguageSwitcher heading="Available in other languages">
                  <LanguageSwitcher.Item href="#" lang="zh" title="中文" />
                  <LanguageSwitcher.Item
                    href="#"
                    lang="nl"
                    title="Nederlands"
                  />
                  <LanguageSwitcher.Item href="#" lang="fr" title="Français" />
                  <LanguageSwitcher.Item href="#" lang="de" title="Deutsch" />
                  <LanguageSwitcher.Item href="#" lang="it" title="Italiano" />
                  <LanguageSwitcher.Item href="#" lang="ja" title="日本語" />
                  <LanguageSwitcher.Item href="#" lang="ko" title="한국어" />
                  <LanguageSwitcher.Item href="#" lang="pt" title="Português" />
                  <LanguageSwitcher.Item href="#" lang="ru" title="Русский" />
                  <LanguageSwitcher.Item href="#" lang="es" title="Español" />
                  <LanguageSwitcher.Item href="#" lang="tr" title="Türk" />
                </LanguageSwitcher>
              </VerticalSpacing>
              <RichText large>
                <h2>Introduction</h2>
                <p>
                  Your privacy and trust are important to us and this Privacy
                  Statement (“Statement”) provides important information about
                  how Thomson Reuters and its worldwide affiliated companies and
                  subsidiaries (“Thomson Reuters,” “we,” or “us”) handle
                  personal information. This Statement applies to any Thomson
                  Reuters website, application, product, software, or service
                  that links to it (collectively, our “Services”). Occasionally,
                  a Service will link to a different Privacy Statement that will
                  outline the particular privacy practices of that Service.
                </p>
                <p>
                  Please read this Statement carefully and contact our Data
                  Protection Officer if you have any questions about our privacy
                  practices or your personal information choices. It is
                  important that you check back often for updates to this
                  Statement. If we make changes we consider to be important, we
                  will let you know by placing a notice on the relevant Services
                  and/or contact you using other methods such as email.
                </p>
                <p>
                  <strong>
                    This Statement was last updated on June 1, 2017.
                  </strong>
                </p>
                <p>Below you will find helpful information about:</p>
                <ul>
                  <li>
                    <a href="#personal-information">Personal information</a>
                    <ul>
                      <li>
                        <a href="#personal-information-collected">
                          The types of personal information we collect
                        </a>
                      </li>
                      <li>
                        <a href="#how-personal-information-used">
                          How we use personal information
                        </a>
                      </li>
                      <li>
                        <a href="#when-personal-information-shared">
                          When we share personal information
                        </a>
                      </li>
                      <li>
                        <a href="#where-personal-information-stored">
                          Where we store and process personal information
                        </a>
                      </li>
                      <li>
                        <a href="#how-personal-information-secured">
                          How we secure personal information
                        </a>
                      </li>
                      <li>
                        <a href="#time-personal-information-kept">
                          How long we keep personal information
                        </a>
                      </li>
                      <li>
                        <a href="#personal-information-access">
                          Your right to access and correct your personal
                          information
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#cookies">Cookies and similar technologies</a>
                    <ul>
                      <li>
                        <a href="#what-is-a-cookie">What is a cookie?</a>
                      </li>
                      <li>
                        <a href="#cookie-types">
                          Types of cookies and why we use them
                        </a>
                      </li>
                      <li>
                        <a href="#managing-cookies">Managing cookies</a>
                      </li>
                      <li>
                        <a href="#other-tracking-tech">
                          Other tracking technologies
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#advertising">Interest-based advertising (IBA)</a>
                    <ul>
                      <li>
                        <a href="#opting-out-iba">Opting out of IBA</a>
                      </li>
                      <li>
                        <a href="#mobile-advertising">
                          Advertising on mobile devices
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#dnt">Do not track (DNT)</a>
                  </li>
                  <li>
                    <a href="#social-networks">
                      Connecting via social networks
                    </a>
                  </li>
                  <li>
                    <a href="#third-party-services">
                      Links and connections to third-party services
                    </a>
                  </li>
                  <li>
                    <a href="#children-privacy">Children’s privacy</a>
                  </li>
                  <li>
                    <a href="#contact-us">How to contact us</a>
                  </li>
                </ul>
                <h2 id="personal-information">Personal information</h2>
                <p>
                  Thomson Reuters is committed to the responsible handling and
                  protection of personal information.
                </p>
                <p>
                  Personal information means any information relating to an
                  identified or identifiable natural person; an identifiable
                  person is one who can be identified, directly or indirectly,
                  in particular by reference to an identifier such as a name, an
                  identification number, location data, online identifier or to
                  one or more factors specific to the physical, physiological,
                  genetic, mental, economic, cultural, or social identity of
                  that person.
                </p>
                <p>
                  We collect, use, disclose, transfer, and store personal
                  information when needed to provide our Services and for our
                  operational and business purposes as described in this
                  Statement. We want to be clear about our privacy practices so
                  that you can make informed choices about the use of your
                  information, and we encourage you to{" "}
                  <a href="#contact-us">contact us</a> at any time with
                  questions or concerns.
                </p>
                <div id="personal-information-collected"> </div>
                <h3>The types of personal information we collect</h3>
                <p>
                  We collect personal information from you, for example, if you
                  register for an event, request information, purchase or use
                  our Services, or request customer support. We may ask you to
                  provide information such as your name, address, phone number,
                  email address, user name and password, and information about
                  your device. Not all of the personal information Thomson
                  Reuters holds about you will always come directly from you. It
                  may, for example, come from your employer, other organizations
                  to which you belong, or a professional service provider such
                  as your tax or accounting professional or attorney, if they
                  use our Services. We also collect personal information from
                  third parties such as our partners, service providers, and
                  publicly available websites, to offer Services we think may be
                  of interest and to help us maintain data accuracy and provide
                  and enhance the Services.
                </p>
                <p>
                  In addition, our servers, logs, and other technologies{" "}
                  <a href="#other-tracking-tech">
                    automatically collect certain information
                  </a>{" "}
                  to help us administer, protect, and improve our Services;
                  analyze usage; and improve users’ experience. We share
                  personal information with others only as described in this
                  Statement, or when we believe that the law permits or requires
                  it.
                </p>
                <p>
                  Occasionally we collect and process what may be considered
                  sensitive personal information.
                </p>
                <p>
                  Sensitive personal information is a subset of personal
                  information and is generally defined as any information
                  related to racial/ethnic origin, political opinions, religious
                  beliefs, trade union membership, physical or mental health,
                  other medical information including biometric and genetic
                  data, or sexual life or preferences. In some instances,
                  sensitive personal information may also include criminal
                  allegations or convictions, precise geolocation information,
                  financial and bank account numbers, or unique identifiers such
                  as government-issued social security numbers, driver’s
                  license, and passport numbers.
                </p>
                <p>
                  For example, if you purchase something from us or subscribe to
                  our Services, we will collect payment information, such as
                  financial or bank card information, and other information
                  necessary for us to process the transaction. If you or your
                  service professional uses our Tax &amp; Accounting Services,
                  we will collect and process financial and tax information.
                  Some of our Services will ask you to share your precise
                  geolocation so we can customize your experience and increase
                  the accuracy of the Service. We may also collect information
                  such as a government-issued identification number if you use
                  our eRecruitment Services to apply for employment. Information
                  that is considered sensitive under applicable law will be
                  handled in accordance with such laws.
                </p>
                <div id="how-personal-information-used" />
                <h3>How we use personal information</h3>
                <p>
                  We process personal information for these Service- and
                  business-related purposes:
                </p>
                <ul>
                  <li>
                    <strong>Account setup and administration:</strong> We use
                    personal information such as your name, email address, phone
                    number, and information about your device to set up and
                    administer your account, provide technical and customer
                    support and training, verify your identity, and send
                    important account, subscription, and Service information.
                  </li>
                  <li>
                    <strong>Personalization:</strong> We use personal
                    information to deliver and suggest tailored content such as
                    news, research, reports, and business information and to
                    personalize your experience with our Services. Some of our
                    Services will ask you to share your precise geolocation so
                    we can customize your experience and increase the accuracy
                    of the Service. If you agree to share your precise
                    geolocation with us, you will be able to turn it off at any
                    time by going to the privacy settings on your mobile device
                    or online.
                  </li>
                  <li>
                    <strong>Marketing and events: </strong> We use personal
                    information to deliver marketing and event communications to
                    you across various platforms, such as email, telephone, text
                    messaging, direct mail, and online. If we send you a
                    marketing email, it will include instructions on how to opt
                    out of receiving these emails in the future. We also
                    maintain email preference centers for you to manage your
                    information and marketing preferences. Please remember that
                    even if you opt out of receiving marketing emails, we may
                    still send you important Service information related to your
                    accounts and subscriptions.
                  </li>
                  <li>
                    <strong>Surveys and polls:</strong> If you choose to
                    participate in a survey or poll, any personal information
                    you provide may be used for marketing or market research
                    purposes.
                  </li>
                  <li>
                    <strong>Research and development:</strong> We use personal
                    information for internal research and development purposes
                    and to improve and test the features and functions of our
                    Services.
                  </li>
                  <li>
                    <strong>
                      Chat rooms, messaging, and community and event forums:
                    </strong>
                    A number of our Services provide features including chat
                    rooms, messaging services, and community and event forums
                    for collaboration, peer connection, games, and information
                    exchange purposes. Depending upon the Service, the personal
                    information you choose to post, share, upload, or make
                    available is public and visible to others who use those
                    Services. You should never post or share any information
                    that is confidential or about others unless you have
                    permission to do so. We may use information you provide in
                    community and event profiles and forums to personalize your
                    experience and to make content and peer connection
                    recommendations. These Services may have their own Terms of
                    Use and, where appropriate, their own privacy statements. We
                    moderate these Services for compliance with our Terms of
                    Use.
                  </li>
                  <li>
                    <strong>Hosted services:</strong> Some of our Services
                    provide data and document storage as an integral part of the
                    product or solution offering. Documents and data stored by
                    our customers may contain personal information in business
                    and personal tax forms, payroll and financial data, and
                    legal and litigation-related documents, for example. Any
                    information stored by or on behalf of our customers is
                    controlled and managed by and only made accessible to those
                    customers or others our customers may authorize from time to
                    time. Our access to this information is limited to Thomson
                    Reuters personnel with a critical business reason, such as
                    technical support. Please{" "}
                    <a href="#where-personal-information-stored">click here</a>{" "}
                    to learn more about where we process and store information.
                  </li>
                  <li>
                    <strong>Legal obligations:</strong> We may be required to
                    use and retain personal information for legal and compliance
                    reasons, such as the prevention, detection, or investigation
                    of a crime; loss prevention; or fraud. We may also use
                    personal information to meet our internal and external audit
                    requirements, information security purposes, and as we
                    otherwise believe to be necessary or appropriate: (a) under
                    applicable law, which may include laws outside your country
                    of residence; (b) to respond to requests from courts, law
                    enforcement agencies, regulatory agencies, and other public
                    and government authorities, which may include such
                    authorities outside your country of residence; (c) to
                    enforce our terms and conditions; and (d) to protect our
                    rights, privacy, safety, or property, or those of other
                    persons.
                  </li>
                </ul>
                <div id="when-personal-information-shared" />
                <h3>When we share personal information</h3>
                <p>
                  Thomson Reuters shares or discloses personal information when
                  necessary to provide Services or conduct our business
                  operations as described below. When we share personal
                  information, we do so in accordance with data privacy and
                  security requirements. We may occasionally share non-personal,
                  anonymized, and statistical data with third parties. Below are
                  the parties with whom we may share personal information and
                  why.
                </p>
                <ul>
                  <li>
                    <strong>Within Thomson Reuters:</strong> Our businesses
                    around the world are supported by a variety of Thomson
                    Reuters teams and functions, and personal information will
                    be made available to them if necessary for the provision of
                    Services, account administration, sales and marketing,
                    customer and technical support, and business and product
                    development, for instance. All of our employees and
                    contractors are required to follow our data privacy and
                    security policies when handling personal information. Please{" "}
                    <a href="#where-personal-information-stored">click here</a>{" "}
                    to see a list of Thomson Reuters locations where we may
                    process personal data.
                  </li>
                  <li>
                    <strong>Our business partners:</strong> We occasionally
                    partner with other organizations to deliver co-branded
                    Services, provide content, or to host events, conferences,
                    and seminars. As part of these arrangements, you may be a
                    customer of both Thomson Reuters and our partners, and we
                    and our partners may collect and share information about
                    you. Thomson Reuters will handle personal information in
                    accordance with this Statement, and we encourage you to
                    review the privacy statements of our partners to learn more
                    about how they collect, use, and share personal information.
                  </li>
                  <li>
                    <strong>Our third-party service providers:</strong> We
                    partner with and are supported by service providers around
                    the world. Personal information will be made available to
                    these parties only when necessary to fulfill the services
                    they provide to us, such as software, system, and platform
                    support; direct marketing services; cloud hosting services;
                    advertising; data analytics; and order fulfillment and
                    delivery. Our third-party service providers are not
                    permitted to share or use personal information we make
                    available to them for any other purpose than to provide
                    services to us.
                  </li>
                  <li>
                    <strong>Third parties for legal reasons:</strong> We will
                    share personal information when we believe it is required,
                    such as:
                    <ul>
                      <li>
                        To comply with legal obligations and respond to requests
                        from government agencies, including law enforcement and
                        other public authorities, which may include such
                        authorities outside your country of residence.
                      </li>
                      <li>
                        In the event of a merger, sale, restructure,
                        acquisition, joint venture, assignment, transfer, or
                        other disposition of all or any portion of our business,
                        assets, or stock (including in connection with any
                        bankruptcy or similar proceedings)
                      </li>
                      <li>
                        To protect our rights, users, systems, and Services.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <strong>E-recruitment partners:</strong> If you register as
                    a user of our e-recruitment Services in order to apply for
                    employment, your personal information, including any
                    sensitive personal information you provide, will be made
                    available to the organization to which you have applied. In
                    order to consider an application fully, your personal
                    information may be forwarded to an organization’s global
                    offices.
                  </li>
                </ul>
                <div id="where-personal-information-stored" />
                <h3>Where we store and process personal information</h3>
                <p>
                  Thomson Reuters is a global organization, and your personal
                  information may be stored and processed outside of your home
                  country. We take steps to ensure that the information we
                  collect is processed according to this Privacy Statement and
                  the requirements of applicable law wherever the data is
                  located.
                </p>
                <p>
                  Thomson Reuters has networks, databases, servers, systems,
                  support, and help desks located throughout our offices around
                  the world. We collaborate with third parties such as cloud
                  hosting services, suppliers, and technology support located
                  around the world to serve the needs of our business,
                  workforce, and customers. We take appropriate steps to ensure
                  that personal information is processed, secured, and
                  transferred according to applicable law. In some cases, we may
                  need to disclose or transfer your personal information within
                  Thomson Reuters or to third parties in areas outside of your
                  home country. The areas in which these recipients are located
                  will vary from time to time, but may include the United
                  States, Europe, Canada, Asia, Australia, India, and other
                  countries where Thomson Reuters has a presence or uses
                  contractors. For a list of Thomson Reuters offices, see the “<a href="#contact-us">
                    Contact Us
                  </a>” section below.
                </p>
                <p>
                  When we transfer personal information from the European
                  Economic Area to other countries in which applicable laws do
                  not offer the same level of data privacy protection as in your
                  home country, we take measures to provide an appropriate level
                  of data privacy protection. In other words, your rights and
                  protections remain with your data. For example, we use
                  approved contractual clauses, multiparty data transfer
                  agreements, intragroup agreements, and other measures designed
                  to ensure that the recipients of your personal information
                  protect it. If you would like to know more about our data
                  transfer practices, please contact our{" "}
                  <a href="#contact-us">Data Protection Officer</a>.
                </p>
                <div id="how-personal-information-secured" />
                <h3>How we secure personal information</h3>
                <p>
                  Thomson Reuters takes data security seriously, and we use
                  appropriate technologies and procedures to protect personal
                  information. Our information security policies and procedures
                  are closely aligned with widely accepted international
                  standards and are reviewed regularly and updated as necessary
                  to meet our business needs, changes in technology, and
                  regulatory requirements.
                </p>
                <p>For example:</p>
                <ul>
                  <li>
                    Policies and procedures
                    <ul>
                      <li>
                        We have measures in place to protect against accidental
                        loss and unauthorized access, use, destruction, or
                        disclosure of data
                      </li>
                      <li>
                        We have a Business Continuity and Disaster Recovery
                        strategy that is designed to safeguard the continuity of
                        our service to our clients and to protect our people and
                        assets
                      </li>
                      <li>
                        We place appropriate restrictions on access to personal
                        information
                      </li>
                      <li>
                        We implement appropriate measures and controls,
                        including monitoring and physical measures, to store and
                        transfer data securely
                      </li>
                      <li>
                        We conduct Privacy Impact Assessments in accordance with
                        legal requirements and our business policies
                      </li>
                    </ul>
                  </li>
                  <li>
                    Training for employees and contractors
                    <ul>
                      <li>
                        We require privacy, information security, and other
                        applicable training on a regular basis for our employees
                        and contractors who have access to personal information
                        and other sensitive data
                      </li>
                      <li>
                        We take steps to ensure that our employees and
                        contractors operate in accordance with our information
                        security policies and procedures and any applicable
                        contractual conditions
                      </li>
                    </ul>
                  </li>
                  <li>
                    Vendor risk management
                    <ul>
                      <li>
                        We require, through the use of contracts and security
                        reviews, our third-party vendors and providers to
                        protect any personal information with which they are
                        entrusted in accordance with our security policies and
                        procedures
                      </li>
                    </ul>
                  </li>
                </ul>
                <div id="time-personal-information-kept" />
                <h3>How long we keep personal information</h3>
                <p>
                  Thomson Reuters has a Records Management team that works in
                  conjunction with the Privacy Office to implement policies and
                  rules relating to the retention of personal information. We
                  have an Enterprise Records Retention Schedule that is based
                  upon a classification scheme consisting of Business Functions,
                  Record Classes, and Record Types. We retain personal
                  information for as long as we reasonably require it for legal
                  or business purposes. In determining data retention periods,
                  Thomson Reuters takes into consideration local laws,
                  contractual obligations, and the expectations and requirements
                  of our customers. When we no longer need personal information,
                  we securely delete or destroy it.
                </p>
                <div id="personal-information-access"> </div>
                <h3>
                  Your right to access and correct your personal information
                </h3>
                <p>
                  We respect your right to access and control your information,
                  and we will respond to requests for information and, where
                  applicable, will correct, amend, or delete your personal
                  information.
                </p>
                <ul>
                  <li>
                    <strong>Access to personal information:</strong> If you
                    request access to your personal information, we will gladly
                    comply, subject to any relevant legal requirements and
                    exemptions, including identity verification procedures.
                    Before providing data to you, we will ask for proof of
                    identity and sufficient information about your interaction
                    with us so that we can locate any relevant data. We may also
                    charge you a fee for providing you with a copy of your data
                    (except where this is not permissible under local law).
                  </li>
                  <li>
                    <strong>Correction and deletion:</strong> In some
                    jurisdictions, you have the right to correct or amend your
                    personal information if it is inaccurate or requires
                    updating. You may also have the right to request deletion of
                    your personal information; however, this is not always
                    possible due to legal requirements and other obligations and
                    factors. Remember that you can update your account
                    information by using the “Contact Us” option within the
                    relevant Service.
                  </li>
                  <li>
                    <strong>Marketing preferences:</strong> To opt out of email
                    marketing, you can use the unsubscribe link found in the
                    email communication you receive from us or visit the
                    applicable email preference center. For other marketing
                    preferences, you can use the “Contact Us” option within the
                    relevant Service. Information related to controlling cookies
                    can be found <a href="#cookies">here</a>, and information
                    related to Interest-Based and Mobile Advertising can be
                    found <a href="#advertising">here</a>.
                  </li>
                  <li>
                    <strong>Filing a complaint:</strong> If you are not
                    satisfied with how Thomson Reuters manages your personal
                    data, you have the right to make a complaint to a data
                    protection regulator. A list of National Data Protection
                    Authorities can be found here:{" "}
                    <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                      https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                    </a>.
                  </li>
                </ul>
                <p>
                  Please contact our{" "}
                  <a href="#contact-us">Data Protection Officer</a> with any
                  requests related to your personal information. For requests
                  related to PeopleMap, Public Records on Westlaw and CLEAR,
                  please{" "}
                  <a href="https://legalsolutions.thomsonreuters.com/law-products/about/legal-notices/privacy/records?CID=TRSite">
                    click here
                  </a>.
                </p>
                <div id="cookies" />
                <h2>Cookies and similar technologies</h2>
                <p>
                  Thomson Reuters and our third-party providers set and use
                  cookies and similar technologies to store and manage user
                  preferences, deliver targeted advertising, enable content, and
                  gather analytic and usage data, for example. The use of
                  cookies and other tracking technologies is standard across
                  websites and apps through which information is collected about
                  your online activities across applications, websites, or other
                  services. More information about how we use cookies and
                  similar technologies and{" "}
                  <a href="#managing-cookies">how you can control and manage</a>{" "}
                  them is below.
                </p>
                <div name="what-is-a-cookie" id="what-is-a-cookie" />
                <h3>What is a cookie?</h3>
                <p>
                  A cookie is a small text file that is placed on a computer or
                  other device and is used to identify the user or device and to
                  collect information. Cookies are typically assigned to one of
                  four categories, depending on their function and intended
                  purpose: absolutely necessary cookies, performance cookies,
                  functional cookies, and cookies for marketing purposes.
                </p>
                <div id="cookie-types" />
                <h3>Types of cookies and why we use them</h3>
                <ul>
                  <li>
                    <strong>Absolutely necessary cookies:</strong> These cookies
                    are essential to enable you to move around a website and use
                    its features. Without these cookies, services you have asked
                    for, like adding items to an online shopping cart, cannot be
                    provided.
                  </li>
                  <li>
                    <strong>Performance cookies:</strong> These cookies collect
                    information about how you use our websites. Information
                    collected includes, for example, the Internet browsers and
                    operating systems used, the domain name of the website
                    previously visited, the number of visits, average duration
                    of visit, and pages viewed. These cookies don’t collect
                    information that personally identifies you and only collect
                    aggregated and anonymous information. Performance cookies
                    are used to improve the user-friendliness of a website and
                    enhance your experience.
                  </li>
                  <li>
                    <strong>Functionality cookies:</strong> These cookies allow
                    the website to remember choices you make (such as your
                    username or ID, language preference, or the area or region
                    you are in) and provide enhanced, more personal features.
                    These cookies can also be used to remember changes you have
                    made to text size, fonts, and other customizable parts of
                    web pages. They may also be used to provide services you
                    have asked for, such as watching a video or commenting on a
                    blog. The information these cookies collect may be
                    anonymized, and they cannot track your browsing activity on
                    other websites.
                  </li>
                  <li>
                    <strong>Targeting and advertising cookies:</strong>
                    These cookies track browsing habits and are used to deliver
                    targeted (interest-based) advertising. They are also used to
                    limit the number of times you see an ad and to measure the
                    effectiveness of advertising campaigns. They are usually
                    placed by advertising networks with the website operator’s
                    permission. They remember that you have visited a website
                    and this information is shared with other organizations,
                    such as advertisers.
                  </li>
                </ul>
                <div id="managing-cookies" />
                <h3>Managing cookies</h3>
                <p>
                  You can manage website cookies in your browser settings, and
                  you always have the choice to change these settings by
                  accepting, rejecting, or deleting cookies. If you choose to
                  change your settings, you may find that certain functions and
                  features will not work as intended on the Services. All
                  browser settings are slightly different, so to manage cookies,
                  you should refer to the relevant settings within your browser.
                </p>
                <p>
                  We understand that you may want to know more about cookies.
                  Here are some useful resources that provide detailed
                  information about types of cookies, how they are used, and how
                  you can manage your cookie preferences:{" "}
                  <a href="https://www.aboutcookies.org">
                    www.aboutcookies.org
                  </a>
                  or
                  <a href="https://www.allaboutcookies.org">
                    www.allaboutcookies.org
                  </a>. Please click below for detailed information on how to
                  disable and delete cookies in some commonly used browsers:
                </p>
                <ul>
                  <li>
                    <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">
                      Mozilla Firefox®
                    </a>
                  </li>
                  <li>
                    <a href="https://windows.microsoft.com/en-us/internet-explorer/delete-manage-cookies#ie=ie-11">
                      Microsoft® Internet Explorer
                    </a>
                  </li>
                  <li>
                    <a href="https://support.google.com/accounts/answer/61416?hl=en">
                      Google Chrome™
                    </a>
                  </li>
                  <li>
                    <a href="https://support.apple.com/kb/PH17191?viewlocale=en_US&amp;locale=de_DE">
                      Safari®
                    </a>
                  </li>
                </ul>
                <div id="other-tracking-tech" />
                <h3>Other tracking technologies</h3>
                <ul>
                  <li>
                    <strong>Local shared objects/Flash cookies:</strong> Flash
                    cookies, also known as local shared objects, are designed to
                    support browser content supported by Adobe ® Flash. They are
                    usually used to enable ads and video content on websites.
                    Like other cookies, they will store information on your
                    device, some of which will be specific to the Flash-enabled
                    content. Flash cookies can only be deleted within Adobe
                    Flash rather than via your browser. Please refer to the
                    following help page for information on how to manage your
                    privacy settings and deletion of Flash cookies:{" "}
                    <a href="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                      https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html
                    </a>
                  </li>
                  <li>
                    <strong>Web beacons:</strong> Our web pages may contain
                    electronic images known as web beacons (also called
                    single-pixel gifs and transparent graphic images) that we
                    use to help deliver cookies on our sites, count users who
                    have visited those sites, deliver services, and analyze the
                    effectiveness of our promotional campaigns, for example. We
                    may also include web beacons in our marketing email messages
                    or newsletters to determine whether an email is opened or if
                    links are clicked.
                  </li>
                  <li>
                    <strong>Web server &amp; application logs:</strong> Our
                    servers automatically collect certain information to help us
                    administer and protect the Services, analyze usage, and
                    improve users’ experience. The information collected
                    includes:
                    <ul>
                      <li>IP address and browser type</li>
                      <li>
                        Device information including Unique Device Identifier
                        (UDID), MAC address, Identifier For Advertisers (IFA),
                        and similar identifiers we or others may assign
                      </li>
                      <li>Device operating system and other technical facts</li>
                      <li>
                        The city, state, and country from which you access our
                        website
                      </li>
                      <li>
                        Pages visited and content viewed, stored, and purchased
                      </li>
                      <li>Information or text entered</li>
                      <li>Links and buttons clicked </li>
                      <li>
                        URLs visited before and after you use our Services
                      </li>
                    </ul>
                  </li>
                </ul>
                <div id="advertising" />
                <h2>Interest-based advertising (IBA)</h2>
                <p>
                  Interest-based advertising (IBA) allows us to deliver targeted
                  advertising to users of our Services. IBA works by showing you
                  advertisements that are based on the type of content you
                  access or read. For example, as you browse our Services, one
                  of the cookies placed on your device will be an advertising
                  cookie so we can better understand what sort of pages or
                  content you are interested in. The information collected about
                  your device enables us to group you with other devices that
                  have shown similar interests. We can then display advertising
                  to categories of users that is based on common interests. For
                  more information about IBA, please visit:{" "}
                  <a href="https://www.iab.net/public_policy/behavioral-advertisingprinciples">
                    https://www.iab.net/public_policy/behavioral-advertisingprinciples
                  </a>.
                </p>
                <div id="opting-out-iba"> </div>
                <h3>Opting out of IBA</h3>
                <p>
                  If you want to opt out of receiving interest-based
                  advertising, it does not mean that you will no longer receive
                  advertising when you are using our Services. It just means
                  that we will not use information collected about you for IBA
                  and that any advertising you see will not be customized or
                  relevant to you. You can exercise your online advertising
                  choices at{" "}
                  <a href="https://optout.aboutads.info">
                    https://optout.aboutads.info
                  </a>
                  or by clicking the AdChoices icon in an ad and following the
                  instructions. You&nbsp;may also opt out of receiving
                  interest-based ads from many sites through the Network
                  Advertising Initiative’s (NAI) Opt Out Tool (<a href="https://www.networkadvertising.org/choices">
                    https://www.networkadvertising.org/choices
                  </a>) and in the EU at{" "}
                  <a href="https://www.youronlinechoices.com/">
                    https://www.youronlinechoices.com/
                  </a>. Remember, if you delete cookies, use a different device,
                  or change web browsers, you may need to opt out again.
                </p>
                <div id="mobile-advertising"> </div>
                <h3>Advertising on mobile devices</h3>
                <p>
                  Mobile devices have an identifier that gives companies the
                  ability to serve targeted ads to a specific mobile device. In
                  many cases, you can turn off mobile device ad tracking or you
                  can reset the advertising identifier at any time within your
                  mobile device privacy settings. Another tool you can use to
                  control advertising on your mobile device is the AppChoices
                  App:{" "}
                  <a href="https://youradchoices.com/appchoices">
                    https://youradchoices.com/appchoices
                  </a>. You may also choose to turn off location tracking on
                  your mobile device. If you turn off ad tracking or location
                  tracking, we will no longer use information collected from
                  your device’s advertising identifier for the purposes of
                  advertising. You may still see the same number of ads but they
                  may be less relevant because they will not be based on your
                  interests.
                </p>
                <h2 id="dnt">Do not track</h2>
                <p>
                  Some browsers transmit Do Not Track (DNT) signals to websites.
                  Due to the lack of a common interpretation of DNT signals
                  throughout the industry, Thomson Reuters does not currently
                  alter, change, or respond to DNT requests or signals from
                  these browsers. We will continue to monitor industry activity
                  in this area and reassess our DNT practices as necessary.
                </p>
                <h2 id="social-networks">Connecting via social networks</h2>
                <p>
                  Some of our Services may include social networking features,
                  such as the&nbsp;Facebook® “Like” button and widgets, “Share”
                  buttons, and interactive mini-programs. Additionally, you may
                  choose to use your own social networking logins from, for
                  example, Facebook or LinkedIn®, to log into some of our
                  Services. If you choose to connect using a social networking
                  or similar service, we may receive and store authentication
                  information from that service to enable you to log in and
                  other information that you may choose to share when you
                  connect with these services. These services may collect
                  information such as the web pages you visited and IP
                  addresses, and may set cookies to enable features to function
                  properly. We are not responsible for the security or privacy
                  of any information collected by these third parties. You
                  should review the privacy statements or policies applicable to
                  the third-party services you connect to, use, or access. If
                  you do not want your personal information shared with your
                  social media account provider or other users of the social
                  media service, please do not connect your social media account
                  with your account for the Services and do not participate in
                  social sharing on the Services.
                </p>
                <h2 id="third-party-services">
                  Links and connections to third-party services
                </h2>
                <p>
                  Our Services may contain links to and may be used by you in
                  conjunction with third-party apps, services, tools, and
                  websites that are not affiliated with, controlled, or managed
                  by us. Examples include Facebook, LinkedIn, Twitter® and,
                  third-party apps like voice software and readers. The privacy
                  practices of these third parties will be governed by the
                  parties’ own Privacy Statements. We are not responsible for
                  the security or privacy of any information collected by these
                  third parties. You should review the privacy statements or
                  policies applicable to these third-party services.
                </p>
                <h2 id="children-privacy">Children’s privacy</h2>
                <p>
                  Thomson Reuters provides information solutions for
                  professionals, and our Services are generally not aimed at
                  children. If, however, we collect and use information about
                  children, such as to develop an educational resource, we will
                  comply with industry guidelines and applicable laws.
                </p>
                <h2 id="contact-us">How to contact us</h2>
                <p>
                  We understand that you may have questions or concerns about
                  this Statement or our privacy practices or may wish to file a
                  complaint. Please feel free to contact us in one of the
                  following ways:
                </p>
                <h3>Thomson Reuters Data Protection Officer:</h3>
              </RichText>
              <MarkupContainer>
                <HorizontalOverflow>
                  <Table>
                    <table>
                      <tbody>
                        <tr>
                          <td>Email:</td>
                        </tr>
                        <tr>
                          <td>
                            <a href="mailto:Privacy.enquiries@thomsonreuters.com">
                              Privacy.enquiries@thomsonreuters.com
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Address:</td>
                        </tr>
                        <tr>
                          <td>
                            Attn: Data Protection Officer
                            <br /> Thomson Reuters
                            <br /> D5 S1866
                            <br /> 610 Opperman Dr.
                            <br /> Eagan, MN 55123
                            <br /> USA
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Table>
                </HorizontalOverflow>
              </MarkupContainer>
              <RichText>
                <h3>Marketing preferences and services support:</h3>
                <p>
                  To update your account information or email marketing
                  preferences, or for technical support or other help with your
                  Services, please visit the applicable email preference center
                  or use the “Contact Us” option for the relevant Service.
                </p>
                <p>
                  <a href="https://www.thomsonreuters.com/en/locations.html">
                    See a list of Thomson Reuters locations
                  </a>
                </p>
              </RichText>
            </Grid.Item>
          </Grid>
        </Section>
      </SemanticMain>
      <SampleFooter />
    </div>
  );
};

export default PrivacyEn;
