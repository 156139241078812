import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";

import PropTypes from "prop-types";
import renderBrowser from "@emcm-ui/bundle-emcm";
import config from "@emcm-ui/utility-configuration";

class StaticRenderer extends Component {
  static propTypes = {
    /**
     * React elements, to be rendered to static and rehydrated with react-from-markup
     */
    children: PropTypes.node,

    /**
     * Should each child be separated into separate markup containers?
     */
    separateContainers: PropTypes.bool,

    /**
     * Should children be rendered without `data-react-from-markup-container`?
     */
    withoutMarkupContainerAttribute: PropTypes.bool
  };

  static defaultProps = {
    separateContainers: true
  };

  static getDerivedStateFromProps(props) {
    if (props.separateContainers) {
      return {
        markup: React.Children.map(props.children, child =>
          ReactDOMServer.renderToStaticMarkup(child)
        )
      };
    }

    return {
      markup: [ReactDOMServer.renderToStaticMarkup(props.children)]
    };
  }

  ref = React.createRef();

  state = {
    markup: ""
  };

  rehydrate() {
    // Support for namespacing. Find all elements in this StaticRenderer that
    // have data-rehydratable, and prefix the value with the namespace from
    // our config.
    const rehydratables = Array.from(
      this.ref.current.querySelectorAll("[data-rehydratable]")
    );
    const prefix = `${config.current.styles.namespace}-`;

    for (const rehydratable of rehydratables) {
      const rehydratableName = rehydratable.getAttribute("data-rehydratable");

      if (rehydratableName.substr(0, prefix.length) !== prefix) {
        rehydratable.setAttribute(
          "data-rehydratable",
          `${prefix}${rehydratableName}`
        );
      }
    }

    renderBrowser();
  }

  componentDidMount() {
    this.rehydrate();
  }

  componentDidUpdate() {
    this.rehydrate();
  }

  render() {
    const { withoutMarkupContainerAttribute } = this.props;
    const { markup } = this.state;

    return (
      <div ref={this.ref}>
        {markup.map(
          (__html, i) =>
            withoutMarkupContainerAttribute ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: `${__html}`
                }}
                key={i}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: `<div data-react-from-markup-container>${__html}</div>`
                }}
                key={i}
              />
            )
        )}
      </div>
    );
  }
}

export default StaticRenderer;
