/**
 * This component provides an abstracted method of providing AjaxContent with a HREF to load using React Context
 * This allows the component outputting the HREF to be unaware of the AjaxContent's location it the DOM
 */

import React from "react";
import PropTypes from "prop-types";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import getRehydratableName from "@emcm-ui/utility-rehydratable-name";

const AjaxContentContext = React.createContext(false);

class AjaxContentContainer extends React.Component {
  static displayName = "AjaxContentContainer";

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ])
  };

  state = {
    href: ""
  };

  getClassName = getClassNameFactory(AjaxContentContainer.displayName);

  updateHref = href => {
    this.setState({
      href
    });
  };

  render() {
    return (
      <div
        data-rehydratable={getRehydratableName(
          AjaxContentContainer.displayName
        )}
      >
        <AjaxContentContext.Provider
          value={{
            href: this.state.href,
            updateHref: this.updateHref
          }}
        >
          {this.props.children}
        </AjaxContentContext.Provider>
      </div>
    );
  }
}

export default AjaxContentContainer;
export { AjaxContentContainer, AjaxContentContext };
