import React from "react";
import { storiesOf } from "@storybook/react";
import { withKnobs, text, select } from "@storybook/addon-knobs";
import withReadme from "storybook-readme/with-readme";
import StaticRenderer from "@emcm-ui/component-static-renderer";
import Recaptcha from "./index";
import "./Recaptcha.css";
import RecaptchaREADME from "../README.md";
import Form from "@emcm-ui/component-form";
import FormsNewStyle from "@emcm-ui/component-forms-new-style";
import Button from "@emcm-ui/component-button";

const stories = storiesOf("Components/Recaptcha", module);

stories.addDecorator(withKnobs);
stories.addDecorator(withReadme(RecaptchaREADME));

const colorOptions = Button.colorOptions;

const actions = {
  "https://jsonplaceholder.typicode.com/todos": "Succeed inline"
};

stories
  .add("Default", () => (
    <Recaptcha
      label={text("Label", "TEST Label")}
      captchaId={text("Captcha ID", "1")}
      apiKey={text("API Key", "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")}
      validationMessage={text("Validation Message", "Captcha is required")}
    />
  ))
  .add("Captcha inside the Form", () => (
    <React.Fragment>
      <Form
        action={select("Action", actions, Object.keys(actions)[0])}
        submitButtonColor={select("Color", colorOptions, colorOptions[0])}
        submitButtonText={text("submitButtonText", "Submit")}
      >
        <Recaptcha
          label={text("Label", "TEST Label")}
          captchaId={text("Captcha ID", "4")}
          apiKey={text("API Key", "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")}
          validationMessage={text("Validation Message", "Captcha is required")}
        />
      </Form>
    </React.Fragment>
  ))
  .add("Captcha inside the FormsNewStyle", () => (
    <React.Fragment>
      <FormsNewStyle
        action={select("Action", actions, Object.keys(actions)[0])}
        submitButtonColor={select("Color", colorOptions, colorOptions[0])}
        submitButtonText={text("submitButtonText", "Submit")}
      >
        <Recaptcha
          label={text("Label", "TEST Label")}
          captchaId={text("Captcha ID", "4")}
          apiKey={text("API Key", "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")}
          validationMessage={text("Validation Message", "Captcha is required")}
        />
      </FormsNewStyle>
    </React.Fragment>
  ))
  .add("Rehydrated", () => (
    <StaticRenderer>
      <Recaptcha
        label={text("Label", "TEST Label")}
        captchaId={text("Captcha ID", "4")}
        apiKey={text("API Key", "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")}
        validationMessage={text("Validation Message", "Captcha is required")}
      />
    </StaticRenderer>
  ))
  .add("Captcha inside the Rehydrated Form", () => (
    <StaticRenderer>
      <Form
        action={select("Action", actions, Object.keys(actions)[0])}
        submitButtonColor={select("Color", colorOptions, colorOptions[0])}
        submitButtonText={text("submitButtonText", "Submit")}
      >
        <Recaptcha
          label={text("Label", "TEST Label")}
          captchaId={text("Captcha ID", "4")}
          apiKey={text("API Key", "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")}
          validationMessage={text("Validation Message", "Captcha is required")}
        />
      </Form>
    </StaticRenderer>
  ))
  .add("Captcha inside the Rehydrated FormsNewStyle", () => (
    <StaticRenderer>
      <FormsNewStyle
        action={select("Action", actions, Object.keys(actions)[0])}
        submitButtonColor={select("Color", colorOptions, colorOptions[0])}
        submitButtonText={text("submitButtonText", "Submit")}
      >
        <Recaptcha
          label={text("Label", "TEST Label")}
          captchaId={text("Captcha ID", "4")}
          apiKey={text("API Key", "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI")}
          validationMessage={text("Validation Message", "Captcha is required")}
        />
      </FormsNewStyle>
    </StaticRenderer>
  ));
