import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import classNames from "classnames";
import { SVGIcon } from "@emcm-ui/component-icon/lib/svg";

const NavItem = ({
  ariaLabel,
  borderlessAtStandard,
  direction,
  disabled,
  href,
  onClick,
  displayNavigationButtonLabels,
  ...rest
}) => {
  const getClassName = getClassNameFactory(NavItem.displayName);

  let ItemInnerName = "span";
  const itemInnerProps = {};

  if (!disabled) {
    if (href) {
      ItemInnerName = "a";
      itemInnerProps.href = href;
    } else if (onClick) {
      ItemInnerName = "button";
      itemInnerProps.onClick = onClick;
    }
  }

  const displayLeftElm = displayNavigationButtonLabels ? (
    ariaLabel
  ) : (
    <span className={getClassName({ descendantName: "icon" })}>
      <SVGIcon name="arrow" style={{ transform: "rotate(90deg)" }} size="s" />
    </span>
  );

  const displayLeftElmDisabled = displayNavigationButtonLabels ? (
    ariaLabel
  ) : (
    <span className={getClassName({ descendantName: "icon" })}>
      <SVGIcon name="arrow" style={{ transform: "rotate(90deg)" }} size="s" />
    </span>
  );

  const displayRightElm = displayNavigationButtonLabels ? (
    ariaLabel
  ) : (
    <span className={getClassName({ descendantName: "icon" })}>
      <SVGIcon name="arrow" style={{ transform: "rotate(270deg)" }} size="s" />
    </span>
  );

  const displayRightElmDisabled = displayNavigationButtonLabels ? (
    ariaLabel
  ) : (
    <span className={getClassName({ descendantName: "icon" })}>
      <SVGIcon name="arrow" style={{ transform: "rotate(270deg)" }} size="s" />
    </span>
  );

  return (
    <li
      className={getClassName({
        modifiers: classNames({ borderlessAtStandard, [direction]: true }),
        states: classNames({ disabled })
      })}
    >
      <ItemInnerName
        aria-label={ariaLabel}
        className={getClassName({
          descendantName: "inner",
          states: classNames({ disabled })
        })}
        {...itemInnerProps}
        {...rest}
      >
        {direction === "left" && disabled === false ? displayLeftElm : null}
        {direction === "left" && disabled === true
          ? displayLeftElmDisabled
          : null}

        {direction === "right" && disabled === false ? displayRightElm : null}
        {direction === "right" && disabled === true
          ? displayRightElmDisabled
          : null}
      </ItemInnerName>
    </li>
  );
};

NavItem.displayName = "Pagination.NavItem";

/* eslint-disable max-len */
NavItem.propTypes = {
  /**
   * Hide border in the standard view
   */
  borderlessAtStandard: PropTypes.bool,

  /**
   * Direction of the nav item. Left or right.
   */
  direction: PropTypes.oneOf(["left", "right"]).isRequired,

  /**
   * Flag the page as disabled. Will disable the link.
   */
  disabled: PropTypes.bool,

  /**
   * Page URL.
   */
  href: PropTypes.string,

  /**
   * On click event handler.
   */
  onClick: PropTypes.func,

  /**
   * Aria label for screen reader
   */
  ariaLabel: PropTypes.string.isRequired,

  /**
   * Whether Previous & Next button labels are visible
   */
  displayNavigationButtonLabels: PropTypes.bool
};
/* eslint-enable max-len */

NavItem.defaultProps = {
  disabled: false
};

export default NavItem;
