import fetchMock from "fetch-mock";

const fetchMockDecorator = story => {
  // Unmock fetch
  fetchMock.restore();

  return story();
};

export default fetchMockDecorator;
