import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";

const TextControl = ({
  defaultValue,
  disabled,
  hashableIdentifier,
  id,
  labelText,
  name,
  onChange,
  required,
  type,
  value
}) => {
  const getClassName = getClassNameFactory(TextControl.displayName);

  const attributes = {};

  if (hashableIdentifier) {
    attributes["data-hashable-identifier"] = hashableIdentifier;
  }

  return (
    <div className={getClassName()}>
      <label className={getClassName({ descendantName: "label" })} htmlFor={id}>
        <span className={getClassName({ descendantName: "labelText" })}>
          {labelText}{" "}
          {!required && (
            <span className={getClassName({ descendantName: "optional" })}>
              (Optional)
            </span>
          )}
        </span>
        <input
          className={getClassName({ descendantName: "input" })}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onChange}
          required={required}
          type={type}
          value={value}
          {...attributes}
        />
      </label>
    </div>
  );
};

TextControl.displayName = "Form.TextControl";

TextControl.types = ["email", "text", "url", "tel"];

TextControl.propTypes = {
  /**
   * Initialize <input> with a value
   */
  defaultValue: PropTypes.string,
  /**
   * Disable the <input>
   */
  disabled: PropTypes.bool,
  /**
   * Mark this field as a hashable identifier - a value which, when hashed, can anonymously identify a user.
   *
   * This field will be picked up and hashed before being put in the data layer.
   */
  hashableIdentifier: PropTypes.bool,
  /**
   * Id attribute for <input> (and <label>'s `for` attribute)
   */
  id: PropTypes.string.isRequired,
  /**
   * Text for <label>
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Name attribute for <input>, required for serialisation
   */
  name: PropTypes.string.isRequired,
  /**
   * Listen for changes on the <input>.
   */
  onChange: PropTypes.func,
  /**
   * Set <input> to be required
   */
  required: PropTypes.bool,
  /**
   * Type of <input>
   */
  type: PropTypes.oneOf(TextControl.types).isRequired,
  /**
   * Explicitly set the value of the <input>. Will switch to controlled mode
   */
  value: PropTypes.string
};

TextControl.defaultProps = { type: "text" };

export default TextControl;
