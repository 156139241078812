import PropTypes from "prop-types";
import React from "react";
import getClassNameFactory from "@emcm-ui/utility-class-names";
import SearchInput from "@emcm-ui/component-search-input";

const SearchForm = ({ action, method, searchInput, suggestion }) => {
  const getClassName = getClassNameFactory(SearchForm.displayName);

  return (
    <form className={getClassName()} action={action} method={method}>
      <div className={getClassName({ descendantName: "input" })}>
        <SearchInput {...searchInput} />
      </div>
      {suggestion ? (
        <div className={getClassName({ descendantName: "suggestion" })}>
          Search instead for <a href={suggestion.href}>{suggestion.text}</a>
        </div>
      ) : null}
    </form>
  );
};

SearchForm.displayName = "SearchForm";

SearchForm.methods = ["get", "post"];

SearchForm.propTypes = {
  /**
   * Form action attribute value
   */
  action: PropTypes.string.isRequired,
  /**
   * Form method attribute
   */
  method: PropTypes.oneOf(SearchForm.methods),
  /**
   * Optional config object to initialise SearchInput component
   */
  searchInput: PropTypes.object,
  /**
   * Optional object containing alternative search suggestion, link href and text
   */
  suggestion: PropTypes.object
};

SearchForm.defaultProps = {
  action: "",
  method: "get",
  searchInput: {
    name: "q",
    typeahead: true,
    typeaheadUrl: "",
    value: ""
  }
};

export default SearchForm;
