import React from "react";
import MediaQuery from "./MediaQuery";

export default async (domNode, rehydrateChildren) => {
  const childrenNode = domNode.querySelector("[data-rehydratable-children]");
  const reactifiedChildren = await rehydrateChildren(childrenNode);

  const props = {
    breakpoint: JSON.parse(domNode.getAttribute(`data-breakpoint`))
  };

  return <MediaQuery {...props}>{reactifiedChildren}</MediaQuery>;
};
