import mountScript from "@emcm-ui/utility-mount-script";
import { YOUTUBE_SCRIPT_URL } from "./config";

export default async () => {
  if (window.YT) {
    return window.YT;
  }

  const existingListener = window.onYouTubeIframeAPIReady;

  const youTubePromise = new Promise(resolve => {
    window.onYouTubeIframeAPIReady = (...extra) => {
      resolve(window.YT);

      window.onYouTubeIframeAPIReady = existingListener;

      if (window.onYouTubeIframeAPIReady) {
        window.onYouTubeIframeAPIReady(...extra);
      }
    };
  });

  try {
    await mountScript(YOUTUBE_SCRIPT_URL);
  } catch (e) {
    const youTubeException = new Error("Unable to load the YouTube API.", e);

    youTubeException.name = "YouTubeLoadError";

    throw youTubeException;
  }

  return youTubePromise;
};
